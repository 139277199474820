import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import '@fullcalendar/bootstrap/main.css';
import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import {Col, FormGroup, Glyphicon, Grid, Row, Panel, Button, FormControl, ControlLabel} from "react-bootstrap";
import * as actions from "./SchedulerApi"
import createApiService from "../../common/clientActionsBuilder";
import Filter from "./Filter";
import moment from "moment"
import $ from "jquery";
import SchedulerEventDialog from "./SchedulerEventDialog";
import {mapEvents, reverseColor, schedulerEventStatuses} from "../../common/commonHandlers";
import WorkOrders from "./WorkOrders";
import {debounce} from "throttle-debounce";
import {Link} from "react-router";
import Datetime from "react-datetime";
import FilterModal from "./FilterModal";
import WorkOrdersFilterModal from "./WorkOrdersFilterModal";
import EmailDialog from "../../components/email/EmailDialog";
import "./Scheduler.scss";
import DayHeader from "./Components/DayHeader";
import EventContent from "./Components/EventContent";
import ResourceComponent from "../../components/ResourceComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAngleLeft,
    faAngleRight,
    faArrowAltCircleLeft,
    faArrowCircleRight,
    faArrowRight, faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import NewFilterModal from './SchedulerNewFilterModal'
import RateModal from "../MapViewPage/new/RateModal";

let schedulerEventsActions = createApiService('scheduler_events', 'scheduler_event', 'Scheduler events');
let workOrderActions = createApiService('work_orders', 'work_order', 'Work order');
const api = {
    ...schedulerEventsActions,
    loadWorkOrders: workOrderActions.load,
    ...actions
};
const screen32 = window.innerWidth >= 1024 && window.innerHeight <= 600;
const height920 = window.innerWidth >= 1024 && window.innerHeight > 920;
const height1175 = window.innerWidth >= 1024 && window.innerHeight > 1175;
const smMobile = window.screen.width <= 450;
const heightProportion = ((window.innerWidth > 767) && (window.innerWidth < 1169)) ? 0.75 : 0.78
const width1400 = window.screen.width <= 1400
const width1175 = window.screen.width <= 1175

class Scheduler extends ResourceComponent {
    calendarComponentRef = React.createRef();
    state = {
        events: [], holidays: [], workOrders: [], loaded: false, birthdays: [], startDates: [],
        showModal: false, event: null, midDate: moment(),
        workOrdersFilter: {
            eq: ">",
            status: ['IN_WORK_ORDER']
        },
        filters: null,
        employees: [],
        filterVisible: false,
        date: moment(),
        workOrdersFilterModal: false,
        calendarVisible: false,
        newSchedulerView: true,
        emailDialogShow: false,
        serviceTypes: [],
        services: [],
        priorities: [],
        equipment: [],
        customerSearchResults: [],
        workOrderSearchResults: [],
        citySearchResults: [],
        zipSearchResults: [],
        proposalServiceStatuses: [],
        ready: false,
        workOrdersLoaded: false,
        proposalInfo: {},
        fullSizeFilterPanel: true,
        fullSizeWOPanel: true,
        resourceSearch: "",
        resource: {},
        isNewFilterModalOpened: false,
        isEmployeeFilterOpened: true,
        isTypeFilterOpened: true,
        isColorFilterOpened: true,
        isStatusesFilterOpened: true
    };

    period = {dayGridMonth: 'months', timeGridWeek: 'weeks', timeGridDay: 'days'};

    constructor(props) {
        super(props);
        this.delayedViewRender = debounce(500, this.viewRender);
        this.delayedSearch = debounce(1000, this.loadEvents);
    }

    componentDidMount = () => {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        const {clientId} = this.props;
        this.loadWorkOrders();
        this.props.actions.loadContextData(clientId, data => {
            data.employees.forEach(e => {
                if (!data.filters.users.map(e => e.label).includes(e.label)) {
                    let user = e;
                    e.selected = true;
                    data.filters.users.push(user);
                }
            });

            this.props.actions.saveFilter(data.filters, () => this.setState({...data, ready: true}))
        });
        document.addEventListener('mousedown', this.handleClickDatepicker);

        this.props.actions.loadFilterData(data => {
            this.setState(data);
        });
    };

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickDatepicker);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.newSchedulerView && nextProps.newSchedulerView === false) {
            this.componentDidMount();
        }
    }

    handleClickDatepicker = (e) => {
        const datePicker = document.getElementById('scheduler-date-picker');
        const calendarButton = document.getElementsByClassName('fc-calendar-button');
        if (datePicker && calendarButton && !datePicker.contains(e.target) && !calendarButton[0].contains(e.target)) {
            this.setState({calendarVisible: false})
        }
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.showTaskDialog === false && this.props.showTaskDialog === true) {
            this.reload();
        }
        return true;
    }

    reload = () => {
        this.loadEvents();
        this.loadWorkOrders();
    };

    loadWorkOrders = () => {
        const {workOrdersFilter} = this.state;
        let filter = {};
        if (workOrdersFilter.from) {
            filter.from = workOrdersFilter.from.format('YYYY-MM-DD');
        }
        if (workOrdersFilter.to) {
            filter.to = workOrdersFilter.to.format('YYYY-MM-DD');
        }
        if (workOrdersFilter.status && workOrdersFilter.status.length > 0) {
            filter.status = workOrdersFilter.status;
        }
        if (workOrdersFilter.serviceType && workOrdersFilter.serviceType.length > 0) {
            filter.serviceType = workOrdersFilter.serviceType;
        }
        if (workOrdersFilter.service && workOrdersFilter.service.length > 0) {
            filter.service = workOrdersFilter.service;
        }
        if (workOrdersFilter.priority && workOrdersFilter.priority.length > 0) {
            filter.priority = workOrdersFilter.priority;
        }
        if (workOrdersFilter.equipment && workOrdersFilter.equipment.length > 0) {
            filter.equipment = workOrdersFilter.equipment;
        }
        if (workOrdersFilter.customer && workOrdersFilter.customer.length > 0) {
            filter.customer = workOrdersFilter.customer;
        }
        if (workOrdersFilter.reason && workOrdersFilter.reason.length > 0) {
            filter.reason = workOrdersFilter.reason;
        }
        if (workOrdersFilter.city && workOrdersFilter.city.length > 0) {
            filter.city = workOrdersFilter.city;
        }
        if (workOrdersFilter.zip && workOrdersFilter.zip.length > 0) {
            filter.zip = workOrdersFilter.zip;
        }
        if (workOrdersFilter.workOrder && workOrdersFilter.workOrder.length > 0) {
            filter.workOrder = workOrdersFilter.workOrder;
        }
        const compare = (limit, eq, value) => {
            if (limit) {
                limit = parseFloat(limit);
                if (eq === '=') {
                    return value === limit;
                } else if (eq === ">") {
                    return value > limit;
                } else if (eq === "<") {
                    return value < limit;
                }
            }
            return true;
        };

        this.props.actions.loadWorkOrders(filter, workOrders => {
            workOrders = workOrders
                .filter(x => compare(workOrdersFilter.price, workOrdersFilter.eq, x.service_price))
                .filter(x => compare(workOrdersFilter.hours, workOrdersFilter.eq, x.service_hours));
            this.setState({workOrders, loaded: true, workOrdersLoaded: true}, () => {
            });
        });
    };

    toggleFilter = (key) => {
        this.setState({[key]: !this.state[key]})
    }

    loadEvents = () => {
        const {employee_id, roles} = this.props;
        let {resource} = this.state;

        let employeeStartDates = [];
        let crewRoleEvents = []

        this.props.actions.loadEventsData({
            from: this.state.start.format(),
            to: this.state.end.format(),
            q: resource.q
        }, response => {
            let events = response.events.map(mapEvents)
            if (response && response.startDates && response.startDates.length > 0) {
                if (roles.includes("admin") || roles.includes("scheduler") || roles.includes("sales_arborist")) {
                    employeeStartDates = response.startDates.map(e => ({
                        id: e.id,
                        person_id: e.person_id,
                        name: e.name,
                        date: e.date
                    }))
                } else {
                    const employeeCrewEvent = response.startDates.find(e => e.id === employee_id)

                    if (employeeCrewEvent) {
                        crewRoleEvents.push(employeeCrewEvent)

                        employeeStartDates = crewRoleEvents.map(e => ({
                            id: e.id,
                            person_id: e.person_id,
                            name: e.name,
                            date: e.date
                        }))
                    }
                }
            }


            response && response.holidays && response.holidays.length > 0 && response.holidays.map(r => {
                r.date = moment(r.date).format('YYYY-MM-DD')
            })

            this.setState({
                events: events,
                holidays: response.holidays,
                birthdays: response.birthdays,
                startDates: employeeStartDates,
            });
        });
    };

    emailDialogShow = () => {
        this.setState({emailDialogShow: !this.state.emailDialogShow})
    }

    handleEvents = (events) => {
        const {filters} = this.state;
        const duplicatedEvents = events.filter((v, i, a) =>
            a.findIndex(t => (t.id === v.id && t.extendedProps.person_id === v.extendedProps.person_id)) !== i
        )
        if (duplicatedEvents.length) {
            duplicatedEvents.map(evt => evt.remove())
        }
        if (filters.defaultView !== 'dayGridMonth') {
            this.setEventsOrder(events.filter(e => !e.allDay))
        }
    }

    handleChange = e => {
        const {events} = this.state;
        let event = e.event.extendedProps
        let date_from = e.event.start
        let date_to = e.event.end ? e.event.end : moment(e.event.start).add(1, 'hour')
        if (e.event.allDay) {
            date_from = moment(e.event.start).utc().format();
            date_to = e.event.end ? moment(e.event.end).utc().format() : moment(e.event.start).add(1, 'day').utc().format();
        }
        let schedulerEvent = {
            id: e.event.id,
            date_from: date_from,
            all_day: e.event.allDay,
            date_to: date_to,
            scheduler_event_resource_id: event.scheduler_event_resource_id
        };

        const existing = events.find(el => +el.id === +e.event.id);
        existing.start = date_from;
        existing.end = date_to
        existing.all_day = e.event.allDay;
        existing.allDay = e.event.allDay;
        this.setState({events}, () =>
            this.props.actions.save(schedulerEvent, () => {
                this.loadEvents();
            }));

    };

    handleClick = ev => {
        const event = (ev.event && ev.event.id) ? this.state.events.find(e => e.id === parseInt(ev.event.id)) : ev
        this.setState({showModal: true, event})
    };

    viewRender = () => {
        const {view, filters, client} = this.state;
        const differentStart = !moment(view.activeStart).isSame(this.state.start);
        const differentEnd = !moment(view.activeEnd).isSame(this.state.end);
        if ((differentStart || differentEnd)) {
            this.setState({events: []});
            filters.defaultView = view.type;
            this.props.actions.saveFilter(filters, () => {
                this.setState({
                    start: moment(view.activeStart),
                    end: moment(view.activeEnd),
                    filters: filters
                }, this.loadEvents)
            });
        }
    };

    updateDate(date) {
        const {filters} = this.state;
        filters.date = date;
        let calendarApi = this.calendarComponentRef.current.getApi();
        calendarApi.gotoDate(date.format());
        this.setState({
            scroll: true,
            filters,
            midDate: date,
            employees: filters.users,
        }, this.delayedViewRender);
    }

    toggleCalendar = () => {
        const {calendarVisible} = this.state;
        this.setState({calendarVisible: !calendarVisible})
    }

    resize = () => {
        if (window.innerWidth !== this.state.oldInnerWidth) {
            if (window.innerHeight < window.innerWidth) {
                this.setState({
                    oldInnerWidth: window.innerWidth,
                    calendarWidth: 8,
                    WOWidth: 4
                });
            } else {
                this.setState({
                    oldInnerWidth: window.innerWidth,
                    calendarWidth: 12,
                    WOWidth: 12
                });
            }
        }
    };

    calculateRowHeight = (event_id = null) => {
        let ready = false
        const rows = document.querySelectorAll(".fc-timegrid-slots  tbody > tr")
        const calendar = document.getElementsByClassName('fc-scroller fc-scroller-liquid-absolute')[0]
        if (calendar && rows.length > 0) {
            let calendarHeight = calendar.offsetHeight
            const rowHeight = (calendarHeight / 24)
            for (let i = 0; i < rows.length; i++) {
                rows[i].style.height = `${rowHeight}px`;
                ready = i === rows.length - 1
            }
        }
        if (ready && this.state.scroll) {
            const events = this.calendarComponentRef.current.getApi().getEvents()
            const currentIndex = events.findIndex(evt => evt.extendedProps.event_id === event_id)
            rows[16] && rows[16].scrollIntoView({
                behavior: 'smooth'
            });
            if (currentIndex === events.length - 1 || this.state.view.type === 'timeGridDay') {
                this.setState({scroll: false})
            }
        }
    }

    groupBy = (xs, f) => {
        return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
    };


    checkIfOverlapEvent = (e, event) => {
        let result
        if (!e.all_day && !event.allDay && e.backgroundColor === event.backgroundColor &&
            e.event_id !== event.extendedProps.event_id &&
            event.end && event.start) {
            const event1Start = new Date(e.start).getTime()
            const event1End = new Date(e.end).getTime()
            const event2Start = event.start.getTime()
            const event2End = event.end.getTime()
            result = ((event1Start >= event2Start && event1Start < event2End) ||
                (event2Start >= event1Start && event2Start < event1End))
        } else {
            result = false
        }
        return result
    }

    setEventsOrder = (apiEvents) => {
        for (let event of apiEvents) {
            const eventStartDay = event.start.getDate()

            const colorsPerDay = []
            let {events, filters} = this.state

            if (filters.users) {
                events = events.filter(e => filters.users.some(u => u.selected && u.value === e.person_id));
            }
            if (filters.statuses) {
                events = events.filter(e => filters.statuses.some(s => s.selected && s.label === e.status));
            }
            if (filters.schedulerEventTypes) {
                events = events.filter(e => filters.schedulerEventTypes.some(s => s.selected && s.label === e.scheduler_event_type_name));
            }

            events.map((e) => {
                if (colorsPerDay.indexOf(e.backgroundColor) === -1 &&
                    !e.all_day &&
                    (new Date(e.start).getDate() === eventStartDay)
                ) {
                    colorsPerDay.push(e.backgroundColor)
                }
            })

            let overlappingEvents = events.filter((v) => this.checkIfOverlapEvent(v, event))
            if (overlappingEvents.length > 0) {
                overlappingEvents.push(event)
                overlappingEvents = overlappingEvents.map(e => e.event_id ? e.event_id : e.extendedProps.event_id)
            }

            if (colorsPerDay.indexOf(event.backgroundColor) !== -1) {
                let position = (100 / colorsPerDay.length) * colorsPerDay.sort().indexOf(event.backgroundColor)
                let width = 100 / colorsPerDay.length;

                if (overlappingEvents.length > 0) {
                    width = width / (overlappingEvents.length)
                    position = position + ((100 / colorsPerDay.length) / overlappingEvents.length) * (overlappingEvents.sort().indexOf(event.extendedProps.event_id))
                }
                const elements = document.querySelectorAll(`[event_id="${event.extendedProps.event_id}"]`);

                elements.forEach(el => {
                    $(el).css('width', `${width}%`)
                    $(el).css('left', `${position}%`);
                })
            }
        }
    }

    renderSchedulerGrid = () => {
        const {newSchedulerView, fullSizeFilterPanel, fullSizeWOPanel} = this.state;
        if (newSchedulerView) {
            return 9;
        } else if (!newSchedulerView) {
            if (fullSizeFilterPanel && fullSizeWOPanel) {
                return 8;
            } else if (fullSizeFilterPanel && !fullSizeWOPanel) {
                return 10;
            } else if (fullSizeWOPanel && !fullSizeFilterPanel) {
                return 10;
            } else {
                return 12;
            }
        }
    }

    setResourceSearch = e => {
        this.setState({resourceSearch: e.target.value})
    }

    renderInputSearch = () => {
        const {newSchedulerView, resource} = this.state;

        if (!newSchedulerView) {
            const calendarBtn = document.querySelector(`.fc-toolbar-chunk`)
            let marginLeft
            if (calendarBtn) {
                marginLeft = calendarBtn && calendarBtn.offsetWidth + 10;
            }

            return (
                <FormControl
                    name="q"
                    placeholder="Search events"
                    className="classic-input-search"
                    style={{marginLeft: marginLeft}}
                    value={resource.q}
                    onChange={e => this.updateResourceAttr(e, this.delayedSearch)}
                />
            )
        }
    }

    render() {
        const isMobile = window.innerWidth < 992;
        let {
            workOrders, events, loaded, workOrdersLoaded, showModal, event, client, fullSizeFilterPanel, fullSizeWOPanel,
            filters, employees, schedulerEventTypes, filterVisible, midDate, calendarVisible, resource, searchValue, isNewFilterModalOpened,
            workOrdersFilterModal, newSchedulerView, ready, holidays, birthdays, proposalInfo, startDates, scroll, isEmployeeFilterOpened,
            isTypeFilterOpened, isColorFilterOpened, isStatusesFilterOpened
        } = this.state;
        const {emails} = this.props;
        const sortArray = []

        if (!newSchedulerView) {

            const schedulerHeader = document.querySelector(`.fc-toolbar-title`)

            if (schedulerHeader) {
                if (width1175) {
                    schedulerHeader.style.marginLeft = "160px";
                    schedulerHeader.style.fontSize = "18px";
                } else if (width1400) {
                    schedulerHeader.style.marginLeft = "160px";
                    schedulerHeader.style.fontSize = "20px";
                }
            }
        }

        if (filters) {
            events.forEach(event => {
                if (filters.color === 'employee') {
                    const employee = employees.find(u => u.value === event.person_id);
                    const color = employee ? (employee.color === "default" ? '#3a87ad' : employee.color) : '#3a87ad'
                    event.backgroundColor = color
                    event.borderColor = color
                    event.textColor = reverseColor(color)
                    if (sortArray.indexOf(event.person_id) === -1) {
                        sortArray.push(event.person_id)
                    }
                } else if (filters.color === 'type') {
                    event.backgroundColor = event.scheduler_event_type_color;
                    event.borderColor = event.backgroundColor;
                    if (sortArray.indexOf(event.scheduler_event_type_color) === -1) {
                        sortArray.push(event.scheduler_event_type_color)
                    }
                } else {
                    event.backgroundColor = schedulerEventStatuses().find(x => x.value === event.status).color;
                    event.borderColor = event.backgroundColor;
                    if (sortArray.indexOf(event.status) === -1) {
                        sortArray.push(event.status)
                    }
                }
                event.event_id = `${event.id}_${event.backgroundColor}`

            });
            if (filters.users) {
                events = events.filter(e => filters.users.some(u => u.selected && u.value === e.person_id));
            }
            if (filters.statuses) {
                events = events.filter(e => filters.statuses.some(s => s.selected && s.label === e.status));
            }
            if (filters.schedulerEventTypes) {
                events = events.filter(e => filters.schedulerEventTypes.some(s => s.selected && s.label === e.scheduler_event_type_name));
            }
        }
        // full calendar work around
        const syncCalendar = this.props.actions.syncCalendar;
        const toggleCalendar = this.toggleCalendar;
        const reload = this.reload;

        const filterStatuses = {
            employee: isEmployeeFilterOpened,
            type: isTypeFilterOpened,
            color: isColorFilterOpened,
            statuses: isStatusesFilterOpened
        }

        return (
            ready && client ?
                <Grid fluid>
                    <Row className="vcenter">
                        <Col xs={!newSchedulerView ? 2 : 6} className="vcenter">
                            <h2 className={screen32 ? 'mt0 bottom5' : "no-top-margin small-margin"}
                                style={{fontSize: screen32 && 20}}>{newSchedulerView ? "Scheduler" : "Calendar"}</h2>
                        </Col>
                        {isNewFilterModalOpened &&
                        <NewFilterModal
                            title="New filter modal"
                            show={isNewFilterModalOpened}
                            onHide={() => this.setState({isNewFilterModalOpened: false})}
                            closeButton={() => this.setState({isNewFilterModalOpened: false})}
                        >
                            <Row className="padding20">
                                <Col>
                                    <FormGroup>
                                        <ControlLabel>
                                            Name Your Filter
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            placeholder="Filter name"
                                            onChange={e => this.setState({searchValue: e.target.value})}
                                            value={searchValue}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </NewFilterModal>
                        }
                        {!newSchedulerView &&
                        <Col xs={8} className="vertical-align">
                            <Col md={1}>
                                <Row className="bottom10 textCenter">
                                    <Button onClick={() => this.setState({fullSizeFilterPanel: !fullSizeFilterPanel})}
                                            className="classic-filter-btn pointer">
                                        <FontAwesomeIcon icon={fullSizeFilterPanel ? faAngleLeft : faAngleRight}
                                                         className="color-white bigger"/>
                                    </Button>
                                </Row>
                            </Col>
                            <Col md={10} className="bottom10 no-left-padding vcenter">
                                <Col md={2} className="no-left-padding text-blue pointer"
                                     onClick={() => this.setState({isNewFilterModalOpened: true})}>Save Filter</Col>
                                <Col md={10} className="d-flex no-padding">
                                    <div className="textCenter vcenter mr-11">
                                        <p className="classic-filter-element-name mr-3 bottom0 pointer">Customer</p>
                                        <span className="pointer">
                                             <FontAwesomeIcon icon={faTimesCircle} style={{color: '#d9230f'}}/>
                                        </span>
                                    </div>
                                    <div className="textCenter vcenter">
                                        <p className="classic-filter-element-name mr-3 bottom0 pointer">PHC</p>
                                        <span className="pointer">
                                             <FontAwesomeIcon icon={faTimesCircle} style={{color: '#d9230f'}}/>
                                        </span>
                                    </div>
                                </Col>
                            </Col>
                            <Col md={1}>
                                <Row className="bottom10 textCenter">
                                    <Button onClick={() => this.setState({fullSizeWOPanel: !fullSizeWOPanel})}
                                            className="classic-filter-btn pointer">
                                        <FontAwesomeIcon icon={fullSizeWOPanel ? faAngleRight : faAngleLeft}
                                                         className="color-white bigger"/>
                                    </Button>
                                </Row>
                            </Col>
                        </Col>}
                        <Col xs={!newSchedulerView ? 2 : 6} className="vcenter hright">
                            <Link className='pointer'
                                  to={'/calendar'}
                                  onClick={() => {
                                      this.setState({newSchedulerView: !newSchedulerView})
                                  }}
                            >
                                <b>{newSchedulerView ? 'CLASSIC VIEW' : 'MODERN VIEW'}</b>
                            </Link>
                        </Col>
                    </Row>
                    {!newSchedulerView && <hr className={'mt0 bottom0'}/>}
                    <Row>
                        {(filters && newSchedulerView) && <FilterModal
                            value={filters}
                            users={employees}
                            show={filterVisible}
                            onHide={() => {
                                this.setState({filterVisible: !filterVisible})
                            }}
                            newSchedulerView={newSchedulerView}
                            schedulerEventTypes={schedulerEventTypes}
                            onChange={(filters) => {
                                const updateState = () => {
                                    this.setState({
                                        filters,
                                        employees: filters.users
                                    }, this.delayedViewRender);
                                };

                                if (Object.keys(this.state.filters).length === 0) {
                                    updateState();
                                } else {
                                    this.props.actions.saveFilter(filters, updateState);
                                }
                            }}
                        />}
                        {!newSchedulerView && fullSizeFilterPanel === true &&
                        <Col className={fullSizeFilterPanel && 'col-md-2 classic-panel mt-20'}>
                            <Panel
                                collapsible
                                bsClass="ps-default-panel-wrap"
                                defaultExpanded
                            >
                                <Col className={isMobile ? 'margin-left15' : "mr-9 ml-9"}>
                                    <Filter
                                        value={filters}
                                        resource={this.state.resourceSearch}
                                        setResource={this.setResourceSearch}
                                        toggleFilter={this.toggleFilter}
                                        loadEvents={this.loadEvents}
                                        filterStatuses={filterStatuses}
                                        users={employees}
                                        newSchedulerView={newSchedulerView}
                                        schedulerEventTypes={schedulerEventTypes}
                                        onChange={(filters) => {
                                            const updateState = () => {
                                                this.setState({
                                                    filters,
                                                    employees: filters.users
                                                }, this.delayedViewRender);
                                            };

                                            if (Object.keys(this.state.filters).length === 0) {
                                                updateState();
                                            } else {
                                                this.props.actions.saveFilter(filters, updateState);
                                            }
                                        }}
                                    />
                                </Col>
                            </Panel>
                        </Col>
                        }

                        <Col id='scheduler' md={this.renderSchedulerGrid()}
                             style={{marginLeft: !newSchedulerView && !fullSizeWOPanel && !fullSizeFilterPanel ? 0 : !newSchedulerView && !fullSizeFilterPanel && 45, position: 'relative'}}
                             className={`${!newSchedulerView && "mt10"} ${fullSizeFilterPanel && "pr-5"}`}
                             xs={newSchedulerView && this.state.calendarWidth}>
                            {this.renderInputSearch()}
                            {filters &&
                            <FullCalendar
                                ref={this.calendarComponentRef}
                                fixedWeekCount={false}
                                plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, bootstrapPlugin]}
                                initialView={filters.defaultView ? filters.defaultView : "dayGridMonth"}
                                slotDuration="00:30:00"
                                snapDuration="00:15:00"
                                slotLabelInterval="01:00"
                                contentHeight={window.innerHeight * heightProportion}
                                events={events}
                                eventSources={events}
                                scrollTime={'08:00:00'}
                                droppable={true}
                                selectable={true}
                                slotEventOverlap={false}
                                editable={true}
                                timeZone={'local'}
                                initialDate={this.state.midDate.format()}
                                eventClick={this.handleClick}
                                eventResize={this.handleChange}
                                eventDrop={this.handleChange}
                                eventsSet={this.handleEvents}
                                dayCellContent={(arg) =>
                                    arg.view.type === 'dayGridMonth' &&
                                    <DayHeader arg={arg} birthdays={birthdays} holidays={holidays}
                                               employees={employees} startDates={startDates}
                                    />
                                }
                                dayHeaderContent={(arg) =>
                                    ["timeGridWeek", "timeGridDay", "listWeek"].includes(arg.view.type) &&
                                    <DayHeader arg={arg} birthdays={birthdays} holidays={holidays}
                                               employees={employees} startDates={startDates}/>
                                }
                                select={values => {
                                    this.setState({
                                        start_date: values.start,
                                        end_date: values.end,
                                    })
                                }}
                                dayMaxEventRows={true}
                                views={{
                                    dayGrid: {
                                        dayMaxEventRows: 2
                                    }
                                }}
                                eventContent={(e) =>
                                    <EventContent event={e} filters={filters} employees={employees}/>
                                }
                                customButtons={{
                                    prev: {
                                        click: () => {
                                            this.updateDate(moment(this.state.midDate).subtract(1, this.period[this.state.view.type]));
                                        }
                                    },
                                    next: {
                                        click: () => {
                                            this.updateDate(moment(this.state.midDate).add(1, this.period[this.state.view.type]));
                                        }
                                    },
                                    today: {
                                        text: 'today',
                                        click: () => {
                                            this.updateDate(moment())
                                        }
                                    },
                                    sync: {
                                        text: <span className="glyphicon glyphicon-refresh"/>,
                                        click: function () {
                                            if (client.gcal_authorized) {
                                                syncCalendar(client.id, reload);
                                            } else {
                                                reload();
                                            }
                                        }
                                    },
                                    calendar: {
                                        text: <span className="glyphicon glyphicon-calendar"/>,
                                        click: toggleCalendar
                                    },
                                    filterCalendar: {
                                        text: <span className="glyphicon glyphicon-search"/>,
                                        click: () => {
                                            this.setState({filterVisible: !this.state.filterVisible})
                                        }
                                    }
                                }}
                                headerToolbar={{
                                    left: `prev,next today${newSchedulerView ? ' calendar filterCalendar' : ''}`,
                                    center: 'title',
                                    right: 'sync,dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                                }}
                                dayCellDidMount={(arg) => {
                                    if (arg.view.type === 'timeGridWeek' || arg.view.type === 'timeGridDay') {
                                        this.setState({view: arg.view}, () => this.delayedViewRender)
                                    }
                                }}
                                viewDidMount={(arg) => {
                                    this.calculateRowHeight()
                                    this.setState({view: arg.view}, this.delayedViewRender);
                                }}
                                datesSet={(dateInfo) => {
                                    const {filters} = this.state;
                                    const differentStart = !moment(dateInfo.startStr).isSame(this.state.start);
                                    const differentEnd = !moment(dateInfo.endStr).isSame(this.state.end);
                                    if ((differentStart || differentEnd)) {
                                        this.setState({events: []});
                                        filters.defaultView = dateInfo.view.type;
                                        this.props.actions.saveFilter(filters, () => {
                                            this.setState({
                                                start: moment(dateInfo.startStr),
                                                end: moment(dateInfo.endStr),
                                                filters: filters,
                                                view: dateInfo.view,
                                                scroll: true
                                            }, this.loadEvents)
                                        });
                                    }
                                }}
                                progressiveEventRendering={false}
                                drop={(dropInfo) => {
                                    if (dropInfo.draggedEl.dataset.id) {
                                        let workOrderId = parseInt(dropInfo.draggedEl.dataset.id);
                                        let event = {
                                            all_day: dropInfo.allDay,
                                            scheduler_event_type_resource: 'WorkOrder',
                                            scheduler_event_resource_id: workOrderId,
                                            date_from: moment(dropInfo.dateStr),
                                            employee: employees[0].value
                                        };
                                        if (moment(dropInfo.dateStr).hour() === 0) {
                                            event.date_from = event.date_from.hour(8);
                                        }
                                        event.date_to = moment(event.date_from).add(1, 'hour');
                                        this.handleClick(event);
                                    }
                                }}
                                eventDidMount={(event) => {
                                    event.el.setAttribute('event_id', event.event.extendedProps.event_id)
                                    window.dispatchEvent(new Event('resize'));
                                    this.calculateRowHeight(event.event.extendedProps.event_id);
                                }}
                            />
                            }
                        </Col>
                        {!newSchedulerView &&
                        <Col className={fullSizeWOPanel && 'col-md-2 classic-right-panel-opened'}>
                            <Panel
                                collapsible
                                bsClass="ps-default-panel-wrap"
                                defaultExpanded
                            >
                                {fullSizeWOPanel &&
                                <Col md={12} className={`${!fullSizeWOPanel && "no-right-padding"}`}>
                                    {
                                        newSchedulerView &&
                                        <div className="font16 heading-orders">
                                            Work Orders:
                                            <Link
                                                className="pointer text-right big-mar-left"
                                                onClick={() => {
                                                    this.setState({
                                                        workOrdersFilterModal: !workOrdersFilterModal,
                                                        oldWorkOrdersFilter: {...this.state.workOrdersFilter}
                                                    })
                                                }}>
                                                Filters<Glyphicon glyph="chevron-right"/>
                                            </Link>
                                        </div>
                                    }
                                    <WorkOrders isMobile={isMobile} handleClick={this.handleClick}
                                                workOrders={workOrders} calendar={this.calendarComponentRef}/>
                                </Col>}
                            </Panel>
                        </Col>
                        }
                        {newSchedulerView &&
                        <Col md={newSchedulerView ? 3 : 2} xs={newSchedulerView && this.state.WOWidth}>
                            {loaded &&
                            <Row>
                                <WorkOrdersFilterModal
                                    closeButton={() => {
                                        this.setState(
                                            {
                                                workOrdersFilterModal: !this.state.workOrdersFilterModal,
                                                workOrdersFilter: {...this.state.oldWorkOrdersFilter}
                                            }, () => {
                                                this.loadWorkOrders()
                                            });
                                    }}
                                    workOrdersLoaded={workOrdersLoaded}
                                    show={this.state.workOrdersFilterModal}
                                    onHide={() => {
                                        this.setState({workOrdersFilterModal: !this.state.workOrdersFilterModal})
                                    }}
                                    onChange={filter => {
                                        this.setState({
                                            workOrdersFilter: filter,
                                            workOrdersLoaded: false
                                        }, this.loadWorkOrders());
                                    }}
                                    filter={this.state.workOrdersFilter}
                                    workOrderSearchResults={this.state.workOrderSearchResults}
                                    onWoSearch={() => this.handleSearchWoResults('workOrder', 'workOrderSearchResults')}
                                    customerSearchResults={this.state.customerSearchResults}
                                    onCustomerSearch={() => this.handleSearchWoResults('customer', 'customerSearchResults')}
                                    citySearchResults={this.state.citySearchResults}
                                    onCitySearch={() => this.handleSearchWoResults('city', 'citySearchResults')}
                                    zipSearchResults={this.state.zipSearchResults}
                                    onZipSearch={() => this.handleSearchWoResults('zip', 'zipSearchResults')}

                                />
                                <Col md={12} className={isMobile && 'mt-20'}>
                                    {
                                        newSchedulerView &&
                                        <div className="font16 heading-orders">
                                            Work Orders:
                                            <Link
                                                className="pointer text-right big-mar-left"
                                                onClick={() => {
                                                    this.setState({
                                                        workOrdersFilterModal: !workOrdersFilterModal,
                                                        oldWorkOrdersFilter: {...this.state.workOrdersFilter}
                                                    })
                                                }}>
                                                Filters<Glyphicon glyph="chevron-right"/>
                                            </Link>
                                        </div>
                                    }
                                    <WorkOrders isMobile={isMobile} handleClick={this.handleClick}
                                                workOrders={workOrders} calendar={this.calendarComponentRef}/>
                                </Col>
                            </Row>
                            }
                        </Col>}
                        {
                            calendarVisible &&
                            <FormGroup id='scheduler-date-picker'>
                                <section className="datetime-picker--section">
                                    <Datetime open viewMode="days"
                                              value={midDate}
                                              onChange={midDate => {
                                                  this.updateDate(midDate)
                                              }
                                              }
                                    />
                                </section>
                            </FormGroup>
                        }
                    </Row>
                    {this.state.emailDialogShow && <EmailDialog
                        onHide={this.emailDialogShow}
                        emailType={emails.options.emailType}
                        referenceId={emails.options.referenceId}
                        recipient={emails.options.recipient}
                        handleAction={emails.options.handleAction}
                        withFollowUpDate={emails.options.withFollowUpDate}
                        defaultEmail={emails.options.defaultEmail}
                        sendInBackground={true}
                    />}

                    {showModal && <SchedulerEventDialog
                        emailDialogShow={this.emailDialogShow}
                        handleClose={() => {
                            this.setState({showModal: false});
                            this.reload();
                        }}
                        event={event}
                    />}
                </Grid> : null
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        clientId: state.auth.client_id,
        emails: state.emails,
        showTaskDialog: state.userStatus.showTaskDialog,
        employee_id: state.auth.employee_id
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(api, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Scheduler);
