import React, {Component} from "react"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"

import {addAlert} from "../App/actions"
import * as MyActions from "./actions"
import Helmet from "react-helmet"
import {Col, Glyphicon, Grid, MenuItem, Nav, NavDropdown, Row,} from "react-bootstrap"
import {LinkContainer} from "react-router-bootstrap"
import PageTitle from "../../components/PageTitle"
import moment from "moment"
import Dollars from "../../components/Dollars"
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";

const Actions = {...MyActions, addAlert: addAlert}
const isMobile = window.screen.width <= 1024;

class ProposalsList extends Component {
    componentWillMount() {
        //TODO: check the state. if it was > a few seconds ago then dont reload all the proposals. give the user a refresh button?
        this.props.actions.fetchProposals()
    }

    siteFormatter = (cell, row) => <span>
                    <strong>{row.siteName}</strong><br/>
        {row.siteAddress}
                </span>;

    statusFormatter = (cell, row) => <span>
                 <small
                     className="text-primary">{row.createdDate ? `Created: ${moment(`${row.createdDate}`).format('l')}` : null}</small><br/>
                    <small
                        className="text-primary">{row.proposedDateCorrect ? `Proposed: ${moment(`${row.proposedDateCorrect}`).format('l')}` : row.proposedDate && `Proposed: ${moment(`${row.proposedDate}`).format('l')}`}</small><br/>
                  <small
                      className="text-info">{row.acceptedDate ? `Accepted: ${moment(`${row.acceptedDate}`).format('l')}` : null}<br/></small>
                  <small
                      className="text-success">{row.approvalDate ? `Approved: ${moment(`${row.approvalDate}`).format('l')}` : null}</small>
                </span>;
    sentCountFormatter = (cell, row) => <span>
                        <small className="text-warning">
                            {row.lastSent ? row.acceptedDate === null ? `Last sent: ${moment(`${row.lastSent}`).format('l')}` : null : null}
                        </small>
                        <br/>
        {row.acceptedDate === null ? row.sentCount ? row.sentCount : 0 :
            <span className='text-success'>{row.sentCount ? row.sentCount : 0}
                <Glyphicon glyph='ok-sign'/>
                            </span>}
        <br/>
                      <small className="text-danger">
                          {row.nextSend ? row.acceptedDate === null ? `Due on: ${moment(`${row.nextSend}`).format('l')}` : null : null}
                      </small>
                    </span>;

    customerFormatter = (cell, row) => <Nav>
        <NavDropdown
            title={row.customerName}
            id={`proposal-menu`}
        >
            <LinkContainer to={`/customer/info/${row.customerId}`}>
                <MenuItem>Customer Info</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/proposals/${row.customerId}`}>
                <MenuItem>Customer Proposals</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/sites/${row.customerId}`}>
                <MenuItem>Customer Sites</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/work_orders/${row.customerId}`}>
                <MenuItem>Customer Work Orders</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/work_history/${row.customerId}`}>
                <MenuItem>Customer Work History</MenuItem>
            </LinkContainer>
        </NavDropdown>
    </Nav>;

    proposalFormatter = (cell, row) => <Nav>
        <NavDropdown
            title={`#${row.proposalNo}`}
            id={`proposal-menu`}
        >
            <LinkContainer to={`/mapview/${row.id}`}>
                <MenuItem>Edit Proposal</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/proposal_service_status/${row.id}`}>
                <MenuItem>Proposal Statuses</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/print_proposal/${row.id}`}>
                <MenuItem>Print Proposal</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/work_orders/${row.id}`}>
                <MenuItem bsSize="small">Manage Work Orders</MenuItem>
            </LinkContainer>
        </NavDropdown>
    </Nav>;

    rowFormatter = (cell, row) => {
        return <div>
            <Row>
                <Col xs={6}>Proposal No.</Col>
                <Col xs={6}>{this.proposalFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Customer</Col>
                <Col xs={6}>{this.customerFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Site</Col>
                <Col xs={6}>{this.siteFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Status Dates</Col>
                <Col xs={6}>{this.statusFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Sent Count</Col>
                <Col xs={6}>{this.sentCountFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Arborist</Col>
                <Col xs={6}>{row.arborist}</Col>
            </Row>
            <Row>
                <Col xs={6}>Price</Col>
                <Col xs={6}>{<Dollars amount={row.proposalTotal}/>}</Col>
            </Row>
        </div>
    };

    renderProposals(proposals) {
        if (proposals === null) {
            return null
        }

        return (
            isMobile ? <BootstrapTable
                    ref="table"
                    data={proposals}
                    striped={true}
                    bordered={false}
                    hover={true}
                    remote
                    className="wrapped"
                >
                    <TableHeaderColumn
                        dataField="id"
                        hidden
                        isKey
                    />
                    <TableHeaderColumn
                        dataField="id"
                        dataFormat={this.rowFormatter}
                    >
                    </TableHeaderColumn>
                </BootstrapTable>
                :
                <BootstrapTable
                    ref="table"
                    data={proposals}
                    striped={true}
                    bordered={false}
                    hover={true}
                    remote
                    className="wrapped"
                >
                    <TableHeaderColumn
                        dataField="id"
                        width={"5%"}
                        hidden
                        isKey
                    />
                    <TableHeaderColumn
                        dataField="proposal_no"
                        width={"5%"}
                        dataFormat={this.proposalFormatter}
                    >
                        Proposal No.
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="customer_name"
                        dataFormat={this.customerFormatter}
                        width={"15%"}
                    >
                        Customer
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataFormat={this.siteFormatter}
                        width={"15%"}
                    >
                        Site
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataFormat={this.statusFormatter}
                        width={"15%"}
                    >
                        Status Dates
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width={"15%"}
                        dataFormat={this.sentCountFormatter}
                    >
                        Sent Count
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="arborist"
                        width={"10%"}
                    >
                        Arborist
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="proposalTotal"
                        width={"15%"}
                        dataFormat={this.dollarsFormatter}
                    >
                        Price
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="inProgress"
                        width={"10%"}
                        dataFormat={(cell) => cell ? <Glyphicon glyph='ok-sign'/> : '' }
                    >
                        Work in progress
                    </TableHeaderColumn>
                </BootstrapTable>
        )
    }

    render() {
        const {proposals} = this.props
        return (
            <Grid fluid>
                <Helmet title="All Proposals"/>
                <PageTitle pageName="Recent Proposals" pageDetail="the last 50 bids..."/>

                <Row>
                    <Col xs={12}>
                        {proposals && proposals.length > 0 ? this.renderProposals(proposals) : null}
                    </Col>

                </Row>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        proposals: state.proposals.fetchedProposals,
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProposalsList)
