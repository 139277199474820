import React, {useEffect, useState} from 'react'
import {Button, Col, Glyphicon, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {bindActionCreators} from "redux";
import * as actions from './CustomerFeedbackApi'
import {connect} from "react-redux";
import PageHeader from "../../../components/PageTitle";
import {BootstrapTable, SizePerPageDropDown, TableHeaderColumn} from "react-bootstrap-table";
import QuestionnaireModal from "./Questionnaire/QuestionnaireModal";
import {Link} from "react-router";
import moment from "moment-timezone";
import QuestionnaireFilterModal from "./Questionnaire/QuestionnaireFilterModal";
import _ from 'lodash'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './CustomerFeedback.scss'

const CustomerFeedback = ({actions, client}) => {
        const [customerFeedbacks, setCustomerFeedbacks] = useState([])
        const [page, setPage] = useState(1)
        const [per_page, setPer_page] = useState(25)
        const [sort, setSort] = useState({sortName: 'updated_at', sortOrder: 'desc'})
        const [total, setTotal] = useState(0)
        const [statistic, setStatistic] = useState({})
        const [showQuestionnaireModal, setShowQuestionnaireModal] = useState(false)
        const [showFilterModal, setShowFilterModal] = useState(false)
        const [filters, setFilters] = useState({
            stars: [1, 2, 3, 4, 5],
            customerIds: [],
            invoiceIds: [],
            noAnswer: true
        })

        const loadCustomerFeedbacks = (newFilters = null) => {
            const feedbackFilters = _.cloneDeep(newFilters ? newFilters : filters)
            feedbackFilters.customerIds = feedbackFilters.customerIds.map(f => f.value)
            feedbackFilters.invoiceIds = feedbackFilters.invoiceIds.map(f => f.value)

            actions.getCustomerFeedbacks(page, per_page, feedbackFilters, sort, res => {
                setCustomerFeedbacks(res.data)
                setTotal(res.total || 0)
                setStatistic(res.statistic)
            })
        }

        const quickFilter = (value, star_value) => {
            const isActive = filters && filters.stars?.length === 1 && filters.stars?.includes(star_value) && !filters.noAnswer
            const onChange = () => {
                const newFilters = {
                    customerIds: [],
                    invoiceIds: [],
                    noAnswer: isActive,
                    stars: isActive ? [1, 2, 3, 4, 5] : [star_value]
                }

                setFilters(newFilters)
                loadCustomerFeedbacks(newFilters)
            }

            return <h4 className="no-margin">
                <div className={`d-flex ${isActive ? 'font-weight-bold' : ''}`}>
                    <div className="text-right" style={{color: 'goldenrod', minWidth: '90px'}}>{'★'.repeat(star_value)}</div>
                    <div className="ml-6 text pointer vertical-align" onClick={onChange}>
                        <b className="mr-5">{value}</b>
                        <span className="font12">{` ${value === 0 || value === 1 ? 'review' : 'reviews'}`}</span>
                    </div>
                </div>
            </h4>
        }

        const customerNameFormatter = (cell, row) => {
            return <Link to={`/customer/info/${row.customer_id}`} onlyActiveOnIndex target='_blank'>{cell}</Link>
        }

        const invoiceNumberFormatter = (cell, row) => {
            return <Link to={`/proposals/${row.proposal_id}/invoices`} onlyActiveOnIndex target='_blank'>#{cell}</Link>
        }

        const feedbackFormatter = (cell, row) => {
            return cell ?
                <OverlayTrigger
                    placement="right" overlay={<Tooltip
                    id="customer_feedback_id">{moment(cell).format("MM/DD/YYYY, h:mm a")}</Tooltip>}
                >
                    <Glyphicon glyph="ok-sign" className='font20' style={{color: 'green'}}/>
                </OverlayTrigger>
                :
                <Glyphicon glyph="remove-sign" className='font20' style={{color: 'red'}}/>
        }

        const ratingFormatter = (cell, row) => {
            let restRating = []
            const userRating = cell ? Array.from(Array(cell).keys()) : []
            if (cell < 5) {
                const rest = 5 - cell
                restRating = Array.from(Array(rest).keys())
            }

            return cell ?
                <span className='font24'
                      style={{color: 'goldenrod'}}>{userRating.map(x => '★')}{restRating.map(x => '☆')}</span>
                :
                <i className="font10">No customer rating</i>
        }

        const questionnaireAnswersFormatter = (cell, row) => {
            const answers = cell?.questionnaire_answers && JSON.parse(cell?.questionnaire_answers)

            return <div>
                {answers && answers.map((a, i) => {
                    return <Row className='bottom10'>
                        <Col sm={1}><span>Question:</span></Col>
                        <Col sm={11} className='bold'><p style={{whiteSpace: 'pre-wrap', wordBreak: 'break-all'}}>{a.question}</p></Col>
                        {a.checkboxAnswer && a.checkboxAnswer.length > 0 && <Col xs={12}>
                            {Array.isArray(a.checkboxAnswer) ?
                                <Row className='bottom5'>
                                    <Col xsOffset={1} xs={11}><span>Selected answers:</span></Col>
                                    {a.checkboxAnswer.map((a, i) => {
                                        return <Col xs={12}>
                                            <Row>
                                                <Col xsOffset={1} xs={11}>
                                                    <div className="d-flex padding-left-20">
                                                        <p className="no-margin pr-5">{i + 1}.</p>
                                                        <p className="no-margin"><b
                                                            style={{whiteSpace: 'pre-wrap', wordBreak: 'break-all'}}>{a}</b></p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    })}
                                </Row>
                                :
                                <Row className='bottom5'>
                                    <Col xsOffset={1} xs={11}><span>Selected answer:</span></Col>
                                    <Col xsOffset={1} xs={11} className='bold'>
                                        <p className="padding-left-20" style={{whiteSpace: 'pre-wrap', wordBreak: 'break-all'}}>{a.checkboxAnswer}</p>
                                    </Col>
                                </Row>
                            }
                        </Col>}
                        {a.descriptionAnswer && a.descriptionAnswer.length > 0 && <Col xs={12}>
                            <Row className='bottom5'>
                                <Col xsOffset={1} xs={11}><span>Answer:</span></Col>
                                <Col xsOffset={1} xs={11} className='bold'>
                                    <p className="padding-left-20" style={{whiteSpace: 'pre-wrap', wordBreak: 'break-all'}}>{a.descriptionAnswer}</p>
                                </Col>
                            </Row>
                        </Col>}
                        {answers.length !== i + 1 && <Col xs={12}>
                            <hr className="no-margin"/>
                        </Col>}
                    </Row>
                })}
            </div>
        }

        const googleFormatter = (cell, row) => {
            return cell ?
                <OverlayTrigger
                    placement="left"
                    overlay={<Tooltip id="customer_feedback_id">{moment(cell).format("MM/DD/YYYY, h:mm a")}</Tooltip>}
                >
                    <Glyphicon glyph="ok-sign" className='font20' style={{color: 'green'}}/>
                </OverlayTrigger>
                :
                <Glyphicon glyph="remove-sign" className='font20' style={{color: 'red'}}/>
        }

        const updatedFormatter = (cell, row) => {
            return moment(cell).format("MM/DD/YYYY, h:mm a")
        }

        const expandFormatter = (cell, row) => {
            return row.questionnaire_answers ?
                <b className="pointer">Show Answers</b>
                :
                <b className="text-gray">No Answers</b>
        }

        useEffect(() => {
            loadCustomerFeedbacks()
        }, [page, per_page, sort])

        const renderSizePerPageDropDown = (props) => {
            const {toggleDropDown} = props;

            return (
                <SizePerPageDropDown
                    className='my-size-per-page'
                    variation='dropup'
                    onClick={() => this.onToggleDropDown(toggleDropDown)}
                    currSizePerPage={per_page}
                />
            );
        };

        const onSortChange = (sortName, sortOrder) => {
            setSort({sortName: sortName, sortOrder: sortOrder})
        }

        const renderDesktopFeedback = () => {
            return <><Row className='vertical-align'>
                <Col xs={6}>
                    <Row>
                        <Col xs={12} className="d-flex">
                            <PageHeader pageName="Customer feedbacks" pageDetail={`${total} records`}/>
                            <h3 className="ml-30"><b>{statistic.arithmetic_average || 5}</b>/5<span
                                style={{color: 'goldenrod'}}>★</span></h3>
                        </Col>
                    </Row>
                </Col>
                <Col xs={6} className='text-right'>
                    <Button
                        className="mr-9"
                        bsStyle="warning"
                        onClick={() => setShowFilterModal(true)}
                    >
                        Filter {" "}<FontAwesomeIcon icon="search" className="small-margin"/>
                    </Button>
                    <Button
                        bsStyle="success"
                        onClick={() => setShowQuestionnaireModal(true)}
                    >
                        {client.customer_questionnaire ? 'Edit Questionnaire' : 'Create Questionnaire'}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    {quickFilter(statistic.one_star_count, 1)}
                    {quickFilter(statistic.two_star_count, 2)}
                    {quickFilter(statistic.three_star_count, 3)}
                    {quickFilter(statistic.four_star_count, 4)}
                    {quickFilter(statistic.five_star_count, 5)}
                </Col>
            </Row></>
        }

        const renderMobileFeedback = () => {
            return <div className="mobile-customer-feedback">
                <div className="header">
                    <PageHeader pageName="Customer feedbacks" pageDetail={`${total} records`}/>
                    <div className="star-with-buttons">
                        <h3 style={{marginTop: 0, marginBottom: 0}}><b>{statistic.arithmetic_average || 5}</b>/5<span
                            style={{color: 'goldenrod'}}>★</span></h3>
                        <div>
                            <Button
                                className="mr-9"
                                bsStyle="warning"
                                onClick={() => setShowFilterModal(true)}
                            >
                                Filter {" "}<FontAwesomeIcon icon="search" className="small-margin"/>
                            </Button>
                            <Button
                                bsStyle="success"
                                onClick={() => setShowQuestionnaireModal(true)}
                            >
                                {client.customer_questionnaire ? 'Edit Questionnaire' : 'Create Questionnaire'}
                            </Button>
                        </div>
                    </div>

                </div>
                <div className="quick-filters">
                        {quickFilter(statistic.one_star_count, 1)}
                        {quickFilter(statistic.two_star_count, 2)}
                        {quickFilter(statistic.three_star_count, 3)}
                        {quickFilter(statistic.four_star_count, 4)}
                        {quickFilter(statistic.five_star_count, 5)}
                </div>
            </div>
        }

        const isMobile = window.screen.width < 768;

        return (
            <div id='customer_feedback'>
                {isMobile ? renderMobileFeedback() : renderDesktopFeedback()}
                <Col md={12} className="remaining no-padding">
                    <BootstrapTable
                        data={customerFeedbacks}
                        striped={true}
                        bordered={false}
                        hover={true}
                        pagination={true}
                        expandableRow={(cell) => cell.questionnaire_answers}
                        expandComponent={questionnaireAnswersFormatter}
                        height={'auto'}
                        remote
                        fetchInfo={{dataTotalSize: total}}
                        options={{
                            onPageChange: (page, per_page) => {
                                setPage(page)
                                setPer_page(per_page)
                            },
                            page,
                            sizePerPageDropDown: renderSizePerPageDropDown,
                            sizePerPageList: [
                                {text: '10', value: 10},
                                {text: '25', value: 25},
                                {text: '30', value: 30},
                                {text: '50', value: 50},
                                {text: '100', value: 100},
                                {text: '200', value: 200}],
                            sizePerPage: per_page,
                            sortName: sort?.sortName,
                            sortOrder: sort?.sortOrder,
                            onSortChange: onSortChange
                        }}
                    >
                        <TableHeaderColumn hidden isKey dataField="id">
                            Id
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={{whiteSpace: 'normal', wordWrap: 'break-word'}}
                            thStyle={{textWrap: "pretty"}}
                            dataField="name"
                            dataSort={true}
                            dataFormat={customerNameFormatter}
                            width={isMobile ? '200px' : '20%'}
                        >
                            Customer name
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={{whiteSpace: 'normal', wordWrap: 'break-word'}}
                            thStyle={{textWrap: "pretty"}}
                            dataField="number"
                            dataSort={true}
                            dataFormat={invoiceNumberFormatter}
                            width={isMobile ? '100px' : '10%'}
                        >
                            Invoice #
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={{whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center'}}
                            thStyle={{textAlign: 'center', textWrap: "pretty"}}
                            dataField="feedback_send_at"
                            dataSort={true}
                            dataFormat={feedbackFormatter}
                            width={isMobile ? '150px' : '15%'}
                        >
                            Feedback Email Send
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={{whiteSpace: 'normal', textAlign: 'center', paddingTop: 0}}
                            thStyle={{textAlign: 'center', textWrap: "pretty"}}
                            dataField="rating"
                            dataSort={true}
                            dataFormat={ratingFormatter}
                            width={isMobile ? '150px' : '15%'}
                        >
                            Rating
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={{whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center'}}
                            thStyle={{textAlign: 'center', textWrap: "pretty"}}
                            dataField="google_review_send_at"
                            dataSort={true}
                            dataFormat={googleFormatter}
                            width={isMobile ? '200px' : '20%'}
                        >
                            Google Review Email Send
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={{whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center'}}
                            thStyle={{textAlign: 'center', textWrap: "pretty"}}
                            dataField="updated_at"
                            dataSort={true}
                            dataFormat={updatedFormatter}
                            width={isMobile ? '100px' : '10%'}
                        >
                            Last modified
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={{whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center'}}
                            thStyle={{textAlign: 'center', textWrap: "pretty"}}
                            dataField="expand"
                            dataFormat={expandFormatter}
                            width={isMobile ? '100px' : '10%'}
                        />
                    </BootstrapTable>
                </Col>
                {showQuestionnaireModal && <QuestionnaireModal
                    client={client}
                    onHide={() => setShowQuestionnaireModal(false)}
                    onSave={actions.saveClientSettings}
                />}
                {showFilterModal && <QuestionnaireFilterModal
                    startFilters={filters}
                    onHide={() => setShowFilterModal(false)}
                    onSave={filters => {
                        loadCustomerFeedbacks(filters)
                        setFilters(filters)
                    }}
                    searchForCustomer={actions.searchForCustomer}
                    searchForInvoices={actions.searchForInvoices}
                />}
            </div>
        );
    }
;

const mapStateToProps = state => (
    {
        name: state.auth.name,
        client: state.client.customerInfo,
    }
)

const mapDispatchToProps = dispatch => (
    {
        actions: bindActionCreators(actions, dispatch),
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(CustomerFeedback)