import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Grid} from "react-bootstrap";
import ResourceComponent from "../../components/ResourceComponent";
import * as api from "./SalesCrossViewListApi";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import {LinkContainer} from "react-router-bootstrap";
import moment from "moment"
import {dollarsFormatter} from "../../components/BootStrapTableCellFormatters";

const actions = {...api};

class SalesCrossViewList extends ResourceComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
            accepted_report: {},
            billed_report: {},
            proposed_report: {}
        };
    }

    reload = () => {
        const query = this.props.location.query;
        const filters = {
            person_id: this.props.params.id,
            label: query.label,
            from: query.from,
            to: query.to,
            service_type: query.service_type ? parseInt(query.service_type) : null,
        };
        this.props.actions.loadReport(filters, result => {
            result.proposed_report.push(this.createTotal(result.proposed_report));
            result.accepted_report.push(this.createTotal(result.accepted_report));
            result.billed_report.push(this.createTotal(result.billed_report));
            this.setState({
                accepted_report: result.accepted_report,
                proposed_report: result.proposed_report,
                billed_report: result.billed_report
            })
        });
    };

    componentDidMount() {
        this.reload();
    };

    proposalFormatter = (cell, row) => {
        return row.proposal_no === 'Total' ? <span>{row.proposal_no}</span> : <LinkContainer to={`/mapview/${row.id}`}>
            <span className={'text-blue pointer'}>{row.proposal_no}</span>
        </LinkContainer>
    };

    dateFormatter = (cell, row) => {
        return cell ? moment(cell).format("MM/DD/YYYY h:mmA") : '-';
    };

    createTotal = (data) => {
        return {
            accepted_date: null,
            proposal_date: null,
            proposal_no: 'Total',
            proposed_total: data.reduce((a, b) => a + (+b['proposed_total'] || 0), 0),
            accepted_total: data.reduce((a, b) => a + (+b['accepted_total'] || 0), 0),
            billed_total: data.reduce((a, b) => a + (+b['billed_total'] || 0), 0)
        }
    };

    indexN = (cell, row, enumObject, index) => {
        return (<div>{index + 1}</div>)
    };

    render() {
        const {
            accepted_report, billed_report, proposed_report
        } = this.state;
        return (
            <div>
                <h3>Accepted</h3>
                <Grid fluid>
                    <BootstrapTable
                        ref="table"
                        data={accepted_report && accepted_report.length > 0 && accepted_report}
                        striped={true}
                        bordered={false}
                        hover={true}
                        remote
                        className="wrapped"
                    >
                        <TableHeaderColumn
                            dataField="any"
                            dataFormat={this.indexN}
                            width={"5%"}
                        >
                            #
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="proposal_no"
                            dataFormat={this.proposalFormatter}
                            width={"10%"}
                            isKey
                        >
                            Proposal No
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="proposal_date"
                            dataFormat={this.dateFormatter}
                            width={"10%"}
                        >
                            Proposal Date
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="accepted_date"
                            dataFormat={this.dateFormatter}
                            width={"10%"}
                        > Accepted Date
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="accepted_total"
                            dataFormat={dollarsFormatter}
                            width={"10%"}
                        >Accepted Total
                        </TableHeaderColumn>
                    </BootstrapTable>
                </Grid>
                <h3>Proposed</h3>
                <Grid fluid>
                    <BootstrapTable
                        ref="table"
                        data={proposed_report && proposed_report.length > 0 && proposed_report}
                        striped={true}
                        bordered={false}
                        hover={true}
                        remote
                        className="wrapped"
                    >
                        <TableHeaderColumn
                            dataField="any"
                            dataFormat={this.indexN}
                            width={"5%"}
                        >
                            #
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="proposal_no"
                            dataFormat={this.proposalFormatter}
                            width={"10%"}
                            isKey
                        >
                            Proposal No
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="proposal_date"
                            dataFormat={this.dateFormatter}
                            width={"10%"}
                        >
                            Proposal Date
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="accepted_date"
                            dataFormat={this.dateFormatter}
                            width={"10%"}
                        > Accepted Date
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="proposed_total"
                            dataFormat={dollarsFormatter}
                            width={"10%"}
                        >Proposed Total
                        </TableHeaderColumn>
                    </BootstrapTable>
                </Grid>
                <h3>Billed</h3>
                <Grid fluid>
                    <BootstrapTable
                        ref="table"
                        data={billed_report && billed_report.length > 0 && billed_report}
                        striped={true}
                        bordered={false}
                        hover={true}
                        remote
                        className="wrapped"
                    >
                        <TableHeaderColumn
                            dataField="any"
                            dataFormat={this.indexN}
                            width={"5%"}
                        >
                            #
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="proposal_no"
                            dataFormat={this.proposalFormatter}
                            width={"10%"}
                            isKey
                        >
                            Proposal No
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="proposal_date"
                            dataFormat={this.dateFormatter}
                            width={"10%"}
                        >
                            Proposal Date
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="accepted_date"
                            dataFormat={this.dateFormatter}
                            width={"10%"}
                        > Accepted Date
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="billed_total"
                            dataFormat={dollarsFormatter}
                            width={"10%"}
                        >Billed Total
                        </TableHeaderColumn>
                    </BootstrapTable>
                </Grid>
            </div>
        );
    }
}

SalesCrossViewList.propTypes = {
    //myProp: PropTypes.object.isRequired
};

SalesCrossViewList.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        email: state.auth.email,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesCrossViewList);