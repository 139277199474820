import React, {useEffect, useState, useRef} from 'react'
import {bindActionCreators} from "redux";
import * as actions from "./EmployeesPageActions";
import {connect} from "react-redux";
import {humanize} from "../../utilities";
import {
    Button,
    Checkbox,
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Grid, InputGroup,
    Nav,
    NavItem, OverlayTrigger,
    Row,
    Tab,
    Tabs, Tooltip
} from "react-bootstrap";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import {colors, defaultDateFormat, isEmailValid, rolesDescriptions, select} from "../../common/commonHandlers";
import Helmet from "react-helmet";
import PageHeader from "../../components/PageTitle";
import {addAlert} from "../App/actions";
import * as EmployeeActions from "../../actions/employees";
import Select from "react-select";
import Dropzone from "react-dropzone";
import Attachment from "../../components/image/Attachment";
import {uploadImages, setAsPrimary, validateUser} from "../ClientsPage/tabs/Users/UsersListApi"
import _ from 'lodash'
import {SketchPicker, GithubPicker} from "react-color";
import "./EmployeesPage.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import InputGroupAddon from "react-bootstrap/lib/InputGroupAddon";
import {checkUniqueEmail} from "../RegisterPage/RegisterPageApi"
import MDSpinner from "react-md-spinner";

const isMobile = window.screen.width <= 1024;
const smMobile = window.screen.width <= 450;

const Actions = {
    ...actions,
    addAlert,
    ...EmployeeActions,
    uploadImages,
    setAsPrimary,
    validateUser,
    checkUniqueEmail
}

const employeesSortOptions = [
    {value: 'NAZ', label: 'First name A-Z'},
    {value: 'NZA', label: 'First name Z-A'},
    {value: 'LAZ', label: 'Last name A-Z'},
    {value: 'LZA', label: 'Last name Z-A'},
    {value: 'JAZ', label: 'Job title A-Z'},
    {value: 'JZA', label: 'Job title Z-A'}
]

const EmployeesPage = ({
                           actions,
                           employee,
                           employeeList,
                           editingEmployeeId,
                           filterText,
                           employeeListLastUpdated, auth
                       }) => {
    const [sortType, setSortType] = useState(employeesSortOptions[0])
    const [key, setKey] = useState(1);
    const [settings, setSettings] = useState({sort_type: "NAZ", key: 1})
    const [loading, setLoading] = useState(true)
    const [searchValue, setSearchValue] = useState('')
    const [employeesRedirectList, setEmployeesRedirectList] = useState([])
    const [roles, setRoles] = useState([])
    const [oldEmployee, setOldEmployee] = useState(undefined)
    const [showPicker, setShowPicker] = useState(false)
    const [errorMessage, setErrorMessage] = useState({})
    const [showPassword, setShowPassword] = useState(false)
    const pickerRef = useRef(null);

    useEffect(() => {
        if (oldEmployee) {
            const merged = _.merge({..._.omitBy(employee, _.isNull)}, {..._.omitBy(oldEmployee, _.isNull)})
            actions.updateEmployee(merged.id, merged)
        }
    }, [oldEmployee])

    useEffect(() => {
        function handleClickOutside(event) {
            if (pickerRef.current && !pickerRef.current.contains(event.target)) {
                setShowPicker(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [pickerRef]);

    const refreshEmployeeList = () => {
        actions.fetchEmployeeList()
        editEmployee(null)
    }

    const validation = () => {
        let is_valid = true;
        let newErrors = {};
        const requiredErrorMessage = "This field is required!";
        if(employee?.first_name?.length === 0) {
            newErrors = {...newErrors, first_name: requiredErrorMessage}
            is_valid = false
        }
        if(employee?.last_name?.length === 0) {
            newErrors = {...newErrors, last_name: requiredErrorMessage}
            is_valid = false
        }
        if(employee?.email_address?.length === 0) {
            newErrors = {...newErrors, email_address: requiredErrorMessage}
            is_valid = false
        }
        if(employee?.email_address?.length > 0 && !isEmailValid(employee?.email_address)) {
            newErrors = {...newErrors, email_address: "Email address incorrect"}
            is_valid = false
        }
        if(employee?.job_title?.length === 0) {
            newErrors = {...newErrors, job_title: requiredErrorMessage}
            is_valid = false
        }
        else if(employee?.job_title?.length > 40) {
            newErrors = {...newErrors, job_title: "Job title can't be longer than 40 characters"}
            is_valid = false
        }
        if(employee?.id > 0) {
            if(employee?.user?.password?.length > 0 || employee?.user?.password_confirmation?.length > 0) {
                if (employee?.user?.password.length < 6) {
                    newErrors = {...newErrors, password: "Passwords must be at least 6 characters"}
                    is_valid = false
                } else if (employee?.user?.password !== employee?.user?.password_confirmation) {
                    newErrors = {...newErrors, password: "Passwords don't match!"}
                    is_valid = false
                }
            }
        } else {
            if (employee?.user?.password.length < 6) {
                newErrors = {...newErrors, password: "Passwords must be at least 6 characters"}
                is_valid = false
            } else if (employee?.user?.password !== employee?.user?.password_confirmation) {
                newErrors = {...newErrors, password: "Passwords don't match!"}
                is_valid = false
            }
        }
        if (employeeList && employeeList.length > 0 && employeeList.some(e => e.color?.toUpperCase() === employee?.color?.toUpperCase() && e.id !== employee?.id)) {
            newErrors = {...newErrors, color: "Another user has already been assigned this color"}
            is_valid = false
        }
        setErrorMessage(newErrors)
        return is_valid
    }

    const submitForm = (e, id) => {
        e.preventDefault()
        if(validation() && !loading) {
            setLoading(true)
            if(oldEmployee && oldEmployee.email_address === employee.email_address && oldEmployee.id === employee.id) {
                actions.saveOrCreateEmployee(id, res => actions.saveUserInfo({
                    ...employee.user,
                    person_id: res.person_id,
                    email: employee.email_address,
                    isSubcontractor: key === 2
                }, res => {
                    if (auth?.user_id === employee?.user?.id) {
                        const newRoles = roles.filter(r => res.roles.includes(r.id)).map(r => r.code)
                        actions.updateRolesInState(newRoles)
                    }
                    actions.loadEmployeeForEditing(res?.employee_id, res => {
                        actions.fetchEmployeeList() //refresh the list
                        setErrorMessage({})
                        setOldEmployee(res)
                        setLoading(false)
                    })
                }, () => {
                    setLoading(false)
                }))
            } else {
                actions.checkUniqueEmail(employee?.email_address, employee?.user?.id, true, res => {
                    if(res.unique) {
                        actions.saveOrCreateEmployee(id, res => actions.saveUserInfo({
                            ...employee.user,
                            person_id: res.person_id,
                            email: employee.email_address,
                            isSubcontractor: key === 2
                        }, res => actions.loadEmployeeForEditing(res?.employee_id, res => {
                            actions.fetchEmployeeList() //refresh the list
                            setErrorMessage({})
                            setOldEmployee(res)
                            setLoading(false)
                        }), () => {
                            setLoading(false)
                        }))
                    } else {
                        setErrorMessage({...errorMessage, email_address: "Email address already exists"})
                        setLoading(false)
                    }
                })
            }
        }
    }

    const editEmployee = employeeId => {
        setErrorMessage({})
        actions.loadEmployeeForEditing(employeeId, res => setOldEmployee(JSON.parse(JSON.stringify(res))))
    }

    const addNewEmployee = () => {
        actions.createNewEmployee()
        editEmployee(0)
    }

    const deleteEmployee = id => {
        actions.deleteEmployee(id, () => {
            actions.fetchEmployeeList() //refresh the list
            editEmployee(null)
        })
    }

    const changeFilterText = txt => {
        setSearchValue(txt)
        editEmployee(null)
    }

    const renderTextField = (employee, attr, require = false) => {
        const name = humanize(attr)
        return (
            <FormGroup key={attr}>
                <ControlLabel>
                    {name}{require && "*"}
                </ControlLabel>
                <FormControl
                    type="text"
                    className="require-field"
                    placeholder={name}
                    value={employee[attr] || ""}
                    onChange={e => {
                        actions.updateEmployeeAttribute(
                            employee.id,
                            attr,
                            e.target.value
                        )
                }}
                />
                {require && errorMessage && errorMessage[attr] && <p className="error-message">{errorMessage[attr]}</p>}
            </FormGroup>
        )
    }

    const handleDateChange = (e, attr) => {
        if (typeof e === 'string' || e instanceof String) {
            const validFormat = moment(e).format(defaultDateFormat) === e
            if (e.length > 10) {
                employee[attr] = 'Invalid Date';
            } else if (e.length === 10 && !validFormat) {
                employee[attr] = 'Invalid Format';
            }
        } else {
            employee[attr] = (e && e !== '') ? e : null;
        }
        actions.updateEmployeeAttribute(employee.id, attr, employee[attr])
    };

    const filterAndSortEmployeeList = employees => {
        let selectedEmployees = []

        if (employees?.length > 0) {
            employees.map(e => {
                selectedEmployees.push({...e, name: `${e.company_name && key === 2 ? `${e.company_name}, ` : ''}${e.name} (${e.job_title})`})
            })

            employees = selectedEmployees
        }

        let filteredEmployeeList = []

        if (key === 1) {
            filteredEmployeeList = employees.filter(value => !value.disabled && !value.roles.includes(7))
        } else if (key === 2) {
            filteredEmployeeList = employees.filter(value => !value.disabled && value.roles.includes(7))
        } else if (key === 3) {
            filteredEmployeeList = employees.filter(value => value.disabled)
        }

        if (searchValue?.length > 0) {
            filteredEmployeeList = filteredEmployeeList.filter(e => (e.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1))
        }

        switch (sortType.value) {
            case 'NAZ':
                filteredEmployeeList = filteredEmployeeList.sort((a, b) => {
                    if (a.first_name > b.first_name) return 1
                    if (b.first_name > a.first_name) return -1
                    return 0
                })
                break;
            case 'NZA':
                filteredEmployeeList = filteredEmployeeList.sort((a, b) => {
                    if (a.first_name > b.first_name) return -1
                    if (b.first_name > a.first_name) return 1
                    return 0
                })
                break;
            case 'LAZ':
                filteredEmployeeList = filteredEmployeeList.sort((a, b) => {
                    if (a.last_name > b.last_name) return 1
                    if (b.last_name > a.last_name) return -1
                    return 0
                })
                break;
            case 'LZA':
                filteredEmployeeList = filteredEmployeeList.sort((a, b) => {
                    if (a.last_name > b.last_name) return -1
                    if (b.last_name > a.last_name) return 1
                    return 0;
                })
                break;
            case 'JAZ':
                filteredEmployeeList = filteredEmployeeList.sort((a, b) => {
                    if (a.job_title > b.job_title) return 1
                    if (b.job_title > a.job_title) return -1
                    return 0
                })
                break;
            case 'JZA':
                filteredEmployeeList = filteredEmployeeList.sort((a, b) => {
                    if (a.job_title > b.job_title) return -1
                    if (b.job_title > a.job_title) return 1
                    return 0;
                })
                break;
        }
        return filteredEmployeeList
    }

    const saveSettings = settings => {
        actions.saveEmployeesSettings(JSON.stringify(settings))
    }

    const handleShowPasswordChanger = () => {
        return (
            <OverlayTrigger placement="top"
                            onClick={() => setShowPassword(!showPassword)}
                            overlay={<Tooltip
                                id="reset_password_tooltip">{showPassword ? "Hide password" : "Show password"}</Tooltip>}>
                {showPassword ?
                    <p className="no-margin pointer"><FontAwesomeIcon icon={faEye}/></p>
                    :
                    <p className="no-margin pointer"><FontAwesomeIcon icon={faEyeSlash}/></p>}
            </OverlayTrigger>
        )
    }

    const renderDateTimeField = (employee, birthday, start_date, termination_date) => {
        return (
            <div>
                {key !== 2 && <Col md={4} style={{paddingRight: isMobile ? "0" : null}}>
                    <FormGroup>
                        <ControlLabel>Date of birth</ControlLabel>
                        <Datetime
                            dateFormat={defaultDateFormat}
                            timeFormat={null}
                            isClearable
                            value={employee.id === 0 ? employee[birthday] : employee[birthday] && moment(employee[birthday]).format(defaultDateFormat)}
                            name={birthday}
                            inputProps={{placeholder: "Date of birth"}}
                            closeOnSelect
                            onChange={e => handleDateChange(e, birthday)}
                        />
                    </FormGroup>
                </Col>}
                <Col md={key === 2 ? 6 : 4} style={{paddingRight: isMobile ? "0" : null}}>
                    <FormGroup>
                        <ControlLabel>Start Date</ControlLabel>
                        <Datetime
                            dateFormat={defaultDateFormat}
                            isClearable
                            utc
                            timeFormat={false}
                            closeOnSelect
                            value={employee[start_date] && moment(employee[start_date]).format(defaultDateFormat)}
                            name={start_date}
                            inputProps={{placeholder: "Start date"}}
                            onChange={e => handleDateChange(e, start_date)}
                        />
                    </FormGroup>
                </Col>
                <Col md={key === 2 ? 6 : 4} style={{paddingRight: isMobile ? "0" : null}}>
                    <FormGroup>
                        <ControlLabel>Termination Date</ControlLabel>
                        <Datetime
                            dateFormat={defaultDateFormat}
                            timeFormat={null}
                            isClearable
                            utc
                            value={employee[termination_date] && moment(employee[termination_date]).format(defaultDateFormat)}
                            name={termination_date}
                            inputProps={{placeholder: "Termination date"}}
                            closeOnSelect
                            onChange={e => handleDateChange(e, termination_date)}
                        />
                    </FormGroup>
                </Col>
            </div>
        )
    }

    const renderWorkDateInputs = (employee, attr_1, attr_2) => (
        <Row>
            <Col md={4}>
                <FormGroup>
                    <ControlLabel>Start Date</ControlLabel>
                    <Datetime
                        dateFormat={defaultDateFormat}
                        timeFormat={false}
                        value={employee[attr_1]}
                        name={attr_1}
                        inputProps={{placeholder: "Date of birth"}}
                        closeOnSelect
                        onChange={e => handleDateChange(e, attr_1)}
                    />
                </FormGroup>
            </Col>
            <Col md={4}>
                <FormGroup>
                    <ControlLabel>Termination Date</ControlLabel>
                    <Datetime
                        dateFormat={defaultDateFormat}
                        timeFormat={false}
                        value={employee[attr_2]}
                        name={attr_2}
                        inputProps={{placeholder: "Date of birth"}}
                        closeOnSelect
                        onChange={e => handleDateChange(e, attr_2)}
                    />
                </FormGroup>
            </Col>
        </Row>
    )


    const renderTextArea = (employee, attr) => {
        const name = humanize(attr)
        return (
            <FormGroup bsSize="sm" key={attr}>
                <ControlLabel>{name}</ControlLabel>
                <FormControl
                    componentClass="textarea"
                    placeholder={name}
                    value={employee[attr] || ""}
                    onChange={e =>
                        actions.updateEmployeeAttribute(
                            employee.id,
                            attr,
                            e.target.value
                        )}
                />
            </FormGroup>
        )
    }

    const renderCheckbox = (employee, attr) => (
        <FormGroup bsSize="sm">
            <Checkbox
                checked={employee[attr]}
                onChange={e =>{
                    actions.updateEmployeeAttribute(
                        employee.id,
                        attr,
                        e.target.checked
                    )
                    if(!e.target.checked){
                        actions.updateEmployeeAttribute(
                            employee.id,
                            'redirect_employee_id',
                            null
                        )
                    }
                    setEmployeesRedirectList([])
                }}
            >
                Disabled?
            </Checkbox>
        </FormGroup>
    )

    const renderUserAccountStatus = employee => {
        const isUserInactive = employee.is_user_disabled ? "Inactive" : "Active";

        if (employee?.user?.id) {
            return (
                <div>
                    User account status: <span
                    className={employee.is_user_disabled ? "colorRed" : "text-green"}>{isUserInactive}</span>
                </div>
            )
        }
    }

    const renderArboristCheckbox = (employee, attr) => (
        <FormGroup bsSize="sm">
            <Checkbox
                checked={employee[attr]}
                onChange={e =>
                    actions.updateEmployeeAttribute(
                        employee.id,
                        attr,
                        e.target.checked
                    )}
            >
                Check if employee is a Sales Arborist
            </Checkbox>
        </FormGroup>
    )

    const renderManagerRoles = () => {
        return <>
            Roles
            {roles && roles.map(
                r => {
                    const checkbox = (
                        <div key={r.id}>
                            <Checkbox
                                name="role_name"
                                onChange={e => {
                                    let roles = employee?.user?.roles

                                    if (e.target.checked) {
                                        roles.push(r.id)
                                    }
                                    if (!e.target.checked) {
                                        roles = roles.filter(ur => ur !== r.id)
                                    }

                                    const user = {...employee.user, roles: roles}
                                    actions.updateEmployeeAttribute(employee.id, "user", user)
                                }}
                                inline
                                checked={employee?.user?.roles?.includes(r.id)}
                            >
                                {" "}
                                <OverlayTrigger placement="right" overlay={<Tooltip>
                                    <div>{rolesDescriptions[r.code]}</div>
                                </Tooltip>}>
                                    <div>
                                        {r.code}
                                    </div>
                                </OverlayTrigger>

                            </Checkbox></div>);
                    return checkbox;
                }
            )}
        </>
    }

    const renderImageDropZone = () => {
        return <>
            <FormGroup>
                <ControlLabel>
                    Image
                </ControlLabel>
                <Dropzone
                    onDrop={file => actions.uploadImages(file, employee.user.id, img => {
                        img.primary = false
                        const oldUser = {...oldEmployee.user, images: employee?.user?.images.concat(img)}
                        employee = {
                            ...employee,
                            user: {
                                ...employee.user,
                                images: employee?.user?.images.concat(img),
                                password: "",
                                password_confirmation: ""
                            }
                        }
                        actions.updateEmployeeAttribute(employee.id, "user", employee.user)
                        actions.saveUserInfo(oldUser)
                    })}
                    className="dropzone pointer small">
                    <p>Drop a file here or click to select a file to upload.</p>
                </Dropzone>
                <Col md={12}>
                    {!employee?.user?.images || employee?.user?.images?.length === 0 ? "No photos found. Please upload some." : ""}
                    {employee?.user?.images && employee?.user?.images?.map(i => <Attachment key={i.id}
                                                                                            reloadData={() => actions.fetchEmployeeList()}
                                                                                            image={i}
                                                                                            allowRemove={true}
                                                                                            setAsMain={() =>
                                                                                                actions.setAsPrimary(i.id, () => {
                                                                                                    const oldPrimary = employee.user.images.find(image => image.primary)
                                                                                                    if (oldPrimary) oldPrimary.primary = false
                                                                                                    employee.user.images.find(image => image.id === i.id).primary = true
                                                                                                    actions.fetchEmployeeList()
                                                                                                })}
                                                                                            deleteImage={imageId => {
                                                                                                const index = employee.user.images.findIndex(image => image.id === imageId)
                                                                                                const oldUser = oldEmployee.user
                                                                                                employee = {
                                                                                                    ...employee,
                                                                                                    user: {
                                                                                                        ...employee.user,
                                                                                                        password: "",
                                                                                                        password_confirmation: ""
                                                                                                    }
                                                                                                }
                                                                                                employee.user.images.splice(index, 1)
                                                                                                oldUser.images.splice(index, 1)
                                                                                                actions.updateEmployeeAttribute(employee.id, "user", employee.user)
                                                                                                actions.saveUserInfo(oldUser)
                                                                                            }}
                    />)}
                </Col>
            </FormGroup>
        </>
    }

    const renderPassword = () => {
        return (
            <Row className="generate-password require-field">
                <Col md={8} xs={12}>
                    <Row className="align-text-end" style={{flexDirection: isMobile ? "column" : "row"}}>
                        <Col xs={isMobile ? 12 : 6}>
                            <FormGroup>
                                <ControlLabel>
                                    New Password*
                                </ControlLabel>
                                <InputGroup>
                                    <FormControl
                                        name="password"
                                        type={showPassword ? "text" : "password"}
                                        autoComplete="new-password"
                                        placeholder="password"
                                        value={employee.user?.password && employee.user?.password}
                                        onChange={e => {
                                            const user = {...employee.user, password: e.target.value?.replace(/ /g,'')}
                                            actions.updateEmployeeAttribute(employee.id, "user", user)
                                        }}
                                    />
                                    <InputGroupAddon>
                                        {handleShowPasswordChanger()}
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs={isMobile ? 12 : 6}>
                            <FormGroup>
                                <ControlLabel>
                                    Password Confirmation*
                                </ControlLabel>
                                <InputGroup>
                                    <FormControl
                                        name="password_confirmation"
                                        type={showPassword ? "text" : "password"}
                                        placeholder="password confirmation"
                                        value={employee.user?.password_confirmation}
                                        onChange={e => {
                                            const user = {...employee.user, password_confirmation: e.target.value?.replace(/ /g,'')}
                                            actions.updateEmployeeAttribute(employee.id, "user", user)
                                        }}
                                    />
                                    <InputGroupAddon>
                                        {handleShowPasswordChanger()}
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        {errorMessage?.password?.length > 0 && <p className="error-message">{errorMessage?.password}</p>}
                    </Row>
                </Col>
                <Col md={4} xs={12} className="bottom15 text-center">
                    <Button bsStyle="success responsive-button" style={{width: "clamp(10px, 100%, 200px)"}}
                            onClick={() => actions.generatePassword(res => {
                                const user = {
                                    ...employee.user,
                                    password: res.password,
                                    password_confirmation: res.password
                                }
                                actions.updateEmployeeAttribute(employee.id, "user", user)
                            })}>
                        Generate Password
                    </Button>
                </Col>
            </Row>
        )
    }

    const renderColor = () => {
        return (
            <>
                <div className="user_color">
                    <div
                        style={{backgroundColor: employee?.color}}
                        className={`color-picker pointer img-circle color-circle`}
                        onClick={() => setShowPicker(!showPicker)}/>
                    <span>
                        User Color
                    </span>
                </div>
                <div ref={pickerRef}>
                    {showPicker && <SketchPicker
                        color={employee?.color}
                        className="picker"
                        onChangeComplete={e => {
                            if (e.hex) {
                                actions.updateEmployeeAttribute(employee.id, "color", e.hex)
                            }
                        }}
                    />}
                </div>
                {errorMessage && errorMessage.color && <p className="error-color-message">{errorMessage.color}</p>}
            </>
        )
    }

    const renderEmployeeEditor = employee => (
        <form onSubmit={e => submitForm(e, employee.id)} id="employee-page">
            <Row className="full-width">
                <Col xs={12}>
                    {key === 2 && <Row>
                        <Col xs={12}>
                            {renderTextField(employee, "company_name")}
                        </Col>
                    </Row>}
                    <Row>
                        <Col xs={6}>
                            {renderTextField(employee, "first_name", true)}
                        </Col>

                        <Col xs={6}>
                            {renderTextField(employee, "last_name", true)}
                        </Col>

                    </Row>

                    <Row>
                        <Col xs={6}>
                            {renderTextField(employee, "phone_number")}
                        </Col>
                        {!isMobile && <Col xs={6}>
                            {renderTextField(employee, "email_address", true)}
                        </Col>}

                    </Row>
                    {isMobile && <Row>
                        <Col xs={12}>
                            {renderTextField(employee, "email_address", true)}
                        </Col>
                    </Row>}
                    <Row>
                        <Col xs={isMobile ? 12 : 6}>
                            {renderTextField(employee, "job_title", true)}
                        </Col>
                        <Col xs={12} md={isMobile ? 12 : 6}
                             className={!smMobile ? "no-padding" : smMobile && "no-left-padding"}>
                            {renderDateTimeField(employee, "birthday", "start_date", "termination_date")}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={isMobile ? 8 : 4}>
                            {renderArboristCheckbox(employee, "is_sales_arborist")}
                            {renderCheckbox(employee, "disabled")}
                            {employeesRedirectList && employeesRedirectList.length > 0 && <p className='text-danger'>The user you want to disable is assigned to receive redirected emails and must be unassigned first from: {employeesRedirectList.join(', ')}</p>}
                            {employee.disabled && <FormGroup bsSize="sm" key={'redirect_employee_id'}>
                                <ControlLabel>Emails redirect to</ControlLabel>
                                <Select className="Select" classNamePrefix="select"
                                        options={employeeList.filter(e => !e.disabled && e.id !== employee.id).map(e => ({
                                            label: e.name,
                                            value: e.id
                                        }))}
                                        isClearable
                                        value={select(employeeList.filter(e => !e.disabled && e.id !== employee.id).map(e => ({
                                            label: e.name,
                                            value: e.id
                                        })), employee.redirect_employee_id)}
                                        onChange={e => actions.updateEmployeeAttribute(
                                            employee.id,
                                            'redirect_employee_id',
                                            e ? e.value : e
                                        )}/>
                            </FormGroup>

                            }
                        </Col>
                        <Col xs={isMobile ? 4 : 2}>
                            {employee.id !== 0 && renderColor()}
                        </Col>
                        <Col xs={isMobile ? 12 : 6}>
                            {renderPassword()}
                        </Col>
                    </Row>
                    <Row>
                        {employee.is_sales_arborist === true ?
                            <Col xs={isMobile ? 12 : 6}>
                                For Export as QuickBooks Sales Rep
                                {renderTextField(employee, "initials")}
                            </Col> : <Col xs={isMobile ? 12 : 6}/>}
                    </Row>
                    <Row>
                        <Col xs={6}>
                            {employee.id !== 0 && renderManagerRoles()}
                        </Col>
                        <Col xs={6}>
                            {employee.id !== 0 && renderImageDropZone()}
                            {renderUserAccountStatus(employee)}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col xs={12} style={isMobile ? {marginTop: "15px"} : {}}>
                    <Row>
                        <Col xs={12}>
                            <Row>
                                <Col xs={4}>
                                    <Button
                                        disabled={!(key === 3 && employee.disabled)}
                                        bsStyle="danger"
                                        className="responsive-button"
                                        style={{width: isMobile ? "150px" : "clamp(10px, 26vw, 130px)"}}
                                        onClick={e => (key === 3 && employee.disabled) && deleteEmployee(employee.id)}
                                    >
                                        Delete employee
                                    </Button>
                                </Col>
                                <Col xs={8}>
                                    {employee.id === 0
                                        ? <Button
                                            bsStyle="info"
                                            className="pull-right"
                                            type="submit"
                                            disabled={loading ||
                                                !employee?.first_name ||
                                                !employee?.last_name ||
                                                !employee?.email_address ||
                                                !employee?.job_title ||
                                                !employee?.user?.password ||
                                                !employee?.user?.password_confirmation ||
                                                (employee?.user?.password !== employee?.user?.password_confirmation)
                                            }
                                        >
                                            {loading ? <span><MDSpinner size={20}/> Loading...</span> : 'Save New employee'}
                                        </Button>
                                        :
                                        <div className="success_buttons">
                                            <div>
                                                <Button
                                                    bsStyle="success"
                                                    style={{width: "clamp(10px, 57vw, 200px)"}}
                                                    className="reset_password_button responsive-button"
                                                    onClick={() => {actions.requestPasswordChange(employee?.user?.id)}}
                                                    disabled={employee.disabled}
                                                >
                                                    Request password change
                                                </Button>
                                            </div>
                                            <div className="d-flex" style={isMobile ? {flexDirection: "column", gap: "10px"} : {}}>
                                                <Button
                                                    bsStyle="success"
                                                    style={{width: isMobile ? "150px" : "clamp(10px, 27vw, 120px)"}}
                                                    className={`${isMobile ? "" : "mr-10"} responsive-button`}
                                                    onClick={() => { actions.requestPasswordChange(employee?.user?.id, 'invitation')}}
                                                    disabled={employee.disabled}
                                                >
                                                    Send Invitation
                                                </Button>
                                                <Button
                                                    disabled={loading}
                                                    bsStyle="success"
                                                    style={{width: isMobile ? "150px" : "clamp(10px, 27vw, 120px)"}}
                                                    className="responsive-button"
                                                    type="submit"
                                                >
                                                    {loading ? <span><MDSpinner size={20}/> Loading...</span> : 'Save Changes'}
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                </Col>
                            </Row>


                        </Col>
                    </Row>
                    <Row className="mt27">
                        <Col xs={12}>
                            <p>
                                {`Last updated ${employee.modified_at ? moment(employee.modified_at).format("llll") : <i>Unknown</i>} by ${employee.modified_by}`}
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </form>
    )

    useEffect(() => refreshEmployeeList(), [])
    useEffect(() => {
        if(employee?.disabled){
            actions.checkIfHaveRedirects(employee.id, res => {
                setEmployeesRedirectList(res.employees_list)
            })
        }
    }, [employee])

    useEffect(() => {
        actions.loadEmployeesSettings(res => {
            const settings = JSON.parse(res.settings)
            setSettings(settings)
            setRoles(res.roles)
            if (settings) {
                settings.sort_type && setSortType(employeesSortOptions.find(e => e.value === settings.sort_type))
                settings.key && setKey(settings.key)
            }
            setLoading(false)
        })
    }, [])

    return (
        <Grid fluid>
            <Helmet title="Employees"/>
            <PageHeader
                pageName="Employees"
                pageDetail="Manage Employees."
            />
            <Row>
                <Col xs={12}>
                    <Button onClick={e => refreshEmployeeList()} bsStyle="success">
                        Refresh employees
                    </Button>
                    {" "}
                    <Button onClick={e => addNewEmployee()} bsStyle="info">
                        New {key === 2 ? 'subcontractor' : 'employee'}
                    </Button>
                </Col>
            </Row>
            <p>&nbsp;</p>

            <Row>
                <Col xs={12} md={3}>
                    <Tabs defaultActiveKey={1} animation={false} activeKey={key}
                          onSelect={k => {
                              saveSettings({...settings, key: k})
                              setKey(k)
                              setSettings({...settings, key: k})
                              refreshEmployeeList()
                          }}>
                        <Tab eventKey={1} title="Active"></Tab>
                        <Tab eventKey={2} title="Subcontractors"></Tab>
                        <Tab eventKey={3} title="Archived"></Tab>
                    </Tabs>
                    <form>
                        <FormGroup className="mt5 bottom5">
                            <ControlLabel>Filter</ControlLabel>
                            <FormControl
                                type="text"
                                placeholder="Filter employees"
                                value={searchValue}
                                onChange={e => changeFilterText(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Sort</ControlLabel>
                            <Select className="Select"
                                    classNamePrefix="select"
                                    defaultValue={employeesSortOptions[0]}
                                    options={employeesSortOptions}
                                    isSearchable={false}
                                    value={sortType}
                                    onChange={newValue => {
                                        setSortType(newValue)
                                        saveSettings({...settings, sort_type: newValue.value})
                                        setSettings({...settings, sort_type: newValue.value})
                                    }}
                            />
                        </FormGroup>
                    </form>
                    <div style={{height: isMobile ? employee ? "250px" : "560px" : "560px", overflowY: "scroll"}}>
                        <Nav
                            bsStyle="pills"
                            stacked
                            activeKey={editingEmployeeId}
                            onSelect={editEmployee}
                        >
                            {filterAndSortEmployeeList(employeeList).map(p => (
                                    <NavItem key={p.id} eventKey={p.id}>
                                        <div className='vertical-align'>
                                            <>{p.name}</>
                                            {/*<span className='font11 ml-7'>{`(${p?.job_title})`}</span>*/}
                                        </div>
                                    </NavItem>
                                )
                            )}

                            {filterText.length > 0 &&
                                filterAndSortEmployeeList(employeeList).length === 0 &&
                                <p>No Employees Match Your Filter</p>}
                        </Nav>
                    </div>
                </Col>
                <Col xs={12} md={9}>
                    {employee && renderEmployeeEditor(employee)}
                </Col>
            </Row>
        </Grid>
    )
}

const mapStateToProps = state => {
    return {
        employeeList: state.employees.employeeList.filter(
            p =>
                p.name
                    .toLowerCase()
                    .indexOf(state.employeesCrud.filterText.toLowerCase()) !== -1
        ),
        employeeListLastUpdated: state.employees.employeeListLastUpdated,
        filterText: state.employeesCrud.filterText,
        editingEmployeeId: state.employeesCrud.editingEmployeeId,
        employee: state.employees.employees[
            `employee_${state.employeesCrud.editingEmployeeId}`
            ],
        auth: state.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(Actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesPage)
