import React, {Component} from "react";
import './Dashboard.css';
import {Badge, Button, Col, FormControl, MenuItem, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {
    ageFormatter,
    dollarsFormatter,
    dateArrayFormatter,
    proposalNoFormatterWithEmail
} from "../../components/BootStrapTableCellFormatters";
import moment from "moment-timezone";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileCsv} from "@fortawesome/free-solid-svg-icons/faFileCsv";
import {faCog, faEnvelopeSquare, faMapMarkerAlt, faThumbsDown} from "@fortawesome/free-solid-svg-icons";
import Dollars from "../../components/Dollars";
import {LinkContainer} from "react-router-bootstrap";

const isMobile = window.screen.width <= 1024;
const smMobile = window.screen.width <= 450;
const xsMobile = window.screen.width <= 350;

class BottomRightPanelMobile extends Component {


    serviceNameFormatter = (cell, row, service_prop) => {
        if (!this.props.resource.isBacklog) return
        if (service_prop === "service_total_accepted") {
            return <div><Dollars
                amount={row.service_total_accepted}/>
            </div>
        }
        if (service_prop === "service_name") {
            return <div>{row.name}</div>
        } else if (service_prop === "service_type") {
            return <div>{row.service_type}</div>
        } else if (service_prop === "service_amount") {
            return <div><Dollars amount={row.service_total}/></div>
        } else if (service_prop === "service_mhrs") {
            return <div>{row.man_hrs}</div>
        } else if (service_prop === "service_status" && row.ps_status) {
            const status_name = row.ps_status.charAt(0).toUpperCase() + row.ps_status.slice(1).toLowerCase();
            return <div>{status_name}</div>
        } else if (service_prop === "service_priority") {
            return <div>{row.priority ? row.priority : 'N/A'}</div>
        } else if (service_prop === "service_event_dates") {
            return <div>{row.event_dates ? row.event_dates : 'N/A'}</div>
        } else if (service_prop === "service_promised_dates") {
            return <div>{row.ps_promised_start_at && row.ps_promised_end_at ? moment(row.ps_promised_start_at).format('MM/DD/YYYY')
                + "-" + moment(row.ps_promised_end_at).format('MM/DD/YYYY') : '' + 'N/A'}</div>
        }

    }

    renderSizePerPageDropDown = (props) => {
        return (
            <div className="btn-group dropup">
                <button className="btn btn-info dropdown-toggle" type="button" id="dropdownMenu2"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span>{props.currSizePerPage}</span>
                    <span className="ml-6 caret"></span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                    {props.sizePerPageList.map(item =>
                        <li>
                            <a
                                className='table-pagination dropdown-item'
                                onClick={() => props.changeSizePerPage(item.value)}
                            >{item.text}</a>
                        </li>
                    )}
                </ul>
            </div>
        );
    }

    rowFormatter = (cell, row, selectedStatus, filter, sentFormatter, lastSentFormatter, sentCompleteFormatter) => {
        const {tabColumns} = this.props;
        return <div>
            {((selectedStatus === 'Invoiced')
                || (selectedStatus === 'In Draft')
                || (selectedStatus === 'Sent')
                || (selectedStatus === 'Completed')
                || (selectedStatus === 'Paid'))
            && tabColumns.includes('Services')
                ?
                <Row>
                    <Col xs={6}>Services</Col>
                    <Col xs={6}>{this.serviceFormatter(cell, row, selectedStatus)}</Col>
                </Row> : null
            }
            {tabColumns.includes('Customer') &&
            <Row>
                <Col xs={6}>Customer</Col>
                <Col xs={6}>{row.customer_name}</Col>
            </Row>}
            {(selectedStatus === 'Sent' && tabColumns.includes('#Sent')) &&
            (<Row>
                <Col xs={6}>#Sent</Col>
                <Col xs={6}>{sentFormatter(row.email_count)}</Col>
            </Row>)}
            {(selectedStatus === 'Sent' && tabColumns.includes('Due on')) &&
            (<Row>
                <Col xs={6}>Due on</Col>
                <Col xs={6}>{lastSentFormatter(row.last_follow_up_date)}</Col>
            </Row>)}
            {(selectedStatus === 'Sent' && tabColumns.includes('Completed')) &&
            (<Row>
                <Col xs={6}>Completed</Col>
                <Col xs={6}>{sentCompleteFormatter(row.complete)}</Col>
            </Row>)}
            {(selectedStatus === 'Backlog') &&
            (
                <div>
                    {tabColumns.includes('Service') &&
                    <Row>
                        <Col xs={6}>Service</Col>
                        <Col xs={6}>{this.serviceNameFormatter(cell, row, "service_name")}</Col>
                    </Row>}
                    {tabColumns.includes('Service Type') &&
                    <Row>
                        <Col xs={6}>Service Type</Col>
                        <Col xs={6}>{this.serviceNameFormatter(cell, row, "service_type")}</Col>
                    </Row>}
                    {tabColumns.includes('Service $') &&
                    <Row>
                        <Col xs={6}>Service $</Col>
                        <Col xs={6}>{this.serviceNameFormatter(cell, row, "service_amount")}</Col>
                    </Row>}
                    {tabColumns.includes('MHRS') &&
                    <Row>
                        <Col xs={6}>MHRS</Col>
                        <Col xs={6}>{this.serviceNameFormatter(cell, row, "service_mhrs")}</Col>
                    </Row>}
                    {tabColumns.includes('Status') &&
                    <Row>
                        <Col xs={6}>Status</Col>
                        <Col xs={6}>{this.serviceNameFormatter(cell, row, "service_status")}</Col>
                    </Row>}
                    {tabColumns.includes('Priority') &&
                    <Row>
                        <Col xs={6}>Priority</Col>
                        <Col xs={6}>{this.serviceNameFormatter(cell, row, "service_priority")}</Col>
                    </Row>}
                </div>
            )}
            {tabColumns.includes('Price') &&
            <Row>
                <Col xs={6}>Price</Col>
                <Col xs={6}>{dollarsFormatter(selectedStatus === 'Invoiced' ? row.invoices_total : row.price)}</Col>
            </Row>}
            {tabColumns.includes('Proposal #') &&
            <Row>
                <Col xs={6}>Proposal#</Col>
                <Col
                    xs={6}>{proposalNoFormatterWithEmail(cell, row, this.props.onEmail, filter && ['unscheduled', 'scheduled'].includes(filter.key))}</Col>
            </Row>}
            {tabColumns.includes('Age') &&
            <Row>
                <Col xs={6}>Age</Col>
                <Col xs={6}>{ageFormatter(selectedStatus === 'Backlog' ? row.accepted_date : row.created_at)}</Col>
            </Row>}
            {(selectedStatus !== 'Backlog' && tabColumns.includes('Created')) &&
            <Row>
                <Col xs={6}>Created</Col>
                <Col xs={6}>{moment(row.created_at).format('MM/DD/YYYY h:mmA')}</Col>
            </Row>
            }
            {this.statusDateFormatter(cell, row, selectedStatus)}
            {tabColumns.includes('Arborist') &&
            <Row>
                <Col xs={6}>Arborist</Col>
                <Col xs={6}>{row.arborist}</Col>
            </Row>}
            {(selectedStatus === 'Invoiced' || selectedStatus === 'Paid') && tabColumns.includes('WOs') &&
            <Row>
                <Col xs={6}>WOs</Col>
                <Col xs={6}>{this.linkFormatter(row.work_orders, row)}</Col>
            </Row>
            }
            {(selectedStatus === 'Paid' || selectedStatus === 'Invoiced') && tabColumns.includes('Invoices') &&
            <Row>
                <Col xs={6}>Invoices</Col>
                <Col xs={6}>{this.linkFormatter(row.invoices_no, row)}</Col>
            </Row>
            }
            {selectedStatus === 'Backlog' && tabColumns.includes('Site') &&
            <Row>
                <Col xs={6}>Site</Col>
                <Col xs={6}>{row.site}</Col>
            </Row>
            }
        </div>
    };

    linkFormatter = (cell, row) => {
        return <div className='dropdown'>{cell && cell.length > 0 && cell.map(item => {
            return <LinkContainer target="_blank"
                                  to={item.work_order_no ? `/work_orders/${row.proposalId}` : `/proposals/${row.proposalId}/invoices/`}>
                <MenuItem bsSize="small">{item.work_order_no ? `#${item.work_order_no}` : `#${item.number}`}</MenuItem>
            </LinkContainer>
        })
        }</div>
    }

    statusDateFormatter = (cell, row, selectedStatus) => {
        const {tabColumns} = this.props;
        if (selectedStatus === 'Sent' && tabColumns.includes('Sent Date')) {
            return (
                <Row>
                    <Col xs={6}>Sent Date</Col>
                    <Col xs={6}>{moment(row.sent_date).format('MM/DD/YYYY h:mmA')}</Col>
                </Row>
            )
        }
        if (selectedStatus === 'Backlog') {
            return (
                <span>
                    {tabColumns.includes('Accepted') &&
                    <Row>
                        <Col xs={6}>Accepted Date</Col>
                        <Col xs={6}>{moment(row.accepted_date).format('MM/DD/YYYY h:mmA')}</Col>
                    </Row>}
                    {tabColumns.includes('Promised') &&
                    <Row>
                        <Col xs={6}>Promised dates</Col>
                        <Col
                            xs={6}>{this.serviceNameFormatter(cell, row, "service_promised_dates")}</Col>
                    </Row>}
                    {tabColumns.includes('Scheduled on') &&
                    <Row>
                        <Col xs={6}>Scheduled on</Col>
                        <Col xs={6}>{this.serviceNameFormatter(cell, row, "service_event_dates")}</Col>
                    </Row>}
                </span>
            )
        }
        if (selectedStatus === 'Completed' && tabColumns.includes('Completed at')) {
            return (
                <Row>
                    <Col xs={6}>Completed at</Col>
                    <Col xs={6}>{moment(row.completed_at).format('MM/DD/YYYY h:mmA')}</Col>
                </Row>
            )
        }
        if (selectedStatus === 'Invoiced' && tabColumns.includes('Invoiced at')) {
            return (
                <Row>
                    <Col xs={6}>Invoiced at</Col>
                    <Col xs={6}>{this.paidFormatter(cell, row, selectedStatus)}</Col>
                </Row>
            )
        }
        if (selectedStatus === 'Paid') {
            return (
                <div>
                    {tabColumns.includes('Paid Dates') &&
                    <Row>
                        <Col xs={6}>Paid Dates</Col>
                        <Col xs={6}>{this.paidFormatter(cell, row, selectedStatus)}</Col>
                    </Row>}
                    {tabColumns.includes('Total Paid') &&
                    <Row>
                        <Col xs={6}>Total Paid</Col>
                        <Col xs={6}>{<Dollars amount={row.total_paid}/>}</Col>
                    </Row>}
                </div>
            )
        }
    };

    serviceTooltip = (serviceName) => (
        <Tooltip id="tooltip">
            {serviceName && serviceName.length > 0 && serviceName.map((service, i) => (
                <div className={`flex-column-start ${i === serviceName.length - 1 ? null : "bottom5"}`} key={i}>
                    <span className={`text-left bold ${smMobile ? "font10" : null}`}>{service.name}</span>
                    <span className={`text-left ${smMobile ? "font10" : null}`}>{service.date}</span>
                </div>
            ))}
        </Tooltip>
    );

    serviceFormatter = (cell, row, selectedStatus) => {
        return (
            <OverlayTrigger placement="right" overlay={this.serviceTooltip(row.status_name)}>
                <Badge
                    className={`proposalBadge font12 ${smMobile ? null : "ml-6"}`}>{selectedStatus === 'Invoiced' || selectedStatus === 'Paid' ? row.service_date && row.service_date.length : row.status}</Badge>
            </OverlayTrigger>
        )
    };

    paidFormatter = (cell, row, selectedStatus) => {
        return (
            <OverlayTrigger placement={smMobile ? "bottom" : "right"}
                            overlay={this.paidTooltip(row.partial_payments, row.invoiced_at, selectedStatus)}>
                <Badge
                    className={`proposalBadge font12 ${smMobile ? null : "ml-6"}`}>{selectedStatus === 'Paid' ? row.partial_payments && row.partial_payments.length : selectedStatus === 'Invoiced' ? row.invoiced_at && row.invoiced_at.length : row.status}</Badge>
            </OverlayTrigger>
        )
    };

    paidTooltip = (paidServices, invoicedServices, selectedStatus) => {
        const invoiced_services = invoicedServices && invoicedServices.map(x => x.split("|"));

        if (selectedStatus === "Paid") {
            return (
                <Tooltip id="tooltip">
                    {paidServices && paidServices.map((service, i) => {
                            return (
                                <div
                                    className={`flex-column-start ${i === paidServices.length - 1 ? null : "bottom5"}`}
                                    key={i}>
                                    <div className="full-width space-between-end">
                                        <span
                                            className={`text-capitalize mr-9 text-left bold ${smMobile ? "font10" : null}`}>{service.status}</span>
                                        <span className={`text-left bold ${smMobile ? "font10" : null}`}>{<Dollars
                                            amount={service.amount}/>}</span>
                                    </div>
                                    <span
                                        className={`text-left ${smMobile ? "font10" : null}`}>{service.date}</span>
                                </div>
                            )
                        }
                    )}
                </Tooltip>
            )
        } else if (selectedStatus === 'Invoiced') {
            return (
                <Tooltip id="tooltip">
                    {invoiced_services && invoiced_services.map((service, i) => {
                            return (
                                <div
                                    className={`flex-column-start ${i === invoiced_services.length - 1 ? null : "bottom5"}`}
                                    key={i}>
                                    <span className={`text-left bold ${smMobile ? "font10" : null}`}>{service[0]}</span>
                                    <span
                                        className={`text-left ${smMobile ? "font10" : null}`}>{moment(service[1]).add(2, 'hour').format('MM/DD/YYYY hh:mmA')}</span>
                                </div>
                            )
                        }
                    )}
                </Tooltip>
            )
        }
    };

    topNavTooltip = (text) => (
        <Tooltip id="tooltip_btn">
            <div>
                <span>{text}</span>
            </div>
        </Tooltip>
    );

    topNavBtnFormatter = (csvBtn, declineBtn, emailBtn, markerBtn, settingsBtn) => {
        const {dashboardStatuses, selected, selectedRecords, handleData, toggleDeclinedModal, allSelected, declineSelected, selectedStatus, resource, reloadReport, data, setEmailRecipients, onChangeState} = this.props;

        return (
            <div style={{display: 'flex'}}>
                <OverlayTrigger placement="top" overlay={this.topNavTooltip(csvBtn)}>
                    <Button className={smMobile ? "table_btn" : "btnHeight mr-3"}
                            disabled={!allSelected && selectedRecords.length === 0}
                            onClick={() => this.props.createCSV(dashboardStatuses[selected])}><FontAwesomeIcon
                        icon={faFileCsv} className="bigger"/></Button>
                </OverlayTrigger>
                {(selectedStatus === 'In Draft' || selectedStatus === 'Sent') &&
                <OverlayTrigger placement="top" overlay={this.topNavTooltip(declineBtn)}>
                    <Button className={smMobile ? "table_btn" : "btnHeight mr-3"}
                            onClick={() => declineSelected()}
                            disabled={!allSelected && selectedRecords.length === 0}><FontAwesomeIcon icon={faThumbsDown}
                                                                                                     className="bigger"/></Button>
                </OverlayTrigger>}
                <OverlayTrigger placement="top" overlay={this.topNavTooltip(emailBtn)}>
                    <Button className={smMobile ? "table_btn" : "btnHeight mr-3"}
                            onClick={() => {
                                if (resource.isBacklog) {
                                    if (data && data.length > 0 && selectedRecords && selectedRecords.length > 0) {
                                        let proposals_helper = []
                                        selectedRecords.map(s => {
                                            data.forEach(d => {
                                                if (allSelected && s === d.proposalId) {
                                                    proposals_helper.push(d.proposalId)
                                                } else if (s === d.id) {
                                                    proposals_helper.push(d.proposalId)
                                                }
                                            })
                                        })
                                        if (proposals_helper && proposals_helper.length > 0) {
                                            proposals_helper = proposals_helper.filter((p, i, arr) => arr.indexOf(p) === i)
                                        }
                                        setEmailRecipients(selectedRecords, proposals_helper)
                                    }
                                } else {
                                    handleData("email", true)
                                }
                            }}
                            disabled={!allSelected && selectedRecords.length === 0}><FontAwesomeIcon
                        icon={faEnvelopeSquare}
                        className="bigger"/></Button>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={this.topNavTooltip(markerBtn)}>
                    <Button className={smMobile ? "table_btn" : "btnHeight mr-3"}
                            onClick={() => this.props.showSelectedMarkers()}
                    ><FontAwesomeIcon icon={faMapMarkerAlt}
                                      className="bigger"/></Button>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={this.topNavTooltip(settingsBtn)}>
                    <Button className="table_btn btnHeight" onClick={() => onChangeState('showDock', true)}
                    ><FontAwesomeIcon icon={faCog}
                                      className="bigger"/></Button>
                </OverlayTrigger>
            </div>
        );
    };

    priceFormatter = (cell, row) => cell ? <Dollars amount={cell}/> : 'N/A';

    render() {
        const {data, report, selectedFilter, onSelectAll, selectedStatus, acceptedTotals, sentFormatter, lastSentFormatter, sentCompleteFormatter, dashboardStatuses, selected, resource, allSelected, selectedRecords, onChangeResource, onSearch, filter, onChangeState, tabColumns, hiddenTable} = this.props;
        const {page, sort_by, per_page, sort} = resource;

        const csvBtn = "Export selected records to CSV";
        const declineBtn = "Decline selected record";
        const emailBtn = "Send email to selected customers";
        const markerBtn = "Show selected records on map";
        const settingsBtn = "Show dashboard settings panel";

        return (
            <div className="bottom20">
                <div className="vertical-align bottom10">
                    <Col md={6} xs={6} className={isMobile ? "no-left-padding" : null}>
                        {this.topNavBtnFormatter(csvBtn, declineBtn, emailBtn, markerBtn, settingsBtn)}
                    </Col>
                    <Col md={6} xs={6} className={isMobile ? "no-left-padding no-right-padding" : null}>
                        <FormControl
                            name="q"
                            placeholder="Search..."
                            value={resource.q}
                            onChange={e => onSearch(e)}
                        />
                    </Col>
                </div>
                <Col className="remaining vertical-responsive-height">
                    <BootstrapTable
                        data={data}
                        fetchInfo={{dataTotalSize: (selectedFilter && acceptedTotals) ? acceptedTotals[selectedFilter].count : report[dashboardStatuses[selected].value].count}}
                        ref="table"
                        striped
                        bordered={false}
                        hover
                        remote
                        pagination
                        options={
                            {
                                sizePerPageDropDown: this.renderSizePerPageDropDown,
                                onPageChange: (page, per_page) => {
                                    onChangeResource({...resource, page, per_page})
                                },
                                page,
                                sizePerPageList: [{
                                    text: '10', value: 10
                                }, {
                                    text: '25', value: 25
                                },
                                    {
                                        text: '30', value: 30
                                    },
                                    {
                                        text: '50', value: 50
                                    },
                                    {
                                        text: '100', value: 100
                                    },
                                    {
                                        text: '200', value: 200
                                    },
                                    {
                                        text: 'All', value: report[dashboardStatuses[selected].value].count
                                    }],
                                sizePerPage: per_page,
                                sortName: sort_by,
                                sortOrder: sort,
                                onSortChange: (sort_by, sort) => {
                                    let {resource} = this.state;
                                    resource = {...resource, sort_by, sort};
                                    this.setState({resource}, this.fetchData);
                                }
                            }
                        }
                        className="wrapped"
                        selectRow={{
                            mode: "checkbox",
                            clickToSelect: false,
                            hideSelectColumn: hiddenTable,
                            selected: allSelected ? data.map(x => x.proposalId) : selectedRecords,
                            onSelect: (row, selected) => {
                                let {selectedRecords, allSelected} = this.props;
                                const index = selectedRecords.indexOf(selectedStatus === 'Backlog' ? row.id : row.proposalId);
                                if (index > -1) {
                                    allSelected = false
                                    selectedRecords.splice(index, 1);
                                } else {
                                    selectedRecords.push(resource.isBacklog ? row.id : row.proposalId);
                                    allSelected = selectedRecords.length === data.length
                                }
                                onChangeState("selectedRecords", selectedRecords);
                            },
                            onSelectAll: () => {
                                let {allSelected} = this.props;
                                onSelectAll(!allSelected, data)
                                onChangeState("allSelected", !allSelected);
                            },
                        }}
                    >
                        <TableHeaderColumn hidden dataField="proposalId" isKey={selectedStatus !== 'Backlog'}/>
                        <TableHeaderColumn hidden dataField="id" isKey={selectedStatus === 'Backlog'}/>
                        <TableHeaderColumn
                            dataField="proposalNo"
                            dataFormat={(cell, row) => this.rowFormatter(cell, row, selectedStatus, filter, sentFormatter, lastSentFormatter, sentCompleteFormatter)}
                        >
                        </TableHeaderColumn>
                    </BootstrapTable>
                </Col>
            </div>
        );
    }
}

export default BottomRightPanelMobile;
