import React, {Component} from "react"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import Helmet from "react-helmet"
import {addAlert} from "../App/actions"
import * as MyActions from "./CompletedServicesApi"
import {Link} from "react-router"
import Dollars from "../../components/Dollars"
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table"
import {
    dollarsFormatter,
    dateFormatter,
} from "../../components/BootStrapTableCellFormatters";
import {
    Grid,
    Row,
    Col,
    Button,
    FormGroup,
    MenuItem,
    Nav,
    NavDropdown,
    Glyphicon,
    FormControl, OverlayTrigger, Tooltip, ControlLabel,
} from "react-bootstrap"
import {LinkContainer} from "react-router-bootstrap"
import PageHeader from "../../components/PageTitle"
import moment from "moment-timezone"
import Datetime from "react-datetime";
import DateRangeFilter from "../../components/DateRangeFilter";
import TermModal from "../NewInvoicePage/TermModal";
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import Select from "react-select";
import {
    defaultDateFormat,
    defaultTimeFormat,
    mapForSelect, saveNewCsvDownload,
    select,
    sortAssetsLabels
} from "../../common/commonHandlers";
import ReactDock from "react-dock";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import './CompletedServices.scss'
import {showEmailDialog} from "../../components/email/actions"
import EmailDialog from "../../components/email/EmailDialog";
import PlantName from "../../components/PlantName";
import _ from 'lodash'
import ResourceComponent from "../../components/ResourceComponent";
import {debounce} from "throttle-debounce";

const isMobile = window.screen.width <= 1024;

const Actions = {...MyActions, addAlert, showEmailDialog, saveNewCsvDownload}

class CompletedServices extends ResourceComponent {
    state = {
        page: 1,
        per_page: 10,
        total: 0,
        loading: false,
        onlyCompleted: false,
        selectedServiceType: 'open',
        selectedInvoices: [],
        selectedCompletedServices: [],
        isFiltered: true,
        showFilters: false,
        invoiceFromDate: moment().startOf('month').format(),
        invoiceToDate: moment().endOf('month').format(),
        invoicedDate: moment(),
        term_types: [],
        termTypesOptions: [],
        selectedInvoice: null,
        completedServices: [],
        resource: {
            enable_payments: true,
            enable_ach_payments: true,
            invoiced_at: moment(),
            term_type_id: null
        },
        newInvoiceAmount: null,
        invoice_settings: {},
        visibleColumns: [],
        columnOptions: ['Customer', 'Site Address', 'Title', 'Service', 'Proposal #', 'WO #', 'Cost', 'Completed On', 'Invoice #', 'Term'],
        showDock: false,
        employeesOptions: [],
        filter: {
            hideNotSentInvoiced: false,
            hideInvoiced: true,
            employees: [],
            excludedEmployees: []
        },
        filteredServices: [],
        downloadInProgress: false,
    }

    tableRef = React.createRef()

    updateCompletedStart = completedStart => {
        completedStart = moment(completedStart).isValid()
            ? moment(completedStart).format()
            : null
        this.setState({from: completedStart})

    }

    constructor(props, context) {
        super(props, context);
        this.delayedSearch = debounce(1000,() => this.fetchServices(this.state.from, this.state.to, this.state.onlyCompleted, this.state.filter));
        this.delayedhandleCompletedChange = debounce(500, this.handleCompletedChange);
    }

    componentDidMount() {
        const {resource} = this.state
        this.props.actions.loadTermTypes(res => {
            const termTypesOptions = mapForSelect(res);
            this.setState({
                term_types: res,
                termTypesOptions,
                resource: {...resource, term_type_id: res.find(tt => tt.default_term)?.id}
            })
        })
        this.props.actions.loadEmployees(res => {
            const employeesOptions = mapForSelect(res);
            this.setState({employeesOptions: res})
        })
        this.props.actions.getInvoiceSettings(settings => {
            this.setState(() => ({visibleColumns: settings.columns}))
        })
    }

    getDate = (date) => {
        if (date && date.length > 0) {
            let datearray = date.split("/");
            let parsed = datearray[1] + '/' + datearray[0] + '/' + datearray[2];
            return parsed;
        } else {
            return null;
        }
    }

    handleFilters = () => {
        const {filter, completedServices} = this.state
        let filteredServices = completedServices
        if (filter.employees.length === 0 && filter.excludedEmployees.length === 0 && !filter.hideNotSentInvoiced) {
            this.setState({filteredServices: filteredServices, selectedInvoices: [],selectedCompletedServices: []});
        } else {
            if (filter.employees.length > 0) {
                filteredServices = filteredServices.filter(ps => ps.assigned_persons_ids.some(pid => filter.employees.map(e => e.value).includes(pid)))
            }
            if (filter.excludedEmployees.length > 0) {
                filteredServices = filteredServices.filter(ps => !ps.assigned_persons_ids.some(pid => filter.excludedEmployees.map(e => e.value).includes(pid)))
            }
            if (filter.hideNotSentInvoiced) {
                filteredServices = filteredServices.filter(ps => !ps.invoice)
            }
            this.setState({filteredServices: filteredServices, selectedInvoices: [],selectedCompletedServices: []});
        }
    }
    handleRangeDateChange = (start, end) => {
        const from = start && moment(this.getDate(start)).startOf('day');
        const to = end && moment(this.getDate(end)).endOf('day');
        this.setState({from, to})
    }
    updateTableData = (servicesIds) => {
        const {completedServices} = this.state
        let newSelectedInvoices = []
        let newSelectedCompletedServices = []

        this.setState({loading: true})
        this.props.actions.reloadServices(servicesIds, this.state.filter, this.state.from, this.state.to, (result) => {

            let newCompletedServices = _.compact(completedServices.map(ps => {
                if (servicesIds.includes(ps.id)) {
                    return result.find(r => r.id === ps.id)
                } else {
                    return ps
                }
            }))

            newCompletedServices.map(ps => {
                if (ps.invoice && this.state.selectedCompletedServices.includes(ps.id)) {
                    newSelectedInvoices.push(ps.invoice.id)
                }
                if (this.state.selectedCompletedServices.includes(ps.id)) {
                    newSelectedCompletedServices.push(ps.id)
                }
            })
            this.setState({
                completedServices: newCompletedServices,
                selectedInvoices: newSelectedInvoices,
                selectedCompletedServices: newSelectedCompletedServices,
                loading: false
            })
        })
    }

    fetchServices = (from, to, onlyCompleted, filter = this.state.filter) => {
        this.setState({loading: true, onlyCompleted: onlyCompleted})
        this.props.actions.fetchServices(from, to, onlyCompleted, filter ,this.state.page, this.state.per_page, (result) => {
            let newSelectedInvoices = []
            let newSelectedCompletedServices = []
            result.data.map(ps => {
                if (ps.invoice && this.state.selectedCompletedServices.includes(ps.id)) {
                    newSelectedInvoices.push(ps.invoice.id)
                }
                if (this.state.selectedCompletedServices.includes(ps.id)) {
                    newSelectedCompletedServices.push(ps.id)
                }
            })
            this.setState({
                total: result.total,
                completedServices: result.data,
                filteredServices: result.data,
                selectedInvoices: newSelectedInvoices,
                selectedCompletedServices: newSelectedCompletedServices,
                loading: false
            })
        })
    }
    saveInvoiceSettings = () => {
        const {invoice_settings} = this.state;
        this.props.actions.saveInvoiceSettings(invoice_settings)
    };

    handleColumnOptionsChange = e => {
        this.setState({
            visibleColumns: e.map(x => x.value),
            invoice_settings: e.map(x => x.value)
        }, this.saveInvoiceSettings);
    };

    showTermModal = (invoice_id, invoice_no, due_date, term_type_id, invoiced_at, proposal_service_id, paid_at, invoice_total, invoice_token, payments, enable_payments_client) => {
        if (invoice_id) {
            let invoice = {};
            invoice.id = invoice_id;
            invoice.total = invoice_total;
            invoice.token = invoice_token;
            invoice.no = invoice_no;
            invoice.due_date = due_date;
            invoice.term_type_id = term_type_id;
            invoice.invoiced_at = invoiced_at;
            invoice.paid_at = paid_at;
            invoice.proposal_service_id = proposal_service_id;
            invoice.payments = payments;
            invoice.enable_payments_client = enable_payments_client;
            this.setState({selectedInvoice: invoice}, () => this.setState({showTermModal: true}));
        } else {
            this.setState({selectedInvoice: null}, () => this.setState({showTermModal: true}));
        }
    };
    closeTermModal = () => {
        this.setState({showTermModal: false, selectedInvoice: null, newInvoiceAmount: null});
    };
    updateTermAttributes = (data, invoice) => {
        const {resource} = this.state;
        let invoiceParams = {};
        invoiceParams.invoiced_at = moment(data.invoiced_at);
        invoiceParams.term_type_id = data.term_type_id;
        invoiceParams.due_date = moment(data.due_date);
        invoiceParams.paid_at = moment(data.paid_at);
        invoiceParams.ps_id = data.proposal_service_id;
        this.props.actions.updateInvoice(invoice.id, invoiceParams, () => {
            let ps_ids = this.state.completedServices.filter(ps => ps.invoice?.id === invoice.id).map(ps => ps.id)
            this.updateTableData(ps_ids)
            this.setState({showTermModal: false, selectedInvoice: null, resource})
        })
    };
    handlePaymentDelete = (currentPayment) => {
        this.props.actions.removePayment(currentPayment, () => {
            this.updateTableData([this.state.selectedInvoice.proposal_service_id])
        });
    };

    handlePaymentUpdate = (payment) => {

        this.props.actions.updatePayment(payment, () => {
            this.updateTableData([this.state.selectedInvoice.proposal_service_id])
        });
    };

    handlePaymentAdd = (token, amount, payment_date, callback) => {
        if (amount !== 0) {
            this.props.actions.addPayment(token, amount, payment_date, result => {
                this.updateTableData([this.state.selectedInvoice.proposal_service_id])
                this.setState({
                    paid: result.paid,
                    showTermModal: false,
                });
            })
        }
    }
    handleRefund = (charge_id, currentInvoice) => {
        const areYouSure = window.confirm(
            "Are you sure you want to refund this payment? Remember that you are only returning the value of an invoice without fee!"
        );
        areYouSure && this.props.actions.refund(charge_id, () => {
            this.props.actions.updateInvoice(currentInvoice, {paid_at: null, paid: false});
            this.updateTableData([this.state.selectedInvoice.proposal_service_id])
            this.setState({showTermModal: null})
        })
    };

    isExpandableRow(row) {
        return true
    }

    expandComponent(row) {
        return (<><Row>
            <Col xs={12} className={'d-flex flex-wrap'}>
                <span><strong>Trees:{" "}</strong></span>
                {row.assets.length && row.assets.sort((a, b) => sortAssetsLabels(a, b, 'asset_label')).map((asset, idx) => {
                        return (
                            <span
                                key={idx}>{`#${asset.asset_label} `}<PlantName plant={asset.name}/>,
                            </span>
                        );
                    }
                )}
            </Col>
        </Row>
            <Row>
                <Col xs={12} md={6}>
                    <div><strong>Service notes:</strong></div>
                    {row.notes ?
                        <div className='pre-line'
                             dangerouslySetInnerHTML={{__html: row.notes}}/> : 'Service notes'}
                    <div><strong>Work order notes:</strong></div>
                    {row.woNotes ?
                        <div className='pre-line'
                             dangerouslySetInnerHTML={{__html: row.woNotes}}/> : 'Work order notes'}
                </Col>
                <Col xs={12} md={6}>
                    <FormGroup bsSize="small">
                        <ControlLabel><strong>Invoice notes:</strong></ControlLabel>
                        <FormControl
                            componentClass="textarea"
                            rows={3}
                            name="invoice-notes"
                            placeholder="Invoice Notes"
                            value={row.invoiceNotes}
                            onChange={(e) => {
                                const {completedServices} = this.state
                                const updatedServices = completedServices.map(s => {
                                    if (s.id === row.id) {
                                        s.invoiceNotes = e.target.value
                                        return s
                                    } else {
                                        return s
                                    }
                                })
                                this.setState({completedServices: updatedServices})
                            }}
                            onBlur={() => {
                                const proposal_service = this.state.completedServices.find(ps => ps.id === row.id)
                                this.props.actions.updateProposalService(proposal_service.id, proposal_service.invoiceNotes)
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row></>);
    }
    rangeLimitValid = () => {
        const {from, to} = this.state
        if(!from){
            return false
        }else if(from && !to){
            return from.diff(moment(), 'days') < 32;
        }else if (from && to){
            return Math.abs(from.diff(to, 'days')) < 32;
        }
    }

    render() {
        const {
            completedServices,
            selectedCompletedServices,
            invoicedDate,
            from,
            to,
            term_types,
            resource,
            visibleColumns,
            showDock,
            columnOptions,
            invoice_settings,
            termTypesOptions,
            employeesOptions,
            filter,
            showFilters
        } = this.state
        let columnOptionsSelect = columnOptions.map(filter => {
            let result = {};
            result.value = filter;
            result.label = filter;
            return result;
        });
        return (
            <Grid fluid id='batch-services'>
                <Helmet title="Services"/>
                <PageHeader
                    pageName="Services"
                    pageDetail={`${completedServices ? `${completedServices.length} found.` : "looking for services..."} `}
                />
                <ReactDock position='right'
                           isVisible={showDock}
                           dimMode="none"
                           defaultSize={window.innerWidth > 990 ? 0.24 : window.innerWidth > 600 ? 0.6 : 1}
                           dockStyle={{overflow: 'hidden'}}
                           zIndex={1031}
                >
                    <div className="close-dock">
                        <h4 className="margin10 pull-left">Columns settings</h4>
                        <div className="pull-right small-margin">
                            <Glyphicon glyph='remove' className="pointer margin10"
                                       onClick={() => this.setState({showDock: null})}/>
                        </div>
                    </div>
                    <div className="margin10 top40">
                        <Row className="no-margin space-between-end">
                            <Col md={8} className='no-left-padding'>
                                <h5 className={isMobile ? 'top25' : 'top50'}>Select columns to display for</h5>
                            </Col>
                            <Col md={4} className='text-right no-right-padding'>
                                <Button disabled={visibleColumns.length === columnOptions.length}
                                        className={`recipient-btn ${isMobile ? 'top25' : 'top50'}`} onClick={() => {
                                    this.setState({
                                        visibleColumns: columnOptions,
                                        invoice_settings: columnOptions
                                    }, this.saveInvoiceSettings)
                                }}>
                                    <span>Select All</span>
                                </Button>
                            </Col>
                        </Row>
                        <Select className="Select"
                                classNamePrefix="small-select"
                                value={select(columnOptionsSelect, visibleColumns)}
                                options={columnOptionsSelect}
                                isClearable={false}
                                isMulti
                                placeholder="Columns"
                                onChange={this.handleColumnOptionsChange}
                        />
                    </div>
                </ReactDock>
                <form onSubmit={(e) => {
                    e.preventDefault()
                    this.setState({page: 1, per_page: 10}, () => this.fetchServices(from, to, true, this.state.filter))
                }}>
                    <Row>
                        <Col sm={12} md={4}>
                            <FormGroup bsSize="sm">
                                <DateRangeFilter
                                    customClassName="customer-DateRangeFilter"
                                    promisedStart={from ? moment(from) : null}
                                    promisedEnd={to ? moment(to) : null}
                                    upsertPromise={(id, start, end) => this.handleRangeDateChange(start, end)}
                                    serviceNo={null}
                                    index={1}
                                    timeDelay={1}
                                    format={defaultDateFormat}
                                />
                                <Button disabled={this.state.loading} className='mt-20 mr-7' bsSize="small"
                                        type="submit" bsStyle="success">
                                    Find Completed Services
                                </Button>
                                <Button disabled={this.state.loading || !this.rangeLimitValid()} className='mt-20' bsSize="small" type="button"
                                        bsStyle="success"
                                        onClick={() => this.setState({page: 1, per_page: 10}, () => this.fetchServices(from, to, false, this.state.filter))}>
                                    Find All Services
                                </Button>
                            </FormGroup>
                        </Col>
                        <Col lg={3} md={2} sm={showFilters ? 12 : 0}>
                            {showFilters && <>
                                <Select className="mb-20 Select" classNamePrefix="select"
                                        placeholder="Employees"
                                        isMulti
                                        isDisabled={this.state.loading}
                                        value={select(employeesOptions, filter.employees)}
                                        onChange={e => {
                                            this.setState({filter: {...filter, employees: e}}, this.delayedSearch);
                                        }}
                                        options={employeesOptions}
                                />
                                <Select className="mb-20 Select" classNamePrefix="select"
                                        placeholder="Excluded Employees"
                                        isMulti
                                        isDisabled={this.state.loading}
                                        value={select(employeesOptions, filter.excludedEmployees)}
                                        onChange={e => {
                                            this.setState({
                                                filter: {
                                                    ...filter,
                                                    excludedEmployees: e
                                                }
                                            }, this.delayedSearch);
                                        }}
                                        options={employeesOptions}
                                />
                                <Col>
                                    <ColorCheckbox
                                        value={filter.hideNotSentInvoiced}
                                        label='Hide Not Send Invoiced'
                                        className='vertical-align'
                                        disabled={this.state.loading}
                                        onChange={e => {
                                            this.setState({
                                                filter: {
                                                    ...filter,
                                                    hideNotSentInvoiced: e
                                                }
                                            }, this.delayedSearch);
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <ColorCheckbox
                                        value={filter.hideInvoiced}
                                        label='Hide Invoiced'
                                        className='vertical-align'
                                        disabled={this.state.loading}
                                        onChange={e => {
                                            this.setState({
                                                filter: {
                                                    ...filter,
                                                    hideInvoiced: e
                                                }
                                            }, this.delayedSearch);
                                        }}
                                    />
                                </Col>
                                {!isMobile && <Col>
                                    Column filters
                                    <OverlayTrigger placement="right" id={'column-filters'}
                                                    overlay={<Tooltip
                                                        id="tooltip">
                                                        Please select 'All' in pagination to enable column filters
                                                    </Tooltip>}>
                                        <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                                    </OverlayTrigger>
                                </Col>}
                            </>}
                        </Col>
                        <Col lg={5} md={6} sm={12}>
                            <Row>
                                <Col md={3}>
                                    <Row>
                                        <Col sm={12}>
                                            <Button bsSize="small" bsStyle="warning"
                                                    className='buttons'
                                                    disabled={this.state.loading || !this.rangeLimitValid()}
                                                    onClick={() => this.toggleColumnFilters()}>Filter Rows</Button>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <Button
                                                className="buttons pointer mr-7"
                                                onClick={() => {
                                                    this.setState({showDock: !showDock})
                                                }}>
                                                Settings {" "}<FontAwesomeIcon icon={faCog}
                                                                               className="small-margin fontSize14"/>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={3}>
                                    <Row>
                                        <Col sm={12}>
                                        <ColorCheckbox
                                            value={resource.enable_ach_payments}
                                            label='Enable ACH Payments'
                                            className='vertical-align'
                                            onChange={e => {
                                                const {resource} = this.state
                                                this.setState({resource: {...resource, enable_ach_payments: e}})
                                            }}
                                        />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                        <ColorCheckbox value={resource.enable_payments}
                                                       label='Enable Stripe Payments'
                                                       className='vertical-align'
                                                       onChange={e => {
                                                           const {resource} = this.state
                                                           this.setState({resource: {...resource, enable_payments: e}})
                                                       }}
                                        />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={3}>
                                    <Row>
                                        <Col sm={12}>
                                            <Datetime
                                                dateFormat={defaultDateFormat}
                                                className="mb-20 pull-left"
                                                isClearable
                                                timeFormat={null}
                                                value={moment(resource.invoiced_at)}
                                                inputProps={{placeholder: 'Invoiced Date'}}
                                                onChange={e => {
                                                    this.setState({
                                                        resource: {
                                                            ...resource,
                                                            invoiced_at: moment(e).isValid() ? moment(e).format() : null
                                                        }
                                                    });
                                                }}/>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={12}>
                                            <Select className="mb-20 Select" classNamePrefix="select"
                                                    placeholder="Term"
                                                    value={select(termTypesOptions, resource.term_type_id)}
                                                    onChange={e => {
                                                        this.setState({resource: {...resource, term_type_id: e.value}});
                                                    }}
                                                    options={termTypesOptions}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={3}>
                                    <Button
                                        className="buttons-tooltip pull-right"
                                        bsStyle="info"
                                        onClick={() => this.createInvoices()}
                                        disabled={this.state.loading || selectedCompletedServices.length === 0 || completedServices.filter(ps => ps.invoice).some(ps => selectedCompletedServices.includes(ps.id))}
                                    >
                                        {completedServices.filter(ps => ps.invoice).some(ps => selectedCompletedServices.includes(ps.id)) ?
                                            <OverlayTrigger placement="top"
                                                            overlay={<Tooltip id="tooltip">
                                                                <div className="text-left">
                                                                    Some of the selected services have already been
                                                                    invoiced
                                                                </div>
                                                            </Tooltip>}>
                                    <span>Create Invoice<FontAwesomeIcon icon={faInfoCircle}
                                                                         className="marginLeft10 pointer"/></span>
                                            </OverlayTrigger> : 'Create Invoice'}
                                    </Button>

                                    <Button
                                        className="buttons-tooltip pull-right"
                                        bsStyle="info"
                                        onClick={() => this.setState({email: true})}
                                        disabled={selectedCompletedServices.length === 0 || completedServices.filter(ps => !ps.invoice).some(ps => selectedCompletedServices.includes(ps.id))}
                                    >
                                        {completedServices.filter(ps => !ps.invoice).some(ps => selectedCompletedServices.includes(ps.id)) ?
                                            <OverlayTrigger placement="top"
                                                            overlay={<Tooltip id="tooltip">
                                                                <div className="text-left">
                                                                    Not all of the selected services have been invoiced
                                                                </div>
                                                            </Tooltip>}>
                                    <span>Email Selected<FontAwesomeIcon icon={faInfoCircle}
                                                                         className="marginLeft10 pointer"/></span>
                                            </OverlayTrigger> : 'Email Selected'}

                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </form>
                <p>&nbsp;</p>

                <Row>
                    <Col md={12}>
                        {completedServices ? this._renderTable(completedServices) : null}
                    </Col>
                </Row>

                <Row>
                    <Col md={12}/>
                </Row>

            </Grid>
        )
    }

    toggleColumnFilters = () => {
        this.setState({
            showFilters: !this.state.showFilters
        })
    }

    createInvoices = () => {
        let {
            selectedCompletedServices,
            resource,
            from,
            to,
            onlyCompleted
        } = this.state

        const psIds = selectedCompletedServices
        // call actions
        if (psIds) {
            this.setState({loading: true})
            this.props.actions.createInvoices(psIds, resource, from, to, () => {
                this.updateTableData(psIds)
            })
        }
    }


    _onSelectAll = (isSelected, rows) => {
        let selectedCompletedServices = []
        let selectedInvoices = []

        if (isSelected) {
            selectedCompletedServices = rows.map(cs => {
                if (cs.invoice) {
                    selectedInvoices.push(cs.invoice.id)
                }
                return cs.id
            })
        }
        this.setState({selectedCompletedServices, selectedInvoices})
    }

    _onRowSelect = (row, isSelected, e) => {
        const id = parseInt(row.id, 10);
        let selectedCompletedServices = [...this.state.selectedCompletedServices];
        let selectedInvoices = [...this.state.selectedInvoices];

        if (isSelected) {
            if (!selectedCompletedServices.includes(id)) {
                if (row.invoice) {
                    selectedInvoices.push(row.invoice.id);
                }
                selectedCompletedServices.push(id);
            }
        } else {
            if (row.invoice) {
                selectedInvoices = selectedInvoices.filter(i => i !== row.invoice?.id);
            }
            selectedCompletedServices = selectedCompletedServices.filter(i => i !== id);
        }

        this.setState({selectedCompletedServices, selectedInvoices});
    };

    _onSingleSelect = (name, selected) => {
        this.setState({
            [name]: selected && selected.value
                ? selected.value
                : null,
        })

        this.setState({selectedCompletedService: []})
    }
    handleCompletedChange = (e, row) => {
        let completedDate = moment(e).isValid() ? moment(e).format() : null
        this.props.actions.updateCompletedDate(row.id, completedDate, () => {
            let updatedServices = this.state.completedServices.map(s => {
                if (s.id === row.id) {
                    s.completed_at = completedDate
                    return s
                } else {
                    return s
                }
            })
            this.setState({completedServices: updatedServices})
        })
    }

    completedOnFormatter = (cell, row) => <Datetime
        dateFormat={defaultDateFormat}
        className="mb-20 pull-left"
        isClearable
        timeFormat={defaultTimeFormat}
        value={moment(cell)}
        inputProps={{placeholder: 'Completed On', disabled: this.state.loading}}
        closeOnSelect={true}
        onChange={(e) => this.delayedhandleCompletedChange(e, row)}/>

    _dateFormatter = cell => cell && moment(cell).format("M/D/YY")

    _dollarFormatter = cell => cell && <Dollars amount={cell}/>

    onExportToCSV = () => {
        const {completedServices} = this.state
        const selectedCompletedServices = this.state.selectedCompletedServices;
        return completedServices.filter(i => {
            if (selectedCompletedServices.indexOf(i.id) > -1) {
                return i;
            }
        });
    }

    rowFormatter = (cell, row) => {
        const {visibleColumns, downloadInProgress} = this.state
        return <div>
            {visibleColumns.includes('Customer') && <Row>
                <Col xs={6}>Customer</Col>
                <Col xs={6}>{this._linkFormatter(row.customerName, row)}</Col>
            </Row>}
            {visibleColumns.includes('Site Address') && <Row>
                <Col xs={6}>Site Address</Col>
                <Col xs={6}>{row.siteAddress}</Col>
            </Row>}
            {visibleColumns.includes('Title') && <Row>
                <Col xs={6}>Title</Col>
                <Col xs={6}>{row.proposalTitle}</Col>
            </Row>}
            {visibleColumns.includes('Arborist') && <Row>
                <Col xs={6}>Arborist</Col>
                <Col xs={6}>{row.arborist}</Col>
            </Row>}
            {visibleColumns.includes('Service') && <Row>
                <Col xs={6}>Service</Col>
                <Col xs={6}>{row.serviceName}</Col>
            </Row>}
            {visibleColumns.includes('Proposal #') && <Row>
                <Col xs={6}>Proposal #</Col>
                <Col xs={6}>{this._proposalLinkFormatter(row.proposalNo, row)}</Col>
            </Row>}
            {visibleColumns.includes('WO #') && <Row>
                <Col xs={6}>WO #</Col>
                <Col xs={6}>{row.workOrderNo}</Col>
            </Row>}
            {visibleColumns.includes('Cost') && <Row>
                <Col xs={6}>Cost</Col>
                <Col xs={6}>{<Dollars amount={row.serviceCost}/>}</Col>
            </Row>}
            {visibleColumns.includes('Completed On') && <Row>
                <Col xs={6}>Completed On</Col>
                <Col xs={6}>{this.completedOnFormatter(row.completed_at, row)}</Col>
            </Row>}
            {visibleColumns.includes('Invoice #') && <Row>
                <Col xs={6}>Invoice #</Col>
                <Col xs={6}>{row.invoice &&
                    <div className='flex-column-start'>
                        <div className='small-margin justify-flex-start'><span className='small-margin'>
                                <NavDropdown
                                    noCaret
                                    eventKey={2}
                                    title={'#' + row.invoice.number}
                                    disabled={this.state.loading}
                                    className="menu-show-fixer proposal-menu-padding-reset"
                                >
                                <LinkContainer to={`/new_invoice_print/${row.invoice.id}`}>
                                    <MenuItem>Print</MenuItem>
                                </LinkContainer>
                                <li className='pointer'>
                                    <Link
                                        onClick={() => !downloadInProgress && this.setState({downloadInProgress: true}, () => this.props.actions.downloadPdf(row.invoice,
                                            () => this.setState({downloadInProgress: false}),
                                            () => this.setState({downloadInProgress: false})
                                        ))}
                                        className={row.invoice.id ? '' : 'disabled'}>PDF</Link>
                                </li>
                                <li className='pointer'>
                                    <Link
                                        onClick={() => this.setState({
                                            selectedInvoices: [row.invoice.id], single_email: {
                                                emailType: 'invoice',
                                                referenceId: row.invoice.id,
                                                recipient: '',
                                                cc_recipients: '',
                                                defaultEmail: this.props.email,
                                                dataPdf: row.invoice,
                                                client: this.props.client
                                            }
                                        })}
                                        className={row.invoice.id ? '' : 'disabled'}>Email</Link>
                                </li>
                                <li className='pointer'>
                                        <Link to={`/quickbooks?invoice_id=${row.invoice.id}`}>
                                            Show in Quickbooks
                                        </Link>
                                        </li>
                            </NavDropdown>
                            </span>{!row.site_moved &&
                            <OverlayTrigger placement="top"
                                            overlay={row.invoice?.payments?.length > 0 ? <Tooltip>
                                                <div>You have initialized payments</div>
                                            </Tooltip> : <div/>}>
                                <a
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                        if (!this.state.loading && !(row.invoice?.payments?.length > 0)) {
                                            this.setState({loading: true})
                                            this.props.actions.removeInvoiceProposalService({
                                                invoice_id: row.invoice.id,
                                                ps_id: row.id
                                            }, () => this.updateTableData([row.id]))
                                        }
                                    }}
                                >

                          <span
                              className={`text-danger ${(row.invoice?.payments?.length > 0 || this.state.loading) ? 'disabled' : ''}`}>
                            <Glyphicon glyph="remove-sign"/>
                          </span>
                                </a>
                            </OverlayTrigger>}
                        </div>
                    </div>}
                </Col>
            </Row>}
            {visibleColumns.includes('Term') &&  <Row>
                <Col xs={6}>Term</Col>
                <Col xs={6}><span
                    className={`pointer ${(!row.invoice || this.state.loading) ? 'text-gray' : 'text-green'}`}
                    onClick={() => {
                        if (!this.state.loading && row.invoice) {
                            this.showTermModal(row.invoice.id, row.invoice.number,
                                row.invoice.due_date, row.term_type.id, row.invoice.invoiced_at, row.id, row.invoice.paid_at,
                                row.invoice && row.invoice.invoice_total, row.invoice.token,
                                row.invoice && row.invoice.payments ? row.invoice.payments : null,
                                row.invoice && row.invoice.payments ? row.enable_payments_client : null)
                        }
                    }
                    }>{row.term_name}</span>
                </Col>
            </Row>}
        </div>
    }

    _renderTable = data => {
        const {showFilters, visibleColumns, email, selectedInvoices, single_email, from, to, downloadInProgress} = this.state
        let selectedRowsEmail = data.filter(d => selectedInvoices?.includes(d.id)).map(d => {
            return {name: d.customer_name, email: d.email_address}
        })
        let disableColumnFilters = this.state.completedServices.filter(ps => this.state.filteredServices.map(fs => fs.id).includes(ps.id)).length < this.state.total

        const options = {
            onPageChange: (page, per_page) => {
                this.setState({page,per_page}, this.delayedSearch)
            },
            sizePerPageDropDown: this.renderSizePerPageDropDown,
            page: this.state.page,
            sizePerPage: this.state.per_page,
            sizePerPageList: [{
                text: '10', value: 10
            }, {
                text: '25', value: 25
            },
                {
                    text: '30', value: 30
                },
                {
                    text: '50', value: 50
                },
                {
                    text: '100', value: 100
                },
                {
                    text: '200', value: 200
                },
                {
                    text: 'All', value: this.state.total
                }],//you can change the dropdown list for size per page
            paginationSize: 3,  //the pagination bar size.
            expandBodyClass: function (row, rowIndex, isExpanding) {
                return 'display-table-row'
            },//the pagination bar size.
            onExportToCSV: this.onExportToCSV
        }


        return (
            <>
                {isMobile ?
                    <BootstrapTable
                        ref={this.tableRef}
                        remote={disableColumnFilters}
                        className="wrapped"
                        data={this.state.completedServices.filter(ps => this.state.filteredServices.map(fs => fs.id).includes(ps.id))}
                        striped={true}
                        bordered={false}
                        hover={true}
                        exportCSV={true}
                        pagination={this.state.completedServices.filter(ps => this.state.filteredServices.map(fs => fs.id).includes(ps.id)).length > 0}
                        search={showFilters && !disableColumnFilters}
                        csvFileName={() => {
                            const fileName = `all-completed-services-${moment().format()}.csv`
                            if (this.tableRef?.current) {
                                this.props.actions.saveNewCsvDownload(this.tableRef.current, fileName, {from, to}, "completed services")
                            }
                            return fileName
                        }}
                        selectRow={{
                            mode: "checkbox",
                            clickToSelect: false,
                            onSelect: this._onRowSelect,
                            onSelectAll: this._onSelectAll,
                            selected: this.state.selectedCompletedServices
                        }}
                        expandable={{isExpand: true}}
                        expandableRow={this.isExpandableRow}
                        expandComponent={(row) => this.expandComponent(row)}
                        expandColumnOptions={{expandColumnVisible: false}}
                        options={options}
                        fetchInfo={{dataTotalSize: this.state.total}}
                    >
                        <TableHeaderColumn hidden isKey dataField="id">
                            Service Id
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="id"
                            dataFormat={this.rowFormatter}
                        >
                        </TableHeaderColumn>
                    </BootstrapTable> :
                    <BootstrapTable
                        ref={this.tableRef}
                        remote={disableColumnFilters}
                        className="wrapped"
                        data={this.state.completedServices.filter(ps => this.state.filteredServices.map(fs => fs.id).includes(ps.id))}
                        striped={true}
                        bordered={false}
                        hover={true}
                        exportCSV={true}
                        pagination={this.state.completedServices.filter(ps => this.state.filteredServices.map(fs => fs.id).includes(ps.id)).length > 0}
                        search={showFilters && !disableColumnFilters}
                        csvFileName={() => {
                            const fileName = `all-completed-services-${moment().format()}.csv`
                            if (this.tableRef?.current) {
                                this.props.actions.saveNewCsvDownload(this.tableRef.current, fileName, {from, to}, "completed services")
                            }
                            return fileName
                        }}
                        selectRow={{
                            mode: "checkbox",
                            clickToSelect: false,
                            onSelect: this._onRowSelect,
                            onSelectAll: this._onSelectAll,
                            selected: this.state.selectedCompletedServices
                        }}
                        expandable={{isExpand: true}}
                        expandableRow={this.isExpandableRow}
                        expandComponent={(row) => this.expandComponent(row)}
                        expandColumnOptions={{expandColumnVisible: false}}
                        options={options}
                        fetchInfo={{dataTotalSize: this.state.total}}
                    >
                        <TableHeaderColumn hidden isKey dataField="id">
                            Service Id
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={{whiteSpace: 'normal'}}
                            thStyle={{whiteSpace: 'normal'}}
                            filter={showFilters && !disableColumnFilters ? {type: "TextFilter", delay: 500,} : null}
                            columnClassName="menu-show-fixer"
                            dataField="customerName"
                            dataFormat={this._linkFormatter}
                            hidden={!visibleColumns.includes('Customer')}
                        >
                            Customer
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={{whiteSpace: 'normal'}}
                            thStyle={{whiteSpace: 'normal'}}
                            filter={showFilters && !disableColumnFilters ? {type: "TextFilter", delay: 500} : null}
                            columnClassName="menu-show-fixer"
                            dataField="siteAddress"
                            hidden={!visibleColumns.includes('Site Address')}
                        >
                            Site Address
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={{whiteSpace: 'normal'}}
                            thStyle={{whiteSpace: 'normal'}}
                            filter={showFilters && !disableColumnFilters ? {type: "TextFilter", delay: 500} : null}
                            columnClassName="menu-show-fixer"
                            dataField="proposalTitle"
                            hidden={!visibleColumns.includes('Title')}
                        >
                            Title
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={{whiteSpace: 'normal'}}
                            thStyle={{whiteSpace: 'normal'}}
                            filter={showFilters && !disableColumnFilters ? {type: "TextFilter", delay: 500} : null}
                            columnClassName="menu-show-fixer"
                            dataField="arborist"
                            hidden={!visibleColumns.includes('Arborist')}
                        >
                            Arborist
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={{whiteSpace: 'normal'}}
                            thStyle={{whiteSpace: 'normal'}}
                            filter={showFilters && !disableColumnFilters ? {type: "TextFilter", delay: 500} : null}
                            columnClassName="menu-show-fixer"
                            dataField="serviceName"
                            hidden={!visibleColumns.includes('Service')}
                        >
                            Service
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            filter={showFilters && !disableColumnFilters ? {type: "TextFilter", delay: 500} : null}
                            columnClassName="menu-show-fixer"
                            dataFormat={this._proposalLinkFormatter}
                            dataField="proposalNo"
                            hidden={!visibleColumns.includes('Proposal #')}
                        >
                            Proposal #
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            filter={showFilters && !disableColumnFilters ? {type: "TextFilter", delay: 500} : null}
                            dataFormat={this._woLinkFormatter}
                            columnClassName="menu-show-fixer"
                            dataField="workOrderNo"
                            hidden={!visibleColumns.includes('WO #')}
                        >
                            WO #
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            filter={showFilters && !disableColumnFilters ? {type: "NumberFilter", delay: 500} : null}
                            dataFormat={dollarsFormatter}
                            filterFormatted={false}
                            dataField="serviceCost"
                            hidden={!visibleColumns.includes('Cost')}
                        >
                            Cost
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            filter={showFilters && !disableColumnFilters ? {type: "DateFilter", delay: 500} : null}
                            width={"180px"}
                            dataFormat={this.completedOnFormatter}
                            dataField="completed_at"
                            hidden={!visibleColumns.includes('Completed On')}
                        >
                            Completed On
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            filter={showFilters && !disableColumnFilters ? {type: "TextFilter", delay: 500,} : null}
                            dataFormat={(cell, row) => cell &&
                                <div className='flex-column-start'>
                                    <div className='small-margin justify-flex-start'><span className='small-margin'>
                                <NavDropdown
                                    noCaret
                                    eventKey={2}
                                    title={'#' + row.invoice.number}
                                    disabled={this.state.loading}
                                    className="menu-show-fixer proposal-menu-padding-reset"
                                >
                                <LinkContainer to={`/new_invoice_print/${row.invoice.id}`}>
                                    <MenuItem>Print</MenuItem>
                                </LinkContainer>
                                <li className='pointer'>
                                    <Link
                                        onClick={() => !downloadInProgress && this.setState({downloadInProgress: true}, () => this.props.actions.downloadPdf(row.invoice,
                                            () => this.setState({downloadInProgress: false}),
                                            () => this.setState({downloadInProgress: false})
                                        ))}
                                        className={row.invoice.id ? '' : 'disabled'}>PDF</Link>
                                </li>
                                <li className='pointer'>
                                    <Link
                                        onClick={() => this.setState({
                                            selectedInvoices: [row.invoice.id], single_email: {
                                                emailType: 'invoice',
                                                referenceId: row.invoice.id,
                                                recipient: '',
                                                cc_recipients: '',
                                                defaultEmail: this.props.email,
                                                dataPdf: row.invoice,
                                                client: this.props.client
                                            }
                                        })}
                                        className={row.invoice.id ? '' : 'disabled'}>Email</Link>
                                </li>
                                <li className='pointer'>
                                        <Link to={`/quickbooks?invoice_id=${row.invoice.id}`}>
                                            Show in Quickbooks
                                        </Link>
                                        </li>
                            </NavDropdown>
                            </span>{!row.site_moved &&
                                        <OverlayTrigger placement="top"
                                                        overlay={row.invoice?.payments?.length > 0 ? <Tooltip>
                                                            <div>You have initialized payments</div>
                                                        </Tooltip> : <div/>}>
                                            <a
                                                style={{cursor: 'pointer'}}
                                                onClick={() => {
                                                    if (!this.state.loading && !(row.invoice?.payments?.length > 0)) {
                                                        this.setState({loading: true})
                                                        this.props.actions.removeInvoiceProposalService({
                                                            invoice_id: row.invoice.id,
                                                            ps_id: row.id
                                                        }, () => this.updateTableData([row.id]))
                                                    }
                                                }}
                                            >

                          <span
                              className={`text-danger ${(row.invoice?.payments?.length > 0 || this.state.loading) ? 'disabled' : ''}`}>
                            <Glyphicon glyph="remove-sign"/>
                          </span>
                                            </a>
                                        </OverlayTrigger>}
                                    </div>
                                </div>}
                            dataField="invoice_number"
                            hidden={!visibleColumns.includes('Invoice #')}
                            csvFormat={(cell) => {
                                return cell || ''
                            }}
                        >
                            Invoice #
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            filter={showFilters && !disableColumnFilters ? {type: "TextFilter", delay: 500} : null}
                            dataFormat={(cell, row) => <span
                                className={`pointer ${(!row.invoice || this.state.loading) ? 'text-gray' : 'text-green'}`}
                                onClick={() => {
                                    if (!this.state.loading && row.invoice) {
                                        this.showTermModal(row.invoice.id, row.invoice.number,
                                            row.invoice.due_date, row.term_type.id, row.invoice.invoiced_at, row.id, row.invoice.paid_at,
                                            row.invoice && row.invoice.invoice_total, row.invoice.token,
                                            row.invoice && row.invoice.payments ? row.invoice.payments : null,
                                            row.invoice && row.invoice.payments ? row.enable_payments_client : null)
                                    }
                                }
                                }>{cell}</span>}
                            dataField="term_name"
                            hidden={!visibleColumns.includes('Term')}
                            csvFormat={(cell) => {
                                return cell || ''
                            }}
                        >
                            Term
                        </TableHeaderColumn>
                    </BootstrapTable>}

                <TermModal show={this.state.showTermModal} onHide={this.closeTermModal}
                           defaultTerm={this.state.defaultTerm} invoice={this.state.selectedInvoice}
                           newInvoiceAmount={this.state.newInvoiceAmount}
                           term_types={this.state.term_types}
                           onSave={this.updateTermAttributes}
                           onPaymentRefund={this.handleRefund}
                           onPaymentDelete={this.handlePaymentDelete}
                           onPaymentAdd={this.handlePaymentAdd}
                           onPaymentUpdate={this.handlePaymentUpdate}
                           loading={this.state.loading}
                />
                {
                    email && <EmailDialog defaultEmail={this.props.email}
                                          sendEmail={(email) => {
                                              this.props.actions.sendBulkEmail(selectedInvoices, email, () => {
                                                  this.setState({email: null}, () => this.fetchServices(this.state.from, this.state.to,this.state.onlyCompleted, this.state.filter ))
                                              })
                                          }}
                                          sendBulkNotification={(email, callback) => {
                                              this.props.actions.sendBulkNotification(email, selectedInvoices, "bulk", email?.email_template_id, (res) => {
                                                  callback && callback(res.data)
                                              })
                                          }}
                                          referenceId={selectedInvoices}
                                          templateMode
                                          emailType="bulk_invoiced"
                                          confirmMessage={true}
                                          selectedRecords={selectedRowsEmail}
                                          hideEmailDialog={() => {
                                              this.setState({email: null});
                                          }}
                    />
                }
                {
                    single_email && <EmailDialog defaultEmail={single_email.defaultEmail}
                                                 sendEmail={(single_email) => {
                                                     this.props.actions.sendEmail(single_email, () => {
                                                         this.setState({single_email: null}, () => this.fetchServices(this.state.from, this.state.to,this.state.onlyCompleted, this.state.filter ))
                                                     })
                                                 }}
                                                 referenceId={single_email.referenceId}
                                                 dataPdf={single_email.dataPdf}
                                                 emailType={single_email.emailType}
                                                 recipient={single_email.recipient}
                                                 cc_recipients={single_email.cc_recipients}
                                                 client={single_email.client}
                                                 hideEmailDialog={() => {
                                                     this.setState({single_email: null});
                                                 }}
                    />
                }
            </>
        )
    }

    _linkFormatter = (cell, row) => (
        <Nav>
            <NavDropdown
                eventKey={2}
                title={`${row.customerName}`}
                id={`proposal-menu`}
                className="menu-show-fixer proposal-menu-padding-reset"
            >
                <LinkContainer to={`/customer/info/${row.customerId}`}>
                    <MenuItem bsSize="small">Info</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/proposals/${row.customerId}`}>
                    <MenuItem bsSize="small">Proposals</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/sites/${row.customerId}`}>
                    <MenuItem bsSize="small">Sites</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/work_orders/${row.customerId}`}>
                    <MenuItem bsSize="small">Work Orders</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/invoices/${row.customerId}`}>
                    <MenuItem bsSize="small">Invoices</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/work_history/${row.customerId}`}>
                    <MenuItem bsSize="small">Work History</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    )

    _proposalLinkFormatter = (cell, row) => (
        <Nav>
            <NavDropdown
                eventKey={2}
                title={cell}
                id={`proposal-menu`}
                className="menu-show-fixer proposal-menu-padding-reset"
            >
                <LinkContainer to={`/mapview/${row.proposalId}`}>
                    <MenuItem bsSize="small">Edit Proposal</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/proposal_service_status/${row.proposalId}`}>
                    <MenuItem bsSize="small">Proposal Statuses</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/print_proposal/${row.proposalId}`}>
                    <MenuItem bsSize="small">Print Proposal</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    )
}

function mapStateToProps(state) {
    return {
        state: state,
        email: state.auth.email,
        client: state.client.customerInfo,
    }
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(Actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(CompletedServices)
