import {addAlert} from "../containers/App/actions";
import moment from "moment-timezone";


export function addAuthorizationHeader(config, getState) {
    return {
        ...config,
        headers: {'Authorization': getState().auth.token}
    };
}

export function addTimeZoneHeader(config) {
    if (!config.headers) {
        config.headers = {};
    }
    config.headers['TZ'] = moment.tz.guess();
    return config;
}

export const apiCall = (message, config, fn, dispatch, getState, showSuccessMessage = true, callback = null) => {
    if (getState) {
        if (config.constructor.name === "Array") {
            config = config.map(c => addAuthorizationHeader(c, getState));
        } else {
            config = addAuthorizationHeader(config, getState);
        }
    }
    config = addTimeZoneHeader(config);
    return fn(config).then(() => {
        if (showSuccessMessage) {
            dispatch(
                addAlert({
                    message: `${message} succeed`,
                    mood: "success",
                    dismissAfter: 1500,
                })
            );
        }
    }).catch(exception => {
        const responseData = exception?.response?.data;
        const showValidationErrors = typeof responseData === 'object';
        let errors = exception.toString();
        if (showValidationErrors) {
            errors = Object.keys(responseData)
                .map(key => key + ': ' + ((typeof responseData[key] === 'string') ? responseData[key] : Array.isArray(responseData[key]) ? responseData[key].flat().map(obj => obj?.email)?.join(', ') : responseData[key].join(','))).join('   ');
        }
        dispatch(
            addAlert({
                message: `${message} failed with ${errors}`,
            }),
            callback && callback(exception.response)
        );
        throw exception
    })
};


