import React, {useEffect, useMemo, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faPencilRuler,
    faRedo,
    faUndo,
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faTrash,
    faToggleOn,
    faToggleOff, faClosedCaptioning
} from "@fortawesome/free-solid-svg-icons";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import moment from "moment-timezone";
import './NewAttachment.scss'
import ImageDrawTool from '../ImageDraw/ImageDrawTool'
import {UPLOAD_FORMAT_DATE_ONLY, UPLOAD_FORMAT_TIME_ONLY} from "../../../constants";

const NewAttachment = ({
                           image,
                           onlyDisplay = false,
                           lockedCrewEditAccess,
                           rotateImage,
                           reloadData,
                           saveData,
                           reloadDataNoSave,
                           deleteImage,
                           setVisible,
                           saveImage,
                           reorderImages,
                           timestamp = "created_at",
                           saveFormat,
                           visibilityLocked = false,
                           client_timezone,
                           addWatermarkClick,
                           originalImage,
                           removeWatermarkLoading
                       }) => {
    const [drawModal, setDrawModal] = useState(false)

    const img = useMemo(() => image, [image]);

    let format = 'MM/DD/YYYY hh:mm A';

    if(saveFormat === UPLOAD_FORMAT_TIME_ONLY && timestamp !== "no_date") {
        format = "hh:mm A"
    }
    if(saveFormat === UPLOAD_FORMAT_DATE_ONLY && timestamp !== "no_date") {
        format = "MM/DD/YYYY"
    }

    const t = ['taken_at_dt', 'taken_at_d', 'taken_at_t'].includes(timestamp) ? img.taken_at : img.created_at

    const date = client_timezone ? moment(t)
        .tz(client_timezone)
        .format(format) :
            moment(t)
                .format(format)

    const getDateString = () => {
        if(['taken_at_dt', 'taken_at_d', 'taken_at_t'].includes(timestamp)) {
            return img.taken_at !== null && img.taken_at !== undefined ? date : "No Taken Date"
        } else {
            return img.created_at !== null && img.created_at !== undefined ? date : "No Uploaded Date"
        }
    }

    return (onlyDisplay ? <>
                <div key={img.id}
                     id={img.id}
                     className="new-attachment-preview">
                    <div className='image-with-description'>
                        <div className={"image-with-icon"}>
                            {addWatermarkClick && <div className={"watermark-icon-wrapper"} onClick={() => addWatermarkClick(img.id)}>
                                <FontAwesomeIcon icon={faClosedCaptioning} size={"lg"} color={"white"}/>
                            </div>}
                            <a href={img.original}
                               title="Open in a new window"
                               target="_blank">
                                <img
                                    style={{boxShadow: img.client_header && ' rgba(0, 0, 0, 0.36) 0px 30px 30px -2px'}}
                                    role="presentation"
                                    src={img.mini}
                                    className="img-thumbnail"/>
                            </a>
                        </div>
                        <p className='image-date'>{getDateString()}</p>
                    </div>
                </div>
            </> :
            <>
                <div key={img.id}
                     id={img.id}
                     className="new-attachment-preview">
                    <div className='image-with-description'>
                        <div className={"image-with-icon"}>
                            {addWatermarkClick && <div className={"watermark-icon-wrapper"} onClick={() => addWatermarkClick(img.id)}>
                                <FontAwesomeIcon icon={faClosedCaptioning} size={"lg"} color={"white"}/>
                            </div>}
                            <a href={img.original}
                               title="Open in a new window"
                               target="_blank">
                                <img
                                    style={{boxShadow: img.client_header && ' rgba(0, 0, 0, 0.36) 0px 30px 30px -2px'}}
                                    role="presentation"
                                    src={img.mini}
                                    className="img-thumbnail"/>
                            </a>
                        </div>

                        <p className='image-date'>{getDateString()}</p>
                        <div className='visibility-buttons'>
                            {!lockedCrewEditAccess &&<Button onClick={() => setDrawModal(true)}>
                                <FontAwesomeIcon icon={faPencilRuler}/>
                            </Button>}
                            {!lockedCrewEditAccess && setVisible && <div className='image-tag'>
                                {visibilityLocked ? <OverlayTrigger overlay={<Tooltip
                                        id="tooltip-image-visibility">Disable showing only first image to allow changing visibility</Tooltip>}>
                                        <div className={`not-allowed image-visibility image-${img.cwo_and_proposal_display ? "visible" : "hidden"}`}>
                                            {img.cwo_and_proposal_display ? "Visible" : "Hidden"}
                                        </div>
                                </OverlayTrigger> :
                                <div onClick={()=>{
                                    setVisible(!img.cwo_and_proposal_display)}}
                                    className={`pointer image-visibility image-${img.cwo_and_proposal_display ? "visible" : "hidden"}`}>
                                    {img.cwo_and_proposal_display ? "Visible" : "Hidden"}
                                </div>}
                            </div>}
                            {!lockedCrewEditAccess && deleteImage && !removeWatermarkLoading &&
                                <Button className='btn-delete' onClick={() => deleteImage(img.id)}>
                                    <FontAwesomeIcon icon={faTrash}/>
                                </Button>}
                            {!lockedCrewEditAccess && deleteImage && removeWatermarkLoading &&
                                <OverlayTrigger overlay={<Tooltip id="tooltip-image-watermark-delete">Removing in progress</Tooltip>}>
                                    <Button className='btn-delete' disabled={true}>
                                        <FontAwesomeIcon icon={faTrash}/>
                                    </Button>
                                </OverlayTrigger>}
                        </div>


                    </div>
                    <div className='button-group'>
                        <div className='edit-buttons'>
                            {!lockedCrewEditAccess && <Button onClick={() => {
                                saveData(()=>rotateImage(img.id, 'left', () => {
                                    reloadDataNoSave ? reloadDataNoSave() : reloadData();
                                }))

                            }}>
                                <FontAwesomeIcon icon={faUndo}/>
                            </Button>}
                            {!lockedCrewEditAccess && <Button onClick={() => {
                                saveData(()=>rotateImage(img.id, 'right', () => {
                                    reloadDataNoSave ? reloadDataNoSave() : reloadData();
                                }))
                            }}>
                                <FontAwesomeIcon icon={faRedo}/>
                            </Button>}
                            {!lockedCrewEditAccess && reorderImages &&
                                <Button onClick={() => reorderImages("left")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft}/>
                                </Button>}
                            {!lockedCrewEditAccess && reorderImages &&
                                <Button onClick={() => reorderImages("right")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight}/>
                            </Button>}
                        </div>


                    </div>
                </div>
                {drawModal && <ImageDrawTool
                    imageId={originalImage ? originalImage.id : img.id}
                    imageUrl={originalImage ? originalImage.original : img.original}
                    onClose={() => setDrawModal(false)}
                    onSave={(file) => saveImage(file, null, originalImage)}/>}
            </>
    );

}

export default NewAttachment;
