import axios from 'axios'
import {addAlert} from '../App/actions'
import {FETCH_STICKIES} from './constants'
import {apiCall} from "../../common/apiActionHandler"


export function fetchSalesArboristStats(callback) {
    return function (dispatch, getState) {

        const store = getState()
        const config = {
            method: 'get',
            url: `/api/proposals/approvable_stats`,
            headers: {'Authorization': store.auth.token},
        }

        axios.request(config)
            .then(response => {
                const data = {
                    dollars: response.data.dollars,
                    numProposals: response.data.num_proposals
                }
                callback && callback(data)
            })
            .catch(error => {
                //   dispatch({ type: BUMMER })
            })
    }
}

export function fetchStats(callback) {
    return function (dispatch, getState) {
        const store = getState()

        const config = {
            method: 'get',
            headers: {'Authorization': store.auth.token},
            url: '/api/proposals/stats'
        }

        axios.request(config)
            .then(response => {
                callback && callback(response.data.stats)
            })
            .catch(error => {
                if (error.response && error.response.status === 404) {
                    dispatch(addAlert({message: "Proposal Stats for this Client were not found"}))
                }
            })
    }

}

export function fetchProposals(callback) {
    return function (dispatch, getState) {
        const store = getState()

        const config = {
            method: 'get',
            headers: {'Authorization': store.auth.token},
            url: '/api/proposals/recent_home'
        }

        axios.request(config)
            .then(response => {
                callback && callback(response.data)
            })
            .catch(error => {
                if (error.response && error.response.status === 404) {
                    dispatch(addAlert({message: "Proposals for this Client were not found"}))
                }
            })
    }

}

export const fetchStickies = () => async (
    dispatch,
    getState
) => {
    dispatch(
        addAlert({
            message: "This might take a minute or two.",
            mood: "info",
            dismissAfter: 1500,
        })
    )

    const store = getState()
    const config = {
        url: `/api/stickies/follow_ups_home`,
        headers: {Authorization: store.auth.token},
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_STICKIES, stickies: response.data})
        if (response.data && response.data.length === 0) {
            dispatch(
                addAlert({
                    message: "No activities returned. Please change your query and try again.",
                    mood: "warning",
                    dismissAfter: 3500,
                })
            )
        }
    } catch (error) {
        dispatch(
            addAlert({
                message: "Couldn't fetch activities because: " + error.toString(),
                mood: "danger",
                dismissAfter: 5000,
            })
        )
        throw error
    }

}

export const loadStickies = (filter, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/stickies/follow_ups_home`,
        params: filter
    };

    apiCall("Getting stickies", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const fetchStickies1 = (id) => async (
    dispatch,
    getState
) => {
    dispatch(
        addAlert({
            message: "This might take a minute or two.",
            mood: "info",
            dismissAfter: 1500,
        })
    )

    const store = getState()
    const config = {
        url: "/api/stickies/' + id + '/complete",
        headers: {Authorization: store.auth.token},
        method: 'put'
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_STICKIES, stickies: response.data})
        if (response.data && response.data.length === 0) {
            dispatch(
                addAlert({
                    message: "No activities returned. Please change your query and try again.",
                    mood: "warning",
                    dismissAfter: 3500,
                })
            )
        }
    } catch (error) {
        dispatch(
            addAlert({
                message: "Couldn't fetch activities because: " + error.toString(),
                mood: "danger",
                dismissAfter: 5000,
            })
        )
        throw error
    }
}

export const loadProposalData = (proposalId, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/proposals/${proposalId}`
    };

    apiCall("Loading proposal", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};