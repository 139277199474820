import {Button, Col, Glyphicon, Modal, Row} from "react-bootstrap";
import React from "react";
import ResourceComponent from "../../../../components/ResourceComponent";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import moment from "moment-timezone";

const isMobile = window.screen.width <= 1024;

class DevicesModal extends ResourceComponent {
    constructor(props) {
        super(props);
        this.state = {
            resource: {}
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.issue) {
            this.setState({resource: {...nextProps.issue}});
        }
    }

    indexN = (cell, row, enumObject, index) => {
        return (<div>{index + 1}</div>)
    }

    dateFormatter = (cell, row) => {
        return <div>
            <div className={'word-break'}>{moment(row.last_activity).format("M/D/YY ha")}</div>
        </div>
    };

    rowFormatter = (cell, row, enumObject, index) => {
        return <div>
            <Row>
                <Col xs={6}>#</Col>
                <Col xs={6}>{this.indexN(cell, row, enumObject, index)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Device</Col>
                <Col xs={6}>{row.device}</Col>
            </Row>
            <Row>
                <Col xs={6}>IP</Col>
                <Col xs={6}>{row.ip}</Col>
            </Row>
            <Row>
                <Col xs={6}>Last Activity</Col>
                <Col xs={6}>{this.dateFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Options</Col>
                <Col xs={6}><span>
                                    {row.device && <div key={index}>
                                        <span>
                                            {(navigator.userAgent === row.device) && '(current)'}
                                        </span>
                                    </div>}
                    <span className="text-danger" onClick={() => this.props.onDelete(row)}>
                                    <Glyphicon glyph="remove-sign"/>
                                </span>
                                </span>
                </Col>
            </Row>
        </div>
    };

    render() {
        const {devices} = this.props;
        return (
            <Modal
                {...this.props}
                bsSize="big"
                animation={true}
                dialogClassName='modal-smaller'
                onHide={() => this.props.onHide()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{`Devices`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        {devices && devices.length > 0 && isMobile ? devices && <Col xs={12}>
                        <BootstrapTable
                            ref="table"
                            data={devices}
                            striped={true}
                            bordered={false}
                            hover={true}
                            fetchInfo={{dataTotalSize: devices.length}}
                            className="wrapped"
                        >
                            <TableHeaderColumn
                                dataField="id"
                                hidden
                                isKey
                            />
                            <TableHeaderColumn
                                dataField="id"
                                dataFormat={this.rowFormatter}
                            >
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </Col> : devices && <Col xs={12}>
                        <BootstrapTable
                            ref="table"
                            data={devices}
                            striped={true}
                            bordered={false}
                            hover={true}
                            fetchInfo={{dataTotalSize: devices.length}}
                            className="wrapped"
                        >
                            <TableHeaderColumn
                                dataField="id"
                                hidden
                                isKey
                            />
                            <TableHeaderColumn
                                dataField="any"
                                dataFormat={this.indexN}
                                width={"5%"}
                            >
                                #
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="device"
                                width={"10%"}
                                className={'word-break'}
                            >
                                Device
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="ip"
                                width={"25%"}
                            >
                                IP
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="last_activity"
                                width={"10%"}
                                dataFormat={this.dateFormatter}
                            >
                                Last Activity
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                width={"15%"}
                                dataFormat={(cell, row, index) => {
                                    return <span>
                                    {row.device && <div key={index}>
                                        <span>
                                            {(navigator.userAgent === row.device) && '(current)'}
                                        </span>
                                    </div>}
                                        <span className="text-danger"
                                              onClick={() => this.props.onDelete(row)}>
                                    <Glyphicon glyph="remove-sign"/>
                                </span>
                                </span>
                                }}
                            >
                                options
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </Col>}
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Row>
                        <Col md={6} className="text-left">
                            <Button bsSize="small" onClick={() => this.props.onHide()}>Close</Button>
                        </Col>
                    </Row>

                </Modal.Footer>
            </Modal>
        )
    }
}

export default DevicesModal;
