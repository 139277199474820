import React from 'react'
import PropTypes from "prop-types"
import { FormattedDate } from 'react-intl'

const ThDate = ({ dateString }) => {
  if (dateString === undefined || dateString === '' || dateString === null) {
    return <span>--</span>
  }
  return <FormattedDate value={dateString} />
}

ThDate.propTypes = {
  dateString: PropTypes.string,
}

export default ThDate
