import {
  FETCH_WORK_ORDER,
  WORK_ORDER_SEARCH_TEXT,
  UPDATE_PROPOSAL_SEARCH_TEXT,
  PRINT_WO,
  ADD_TO_WO,
  SELECT_PROPOSAL_SERVICE,
  UN_SELECT_PROPOSAL_SERVICE,
  REMOVE_PS_FROM_WO,
  FETCH_PS_INFO,
  FETCH_PROPOSAL_INFO,
  UPDATE_PS_INFO_ATTR,
  PS_TO_WO_MOD_SUCCESS,
  REMOVE_SELECT_PROPOSAL_SERVICE,
} from '../constants'

const initialState = {
  workOrders: [],
  workOrderSearchText: '',
  proposalNoSearchText: '',
  printWo: '-',
  addToWo: '-',
  selectedPs: [],
  proposalInfo: {},
  proposalServicesInfo: [],
}

const removePsFromWo = (state, psId) => {
  //copy and find the ps in teh state slice and set its workOrderNo to null
  let psList = [...state]
  if(psList.length > 0){
    console.info(psList, psId)
    const idx = psList.findIndex(ps => ps.id === psId)
    psList[idx]['workOrderNo'] = null
  }
  return psList

}

const updatePsInfoAttr = (proposalServicesInfo, psId, attr, newVal) => {
  let psInfo = [...proposalServicesInfo]
  let idx = psInfo.findIndex(p => p.id === psId)
  psInfo[idx][attr] = newVal
  return psInfo
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_WORK_ORDER:
      return { ...state, workOrders: action.workOrders }
    case FETCH_PROPOSAL_INFO:
      return { ...state, proposalInfo: action.proposalInfo }
    case FETCH_PS_INFO:
      return { ...state, proposalServicesInfo: action.proposalServicesInfo }
    case WORK_ORDER_SEARCH_TEXT:
      return { ...state, workOrderSearchText: action.workOrderSearchText }
    case UPDATE_PROPOSAL_SEARCH_TEXT:
      return { ...state, proposalNoSearchText: action.proposalNoSearchText }
    case UPDATE_PS_INFO_ATTR:
      return {
        ...state,
        proposalServicesInfo: updatePsInfoAttr(
          state.proposalServicesInfo,
          action.psId,
          action.attr,
          action.newVal
        ),
      }
    case PRINT_WO:
      return { ...state, printWo: action.wo }
    case ADD_TO_WO:
      return { ...state, addToWo: action.wo }
    case REMOVE_PS_FROM_WO:
      return {
        ...state,
        proposalServicesInfo: removePsFromWo(
          state.proposalServicesInfo,
          action.psId,
          action.attr,
          action.newVal
        ),
      }
    case SELECT_PROPOSAL_SERVICE:
      return {
        ...state,
        selectedPs: [...new Set(state.selectedPs.concat(action.psId))],
      }
    case UN_SELECT_PROPOSAL_SERVICE:
      return {
        ...state,
        selectedPs: state.selectedPs.filter(ps => ps !== action.psId),
      }
    case REMOVE_SELECT_PROPOSAL_SERVICE:
      return {
        ...state,
        selectedPs: [],
      }
    case PS_TO_WO_MOD_SUCCESS:
      return { ...state, selectedPs: [] }
    default:
      return state
  }
}
