import React from 'react';
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import Dollars from "../../../../components/Dollars";
import {Col, MenuItem, NavDropdown} from "react-bootstrap";
import './ClientTransactions.css';
import {LinkContainer} from "react-router-bootstrap";
import {Link} from "react-router";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../TransactionsAPI";
import {showEmailDialog} from "../../../../components/email/actions"
import ResourceComponent from "../../../../components/ResourceComponent";
import moment from "moment-timezone";

const Actions = {...actions, showEmailDialog};

class ClientTransactionsTable extends ResourceComponent {

    indexN = (cell, row, enumObject, index) => <div>{index + 1}</div>;

    valueFormatter = (cell, amount) => {
        return <div><Dollars amount={amount}/></div>
    };

    showEmailDialog = (invoice_id, contact_email) => {
        const {email} = this.props;
        this.props.actions.showEmailDialog(
            {
                emailType: "invoice",
                referenceId: invoice_id,
                recipient: contact_email,
                defaultEmail: email
            });
    };

    renderInvoiceCell = (cell, row) => (
        <NavDropdown
            eventKey={1}
            title={row.invoice.number}
            className="menu-show-fixer proposal-menu-padding-reset"
        >
            <LinkContainer to={`/new_invoice_print/${row.invoice.id}`}>
                <MenuItem>Print</MenuItem>
            </LinkContainer>
            <li className='pointer'>
                <Link onClick={() => this.props.actions.downloadPdf(row.invoice)}
                      className={row.invoice.id ? '' : 'disabled'}>PDF</Link>
            </li>
            <li className='pointer'>
                <Link
                    onClick={() => this.setState({selectedInvoiceId: row.invoice.id}, this.showEmailDialog(row.invoice.id, row.contactEmail))}
                    className={row.invoice.id ? '' : 'disabled'}>Email</Link>
            </li>
        </NavDropdown>
    );

    customerFormatter(cell, row) {
        return <NavDropdown
            title={<div>{row.customer.name}</div>}
            noCaret
        >
            <LinkContainer to={`/customer/info/${row.customer.id}`}>
                <MenuItem bsSize="small" eventKey={2.6}>Info</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/proposals/${row.customer.id}`}>
                <MenuItem bsSize="small" eventKey={2.6}>Proposals</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/sites/${row.customer.id}`}>
                <MenuItem bsSize="small" eventKey={2.6}>Sites</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/work_orders/${row.customer.id}`}>
                <MenuItem bsSize="small" eventKey={2.6}>Work Orders</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/invoices/${row.customer.id}`}>
                <MenuItem bsSize="small" eventKey={2.6}>Invoices</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/work_history/${row.customer.id}`}>
                <MenuItem bsSize="small" eventKey={2.6}>Work History</MenuItem>
            </LinkContainer>
        </NavDropdown>
    }

    dateFormatter = (cell, row) => {
        return <div>
            <div className={'word-break'}>{moment(row.created_at).format('MM/DD/YYYY h:mmA')}</div>
        </div>
    };

    render() {
        const {transactions, resource, onChangeResource, renderSizePerPageDropDown, total_payments, auth, client_id} = this.props;
        const {page} = resource;

        let trHeight;
        if (window.screen.width >= 1250) {
            trHeight = 'calc(100vh - 460px)';
        }
        if (window.screen.width < 1250) {
            trHeight = 'calc(100vh - 460px)';
        }

        return (
            <Col md={12} className="remaining vertical-responsive-height no-padding">
                <BootstrapTable
                    ref="table"
                    data={transactions}
                    striped={true}
                    bordered={false}
                    hover={true}
                    remote
                    fetchInfo={{dataTotalSize: total_payments}}
                    trStyle={{height: transactions && transactions.length === 1 ? `${trHeight}` : '55px'}}
                    pagination={transactions && transactions.length > 0}
                    className="wrapped"
                    trClassName={this.selectedMarkerClass}
                    options={
                        {
                            onPageChange: (page, per_page) => {
                                onChangeResource({...resource, page, per_page})
                            },
                            page,
                            sizePerPageList: [{
                                text: '10', value: 10
                            }, {
                                text: '25', value: 25
                            },
                                {
                                    text: '30', value: 30
                                },
                                {
                                    text: '50', value: 50
                                },
                                {
                                    text: '100', value: 100
                                },
                                {
                                    text: '200', value: 200
                                },
                                {
                                    text: 'All', value: total_payments
                                }],
                            sizePerPageDropDown: renderSizePerPageDropDown,
                            sizePerPage: resource.per_page,
                            sortName: resource.sort_by,
                            sortOrder: resource.sort,
                            onSortChange: (sort_by, sort) => onChangeResource({...resource, sort_by, sort})
                        }
                    }
                >
                    <TableHeaderColumn
                        dataField="id"
                        hidden
                        isKey
                    />
                    <TableHeaderColumn
                        dataField="any"
                        dataFormat={this.indexN}
                        width={"3%"}
                    >
                        #
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="customer"
                        dataSort
                        dataFormat={(cell, row) => client_id !== auth ?
                            <span>{row.customer.name}</span> : this.customerFormatter(cell, row)}
                        width={"9%"}
                        className={'word-break'}
                    >
                        Customer
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="invoice_number"
                        dataSort
                        dataFormat={(cell, row) => client_id !== auth ?
                            <span>{row.invoice.number}</span> : this.renderInvoiceCell(cell, row)}
                        width={"7%"}
                        className={'word-break'}
                    >
                        #Invoice
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="amount"
                        dataSort
                        dataFormat={(cell, row) => this.valueFormatter(cell, row.amount)}
                        width={"7%"}
                        className={'word-break'}
                    >
                        Amount
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="customer_fee_amount"
                        dataSort
                        dataFormat={(cell, row) => this.valueFormatter(cell, row.customer_fee_amount)}
                        width={"7%"}
                        className={'word-break'}
                    >
                        Customer Fee
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="transaction_fee_amount"
                        dataSort
                        dataFormat={(cell, row) => this.valueFormatter(cell, row.transaction_fee_amount)}
                        width={"7%"}
                        className={'word-break'}
                    >
                        Transaction Fee
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="stripe_fee_amount"
                        dataSort
                        dataFormat={(cell, row) => this.valueFormatter(cell, row.stripe_fee_amount)}
                        width={"7%"}
                        className={'word-break'}
                    >
                        Stripe Fee
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="total"
                        dataSort
                        dataFormat={(cell, row) => this.valueFormatter(cell, row.total)}
                        width={"6%"}
                        className={'word-break'}
                    >
                        Total
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="status"
                        dataSort
                        dataFormat={(cell, row) => <span
                            className="uppercase">{row.status === 'refund_requested' ? 'REFUND REQUESTED' : row.status}</span>}
                        width={"7%"}
                        className={'word-break'}
                    >
                        Status
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="created_at"
                        dataSort
                        width={"10%"}
                        dataFormat={this.dateFormatter}
                    >
                        Created
                    </TableHeaderColumn>
                </BootstrapTable>
            </Col>
        );
    }
}

function mapStateToProps(state) {
    return {
        email: state.auth.email,
        auth: state.auth.client_id
    };
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(Actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientTransactionsTable);
