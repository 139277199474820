import React, {Component} from "react"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import Helmet from "react-helmet"
import {addAlert} from "../App/actions"
import * as MyActions from "./actions"
import {Link} from "react-router"
import Dollars from "../../components/Dollars"
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table"
import {dateFormatter, dollarsFormatter,} from "../../components/BootStrapTableCellFormatters";
import {Button, Col, FormControl, FormGroup, Glyphicon, Grid, MenuItem, Nav, NavDropdown, Row,} from "react-bootstrap"
import {LinkContainer} from "react-router-bootstrap"
import PageHeader from "../../components/PageTitle"
import moment from "moment-timezone"
import './Invoices.scss';
import Datetime from "react-datetime";
import {saveNewCsvDownload} from "../../common/commonHandlers";
const Actions = {...MyActions, addAlert, saveNewCsvDownload}

class Invoices extends Component {
    state = {
        selectedServiceType: 'open',
        selectedInvoices: [],
        isFiltered: true,
        showFilters: false,
        invoiceFromDate: moment().startOf('month').format('YYYY-MM-DD'),
        invoiceToDate: moment().endOf('month').format('YYYY-MM-DD'),
        invoicePaidDate: moment()
    }

    tableRef = React.createRef()

    editInvoiceFormatter = (cell, row) => {
        return (
            <Link
                to={`/customer/manage_invoices/${row.customerId}?invoice=${row.id}`}
                className="clean-menu-item"

            >
                {row.invoiceNo}
            </Link>
        )

    }
    toggleColumnFilters = () => {
        this.setState({
            showFilters: !this.state.showFilters
        })
    }
    filterInvoices = () => {
        const {selectedSite, params} = this.props
        const {
            invoiceFromDate,
            invoiceToDate,
            selectedInvoiceType,
        } = this.state

        this.setState({
            isFiltered: true,
        })
        // send filter parms

        this.props.actions.fetchInvoices(
            {selectedSite, selectedInvoiceType, invoiceFromDate, invoiceToDate},
            params.id
        )
    }
    markInvoicesPaid = () => {
        const {
            selectedInvoices,
            invoicePaidDate
        } = this.state

        // call actions
        this.props.actions.markInvoicesPaid(selectedInvoices, invoicePaidDate)
    }
    fetchInvoices = e => {
        e.preventDefault()
        this.props.actions.fetchInvoices()
    }
    _onSelectAll = (isSelected, rows) => {
        let selectedInvoices = []

        if (isSelected) {
            selectedInvoices = rows.map(i => i.id)
        }
        this.setState({selectedInvoices})
    }
    _onRowSelect = (row, isSelected, e) => {
        let selectedInvoices = [...this.state.selectedInvoices]
        const invoiceId = parseInt(row.id, 10)

        if (selectedInvoices) {
            if (isSelected) {
                selectedInvoices = selectedInvoices.concat(invoiceId) // prob concatted a dup #
            }
            else {
                selectedInvoices = selectedInvoices.filter(i => i !== invoiceId)
            }
            const uniqSelecteds = new Set(selectedInvoices) //uniqu them
            selectedInvoices = [...uniqSelecteds] //transform uniqs back into an array
        } else {
            selectedInvoices = selectedInvoices.filter(s => s !== invoiceId)
        }
        this.setState({selectedInvoices})
    }
    _onSingleSelect = (name, selected) => {
        this.setState({
            [name]: selected && selected.value
                ? selected.value
                : null,
        })

        if (name === "selectedInvoiceType") {
            //if we change the service type then zero out the selected services too
            this.setState({selectedInvoice: []})
        }
    }
    _dateFormatter = cell => cell && moment(cell).format("M/D/YY")
    _dollarFormatter = cell => cell && <Dollars amount={cell}/>
    _renderTable = data => {
        const {showFilters} = this.state
        const options = {
            page: 1,  //which page you want to show as default
            sizePerPageList: [25, 75, 200], //you can change the dropdown list for size per page
            sizePerPage: 25,  //which size per page you want to locate as default
            paginationSize: 3,  //the pagination bar size.
        }

        return (
            <BootstrapTable
                ref={this.tableRef}
                data={data}
                striped={true}
                bordered={false}
                hover={true}
                exportCSV={true}
                pagination={true}
                search={showFilters}
                csvFileName={() => {
                    const params = {
                        start_date: this.props.invoicedStart,
                        end_date: this.props.invoicedEnd,
                        status: this.props.status,
                    }

                    const fileName = `customer-invoices-${moment().format()}.csv`
                    if (this.tableRef?.current) {
                        this.props.actions.saveNewCsvDownload(this.tableRef.current, fileName, params, "invoices")
                    }
                    return fileName
                }}
                selectRow={{
                    mode: "checkbox",
                    clickToSelect: false,
                    onSelect: this._onRowSelect,
                    onSelectAll: this._onSelectAll,
                }}
                options={options}
            >
                <TableHeaderColumn hidden isKey dataField="id">
                    Invoice Id
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="10%"
                    // filter={showFilters ? { type: "DateFilter" } : null}
                    dataSort={true}
                    columnClassName="menu-show-fixer"
                    dataFormat={dateFormatter}
                    dataField="invoicedAt"
                >
                    Invoiced Date
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="15%"
                    filter={showFilters ? {type: "TextFilter", delay: 500} : null}
                    dataSort={true}
                    dataFormat={this.editInvoiceFormatter}
                    dataField="invoiceNo"
                >
                    Invoice #
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="20%"
                    dataSort={true}
                    filter={showFilters ? {type: "TextFilter", delay: 500} : null}
                    columnClassName="menu-show-fixer"
                    dataField="customerName"
                    dataFormat={this._linkFormatter}
                >
                    Customer
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="20%"
                    tdStyle={{whiteSpace: 'normal'}}
                    thStyle={{whiteSpace: 'normal'}}
                    dataSort={true}
                    filter={showFilters ? {type: "TextFilter", delay: 500} : null}
                    columnClassName="menu-show-fixer"
                    dataField="siteAddress"
                >
                    Site Address
                </TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={{whiteSpace: 'normal'}}
                    thStyle={{whiteSpace: 'normal'}}
                    width="10%"
                    dataSort={true}
                    filter={showFilters ? {type: "TextFilter", delay: 500} : null}
                    columnClassName="menu-show-fixer"
                    dataField="arborist"
                >
                    Arborist
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="10%"
                    filter={showFilters ? {type: "TextFilter", delay: 500} : null}
                    dataSort={true}
                    dataFormat={this._proposalLinkFormatter}
                    columnClassName="menu-show-fixer"
                    dataField="proposalNo"
                >
                    Proposal #
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="5%"
                    filter={showFilters ? {type: "TextFilter", delay: 500} : null}
                    dataSort={true}
                    dataFormat={this._woLinkFormatter}
                    columnClassName="menu-show-fixer"
                    dataField="workOrderNo"
                >
                    WO #
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="5%"
                    filter={showFilters ? {type: "TextFilter", delay: 500} : null}
                    dataSort={true}
                    dataFormat={dollarsFormatter}
                    filterFormatted={false}
                    dataField="total"
                >
                    Cost
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="5%"
                    filter={showFilters ? {type: "DateFilter"} : null}
                    dataSort={true}
                    filterFormatted={false}
                    dataFormat={dateFormatter || "--"}
                    dataField="paidAt"
                >
                    Paid Date
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="5%"
                    filter={showFilters ? {type: "TextFilter", delay: 500} : null}
                    dataSort={true}
                    dataField="aging"
                >
                    Aging
                </TableHeaderColumn>
            </BootstrapTable>
        )
    }
    _linkFormatter = (cell, row) => (
        <Nav>
            <NavDropdown
                eventKey={2}
                title={`${row.customerName}`}
                id={`proposal-menu`}
                className="menu-show-fixer proposal-menu-padding-reset"
            >
                <LinkContainer to={`/customer/info/${row.customerId}`}>
                    <MenuItem bsSize="small">Info</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/proposals/${row.proposalId}`}>
                    <MenuItem bsSize="small">Proposals</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/sites/${row.customerId}`}>
                    <MenuItem bsSize="small">Sites</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/work_orders/${row.customerId}`}>
                    <MenuItem bsSize="small">Work Orders</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/invoices/${row.customerId}`}>
                    <MenuItem bsSize="small">Invoices</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/work_history/${row.customerId}`}>
                    <MenuItem bsSize="small">Work History</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    )
    _proposalLinkFormatter = (cell, row) => (
        <Nav>
            <NavDropdown
                eventKey={2}
                title={cell}
                id={`proposal-menu`}
                className="menu-show-fixer proposal-menu-padding-reset"
            >
                <LinkContainer to={`/mapview/${row.proposalId}`}>
                    <MenuItem bsSize="small">Edit Proposal</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/proposal_service_status/${row.proposalId}`}>
                    <MenuItem bsSize="small">Proposal Statuses</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/print_proposal/${row.proposalId}`}>
                    <MenuItem bsSize="small">Print Proposal</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    )
    _acceptFormatter = (cell, row) => (
        <Link to={`/proposal_service_status/${row.proposalId}`}>
            <span className='text-success'><Glyphicon glyph='ok'/></span>
        </Link>
    )
    _blankFormatter = (cell, row) => (
        <Link to={`/proposal_service_status/${row.proposalId}`}>
            <span className='text-success'></span>
        </Link>
    )

    render() {
        const {
            invoices,
            invoicedStart,
            invoicedEnd,
            status,
        } = this.props

        const {
            updateInvoicedStart,
            updateInvoicedEnd,
            updateInvoiceTypeSelection
        } = this.props.actions

        const {
            selectedInvoices,
            invoicePaidDate,
        } = this.state

        return (
            <Grid fluid>
                <Helmet title="Invoices"/>
                <PageHeader
                    pageName="Invoices"
                    pageDetail={`${invoices ? `${invoices.length === 200 ? "Showing the first 200" : `${invoices.length} found.`}` : "looking for invoices..."} `}
                />
                <Row>
                    <Col md={2} xs={6} className={"text-right pointer modern-view"}>
                        <Link to={`invoices`}><b>MODERN VIEW</b></Link>
                    </Col>
                </Row>
                <form onSubmit={this.fetchInvoices}>
                    <Row>
                        <Col md={5}>
                            <FormGroup bsSize="sm">
                                <Datetime
                                    dateFormat="MM/DD/YYYY"
                                    timeFormat={null}
                                    value={invoicedStart ? moment(invoicedStart) : ""}
                                    className="text-center"
                                    inputProps={{placeholder: 'Start Date'}}
                                    onChange={updateInvoicedStart}/>
                                {" "}
                                <span className='text-default'><Glyphicon glyph='minus'/></span>
                                {" "}
                                <Datetime
                                    dateFormat="MM/DD/YYYY"
                                    timeFormat={null}
                                    value={invoicedEnd ? moment(invoicedEnd) : ""}
                                    className="text-center"
                                    inputProps={{placeholder: 'End Date'}}
                                    onChange={updateInvoicedEnd}/>
                                <Col md={2}>
                                    <FormControl
                                        value={status}
                                        componentClass="select"
                                        onChange={updateInvoiceTypeSelection}
                                    >
                                        <option value="all">All</option>
                                        <option value="open">Unpaid</option>
                                        <option value="closed">Paid</option>
                                    </FormControl>
                                </Col>
                                {' '}
                                <Button bsSize="small" type="submit" bsStyle="success">
                                    Find Invoices
                                </Button>
                            </FormGroup>
                            {/*jank way of valigning button to bottom*/}
                            <FormGroup bsSize="sm">
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            {invoices && invoices.length ?
                                <Button bsSize="small" bsStyle="warning" onClick={() => this.toggleColumnFilters()}>Filter
                                    Rows</Button>
                                :
                                null
                            }
                        </Col>
                        <Col md={3}>
                            <Button
                                className="pull-right"
                                bsSize="small"
                                bsStyle="warning"
                                onClick={() => this.markInvoicesPaid()}
                                disabled={selectedInvoices.length === 0}
                            >
                                Update Paid Date
                            </Button>
                        </Col>
                        <Col md={2}>
                            <Datetime
                                dateFormat="MM/DD/YYYY"
                                timeFormat={null}
                                value={moment(invoicePaidDate).isValid() ? moment(invoicePaidDate) : null}
                                isClearable
                                inputProps={{placeholder: 'Paid Date'}}
                                onChange={m =>
                                    this._onSingleSelect("invoicePaidDate", {
                                        value: moment(m).isValid() ? moment(m).format() : null,
                                    })}/>
                        </Col>

                    </Row>
                </form>
                <p>&nbsp;</p>

                <Row>
                    <Col md={12}>
                        {invoices ? this._renderTable(invoices) : null}
                    </Col>
                </Row>

                <Row>
                    <Col md={12}/>
                </Row>

            </Grid>
        )
    }
}

function mapStateToProps(state) {
    return {
        invoicedStart: state.invoices.invoicedStart,
        invoicedEnd: state.invoices.invoicedEnd,
        status: state.invoices.status,
        invoices: state.invoices.invoices,
    }
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(Actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoices)
