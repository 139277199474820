import React, {PureComponent} from "react";
import {Badge, Button, ButtonToolbar, Col, FormControl, MenuItem, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {BootstrapTable, SizePerPageDropDown, TableHeaderColumn} from "react-bootstrap-table";
import {
    ageFormatter,
    dateFormatter,
    dateArrayFormatter,
    dollarsFormatter,
    proposalNoFormatterWithEmail, dateNoTimeFormatter
} from "../../components/BootStrapTableCellFormatters";
import moment from "moment-timezone";
import {Rnd} from "react-rnd";
import windowSize from 'react-window-size';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileCsv} from "@fortawesome/free-solid-svg-icons/faFileCsv";
import {faEnvelopeSquare, faThumbsDown, faMapMarkerAlt, faExclamationTriangle, faCog} from "@fortawesome/free-solid-svg-icons";
import DragComponent from "./DragComponent";
import './Dashboard.css';
import Dollars from "../../components/Dollars";
import {LinkContainer} from "react-router-bootstrap";

const isMobile = window.screen.width <= 1024;
const smMobile = window.screen.width <= 450;
const xsMobile = window.screen.width <= 350;
const screenHeightL = window.screen.height <= 900;
const screenHeightXL = window.screen.height > 1100;

class BottomRightPanel extends DragComponent {
    constructor(props) {
        super(props);
        this.state = {
            y: 0,
            width: 0,
            x: 0,
            height: 0,
            parentWidth: 0,
            page: 1,
            perPage: 25,
            startPosition: false,
            oldHeight: this.props.parentRef.offsetHeight
        }
    }

    newValue = (_old, _new, value) => {
        const x = value * 100 / _old;
        return _new * x / 100;
    }

    componentDidMount() {
        let new_info = this.props.info;
        new_info.x = this.state.x;
        new_info.y = this.state.y;
        new_info.width = this.state.width;
        new_info.height = this.state.height;
        this.props.handleData("right_panel", new_info);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {


        if (!this.state.startPosition) {
            this.setState({
                y: screenHeightXL ? this.props.parentRef.offsetHeight * 0.55 : screenHeightL ? this.props.parentRef.offsetHeight * 0.50 : this.props.parentRef.offsetHeight * 0.525,
                width: this.props.parentRef.offsetWidth / 1.5,
                x: this.props.parentRef.offsetWidth / 2,
                height: this.props.parentRef.offsetHeight * 0.35,
                startPosition: true,
                oldHeight: this.props.parentRef.offsetHeight
            });
        }

        if (prevState.x !== this.state.x || prevState.y !== this.state.y || prevState.width !== this.state.width ||
            prevState.height !== this.state.height) {
            let new_info = this.props.info;
            new_info.x = this.state.x;
            new_info.y = this.state.y;
            new_info.width = this.state.width;
            new_info.height = this.state.height;
            this.props.handleData("right_panel", new_info);
        }
        if ((prevProps.windowWidth !== this.props.windowWidth || this.state.oldHeight !== this.props.parentRef.offsetHeight) && this.state.startPosition) {
            this.setState({
                width: this.newValue(prevProps.windowWidth, this.props.windowWidth,
                    this.state.width),
                x: this.newValue(prevProps.windowWidth, this.props.windowWidth,
                    this.state.x),
                height: this.newValue(this.state.oldHeight, this.props.parentRef.offsetHeight, this.state.height),
                y: this.newValue(this.state.oldHeight, this.props.parentRef.offsetHeight,
                    this.state.y),
                oldHeight: this.props.parentRef.offsetHeight
            });
        }
        this.rnd.updatePosition({x: this.state.x, y: this.state.y});
        this.rnd.updateSize({width: this.state.width, height: this.state.height});
    }

    indexN = (cell, row) => {
        return (<div>{this.props.data.indexOf(row) + 1}</div>)
    };

    email = (proposalId) => {
        const {email} = this.props;
        this.props.actions.loadProposalData(proposalId, proposal => {
            this.props.actions.showEmailDialog(
                {
                    emailType: "proposal",
                    referenceId: proposalId,
                    recipient: proposal.customer_email_address,
                    defaultEmail: email
                });
        });
    }

    createCustomButtonGroup = (table) => {
        const {selectedRows, selectedStatus, handleData, resource, toggleDeclinedModal} = this.props;
        return (
            <ButtonToolbar>
                {(selectedStatus === 'In Draft' || selectedStatus === 'Sent') &&
                <Button type='button' disabled={selectedRows.length === 0 || !resource.onlyProposed}
                        onClick={() => toggleDeclinedModal()}>
                    <FontAwesomeIcon icon={faThumbsDown} className="bigger"/>
                </Button>
                }
                <Button type='button' disabled={selectedRows.length === 0}
                        onClick={() => handleData("email", true)}>
                    <FontAwesomeIcon icon={faEnvelopeSquare} className="bigger"/>
                </Button>
                {table.exportCSVBtn}
            </ButtonToolbar>
        );
    }

    renderSizePerPageDropDown = (props) => {
        return (
            <div className="btn-group dropup">
                <button className="btn btn-info dropdown-toggle" type="button" id="dropdownMenu2"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span>{props.currSizePerPage}</span>
                    <span className="ml-6 caret"></span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                    {props.sizePerPageList.map(item =>
                        <li>
                            <a
                                className='table-pagination dropdown-item'
                                onClick={() => props.changeSizePerPage(item.value)}
                            >{item.text}</a>
                        </li>
                    )}
                </ul>
            </div>
        );
    }

    serviceTooltip = (serviceName) => (
        <Tooltip id="tooltip">
            {serviceName && serviceName.length > 0 && serviceName.map((service, i) => (
                <div className={`flex-column-start ${i === serviceName.length - 1 ? null : "bottom5"}`} key={i}>
                    <span className={`text-left bold ${smMobile ? "font10" : null}`}>{service.name}</span>
                    <span className={`text-left ${smMobile ? "font10" : null}`}>{service.date}</span>
                </div>
            ))}
        </Tooltip>
    );

    topNavTooltip = (text) => (
        <Tooltip id="tooltip_btn">
            <div>
                <span>{text}</span>
            </div>
        </Tooltip>
    );

    topNavBtnFormatter = (csvBtn, declineBtn, emailBtn, markerBtn, settingsBtn) => {
        const {dashboardStatuses, selected, selectedStatus, selectedRecords, handleData, toggleDeclinedModal, resource, declineSelected, data, setEmailRecipients, allSelected, onChangeState} = this.props;

        return (
            <div>
                <OverlayTrigger placement="top" overlay={this.topNavTooltip(csvBtn)}>
                    <Button className={smMobile ? "table_btn" : "btnHeight mr-3"}
                            disabled={!allSelected && selectedRecords.length === 0}
                            onClick={() => this.props.createCSV(dashboardStatuses[selected])}><FontAwesomeIcon
                        icon={faFileCsv} className="bigger"/></Button>
                </OverlayTrigger>
                {(selectedStatus === 'In Draft' || selectedStatus === 'Sent') &&
                <OverlayTrigger placement="top" overlay={this.topNavTooltip(declineBtn)}>
                    <Button className={smMobile ? "table_btn" : "btnHeight mr-3"}
                            onClick={() => toggleDeclinedModal()}
                            disabled={selectedRecords.length === 0 || !resource.onlyProposed}><FontAwesomeIcon icon={faThumbsDown}
                                                                                                     className="bigger"/></Button>
                </OverlayTrigger>}
                <OverlayTrigger placement="top" overlay={this.topNavTooltip(emailBtn)}>
                    <Button className={smMobile ? "table_btn" : "btnHeight mr-3"}
                                onClick={() => {
                                    if (resource.isBacklog) {
                                        if (data && data.length > 0 && selectedRecords && selectedRecords.length > 0) {
                                            let proposals_helper = []
                                            selectedRecords.map(s => {
                                                data.forEach(d => {
                                                    if (allSelected && s === d.proposalId) {
                                                        proposals_helper.push(d.proposalId)
                                                    } else if (s === d.id) {
                                                        proposals_helper.push(d.proposalId)
                                                    }
                                                })
                                            })
                                            if (proposals_helper && proposals_helper.length > 0) {
                                                proposals_helper = proposals_helper.filter((p, i, arr) => arr.indexOf(p) === i)
                                            }
                                            setEmailRecipients(selectedRecords, proposals_helper)
                                        }
                                    } else {
                                        handleData("email", true)
                                    }
                                }}
                            disabled={!allSelected && selectedRecords.length === 0}><FontAwesomeIcon
                        icon={faEnvelopeSquare}
                        className="bigger"/></Button>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={this.topNavTooltip(markerBtn)}>
                    <Button className={smMobile ? "table_btn" : "btnHeight mr-3"} onClick={() => this.props.showSelectedMarkers()}
                    ><FontAwesomeIcon icon={faMapMarkerAlt}
                                      className="bigger"/></Button>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={this.topNavTooltip(settingsBtn)}>
                    <Button className="table_btn btnHeight" onClick={() => onChangeState('showDock', true)}
                    ><FontAwesomeIcon icon={faCog}
                                      className="bigger"/></Button>
                </OverlayTrigger>
            </div>
        );
    };

    serviceFormatter = (cell, row, selectedStatus) => {
        return (
            <OverlayTrigger placement="right" overlay={this.serviceTooltip(row.status_name)}>
                <Badge
                    className={`proposalBadge font12 ${isMobile ? null : "ml-6"}`}>{selectedStatus === 'Invoiced' || selectedStatus === 'Paid' ? row.service_date && row.service_date.length : row.status}</Badge>
            </OverlayTrigger>
        )
    };

    serviceNameFormatter = (cell, row, service_prop) => {
        if (!this.props.resource.isBacklog) return
        if (service_prop === "service_total_accepted") {
            return <div><Dollars
                amount={row.service_total_accepted}/>
            </div>
        }
        if (service_prop === "service_name") {
            return <div>{row.name}</div>
        } else if (service_prop === "service_type") {
            return <div>{row.service_type}</div>
        } else if (service_prop === "service_amount") {
            return <div><Dollars amount={row.service_total}/></div>
        } else if (service_prop === "service_mhrs") {
            return <div>{row.man_hrs}</div>
        } else if (service_prop === "service_status" && row.ps_status) {
            const status_name = row.ps_status.charAt(0).toUpperCase() + row.ps_status.slice(1).toLowerCase();
            return <div>{status_name}</div>
        } else if (service_prop === "service_priority") {
            return <div>{row.priority ? row.priority : 'N/A'}</div>
        } else if (service_prop === "service_event_dates") {
            return <div>{row.event_dates ? row.event_dates : 'N/A'}</div>
        } else if (service_prop === "service_promised_dates") {
            return <div>{row.ps_promised_start_at && row.ps_promised_end_at ? moment(row.ps_promised_start_at).format('MM/DD/YYYY')
                + "-" + moment(row.ps_promised_end_at).format('MM/DD/YYYY') : '' + 'N/A'}</div>
        }

    }

    paidFormatter = (cell, row, selectedStatus) => {
        return (
            <OverlayTrigger placement={smMobile ? "bottom" : "right"}
                            overlay={this.paidTooltip(row.partial_payments, row.invoiced_at, selectedStatus)}>
                <Badge
                    className={`proposalBadge font12 ${smMobile ? null : "ml-6"}`}>{selectedStatus === 'Paid' ? row.partial_payments && row.partial_payments.length : selectedStatus === 'Invoiced' ? row.invoiced_at && row.invoiced_at.length : row.status}</Badge>
            </OverlayTrigger>
        )
    };

    linkFormatter = (cell, row) => {
        return <div className='dropdown'>{cell && cell.length > 0 && cell.map(item => {
            return <LinkContainer target="_blank"
                                  to={item.work_order_no ? `/work_orders/${row.proposalId}` : `/proposals/${row.proposalId}/invoices/`}>
                <MenuItem bsSize="small">{item.work_order_no ? `#${item.work_order_no}` : `#${item.number}`}</MenuItem>
            </LinkContainer>
        })
        }</div>
    }

    paidTooltip = (paidServices, invoicedServices, selectedStatus) => {
        const invoiced_services = invoicedServices && invoicedServices.map(x => x.split("|"));

        if (selectedStatus === "Paid") {
            return (
                <Tooltip id="tooltip">
                    {paidServices && paidServices.map((service, i) => {
                            return (
                                <div
                                    className={`flex-column-start ${i === paidServices.length - 1 ? null : "bottom5"}`}
                                    key={i}>
                                    <div className="full-width space-between-end">
                                        <span
                                            className={`text-capitalize mr-9 text-left bold ${smMobile ? "font10" : null}`}>{service.status}</span>
                                        <span className={`text-left bold ${smMobile ? "font10" : null}`}>{<Dollars
                                            amount={service.amount}/>}</span>
                                    </div>
                                    <span
                                        className={`text-left ${smMobile ? "font10" : null}`}>{service.date}</span>
                                </div>
                            )
                        }
                    )}
                </Tooltip>
            )
        } else if (selectedStatus === 'Invoiced') {
            return (
                <Tooltip id="tooltip">
                    {invoiced_services && invoiced_services.map((service, i) => {
                            return (
                                <div
                                    className={`flex-column-start ${i === invoiced_services.length - 1 ? null : "bottom5"}`}
                                    key={i}>
                                    <span className={`text-left bold ${smMobile ? "font10" : null}`}>{service[0]}</span>
                                    <span
                                        className={`text-left ${smMobile ? "font10" : null}`}>{moment(service[1]).add(2, 'hour').format('MM/DD/YYYY hh:mmA')}</span>
                                </div>
                            )
                        }
                    )}
                </Tooltip>
            )
        }
    };

    totalPaidFormatter = (cell, row) => cell ? <Dollars amount={cell}/> : 'N/A';
    priceFormatter = (cell, row) => cell ? <Dollars amount={cell}/> : 'N/A';

    render() {
        const {onSearch, sentFormatter, selected, lastSentFormatter, isDeclinedModalOpened, sentCompleteFormatter, onChangeState, allSelected, selectedRecords, report, selectedFilter, acceptedTotals, onRowClick, selectedStatus, dashboardStatuses, info, selectedMarkerClass, onRowSelect, onSelectAll, data, filter, otherPanels, resource, onChangeResource, tabColumns, hiddenTable} = this.props;
        const {sort_by, sort, page, per_page, isBacklog} = resource;

        const csvBtn = "Export selected records to CSV";
        const declineBtn = "Decline selected record";
        const emailBtn = "Send email to selected customers";
        const markerBtn = "Show selected records on map";
        const settingsBtn = "Show dashboard settings panel";

        const options = {
            sizePerPageDropDown: this.renderSizePerPageDropDown,
            page,  //which page you want to show as default
            sizePerPageList: [{
                text: '10', value: 10
            }, {
                text: '25', value: 25
            }, {
                text: '30', value: 30
            }, {
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '200', value: 200
            },
                {
                    text: 'All', value: report[dashboardStatuses[selected].value].count
                }],
            sizePerPage: per_page,  //which size per page you want to locate as default
            sortName: sort_by,
            sortOrder: sort,
            onSortChange: (sort_by, sort) => onChangeResource({...resource, sort_by, sort}),
            onRowClick: row => {
                onRowClick(row)
            },
            onPageChange: (page, per_page) => {
                this.props.handleData('page', page, true);
                this.props.handleData('per_page', per_page, true);
                this.setState({page, perPage: per_page}, this.props.reloadTableData(false));
            },
        };

        let trHeight;
        if (window.screen.width >= 1250) {
            trHeight = '200px';
        }
        if (window.screen.width < 1250) {
            trHeight = '220px';
        }

        return (
            <Rnd
                ref={c => {
                    this.rnd = c;
                }}
                size={{width: this.state.width, height: this.state.height}}
                position={{x: this.state.x, y: this.state.y}}
                onDragStop={(e, d) => {
                    this.setState({x: d.x, y: d.y});
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    this.setState({
                        width: parseInt(ref.style.width),
                        height: parseInt(ref.style.height),
                        ...position
                    });
                }}
                bounds="parent"
                dragHandleClassName="panel-heading"
            >
                <div className="panel panel-default"
                     style={{
                         overflow: "hidden",
                         width: "100%",
                         height: "100%",
                         flexFlow: "column",
                         marginBottom: "0px",
                         display: info.show ? "flex" : "none"
                     }}>
                    <div style={{cursor: "pointer"}} className="panel-heading">
                        Proposals
                    </div>
                    <div className="panel-body"
                         style={{
                             height: "calc(100% - 39px)",
                             display: "flex",
                             flexFlow: "column",
                             flexGrow: "1"
                         }}
                    >
                        <div>
                            <Col md={6} xs={6} className="pl-10">
                                {this.topNavBtnFormatter(csvBtn, declineBtn, emailBtn, markerBtn, settingsBtn)}
                            </Col>
                            <Col md={6} xs={6}>
                                <FormControl
                                    name="q"
                                    placeholder="Search..."
                                    className={smMobile ? "mt10" : ""}
                                    value={resource.q}
                                    onChange={e => onSearch(e)}
                                />
                            </Col>
                        </div>
                        <BootstrapTable
                            ref="table"
                            data={data}
                            options={options}
                            fetchInfo={{dataTotalSize: (selectedFilter && acceptedTotals) ? acceptedTotals[selectedFilter].count : report[dashboardStatuses[selected].value].count}}
                            bordered={false}
                            className="wrapped paddingTable"
                            striped
                            printable
                            trStyle={{height: data && data.length === 1 ? `${trHeight}` : null}}
                            remote
                            hover
                            pagination
                            containerStyle={{
                                flexGrow: "1",
                                maxHeight: "100%",
                                display: "flex",
                                flexFlow: "column"
                            }}
                            tableStyle={{
                                display: "flex",
                                height: "calc(100% - 36px)",
                                flexFlow: "column",
                                flexGrow: "1",
                                overflowY: "auto",
                                fontSize: 11
                            }}
                            headerStyle={{overflow: "initial"}}
                            bodyStyle={{maxHeight: "100%", height: "unset"}}
                            trClassName={selectedMarkerClass}

                            selectRow={{
                                mode: "checkbox",
                                clickToSelect: false,
                                hideSelectColumn: hiddenTable,
                                selected: allSelected ? data.map(x => selectedStatus === 'Backlog' ? x.id : x.proposalId) : selectedRecords,
                                onSelect: (row, selected) => {
                                    let {selectedRecords, allSelected} = this.props;
                                    const index = selectedRecords.indexOf(selectedStatus === 'Backlog' ? row.id : row.proposalId);
                                    if (index > -1) {
                                        allSelected = false
                                        selectedRecords.splice(index, 1);
                                    } else {
                                        selectedRecords.push(selectedStatus === 'Backlog' ? row.id : row.proposalId);
                                        allSelected = selectedRecords.length === data.length
                                    }
                                    onChangeState("selectedRecords", selectedRecords);
                                    onChangeState("allSelected", allSelected);

                                },
                                onSelectAll: () => {
                                    let {allSelected} = this.props;
                                    onSelectAll(!allSelected, data)
                                    onChangeState("allSelected", !allSelected);
                                },
                            }}
                            csvFileName={`proposals-${moment().format()}.csv`}
                        >
                            <TableHeaderColumn width={selectedStatus === 'Backlog' ? "15%" : "10%"}
                                               dataField="customer_name" dataSort hidden={!tabColumns.includes('Customer')}>
                                Customer
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="status" width="10%" dataSort
                                               hidden={!(selectedStatus === 'Sent'
                                                   || (selectedStatus === 'In Draft')
                                                   || (selectedStatus === 'Invoiced')
                                                   || (selectedStatus === 'Paid')
                                                   || (selectedStatus !== 'Backlog'))
                                                   || !tabColumns.includes('Services')
                                               }
                                               dataFormat={(cell, row) => this.serviceFormatter(cell, row, selectedStatus)}
                            >Services</TableHeaderColumn>
                            <TableHeaderColumn dataField="service_name" width="10%" dataSort
                                               dataFormat={(cell, row) => this.serviceNameFormatter(cell, row, "service_name")}
                                               hidden={!isBacklog || !tabColumns.includes('Service')}
                            >Service</TableHeaderColumn>
                            <TableHeaderColumn dataField="service_type" width="10%" dataSort
                                               dataFormat={(cell, row) => this.serviceNameFormatter(cell, row, "service_type")}
                                               hidden={!isBacklog || !tabColumns.includes('Service Type')}
                            >Service Type</TableHeaderColumn>
                            <TableHeaderColumn dataField="service_amount" width="10%" dataSort
                                               dataFormat={(cell, row) => this.serviceNameFormatter(cell, row, "service_amount")}
                                               hidden={!isBacklog || !tabColumns.includes('Service $')}
                            >Service $</TableHeaderColumn>
                            <TableHeaderColumn dataField="service_mhrs" width="5%" dataSort
                                               dataFormat={(cell, row) => this.serviceNameFormatter(cell, row, "service_mhrs")}
                                               hidden={!isBacklog || !tabColumns.includes('MHRS')}
                            >MHRS</TableHeaderColumn>
                            <TableHeaderColumn dataField="sent_date" width="10%" dataSort
                                               dataFormat={dateFormatter}
                                               hidden={!(selectedStatus === 'Sent') || !tabColumns.includes('Sent Date')}>Sent Date</TableHeaderColumn>
                            <TableHeaderColumn hidden dataField="proposalId" isKey={selectedStatus !== 'Backlog'}/>
                            <TableHeaderColumn hidden dataField="id" isKey={resource.isBacklog}/>
                            <TableHeaderColumn dataField="email_count" width="10%" dataSort
                                               dataFormat={(cell, row) => sentFormatter(cell, row)}
                                               hidden={!(selectedStatus === 'Sent') || !tabColumns.includes('#Sent')}
                            >#Sent</TableHeaderColumn>
                            <TableHeaderColumn dataField="last_follow_up_date" width="15%" dataSort
                                               dataFormat={(cell, row) => lastSentFormatter(cell, row)}
                                               hidden={!(selectedStatus === 'Sent') || !tabColumns.includes('Due on')}
                            >Due on</TableHeaderColumn>
                            <TableHeaderColumn dataField="complete" width="10%" dataSort
                                               dataFormat={(cell, row) => sentCompleteFormatter(cell, row)}
                                               hidden={!(selectedStatus === 'Sent') || !tabColumns.includes('Completed')}
                            >Completed</TableHeaderColumn>
                            <TableHeaderColumn
                                width={"10%"}
                                dataField={selectedStatus === 'Paid' ? "invoices_total" : "price"}
                                dataFormat={dollarsFormatter}
                                hidden={isBacklog || !tabColumns.includes('Price')}
                                dataSort>
                                Price
                            </TableHeaderColumn>
                            <TableHeaderColumn width="15%" dataField="service_status" dataSort
                                               dataFormat={(cell, row) => this.serviceNameFormatter(cell, row, "service_status")}
                                               hidden={!isBacklog || !tabColumns.includes('Status')}>
                                Status
                            </TableHeaderColumn>
                            <TableHeaderColumn width="10%" dataField="service_priority" dataSort
                                               dataFormat={(cell, row) => this.serviceNameFormatter(cell, row, "service_priority")}
                                               hidden={!isBacklog || !tabColumns.includes('Priority')}>
                                Priority
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                width={"10%"}
                                dataField='proposalNo'
                                hidden={!tabColumns.includes('Proposal #')}
                                dataFormat={(x, y) => proposalNoFormatterWithEmail(x, y, this.props.onEmail, filter && ['unscheduled', 'scheduled'].includes(filter.key))}
                                dataSort>
                                Proposal #
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={"10%"}
                                dataField='accepted_date'
                                dataFormat={ageFormatter}
                                hidden={(selectedStatus === 'In Draft' || selectedStatus === 'Sent') || !tabColumns.includes('Age')}
                                dataSort>
                                Age
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={"15%"}
                                dataField='created_at'
                                dataFormat={dateFormatter}
                                hidden={isBacklog || !tabColumns.includes('Created')}
                                dataSort>
                                Created
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="10%"
                                dataField="partial_payments"
                                dataFormat={(cell, row) => this.paidFormatter(cell, row, selectedStatus)}
                                dataSort
                                hidden={selectedStatus !== 'Paid' || !tabColumns.includes('Paid Dates')}
                            >
                                Paid Dates
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="10%"
                                dataField="total_paid"
                                dataFormat={(cell, row) => this.totalPaidFormatter(cell, row)}
                                dataSort
                                hidden={selectedStatus !== 'Paid' || !tabColumns.includes('Total Paid')}
                            >
                                Total Paid
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="20%"
                                dataField="completed_at"
                                dataFormat={dateFormatter}
                                dataSort
                                hidden={selectedStatus !== 'Completed' || !tabColumns.includes('Completed at')}
                            >
                                Completed at
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="20%"
                                dataField="invoiced_at"
                                dataFormat={(cell, row) => this.paidFormatter(cell, row, selectedStatus)}
                                dataSort
                                hidden={selectedStatus !== 'Invoiced' || !tabColumns.includes('Invoiced at')}
                            >
                                Invoiced at
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="20%"
                                dataField="accepted_date"
                                dataFormat={dateFormatter}
                                dataSort
                                hidden={selectedStatus !== 'Backlog' || !tabColumns.includes('Accepted')}
                            >
                                Accepted
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={"20%"}
                                dataField={"service_promised_from"}
                                dataFormat={(cell, row) => {
                                    return this.serviceNameFormatter(cell, row, "service_promised_dates")
                                }}
                                dataSort
                                hidden={!(isBacklog) || !tabColumns.includes('Promised')}
                            >
                                Promised
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={"15%"}
                                dataField={"event_dates"}
                                dataSort
                                dataFormat={(cell, row) => this.serviceNameFormatter(cell, row, "service_event_dates")}
                                hidden={!(isBacklog) || !tabColumns.includes('Scheduled on')}
                            >
                                Scheduled on
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="20%"
                                dataField="work_orders"
                                dataFormat={this.linkFormatter}
                                hidden={selectedStatus !== 'Invoiced' && selectedStatus !== 'Paid' || !tabColumns.includes('WOs')}
                            >
                                WOs
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="20%"
                                dataField="invoices_no"
                                dataFormat={this.linkFormatter}
                                hidden={selectedStatus !== 'Invoiced' && selectedStatus !== 'Paid' || !tabColumns.includes('Invoices')}
                            >
                                Invoices
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={"15%"}
                                dataField='arborist'
                                hidden={!tabColumns.includes('Arborist')}
                                dataSort>
                                Arborist
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="15%"
                                dataField="site"
                                dataSort
                                style={{color: 'blue'}}
                                hidden={!isBacklog || !tabColumns.includes('Site')}
                            >
                                Site
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </div>
            </Rnd>
        );
    }
}

export default windowSize(BottomRightPanel);
