import React, {useCallback, useEffect, useRef, useState} from "react";
import './NewCustomerInfoPage.scss'
import {
    Button,
    Checkbox,
    Col,
    FormControl,
    FormGroup,
    Glyphicon,
    Grid,
    MenuItem,
    NavDropdown,
    OverlayTrigger,
    Row,
    Tooltip
} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as CustomerActions from "./NewCustomerInfoPageApi"
import {colors, select, themeBodyColor} from "../../../common/commonHandlers";
import Select from "react-select";
import {debounce} from "throttle-debounce";
import {browserHistory, Link} from "react-router";
import Container from "./NewCustomerInfoComponents/Container";
import quickbooks from "../CustomerInfo/quickbooks.png";
import Dropzone from "react-dropzone";
import ReorderingDragAndDrop from "../../../components/ReorderingDragAndDrop/ReorderingDragAndDrop";
import {boxesPerRowReorderingDNDCustomerInfo} from "../CustomerInfo/constants";
import CustomerContactsList from "./NewCustomerInfoComponents/CustomerContactsList";
import CustomerContactsEditModal from "./NewCustomerInfoComponents/CustomerContactsEditModal";
import CustomerSitesList from "./NewCustomerInfoComponents/CustomerSitesList";
import CustomerSitesEditModal from "./NewCustomerInfoComponents/CustomerSitesEditModal";
import CustomerTimeline from "./NewCustomerInfoComponents/CustomerTimeline";
import DateRangeFilter from "../../../components/DateRangeFilter";
import moment from "moment-timezone";
import TimelineFilterModal from "./NewCustomerInfoComponents/TimelineElements/TimelineFilterModal";
import CustomerSitesMap from "./NewCustomerInfoComponents/CustomerMap";
import Dollars from "../../../components/Dollars";
import CustomerSalesData from "./NewCustomerInfoComponents/CustomerSalesData";
import DuplicatesModal from "./NewCustomerInfoComponents/DuplicatesModal";
import InfoModal from "../../../common/InfoModal";
import SendCredentialsModal from "./NewCustomerInfoComponents/SendCredentialsModal";
import ConfirmationSaveModal from "./NewCustomerInfoComponents/ConfirmationSaveModal";
import MDSpinner from "react-md-spinner";

const actions = {...CustomerActions}
const isMobile = window.screen.width <= 1024;

const NewCustomerInfoPage = ({actions}) => {
    const [selectedCustomerId, setSelectedCustomerId] = useState(null)
    const [customer, setCustomer] = useState({})
    const [customerBasicData, setCustomerBasicData] = useState({})
    const [client, setClient] = useState({})
    const [defaultCustomerSelectOptions, setDefaultCustomerSelectOptions] = useState([])
    const [customerSelectOptions, setCustomerSelectOptions] = useState([])
    const [employees, setEmployees] = useState([])
    const [customerTypes, setCustomerTypes] = useState([])
    const [contactTypes, setContactTypes] = useState([])
    const [roles, setRoles] = useState([])
    const [referrals, setReferrals] = useState([])
    const [isSearchCustomer, setIsSearchCustomer] = useState(false)
    const [editable, setEditable] = useState(false)
    const [windowWidth, setWindowWidth] = useState(0)
    const [customerContacts, setCustomerContacts] = useState([])
    const [filteredCustomerContacts, setFilteredCustomerContacts] = useState([])
    const [customerSites, setCustomerSites] = useState([])
    const [filteredCustomerSites, setFilteredCustomerSites] = useState([])
    const [billingCustomerContact, setBillingCustomerContact] = useState({})
    const [customerTextSearch, setCustomerTextSearch] = useState({})
    const [showCustomerContactsModal, setShowCustomerContactsModal] = useState({})
    const [showCustomerSitesModal, setShowCustomerSitesModal] = useState({})
    const [showCustomerNotesFilterModal, setShowCustomerNotesFilterModal] = useState(false)
    const [showDuplicatesModal, setShowDuplicatesModal] = useState(false)
    const [showInfoModal, setShowInfoModal] = useState(false)
    const [showSendCredentialsModal, setShowSendCredentialsModal] = useState(false)
    const [showConfirmationSaveModal, setShowConfirmationSaveModal] = useState({show: false, onSaveWith: null, onSave: null})
    const [customerNotesFilters, setCustomerNotesFilters] = useState({
        showEvents: true,
        showCrewMessage: true,
        showNotes: true,
        date_from: null,
        date_to: null
    })
    const [timelineData, setTimelineData] = useState([])
    const [currentUserPersonId, setCurrentUserPersonId] = useState(null)
    const [readyCustomerNotes, setReadyCustomerNotes] = useState(true)
    const [readyCustomerContacts, setReadyCustomerContacts] = useState(true)
    const [readyCustomerSites, setReadyCustomerSites] = useState(true)
    const [employeeForNotes, setEmployeeForNotes] = useState([])
    const [customerSalesData, setCustomerSalesData] = useState({})
    const [user, setUser] = useState({})
    const [submitted, setSubmitted] = useState(false)
    const [loadingCustomerAttachments, setLoadingCustomerAttachments] = useState(false)
    const [saveInProgress, setSaveInProgress] = useState(false)

    const communication_preference_options = [
        {value: null, label: "-"},
        {value: "Email", label: "Email"},
        {value: "Phone", label: "Phone"},
        {value: "Text", label: "Text"}
    ]

    const selectCustomerRef = useRef(null)

    const customer_view_options = client && Object.keys(client).length > 0 && client?.customer_view_options && JSON.parse(client.customer_view_options)
    let themeColor;

    const pageTheme = localStorage.getItem('reduxPersist:theme')
    if (pageTheme) {
        themeColor = themeBodyColor.filter(x => x.theme === JSON.parse(pageTheme).theme)[0]
    }

    const reload = id => {
        if (!isNaN(id)) {
            let newFilters = null

            reloadBillingContact(id)
            actions.load(res => {
                setReferrals(res.referrals)
                setCustomerTypes(res.customerTypes)
                setEmployees(res.employees)
                setContactTypes(res.contactTypes)
                setRoles(res.roles)
                setClient(res.client)
                setUser(res.user)

                if (res?.user?.timeline_filters) {
                    const currentUser = JSON.parse(res?.user?.timeline_filters)
                    newFilters = {...customerNotesFilters, ...currentUser}
                    setCustomerNotesFilters(newFilters)
                }
                reloadCustomerNotes(id, newFilters)
            })
            reloadCustomerContacts(id)
            reloadCustomerSites(id)
            actions.fetchCustomerSalesData(id, res => {
                setCustomerSalesData(res)
            })
        } else {
            setEditable(true)
            actions.load(res => {
                setReferrals(res.referrals)
                setCustomerTypes(res.customerTypes)
                setEmployees(res.employees)
                setContactTypes(res.contactTypes)
                setRoles(res.roles)
                setClient(res.client)

                setCustomer({
                    ...customer,
                    quickbooks_sync: res.client?.auto_qb_sync,
                    create_site_from_customer: true,
                    customerSales: null,
                    files: [],
                    name: "New Customer",
                    show_company_name: false,
                    country: "United States"
                })
            })
        }
    }

    const reloadCustomerNotes = (id, filters = null) => {
        if (!isNaN(id)) {
            setReadyCustomerNotes(false)
            actions.customerTimelineData(id, filters ? filters : customerNotesFilters, result => {
                setTimelineData(result.timeline_result)
                setReadyCustomerNotes(true)
                setCurrentUserPersonId(result.current_user_person_id)
                setEmployeeForNotes(result.employees)
            })
        }
    }

    const saveTimelineFilters = () => {
        const newFilters = {
            showEvents: customerNotesFilters.showEvents,
            showCrewMessage: customerNotesFilters.showCrewMessage,
            showNotes: customerNotesFilters.showNotes
        }
        actions.saveUserTimelineFilters({timeline_filters: JSON.stringify(newFilters), id: user.id})
    }

    const reloadBillingContact = (id) => {
        if (!isNaN(id)) {
            actions.loadCustomerInfo(id, res => {
                setCustomer(res)
                setDefaultCustomerSelectOptions([{value: res?.id, label: res?.name}])
                setSelectedCustomerId(res?.id)
                setCustomerBasicData(res)
            })
        }
    }

    const reloadCustomerContacts = (id) => {
        if (!isNaN(id)) {
            setReadyCustomerContacts(false)
            actions.fetchContacts(id, res => {
                setFilteredCustomerContacts(res)
                setCustomerContacts(res)
                setBillingCustomerContact(res.find(r => r.contact_type === "Billing"))
                setReadyCustomerContacts(true)
            })
        }
    }

    const reloadCustomerSites = (id) => {
        if (!isNaN(id)) {
            setReadyCustomerSites(false)
            actions.fetchSites(id, res => {
                setCustomerSites(res)
                setFilteredCustomerSites(res)
                setReadyCustomerSites(true)
            })
        }
    }

    const onCustomerSearch = q => {
        if (q && q.length > 0) {
            debounce(1000, actions.searchForCustomer(q, res => setCustomerSelectOptions(res)))
        }
    }

    const onSelectCustomer = e => {
        if (e) {
            setSelectedCustomerId(e.value)
            browserHistory.push(`/customer/info/${e.value}`);
            reload(e.value)
        }
    }
    const isEmailVaild = (value) => {
        return /^$|^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)
    }
    const isPhoneValid = (value) => {
        return /^(?:\+\d{1,3}\s?)?(?:\d{3}|\(\d{3}\))[-.\s]?\d{3}[-.\s]?\d{4}(?:\s(?:x|ext)\s\d+)?$|^$/.test(value)
    }

    const isCustomerValid = () => {
        const isCompanyNameValid = () => {
            return !!((customer?.show_company_name && customer?.company_name?.trim().length >= 2 && !customer?.company_name?.includes(':') ) || !customer?.show_company_name);
        }
        return customer &&
            isCompanyNameValid() &&
            isPhoneValid(customer?.phone_number || '') &&
            isPhoneValid(customer?.phone_number2 || '') &&
            customer?.first_name?.trim().length > 0 &&
            customer?.last_name?.trim().length > 0 &&
            customer?.phone_number?.trim().length > 0 &&
            customer?.address_1?.trim().length > 0 &&
            customer?.city?.trim().length > 0 &&
            customer?.state?.trim().length > 0 &&
            customer?.zip?.trim().length === 5 &&
            customer?.country?.trim().length > 0 &&
            isEmailVaild(customer?.email_address || '') &&
            isEmailVaild(customer?.email_address2 || '') &&
            !customer?.company_name?.includes(':') &&
            !customer?.address_1?.includes(':') &&
            !customer?.address_2?.includes(':')
    }

    const updateZip = (zip, customer) => {
        if (zip.length === 5) {
            actions.fetchStateAndCityForCustomer(zip, (response) => {
                const zip = response.zipcode;
                const state = response.state ? response.state : customer?.state;
                const city = response.city ? response.city : customer?.city;
                setCustomer({...customer, zip: zip, state: state, city: city});
            })
        }
    }

    const debounceUpdateZip = useCallback(debounce(1000, false, (zip, customer) => updateZip(zip, customer)), [])

    const updateNotes = (newNotes) => {
        customer.notes = newNotes
        if (customer.quickbooks_sync) {
            actions.updateCustomer(customer, 'right')
        }
        setCustomer({...customer})
    }

    const saveCustomer = () => {
        setSubmitted(true)
        setSaveInProgress(true)
        const redirect = (id) => {
            browserHistory.push(`/customer/info/${id}`)
            reload(id)
            setSaveInProgress(false)
        }

        const save = (doUpdate = false, callback = null, errorCallback = null) => {
            if (customer?.id) {
                actions.saveCustomer(doUpdate ? {...customer, updateSettings: true} : customer, response => {
                    reloadBillingContact(response.id)
                    callback && callback()
                    if (customer.quickbooks_sync) {
                        actions.updateCustomer(customer, 'right')
                    }
                    reloadCustomerContacts(customer.id)
                    reloadCustomerSites(customer.id)
                    setEditable(false)
                    setSaveInProgress(false)
                }, () => {
                    errorCallback && errorCallback()
                    setSaveInProgress(false)
                })
            } else {
                actions.saveCustomer(customer, response => {
                    if (customer.quickbooks_sync) {
                        actions.updateCustomer(response, 'right',
                            () => redirect(response.id),
                            () => redirect(response.id)
                        )
                    } else {
                        redirect(response.id)
                    }
                    setEditable(false)
                })
            }
        }

        if (isCustomerValid()) {
            if (customer?.id &&
                (customer.show_company_name && ((customerBasicData.company_name?.trim() || "") !== (customer.company_name?.trim() || "")) ||
                !customer.show_company_name && ((customerBasicData.first_name?.trim() || "") !== (customer.first_name?.trim() || "") || (customerBasicData.last_name?.trim() || "") !== (customer.last_name?.trim() || "")) ||
                customerBasicData.show_company_name !== customer.show_company_name)
            ) {
                setShowConfirmationSaveModal({
                    show: true,
                    onSaveWith: (callback, errorCallback) => save(true, callback, errorCallback),
                    onSave: () => save()
                })
            } else {
                save()
            }
        } else {
            setSaveInProgress(false)
        }
    }

    const deleteCustomer = (customerId) => {
        const action_confirmed = window.confirm('Are your sure? This cannot be reversed!')

        if (action_confirmed) {
            actions.deleteCustomer(customerId, () => {
                browserHistory.push("/customer_search")
            })
        }
    }

    const inputElement = (label, value, require = false, onChangeFunction, invalid) => {
        const element = document.getElementById(value)
        const parentWidth = element && element?.clientWidth
        const childrenWidth = element && element?.children && element?.children[0].offsetWidth

        const elementValue = customer[value] && customer[value].length > 0 ? customer[value] : "-"

        return <div className="input-element-block_editable">
            <div>
                <span className="input-element-label">{label}{require && "*"}</span>
                {((isMobile && elementValue !== "-") && (value==="phone_number" || value==="phone_number2")) &&
                <a className="ml-4" href={"tel:"+elementValue.replace(/\D/g,'')} style={{color: "#209f1e"}}><i className="fa fa-phone" aria-hidden="true"/></a>}
            </div>
            {customer && <div id={value} className="input-element-contents">
                {editable ?
                    <FormControl
                        className={`form-control-xs ${(submitted && invalid) ? 'invalid' : ''}`}
                        value={customer[value]}
                        onChange={e => {
                            setCustomer({...customer, [value]: e.target.value})
                            onChangeFunction && onChangeFunction(e.target.value, customer)
                        }}
                    />
                    :
                    parentWidth < childrenWidth ?
                        <OverlayTrigger placement="top" overlay={<Tooltip id="user_tooltip">{elementValue}</Tooltip>}>
                            <span>{elementValue}</span>
                        </OverlayTrigger>
                        :
                        <span>{elementValue}</span>
                }
            </div>}
        </div>
    }

    const selectElement = (label, options, value, require = false, overflow = false) => {
        const element = document.getElementById(value)
        const parentWidth = element && element?.clientWidth
        const childrenWidth = element && element?.children && element?.children[0].offsetWidth

        const selectedOption = customer[value] && options.find(o => o.value === customer[value])
        const elementValue = selectedOption ? selectedOption?.label : "-"

        return <div className="select-element-block_editable">
            <span className="select-element-label">{label}{require && "*"}</span>
            {customer && <div id={value}
                              className={`select-element-contents ${editable ? "" : "select-element-contents-non-edited"}`}>
                {editable ?
                    <Select
                        menuPortalTarget={overflow ? document.body : ''}
                        menuPosition={overflow ? 'absolute' : ''}
                        styles={{
                            control: () => ({
                                display: 'flex',
                                alignItems: 'center',
                                border: '1.5px solid rgb(205,205,205)',
                                height: '30px',
                                whiteSpace: 'nowrap',
                                fontWeight: '700',
                                borderRadius: '5px',
                                fontSize: '12px',
                                paddingTop: '2px'
                            })
                        }}
                        value={select(options, customer[value])}
                        options={options}
                        onChange={e => setCustomer({...customer, [value]: e.value})}
                        placeholder={label}
                    />
                    :
                    parentWidth < childrenWidth ?
                        <OverlayTrigger placement="top" overlay={<Tooltip id="user_tooltip">{elementValue}</Tooltip>}>
                            <span>{elementValue}</span>
                        </OverlayTrigger>
                        :
                        <span>{elementValue}</span>
                }
            </div>}
        </div>
    }

    const checkboxElement = (label, value, require = false, onChangeFunction, permanentlyDisabled) => {
        return <div className="checkbox-element-block_editable">
            <Checkbox
                className="no-margin"
                name={value}
                onChange={() => {
                    setCustomer({...customer, [value]: !customer[value]});
                    onChangeFunction && onChangeFunction()
                }}
                disabled={!editable || permanentlyDisabled}
                checked={customer[value]}
            >
                <span className="checkbox-label">{label}{require && "*"}</span>
            </Checkbox>
        </div>
    }

    const attachments = () => {
        const uploadAttachment = (attachment) => {
            actions.uploadAttachment(attachment, customer.id, newFile => {
                newFile.order = customer.files.length + newFile.id
                setCustomer({...customer, files: [...customer.files, newFile]});
            })
        };

        const deleteAttachment = (id) => {
            const reloadCustomerAttachments = () => {
                actions.getCustomerAttachments(customer.id, files => {
                    setCustomer({...customer, files: files})
                    setLoadingCustomerAttachments(false)
                }, () => setLoadingCustomerAttachments(false))
            }

            setLoadingCustomerAttachments(true)
            actions.deleteAttachment(id, () => {
                reloadCustomerAttachments()
            }, () => reloadCustomerAttachments())
        }

        const reorderAttachments = (orderedFiles) => {
            const order = JSON.stringify(orderedFiles.map((image) => image.id))
            actions.reorderAttachment(order, customer.id, () => setCustomer({...customer, files: orderedFiles}))
        };

        const selectAttachment = (id) => {
            actions.selectAttachmentForPrint(id, () => {
                const updatedFiles = customer.files.map(file => {
                    if (file.id === id) {
                        file.selected_for_proposal = !file.selected_for_proposal
                    }
                    return file
                })
                setCustomer({...customer, files: updatedFiles})
            })
        }

        return customer.id ?
            <div className="dropzone-element">
                <span className="dropzone-label">Attachments</span>
                <Dropzone
                    onDrop={uploadAttachment}
                    className="dropzone-area"
                >
                    <p className="font12">Drop a file here or click to select a file to
                        upload.</p>
                </Dropzone>
                {loadingCustomerAttachments ?
                    <div className="vhCenter full-height">
                        <MDSpinner size={30} borderSize={2}/>
                    </div>
                    :
                    customer?.files?.length > 0 ? <ReorderingDragAndDrop
                            files={customer.files.sort((a, b) => a.order - b.order)}
                            deleteAttachment={deleteAttachment}
                            reorderAttachments={reorderAttachments}
                            numberOfColumns={boxesPerRowReorderingDNDCustomerInfo}
                            selectAttachment={selectAttachment}
                        />
                        :
                        <div className="empty-files-area">
                            <p className='text-center'>Customer has no photos!</p>
                        </div>

                }
            </div>
            :
            <p className='text-center'>Create user first!</p>
    }

    const customerSearch = (type) => {
        const filterContacts = (list, filterValue) => {
            return list.filter(item => {
                return Object.keys(item).some(key => {
                    return (
                        item[key] &&
                        item[key]
                            .toString()
                            .toLowerCase()
                            .indexOf(filterValue.toLowerCase()) !== -1
                    )
                })
            })
        }

        return <FormGroup bsSize="small" className="no-margin">
            {type === "contacts" ?
                <FormControl
                    id='input-search'
                    onChange={e => {
                        const filteredContacts = filterContacts(customerContacts, e.target.value)
                        setFilteredCustomerContacts(filteredContacts)
                        setCustomerTextSearch({...customerTextSearch, [type]: e.target.value})
                    }}
                    placeholder="Filter by person's name, email, phone, or contact type"
                    value={customerTextSearch ? customerTextSearch[type] : ""}
                />
                :
                <FormControl
                    id='input-search'
                    onChange={e => {
                        const filteredSites = filterContacts(customerSites, e.target.value)
                        setFilteredCustomerSites(filteredSites)
                        setCustomerTextSearch({...customerTextSearch, [type]: e.target.value})
                    }}
                    placeholder="Filter by site's name, address, or contact name"
                    value={customerTextSearch ? customerTextSearch[type] : ""}
                />
            }
        </FormGroup>
    }

    const customerContactsButton = () => {
        return <>
            {customer_view_options.newCustomerView && <Button
                className="send-credentials-button"
                bsStyle="success"
                onClick={() => setShowSendCredentialsModal(true)}
                bsSize="xsmall"
            >
                Send Credentials
            </Button>}
        </>
    }

    const customerNotesFunctions = () => {
        const getDate = (date) => {
            if (date && date.length > 0) {
                let datearray = date.split("/");
                return datearray[1] + '/' + datearray[0] + '/' + datearray[2];
            } else {
                return null;
            }
        }

        const handleRangeDateChange = (start, end) => {
            const from = start && moment(getDate(start)).format('DD/MM/YYYY');
            const to = end && moment(getDate(end)).format('DD/MM/YYYY');
            setCustomerNotesFilters({
                ...customerNotesFilters,
                date_from: from && from,
                date_to: to && to,
                all_records: true
            })
        }

        return <Row className="vertical-align">
            <Col xs={9} className="text-center">
                <DateRangeFilter
                    customClassName="customer-DateRangeFilter"
                    promisedStart={!customerNotesFilters.all_records ? moment(timelineData[0]?.data?.created_at).format('MM/DD/YYYY') : getDate(customerNotesFilters.date_from)}
                    promisedEnd={!customerNotesFilters.all_records ? moment().format('MM/DD/YYYY') : getDate(customerNotesFilters.date_to)}
                    upsertPromise={(id, start, end) => handleRangeDateChange(start, end)}
                    serviceNo={null}
                    index={1}
                    format="M/DD/YY"
                />
            </Col>
            <Col xs={3} className="text-center filter-button">
                <Button
                    className="button"
                    bsSize="xsmall"
                    bsStyle="primary"
                    onClick={() => setShowCustomerNotesFilterModal(true)}
                >
                    Filter
                </Button>
            </Col>
        </Row>
    }

    const onChangeNotesFilter = (value) => {
        setCustomerNotesFilters({...customerNotesFilters, [value]: !customerNotesFilters[value]})
    }

    const saveNote = note => {
        actions.saveCustomerNote(note, customer.id, res => {
            if (res?.customer_note) {
                const index = timelineData.findIndex(el => el.type === 'note' && el.data.id === res.customer_note.id)
                const newNote = {data: res?.customer_note, type: "note"}
                if (index !== -1) {
                    timelineData.splice(index, 1, newNote)
                    setTimelineData(timelineData.concat([]))
                } else {
                    setTimelineData(timelineData.concat(newNote))
                }

                if (res?.customer_legacy_notes) {
                    updateNotes(res?.customer_legacy_notes)
                }
            }
        })
    }

    const deleteCustomerNote = id => {
        actions.deleteCustomerNote(id, res => {
            reloadCustomerNotes(customer.id)

            if (res?.customer_legacy_notes) {
                updateNotes(res?.customer_legacy_notes)
            }
        })
    }

    const lastEditInfo = () => {
        return <div className="last-edit-info">
            <div className="last-edit-info_declined-services last-edit-info_wrapper-row">
                <p className="last-edit-info_label no-padding">Declined Services:</p>
                <p className="small-padding-left">
                    <Dollars amount={customerSalesData?.totalDeclinedServices}/>
                </p>
            </div>
            <div className="last-edit-info_wrapper-row">
                <p className="last-edit-info_label no-padding">Last Edit On:</p>
                <p className="small-padding-left last-edit-info_description">
                    {moment(customer?.modified_at).format("MM/DD/YYYY h:mmA")}
                    <span
                        className="ml-6">by {customer?.last_modified_by ? customer?.last_modified_by : "Contact Form"}</span>
                </p>
            </div>
            <div className="last-edit-info_wrapper-row">
                <p className="last-edit-info_label no-padding">Customer Since:</p>
                <p className="small-padding-left last-edit-info_description">
                    {customer?.created_at ? `${moment(customer?.created_at).format("MM/DD/YYYY h:mmA")}` : "before March 2018"}
                    <span className="ml-6">by {customer?.created_by}</span>
                </p>
            </div>
            <div className="last-edit-info_wrapper-row">
                <p className="last-edit-info_label no-padding">Customer ID:</p>
                <p className="small-padding-left last-edit-info_description">
                    {customer?.id ? customer?.id : "-"}
                </p>
            </div>
            <div className="small-padding-left button-wrapper">
                <Button
                    className="button"
                    bsSize="xsmall"
                    bsStyle="danger"
                    onClick={() => deleteCustomer(customer.id)}
                    disabled={customer?.has_asset}
                >
                    Delete Customer
                </Button>
            </div>
        </div>
    }

    const customerSalesArborist = () => {
        let findEmployee = undefined
        let photo_url = undefined

        if (employeeForNotes.length > 0) {
            findEmployee = employeeForNotes.find(e => e.label === customer.sales_arborist)
        }
        const arboristColor = () => {
            if (findEmployee) {
                const employeeColor = findEmployee.color ? findEmployee.color : ""
                return {background: `${employeeColor}`}
            }
        }

        if (findEmployee) {
            photo_url = findEmployee.images.find(i => i.primary)?.mini
        }

        return <div className="customer-sales-arborist">
            <div className="customer-sales-arborist-info">
                <p>Customer Arborist:</p>
                <div className="d-flex">
                    <div className="customer-sales-arborist-square" style={arboristColor()}/>
                    {customer.sales_arborist ? <h4>{customer.sales_arborist}</h4> : <i>Not assigned</i>}
                </div>
            </div>
            <div className="customer-sales-arborist-photo">
                {photo_url && <img src={photo_url} alt=" "/>}
            </div>
        </div>
    }

    useEffect(() => {
        if (customer.id) {
            reloadCustomerNotes(customer.id)
        }
    }, [customerNotesFilters.date_from, customerNotesFilters.date_to])

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        const handleClickOutside = event => {
            if (selectCustomerRef.current && selectCustomerRef.current?.contains(event.target)) {
                setIsSearchCustomer(true)
            } else {
                setIsSearchCustomer(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [selectCustomerRef]);

    useEffect(() => {
        const pathname = window.location.pathname?.split('/')
        if (pathname) {
            const id = pathname[pathname.length - 1]
            reload(id)
        }
    }, [])

    return customer && <Grid fluid id="new-customer-info">
        <div className="header-wrapper">
            <div className="header-customer">
                <LinkContainer to="/customer_search" active={window.location.href.includes('/customer')}
                               className="user-icon">
                    <Glyphicon glyph="user"/>
                </LinkContainer>
                <span onClick={() => browserHistory.push("/customer_search")} className="pointer">Customers</span>
                <Glyphicon glyph="menu-right" className="arrow-icon"/>
                <div ref={selectCustomerRef} className='select-wrapper'>
                    {isSearchCustomer ?
                        <Select className="select"
                                classNamePrefix="select"
                                options={customerSelectOptions}
                                value={select([...customerSelectOptions, ...defaultCustomerSelectOptions], selectedCustomerId)}
                                autoFocus
                                placeholder="Customer"
                                onInputChange={onCustomerSearch}
                                onChange={onSelectCustomer}
                        />
                        :
                        <b className="customer-name">{customer.name}</b>
                    }
                </div>
            </div>
            <div className="header-navigation">
                <Link activeClassName='active' className='header-link' to={`/customer/info/${customer.id}`}>
                    Details
                </Link>
                <Link activeClassName='active' className='header-link' to={`/lead?customer_id=${customer.id}`}>
                    Find a Time
                </Link>
                {window.location.href.includes('/customer') &&
                    <Link to="" className='header-link' onClick={() => setShowDuplicatesModal(true)}>Show
                        Duplicates</Link>}
                {customer.id !== 0
                    ? <NavDropdown
                        title={<span className="no-margin no-padding">Customer Info</span>}
                        id="customer-menu"
                        onMouseEnter={() => document.getElementById('customer-menu').classList.add('open')}
                        onMouseLeave={() => document.getElementById('customer-menu').classList.remove('open')}
                    >
                        <LinkContainer to={`/customer/info/${customer.id}`}>
                            <MenuItem className="customer-info-menu-item">Customer Info</MenuItem>
                        </LinkContainer>
                        {customer?.customer_sites ?
                            <>
                                <LinkContainer to={`/customer/proposals/${customer.id}`}>
                                    <MenuItem className="customer-info-menu-item">Customer Proposals</MenuItem>
                                </LinkContainer>
                                <LinkContainer to={`/customer/sites/${customer.id}`}>
                                    <MenuItem className="customer-info-menu-item">Customer Sites</MenuItem>
                                </LinkContainer>
                                <LinkContainer to={`/customer/work_orders/${customer.id}`}>
                                    <MenuItem className="customer-info-menu-item">Customer Work
                                        Orders</MenuItem>
                                </LinkContainer>
                                <LinkContainer to={`/customer/invoices/${customer.id}`}>
                                    <MenuItem className="customer-info-menu-item">Customer Invoices</MenuItem>
                                </LinkContainer>
                                <LinkContainer to={`/customer/work_history/${customer.id}`}>
                                    <MenuItem className="customer-info-menu-item">Work History</MenuItem>
                                </LinkContainer>
                            </>
                            :
                            <>
                                <MenuItem className="customer-info-menu-item" disabled={true}>Customer
                                    Proposals</MenuItem>
                                <MenuItem className="customer-info-menu-item" disabled={true}>Customer
                                    Sites</MenuItem>
                                <MenuItem className="customer-info-menu-item" disabled={true}>Customer Work
                                    Orders</MenuItem>
                                <MenuItem className="customer-info-menu-item" disabled={true}>Customer
                                    Invoices</MenuItem>
                                <MenuItem className="customer-info-menu-item" disabled={true}>Work
                                    History</MenuItem>
                            </>
                        }
                        <MenuItem divider/>
                        <LinkContainer to={`/customer/info`}>
                            <MenuItem
                                className="customer-info-menu-item"
                                onClick={() => {
                                    setSelectedCustomerId(null)
                                    setCustomer({
                                        create_site_from_customer: true,
                                        customerSales: null,
                                        files: [],
                                        name: "New Customer",
                                        show_company_name: false,
                                        country: "United States"
                                    })
                                    setDefaultCustomerSelectOptions([])
                                    setRoles([])
                                    setCustomerContacts([])
                                    setFilteredCustomerContacts([])
                                    setCustomerSites([])
                                    setFilteredCustomerSites([])
                                    setBillingCustomerContact({})
                                    setTimelineData([])
                                    setCurrentUserPersonId(null)
                                    setEmployeeForNotes([])
                                    setCustomerSalesData({})
                                    setEditable(true)
                                }}
                            >
                                Create New Customer
                            </MenuItem>
                        </LinkContainer>
                    </NavDropdown>
                    : null}
            </div>
            <div className="header-edit-buttons">
                {editable ?
                    <>
                        <Button
                            className="mr-5"
                            bsStyle="danger"
                            bsSize="xsmall"
                            onClick={() => {
                                setEditable(false)
                                reloadBillingContact(customer.id)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            bsStyle="success"
                            bsSize="xsmall"
                            disabled={saveInProgress}
                            onClick={saveCustomer}
                        >
                            Save
                        </Button>
                    </> :
                    <Button
                        bsSize="xsmall"
                        bsStyle="success"
                        onClick={() => setEditable(true)}
                    >
                        Edit
                    </Button>}
            </div>
        </div>
        <Row className="padding7">
            <Col md={3} className="customer-info-column no-padding-sides">
                <Container title="Billing Contact">
                    <Row className={"content"}>
                        <Col xs={12}>
                            <Row>
                                <Col md={6} xs={6}>
                                    {inputElement("Company Name", "company_name", customer.show_company_name, null, customer.show_company_name && !(customer?.company_name?.trim().length >= 2 && !customer?.company_name?.includes(':')))}
                                </Col>
                                <Col md={6} xs={6}>
                                    {checkboxElement("Inactive", "inactive", false, () => setShowInfoModal(true))}
                                    {checkboxElement("List by company name", "show_company_name")}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} xs={6}>
                                    {inputElement("First Name", "first_name", true, null, !(customer?.first_name?.trim().length > 0))}
                                </Col>
                                <Col md={6} xs={6}>
                                    {inputElement("Last Name", "last_name", true, null, !(customer?.last_name?.trim().length > 0))}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} xs={6}>
                                    {inputElement("Phone 1", "phone_number", true, null, !(customer?.phone_number?.trim().length > 0) || !isPhoneValid(customer.phone_number))}
                                </Col>
                                <Col md={6} xs={6}>
                                    {inputElement("Phone 2", "phone_number2", false, null, customer.phone_number2 && !isPhoneValid(customer.phone_number2))}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} xs={6}>
                                    {inputElement("Email 1", "email_address", false, null, customer.email_address && !isEmailVaild(customer.email_address))}
                                </Col>
                                <Col md={6} xs={6}>
                                    {inputElement("Email 2", "email_address2", false, null, customer.email_address2 && !isEmailVaild(customer.email_address2))}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} xs={6}>
                                    {selectElement("Communication preference", communication_preference_options, "communication_preference", false, true)}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <Container title="Billing Address">
                    <Row className={"content"}>
                        <Col md={6} xs={6}>
                            {inputElement("Address 1", "address_1", true, null, !(customer?.address_1?.trim().length > 0 && !customer?.address_1?.includes(':')))}
                            {inputElement("City", "city", true, null, !(customer?.city?.trim().length > 0))}
                            <Row>
                                <Col md={6} xs={6}>
                                    {inputElement("State", "state", true, null, !(customer?.state?.trim().length > 0))}
                                </Col>
                                <Col md={6} xs={6}>
                                    {inputElement("Zip", "zip", true, debounceUpdateZip, !(customer?.zip?.trim().length === 5))}
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6} xs={6}>
                            {inputElement("Address 2", "address_2", false, null, customer?.address_2?.includes(':'))}
                            {inputElement("County", "county")}
                            {inputElement("Country", "country", true, null, !(customer?.country?.trim().length > 0))}
                        </Col>
                        {!customer.id && <Col xs={12}>
                            {checkboxElement("Create New Site at address", "create_site_from_customer")}
                        </Col>}
                    </Row>
                </Container>
                <Container title="Customer Info" style={{flexGrow: 1}}>
                    <Row className={"content"}>
                        <Col md={5} xs={6}>
                            {selectElement("Customer Type", customerTypes, "customer_type_id")}
                            {selectElement("Referral Type", referrals, "referral")}
                            {selectElement("Assigned Arborist", employees, "person_id")}
                        </Col>
                        <Col md={7} xs={6} className={'customer-info-checkboxes'}>
                            <div>
                                {checkboxElement("Opted Out", "opt_out")}
                            </div>
                            <div>
                                {client?.quickbooks_access_token ?
                                    checkboxElement("Sync with QuickBooks", "quickbooks_sync")
                                    :
                                    <OverlayTrigger placement="top" overlay={<Tooltip>The quickbooks account needs to be synced.</Tooltip>}>
                                        {checkboxElement("Sync with QuickBooks", "quickbooks_sync", false, false, true)}
                                    </OverlayTrigger>
                                }
                            </div>
                            <div className={'quickbooks-show-link'}>
                                <img src={quickbooks}/>
                                {client?.quickbooks_access_token ?
                                    <Link to={`/quickbooks?customer_id=${customer.id}`}>
                                        Show in Quickbooks
                                    </Link>
                                    :
                                    <OverlayTrigger placement="top" overlay={<Tooltip>The quickbooks account needs to be synced.</Tooltip>}>
                                        <Link to={``} className="disabled-link">
                                            Show in Quickbooks
                                        </Link>
                                    </OverlayTrigger>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row className="attachment-wrapper">
                        <Col md={12} className="full-height">
                            {attachments()}
                        </Col>
                    </Row>
                </Container>
            </Col>
            <Col md={3} className="customer-info-column no-padding-sides">
                <Container
                    title={`${readyCustomerContacts ? customerContacts?.length : "-"} Contacts`}
                    style={{height: "48%"}}
                    subtitleFunction={() => customerSearch("contacts")}
                    titleFunction={customerContactsButton}
                    containerType="Contact"
                    disabled={editable}
                    addNewFunctions={() => customer?.id && setShowCustomerContactsModal({show: true, id: null})}
                >
                    <CustomerContactsList
                        customerContacts={filteredCustomerContacts}
                        onSelect={setShowCustomerContactsModal}
                        billingContact={billingCustomerContact}
                        ready={readyCustomerContacts}
                        disabled={editable}
                    />
                </Container>
                <Container
                    title={`${readyCustomerSites ? customerSites?.length : "-"} Sites`}
                    style={{height: "49%"}}
                    subtitleFunction={() => customerSearch("sites")}
                    containerType="Site"
                    disabled={editable}
                    addNewFunctions={() => customer?.id && setShowCustomerSitesModal({show: true, id: null})}
                >
                    <CustomerSitesList
                        customerSites={filteredCustomerSites}
                        onSelect={setShowCustomerSitesModal}
                        employees={employeeForNotes}
                        ready={readyCustomerSites}
                        disabled={editable}
                    />
                </Container>
            </Col>
            <Col md={3} className="customer-info-column no-padding-sides">
                <Container
                    title={"Customer Notes"}
                    style={{height: "98.5%"}}
                    contentStyle={{padding: "5px 0 0", height: "500px"}}
                    subtitleFunction={readyCustomerNotes && customerNotesFunctions}
                >
                    <CustomerTimeline
                        onSuccess={reloadCustomerNotes}
                        customerId={customer.id}
                        saveNote={saveNote}
                        deleteCustomerNote={deleteCustomerNote}
                        timelineData={timelineData}
                        currentUserPersonId={currentUserPersonId}
                        employees={employeeForNotes}
                        ready={readyCustomerNotes}
                    />
                </Container>
            </Col>
            <Col md={3} className="customer-info-column no-padding-sides">
                <Container
                    title={"Sales Data"}
                >
                    {customerSalesArborist()}
                    <CustomerSalesData
                        data={customerSalesData}
                    />
                    {lastEditInfo()}
                </Container>
                <Container
                    title={"Sites Map"}
                    style={{flexGrow: 1}}
                    contentStyle={{padding: 0, height: "200px", borderRadius: '0 0 7px 7px'}}
                >
                    <CustomerSitesMap
                        sites={filteredCustomerSites}
                    />
                </Container>
            </Col>
        </Row>
        {showCustomerContactsModal?.show && <CustomerContactsEditModal
            onSuccess={() => reloadCustomerContacts(customer.id)}
            customerId={customer.id}
            onSave={actions.saveContact}
            editedContact={customerContacts.find(c => c.id === showCustomerContactsModal?.id)}
            contactTypes={contactTypes}
            onHide={() => setShowCustomerContactsModal(undefined)}
            onDelete={actions.deleteContact}
            communication_preference_options={communication_preference_options}
        />}
        {showCustomerSitesModal?.show && <CustomerSitesEditModal
            onSuccess={() => reloadCustomerSites(customer.id)}
            customerId={customer.id}
            onSave={actions.saveSite}
            getMovedProposals={actions.getMovedProposals}
            editedSite={customerSites.find(s => s.id === showCustomerSitesModal?.id)}
            onHide={() => setShowCustomerSitesModal(undefined)}
            onDelete={actions.deleteSite}
            customerContacts={filteredCustomerContacts}
            uploadSiteAttachment={actions.uploadSiteAttachment}
            deleteSiteAttachment={actions.deleteSiteAttachment}
            reorderSieAttachment={actions.reorderSieAttachment}
            selectSiteAttachmentForPrint={actions.selectSiteAttachmentForPrint}
            setCustomerSites={setCustomerSites}
            getSites={actions.fetchSites}
            onCustomerSearch={actions.searchForCustomer}
            onCustomerContactSearch={actions.fetchContacts}
            roles={roles}
            fetchStateAndCityForSite={actions.fetchStateAndCityForCustomer}
            getProposalsForInactive={actions.getProposalsForInactive}
            fetchOrdinances={actions.fetchOrdinances}
            getLeadList={actions.getLeadList}
            deleteLead={actions.deleteLead}
        />}
        {showCustomerNotesFilterModal && <TimelineFilterModal
            onSuccess={reloadCustomerNotes}
            onSaveFilters={saveTimelineFilters}
            customerId={customer.id}
            onHide={() => setShowCustomerNotesFilterModal(undefined)}
            filters={customerNotesFilters}
            onChange={onChangeNotesFilter}
        />}
        {showDuplicatesModal && <DuplicatesModal
            customer={customer}
            findDuplicates={actions.showDuplicates}
            onHide={() => setShowDuplicatesModal(false)}
            redirectFunction={() => browserHistory.push('/customer_search')}
            mergeCustomer={actions.mergeCustomer}
        />}
        {showInfoModal && customer.inactive && <InfoModal
            isOpen={true}
            closeModal={() => setShowInfoModal(false)}
            title={'Warning'}
            getProposals={actions.getProposalsForInactive}
            searchId={{customer_id: customer?.id}}
            text={<p>If you save a Customer with the Inactive option checked, all Open Proposals assigned to that
                customer will be marked as Declined.</p>}
        />}
        {showSendCredentialsModal && <SendCredentialsModal
            sendCredentials={actions.sendCredentials}
            showCredentialsModal={showSendCredentialsModal}
            hideCredentialsModal={() => setShowSendCredentialsModal(false)}
            customerContacts={customerContacts}
            billingContact={billingCustomerContact}
            onSelect={setShowCustomerContactsModal}
        />}
        {showConfirmationSaveModal?.show && <ConfirmationSaveModal
            onHide={() => setShowConfirmationSaveModal({show: false, onSaveWith: null, onSave: null})}
            onSave={showConfirmationSaveModal?.onSave}
            onSaveWith={showConfirmationSaveModal?.onSaveWith}
            type="Customer"
            message="Changed customer data appearing in events. Do you want to update all events to the new customer data?"
        />}
    </Grid>
}

const mapDispatchToProps = dispatch => ({actions: bindActionCreators(actions, dispatch)})

export default connect(undefined, mapDispatchToProps)(NewCustomerInfoPage)