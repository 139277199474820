import React, {Component} from 'react';
import PropTypes from "prop-types"
import 'awesome-bootstrap-checkbox/awesome-bootstrap-checkbox.css'
import 'font-awesome/css/font-awesome.css';
import './colors.css';
import {SketchPicker} from "react-color";
import styled from "styled-components";
import {colors} from "../../common/commonHandlers";


class ColorCheckbox extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            showPicker: false,
            selectedColor: props.color,
            colorChanged: false
        };
        if (props.value !== undefined) {
            this.state.value = this.props.value;
        }
    }

    onClick = (e) => {
        if (!this.props.disabled) {
            let value = !this.state.value;
            this.setState({value: value});
            this.props.onChange && this.props.onChange(value, e);
        }
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.value !== undefined && nextProps.value !== this.state.value) {
            this.setState({value: nextProps.value});
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.showPicker && !this.state.showPicker && this.state.colorChanged){
            this.props.selectColor && this.props.selectColor(this.state.selectedColor)
        }
    }

    render() {
        const {value, showPicker, selectedColor} = this.state;
        const {color, label, disabled, className, selectColor, textColor} = this.props;
        let newColor;
        if(selectColor) {
            newColor = selectedColor && selectedColor.includes('#') ? selectedColor : colors[+selectedColor]
        } else {
            newColor = color && color.includes('#') ? color : colors[+color]
        }

        const Label = styled.label`
          .abc-checkbox input[type="checkbox"]:checked + &::before {
            background: ${newColor} !important;
          }
          
          &::before {
            border: 2px solid ${newColor} !important;
          }
        `;

        return (
            <div
                style={{color: textColor && textColor.includes('#') && textColor}}
                className={`${selectedColor ? `c${selectedColor} color` : ''} form-check abc-checkbox ${className !== undefined ? className : ''}`}>

                <input className="form-check-input" type="checkbox" checked={value} disabled={disabled}
                       onClick={this.onClick}/>
                {selectedColor ?
                    <Label className="form-check-label" onClick={this.onClick}>{label}</Label>
                    :
                    <label className="form-check-label" onClick={this.onClick}>
                        {label}
                    </label>
                }
                <span>
                    {selectColor &&
                    <div
                        style={{background: selectedColor && typeof selectedColor === 'string' && selectedColor.includes('#') ? selectedColor : ''}}
                        className={`${selectedColor && typeof selectedColor === 'string' && selectedColor.includes('#') ? '' : `c${selectedColor}`} color-picker pointer`}
                        onClick={() => this.setState({showPicker: !showPicker})}
                    />}
                    {showPicker &&
                        <div style={{maxWidth: "250px"}}>
                            <SketchPicker
                                width={'80%'}
                                color={this.state.selectedColor}
                                onChangeComplete={e => {
                                    if (e.hex) {
                                        this.setState({colorChanged: true, selectedColor: e.hex})
                                    }
                                }}
                            />
                        </div>
                    }
                </span>
            </div>
        );
    }
}

ColorCheckbox.propTypes = {
    value: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    color: PropTypes.string,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    className: PropTypes.string,
    selectColor: PropTypes.func
};

ColorCheckbox.defaultProps = {
    disabled: false
};

export default ColorCheckbox;