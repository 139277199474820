import React, {Component} from 'react';
import PropTypes from "prop-types"
import  {Panel, Row, Col, Lin} from 'react-bootstrap'
import {LinkContainer} from 'react-router-bootstrap'
import Dollars from '../../components/Dollars'
import moment from "moment"
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default class Leads extends Component{

  render(){
    const {
      leads,
      numProposed,
      dollarsProposed,
      dollarsAccepted,
      confirmedLeads,
      followUpCount,
      initialSendCount,
      followUpToDo,
      nextWeeksLeads,
      confirmedNext,
    } = this.props

    const dataLeads= {
      labels: ["Leads", "Confirmed"],
      datasets: [{
        label: ["Leads", "Confirmed"],
        backgroundColor: [
          "#0079ff",
          "#01b700",
        ],
        borderColor: [
          "#0079ff",
          "#01b700",
        ],
        data: [leads, confirmedLeads],
        // Change options only for labels of THIS DATASET
        datalabels: {
          color: [
            "#a7c5fa",
            "#01ff00"
          ]
        }
      }]
    }
    const dataLeads2= {
      labels: ["Leads", "Confirmed"],
      datasets: [{
        label: ["Leads", "Confirmed"],
        backgroundColor: [
          "#0079ff",
          "#01b700",
        ],
        borderColor: [
          "#0079ff",
          "#01b700",
        ],
        data: [nextWeeksLeads, confirmedNext],
        // Change options only for labels of THIS DATASET
        datalabels: {
          color: [
            "#a7c5fa",
            "#01ff00"
          ]
        }
      }]
    }
    const dataFus= {
      labels: ["Todo", "Sent", "Initial"],
      datasets: [{
        label: "Follow Ups",
        backgroundColor: [
          "#6b8c42",
          "#7bc67b",
          "#ffffb5",
        ],
        borderColor: [
          "#6b8c42",
          "#7bc67b",
          "#ffffb5",
        ],
        data: [followUpToDo, followUpCount, initialSendCount],
        // Change options only for labels of THIS DATASET
        datalabels: {
          color: [
            "#c5e1d1",
            "#a3ffa3",
            "#adad70",
          ]
        }
      }]
    }
    const dataProposals= {
      labels: ["Proposed", "Accepted"],
      datasets: [{
        label: "Proposals",
        backgroundColor: [
          // "#f1c40f",
          "#e74c3c",
          "#34495e"
        ],
        borderColor: [
          // "#f1c40f",
          "#e74c3c",
          "#34495e"
        ],
        data: [parseInt(dollarsProposed), parseInt(dollarsAccepted)],
        // Change options only for labels of THIS DATASET
        datalabels: {
          color: [
            "#ffdbe2",
            "#91c7ff"
          ]
        }
      }],
      options: {
        tooltips: {
          callbacks: {
            label: function(tooltipItems, data) {
              return "$" + tooltipItems.yLabel.toString()
            }
          }
        },
        cutoutPercentage: 80,
        animation: {
          animateScale: true,
        }
      }
    }
    const options = {
      // String - Template string for single tooltips
      tooltipTemplate: "<%if (label){%><%=label %>: <%}%><%= value + ' %' %>",
      // String - Template string for multiple tooltips
      multiTooltipTemplate: "<%= value + ' %' %>",
    };
    return(

    <Panel bsStyle='default' className="text-center">

      <h3 className="text-success">My measures this week <h6>{moment().startOf('week').format('ddd M/D')} - {moment().endOf('week').format('ddd M/D')}</h6></h3>
      <br/>
      <Row>
        <Col xs={12} md={6} lg={3}>
          <Panel className="sales-graph">
              { leads > 0 || confirmedLeads > 0
                ?
                <Doughnut
                ref={(reference) => this.chartReference = reference }
                data={dataLeads}
                plugins={ChartDataLabels}
                options={{
                  title: {
                    display: true,
                    text: "This Weeks Leads"
                  },
                  cutoutPercentage: 60,
                  animation: {
                    animateScale: true,
                  }
                }}
              />
                : <div className="text-center text-warning" style={{minWidth: "274px", minHeight: "137px"}}>No Lead Activity This Week</div>

              }
            <footer>
              <LinkContainer to='/tasks'>
                <small className="text-success text-center">View Leads</small>
              </LinkContainer>
            </footer>
          </Panel>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Panel className="sales-graph">
          { followUpToDo > 0 || followUpCount > 0 || initialSendCount > 0
          ?
            <Doughnut
              ref={(reference) => this.chartReference = reference }
              data={dataFus}
              options={{
                title: {
                  display: true,
                  text: 'Follow Ups'
                },
                cutoutPercentage: 60,
                animation: {
                  animateScale: true,
                }
              }}
            />
             : <div className="text-center text-warning" style={{minWidth: "274px", minHeight: "137px"}}>No Follow Up Activity This Week</div>
            }
            <footer>
              <LinkContainer to='/new_sales_dashboard'>
                <small className="text-success text-center">View Sent</small>
              </LinkContainer>
            </footer>
          </Panel>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Panel className="sales-graph">
            { dollarsProposed > 0 || dollarsAccepted > 0
              ?
                <Doughnut
                  ref={(reference) => this.chartReference = reference }
                  data={dataProposals}
                  options={{
                    title: {
                      display: true,
                      text: 'Proposals'
                    },
                    cutoutPercentage: 40,
                    animation: false
                  }
                  }
                />
            : <div className="text-center text-warning" style={{minWidth: "274px", minHeight: "137px"}}>No Proposal Activity This Week</div>
            }
              <footer>
              <LinkContainer to='/new_sales_dashboard'>
                <small className="text-success text-center">View Proposals</small>
              </LinkContainer>
            </footer>
          </Panel>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Panel className="sales-graph">
            { nextWeeksLeads > 0 || confirmedNext > 0
              ?
              <Doughnut
              ref={(reference) => this.chartReference = reference }
              data={dataLeads2}
              plugins={ChartDataLabels}
              options={{
                title: {
                  display: true,
                  text: "Next Weeks Leads"
                },
                cutoutPercentage: 60,
                animation: {
                  animateScale: true,
                }
              }}
            />
              : <div className="text-center text-warning" style={{minWidth: "274px", minHeight: "137px"}}>No Lead Activity Next Week</div>
            }
            <footer>
              <LinkContainer to='/tasks'>
                <small className="text-success text-center">View Leads</small>
              </LinkContainer>
            </footer>
          </Panel>
        </Col>
      </Row>
      {/*<Row>*/}
        {/*<Col xs={12} md={7} lg={5}>*/}
          {/*<Row>*/}
            {/*<Col xs={6} sm={3} md={3}>*/}
              {/*<LinkContainer to='/tasks'>*/}
                {/*<Panel className="sales-widget">*/}
                  {/*<div className="header">*/}
                    {/*<h6 className="sales-widget">Leads</h6>*/}
                  {/*</div>*/}
                  {/*<small className="sales-widget">*/}
                    {/*This Week: {leads || 0}*/}
                  {/*</small>*/}
                  {/*<br/>*/}
                  {/*<small className="sales-widget">*/}
                    {/*Next Week: {nextWeeksLeads || 0}*/}
                  {/*</small>*/}
                {/*</Panel>*/}
              {/*</LinkContainer>*/}
            {/*</Col>*/}
            {/*<Col xs={6} sm={3} md={3}>*/}
              {/*<LinkContainer to='/tasks'>*/}
                {/*<Panel className="sales-widget">*/}
                  {/*<div className="header">*/}
                    {/*<h6 className="sales-widget">Confirmed Leads</h6>*/}
                  {/*</div>*/}
                  {/*<h5 className="sales-widget">*/}
                    {/*{confirmedLeads || 0}*/}
                  {/*</h5>*/}
                {/*</Panel>*/}
              {/*</LinkContainer>*/}
            {/*</Col>*/}
            {/*<Col xs={6} sm={3} md={3}>*/}
              {/*<LinkContainer to='/new_sales_dashboard'>*/}
                {/*<Panel className="sales-widget">*/}
                  {/*<div className="header">*/}
                    {/*<h6 className="sales-widget">Sent Proposals</h6>*/}
                  {/*</div>*/}
                  {/*<h5 className="sales-widget">*/}
                    {/*{initialSendCount || 0}*/}
                  {/*</h5>*/}
                {/*</Panel>*/}
              {/*</LinkContainer>*/}
            {/*</Col>*/}
            {/*<Col xs={6} sm={3} md={3}>*/}
              {/*<LinkContainer to='/new_sales_dashboard'>*/}
                {/*<Panel className="sales-widget">*/}
                  {/*<div className="header">*/}
                    {/*<h6 className="sales-widget">Follow Ups</h6>*/}
                  {/*</div>*/}
                  {/*<small className="sales-widget">*/}
                    {/*Sent: {followUpCount || 0}*/}
                  {/*</small>*/}
                  {/*<br/>*/}
                  {/*<small className="sales-widget">*/}
                    {/*{`ToDo: ${followUpToDo || 0}`}*/}
                  {/*</small>*/}
                {/*</Panel>*/}
              {/*</LinkContainer>*/}
            {/*</Col>*/}
          {/*</Row>*/}
        {/*</Col>*/}
        {/*<Col xs={12} md={5} lg={4}>*/}
          {/*<Row>*/}
            {/*<Col xs={6} sm={3} md={4}>*/}
              {/*<LinkContainer to='/new_sales_dashboard'>*/}
                {/*<Panel className="sales-widget">*/}
                  {/*<div className="header">*/}
                    {/*<h6 className="sales-widget"># Proposed</h6>*/}
                  {/*</div>*/}
                  {/*<h5 className="sales-widget">*/}
                    {/*{numProposed || 0}*/}
                  {/*</h5>*/}
                {/*</Panel>*/}
              {/*</LinkContainer>*/}
            {/*</Col>*/}
            {/*<Col xs={6} sm={3} md={4}>*/}
              {/*<LinkContainer to='/new_sales_dashboard'>*/}
                {/*<Panel className="sales-widget">*/}
                  {/*<div className="header">*/}
                    {/*<h6 className="sales-widget">$ Proposed</h6>*/}
                  {/*</div>*/}
                  {/*<h5 className="sales-widget">*/}
                    {/*<Dollars amount={dollarsProposed}/>*/}
                  {/*</h5>*/}
                {/*</Panel>*/}
              {/*</LinkContainer>*/}
            {/*</Col>*/}
            {/*<Col xs={6} sm={3} md={4}>*/}
              {/*<LinkContainer to='/new_sales_dashboard'>*/}
                {/*<Panel className="sales-widget">*/}
                  {/*<div className="header">*/}
                    {/*<h6 className="sales-widget">$ Accepted</h6>*/}
                  {/*</div>*/}
                  {/*<h5 className="sales-widget">*/}
                    {/*<Dollars amount={dollarsAccepted}/>*/}
                  {/*</h5>*/}
                {/*</Panel>*/}
              {/*</LinkContainer>*/}
            {/*</Col>*/}
          {/*</Row>*/}
        {/*</Col>*/}
      {/*</Row>*/}
    </Panel>


    )
  }
}


Leads.defaultProps = {
  leads: 0,
  numProposed: 0,
  dollarsProposed: 0,
  dollarsAccepted: 0,
  followUpCount: 0,
  followUpToDo: 0,
  nextWeeksLeads: 0,

}
