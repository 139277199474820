import {apiCall} from "../../../common/apiActionHandler";
import axios from "axios";
import {mapForSelect} from "../../../common/commonHandlers";

export const getTextMessageTemplates = (callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/text_message_templates`,
    };

    apiCall("Getting text message templates", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};
