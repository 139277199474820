import React, {memo} from 'react';
import ColorCheckbox from "../../Scheduler/ColorCheckbox";
import {Glyphicon} from "react-bootstrap";
import * as _ from "lodash";

function areEquals(prev, next) {
    return prev?.selected === next?.selected &&
        prev?.checked === next?.checked &&
        prev?.searchString === next?.searchString &&
        _.isEqual(prev.listAssets.map(a => a.id).sort(), next.listAssets.map(a => a.id).sort())
}

const MemoizedAssetSelectRow = ({everyOther, style, asset, site, assetClick, assetLabel, checkPolygonsCoords, setState, listAssets}) => {
    return <div onClick={assetClick(asset)} style={{...style, paddingRight: 5, backgroundColor: everyOther ? "#f1f1f1" : ""}} className={`assets-list-row ${asset.selected ? 'highlight' : '' }`}>
        <div
            style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <ColorCheckbox value={asset.checked} label={assetLabel(asset)}
                           onChange={(e) => {
                               asset.checked = e;
                               setState({site});
                           }}
                           color={asset.plant_color}
                           className="d-flex"
            />
            <span className="pull-right list-right-icons">
                                                           {((!asset.latitude && asset.latitude !== 0) || (!asset.longitude && asset.longitude !== 0)) &&
                                                               <span className='padding10'>
                                                                   <Glyphicon glyph="exclamation-sign"/>
                                                               </span>}
                {(checkPolygonsCoords(asset) && ((asset.latitude && asset.latitude !== 0) && (asset.longitude && asset.longitude !== 0))) &&
                    <span className='padding10'>
                                                                   <Glyphicon glyph="exclamation-sign"/>
                                                               </span>}
                {(asset.images.length > 0) ?
                    <span className='text-success small-right-padding'>
                                                                    <Glyphicon glyph='camera' className="small-margin"/></span> : ""}
                <Glyphicon glyph='modal-window' className="pointer"
                           onClick={() => setState({asset: asset, openedInMap: false})}/>
                                                        </span>
        </div>
    </div>
}

export default memo(MemoizedAssetSelectRow, areEquals);