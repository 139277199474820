import React, {Component} from 'react';
import PropTypes from "prop-types"
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {addAlert} from '../App/actions'
import {showEmailDialog} from "../../components/email/actions"
import * as MyActions from '../../actions/workOrders'
import {Button, Col, Form, FormControl, FormGroup, ControlLabel, Grid, Panel, Row, Well,} from 'react-bootstrap'
import ProposalServicesList from './ProposalServicesList'
import {load} from './client'
import {getPastDueInvoices} from "../InvoicesPage/InvoicesApi";
import {browserHistory} from "react-router";
import ProposalMenu from "../MapViewPage/new/ProposalMenu";
import {formatInlineDateOnly, select} from "../../common/commonHandlers";
import Dollars from "../../components/Dollars";
import Select from "react-select";
import PastDueInvoicesModal from "../InvoicesPage/PastDueModal/PastDueInvoicesModal";

const Actions = {...MyActions, addAlert, showEmailDialog, load, getPastDueInvoices}


class WorkOrdersPage extends Component {

    static propTypes = {
        proposalNoSearchText: PropTypes.string.isRequired,
        proposalInfo: PropTypes.object.isRequired,
        proposalServicesInfo: PropTypes.array.isRequired,
        printWo: PropTypes.string.isRequired,
        addToWo: PropTypes.string.isRequired,
        selectedPs: PropTypes.array.isRequired,
    }
    onSearch = e => {
        e.preventDefault()
        this.props.actions.searchByProposalNo()
    }
    onWorkOrderSearch = e => {
        e.preventDefault()
        this.props.actions.searchByWorkOrderNo()
    }
    handleSelectChange = e => {
        if (e.target.name === 'printWo') {
            this.props.actions.printWo(e.target.value)
        } else {
            this.props.actions.addToWo(e.target.value)
        }
    }
    doPrintWo = e => {
        e.preventDefault()
        this.props.actions.doPrintWo()
    }
    doAddToWo = e => {
        e.preventDefault()
        const {customerId} = this.props.proposalInfo
        if (customerId) {
            this.props.actions.getPastDueInvoices({customer_id: customerId}, (response) => {
                const {past_due, past_due_invoices} = response
                if (past_due) {
                    this.setState({pastDueInvoicesModal: {show: true, past_due_invoices}})
                } else {
                    this.props.actions.doAddToWo(() => this.setState({remountKey: this.state.remountKey + 1}))
                }
            })
        }
    }
    pastDueInvoicesModalOnSubmit = () => {
        this.setState({pastDueInvoicesModal: {show: false}})
        this.props.actions.doAddToWo(() => this.setState({remountKey: this.state.remountKey + 1}))
    }

    handleSelectAllChecked = e => {
        this.props.eligibleProposalServicesInfo.forEach(eps =>
            this.props.actions.selectPs(e, eps.id)
        )
    }
    totalAccepted = proposalServicesInfo =>
        proposalServicesInfo.reduce(
            (memo, ps) =>
                memo + (ps.proposalServiceStatus !== "Proposed" && ps.proposalServiceStatus !== "Not Offered" && ps.proposalServiceStatus !== "Declined" ? ps.serviceCost : 0),
            0
        )
    totalProposed = proposalServicesInfo =>
        proposalServicesInfo.reduce(
            (memo, ps) =>
                memo + ps.serviceCost,
            0
        )

    componentWillMount() {
        this.state = {
            remountKey: 0
        };
    }

    showEmailDialog = () => {
        const {email, proposalInfo} = this.props;
        this.props.actions.showEmailDialog(
            {
                emailType: "proposal",
                referenceId: proposalInfo.id,
                recipient: proposalInfo.contactEmail,
                defaultEmail: email
            });
    };

    componentDidMount() {
        if (this.props.params && this.props.params.proposalId) {
            this.props.actions.loadProposalAndPServicesInfo(this.props.params.proposalId)
        } else {
            //TODO blank out the previous WO in the redux store ?
        }
        this.props.actions.load(result => {
            this.setState(result);
        });
    }

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.params &&
            nextProps.params.proposalId &&
            nextProps.params.proposalId !== this.props.params.proposalId
        ) {
            console.info(
                'nextprops params was different so reloading page this and next',
                this.props.params.proposalId,
                nextProps.params.proposalId
            )
            this.props.actions.loadProposalAndPServicesInfo(nextProps.params.proposalId)
            this.props.actions.printWo("-")
            this.props.actions.addToWo("-")
        }
    }

    handleProposalIdChange = (id) => {
        browserHistory.push(`/work_orders/${id}`)
    };

    onEmailSend = (id) => {
        this.props.actions.loadProposalAndPServicesInfo(id)
    }

    render() {
        const {
            proposalNoSearchText,
            proposalInfo,
            proposalServicesInfo,
            selectedPs,
            eligibleProposalServicesInfo,
            workOrderSearchText,
        } = this.props
        const {reasons, pastDueInvoicesModal, remountKey} = this.state

        return (
            <Grid fluid>
                {pastDueInvoicesModal?.show &&
                <PastDueInvoicesModal
                    show={pastDueInvoicesModal.show}
                    onHide={() => this.setState({pastDueInvoicesModal: {show: false}})}
                    onSubmit={this.pastDueInvoicesModalOnSubmit}
                    pastDueInvoices={pastDueInvoicesModal.past_due_invoices}
                />}
                <ProposalMenu
                    id={this.props && this.props.routeParams && this.props.routeParams.proposalId}
                    expandButton={false}
                    onReload={this.handleProposalIdChange}
                    onEmailSend={this.onEmailSend}
                    key={remountKey}
                />
                <Row>
                    <Col xs={12} md={4}>
                        <h5><strong>
                            Proposed On:
                            {" "}
                            {this.props && this.props.proposalInfo && formatInlineDateOnly(this.props.proposalInfo.proposalDate)}
                        </strong></h5>
                    </Col>
                    <Col xs={12} md={4}>
                        <h5><strong>
                            Proposed:
                            {" "}
                            <Dollars
                                amount={this.totalProposed(this.props.proposalServicesInfo)}
                            />
                        </strong></h5>
                    </Col>
                    <Col xs={12} md={4}>
                        <h5 className="text-success"><strong>
                            Accepted:
                            {" "}
                            <Dollars
                                amount={this.totalAccepted(this.props.proposalServicesInfo)}
                            />
                        </strong></h5>
                    </Col>
                </Row>
                <Row className={`top15`}>
                    <Col md={3}>
                        <ControlLabel><strong className="small-margin">With checked Proposal
                            Services...</strong></ControlLabel>
                        <FormGroup bsSize="small">
                            <FormControl
                                componentClass="select"
                                value={this.props.addToWo}
                                onChange={this.handleSelectChange}
                                name="addToWo"
                                placeholder="select"
                                className={`full-width`}
                            >
                                <option value="-">Create a New WO</option>
                                {this.props.existingWo
                                    .slice()
                                    .sort((a, b) => a - b)
                                    .map(wo => (
                                        <option key={wo} value={wo}>Add to WO# {wo}</option>
                                    ))}
                            </FormControl>
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <ControlLabel><strong className="small-margin">Find Work Orders</strong></ControlLabel>
                        <Form className={`remaining vertical-align`} inline onSubmit={e => this.onWorkOrderSearch(e)}>
                            <FormGroup bsSize="small">
                                <FormControl
                                    value={workOrderSearchText || ''}
                                    placeholder="WO Number"
                                    className={`full-width`}
                                    onChange={e =>
                                        this.props.actions.updateWorkOrderSearchText(
                                            e.target.value
                                        )}
                                />
                            </FormGroup>{' '}
                            <Button type="submit" bsSize="small" bsStyle="info" className={`self-start`}>
                                Find Work Order No.
                            </Button>
                        </Form>
                    </Col>
                    <Col md={3}>
                        <ControlLabel><strong className="small-margin">Work Orders to Print</strong></ControlLabel>
                        <Form onSubmit={this.doPrintWo} inline className={`remaining vertical-align`}>
                            <FormGroup bsSize="small">
                                <FormControl
                                    bsSize="small"
                                    componentClass="select"
                                    value={this.props.printWo}
                                    onChange={this.handleSelectChange}
                                    name="printWo"
                                    placeholder="select"
                                    className={`full-width`}
                                >
                                    <option value="-">Choose</option>
                                    <option value="phc">All PHC</option>
                                    <option value="treeCare">All Tree Care</option>
                                    <option value="--">-----------</option>
                                    {this.props.existingWo
                                        .slice()
                                        .sort((a, b) => a - b)
                                        .map(wo => <option key={wo} value={wo}>WO# {wo}</option>)}
                                </FormControl>
                            </FormGroup>
                            {' '}
                            <Button bsStyle="info" bsSize="small" type="submit" className={`self-start`}>
                                Print Preview
                            </Button>
                        </Form>
                    </Col>
                    <Col md={3}>
                        <ControlLabel><strong className="small-margin">Filter reason</strong></ControlLabel>
                        <FormGroup bsSize="small">
                            <FormControl
                                bsSize="small"
                                componentClass="select"
                                value={this.props.printWo}
                                onChange={this.handleSelectChange}
                                name="printWo"
                                placeholder="select"
                                className={`full-width`}
                            >
                                {reasons && reasons.map(reason =>
                                    (<option value={reason.id}>{reason.name}</option>)
                                )}
                            </FormControl>
                        </FormGroup>
                    </Col>
                    <Col md={3} className={`remaining vertical-align`}>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="top27">
                        <Button
                            disabled={this.props.selectedPs.length < 1}
                            bsSize="small"
                            bsStyle="success"
                            type="submit"
                            onClick={this.doAddToWo}
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
                <p>&nbsp;</p>

                <Row>
                    <Col sm={12}>
                        <Well bsSize="small">
                            <Panel>

                                <Row>
                                    <Col md={1}>
                                        <label>
                                            <input
                                                type="checkbox"
                                                disabled={eligibleProposalServicesInfo.length === 0}
                                                checked={
                                                    selectedPs.length ===
                                                    eligibleProposalServicesInfo.length &&
                                                    eligibleProposalServicesInfo.length > 0
                                                }
                                                onChange={this.handleSelectAllChecked}
                                            />
                                            {' '}
                                            #
                                        </label>
                                    </Col>
                                    <Col md={3}>
                                        Promised Date
                                    </Col>
                                    <Col md={2}>
                                        Service
                                    </Col>
                                    <Col md={1}>
                                        Priority
                                    </Col>
                                    <Col md={1}>
                                        Status
                                    </Col>
                                    <Col md={1}>
                                        Est Hrs
                                    </Col>
                                    <Col md={1}>
                                        Price
                                    </Col>
                                    <Col md={2}>
                                        WO #
                                    </Col>

                                </Row>
                            </Panel>

                            <Row>
                                <Col md={12}>
                                    {proposalServicesInfo &&
                                    <ProposalServicesList
                                        proposalServicesInfo={proposalServicesInfo}
                                        selectedPs={selectedPs}
                                        selectPs={this.props.actions.selectPs}
                                        proposalInfo={this.props.proposalInfo}
                                        removePsFromWo={this.props.actions.removePsFromWo}
                                        updateWoNote={this.props.actions.updateWoNote}
                                        updateAttr={this.props.actions.updateAttr}
                                        upsertPromise={this.props.actions.upsertPromise}
                                    />}
                                </Col>
                            </Row>
                        </Well>
                    </Col>
                </Row>
            </Grid>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    username: state.auth.email,
    email: state.auth.email,
    proposalNoSearchText: state.workOrders.proposalNoSearchText,
    proposalInfo: state.workOrders.proposalInfo,
    proposalServicesInfo: state.workOrders.proposalServicesInfo,
    printWo: state.workOrders.printWo,
    addToWo: state.workOrders.addToWo,
    selectedPs: state.workOrders.selectedPs,
    workOrderSearchText: state.workOrders.workOrderSearchText,

    existingWo: [
        ...new Set(
            state.workOrders.proposalServicesInfo
                .map(ps => ps.workOrderNo)
                .filter(no => no !== null)
        ),
    ],
    eligibleProposalServicesInfo: state.workOrders.proposalServicesInfo.filter(
        ps => ps.proposalServiceStatus === 'Accepted'
    ),
    upsertPromise: ownProps.upsertPromise,
})
//NOTE: Per business rules we have to delete the WO if theres no PS for it so its ok to calculate the existing WO's from looking at the PS list

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrdersPage)
