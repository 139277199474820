import React, {Component} from "react"
import {Button, Col, Grid, MenuItem, Nav, NavDropdown, Row,} from "react-bootstrap"
import {Link} from "react-router"
import {LinkContainer} from "react-router-bootstrap"
import * as Actions from "./actions"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"

import Select from "react-select"
import moment from "moment"
import {Circle, GoogleMap, Marker, Polygon, Polyline, Rectangle} from "react-google-maps"
import {GoogleMapLoader} from "../../../components/GoogleMapLoader";
import {BootstrapTable, SizePerPageDropDown, TableHeaderColumn} from "react-bootstrap-table"
import AssetEditorModal from "../../AssetEditorModal"

import CustomerHeader from "../../../components/CustomerHeader"
import {
    defaultDateFormat,
    defaultDateFormatShort, defaultDateTimeFormat,
    defaultMapParams, fitBounds,checkRectangleCoordinates, colors,
    mapForSelect,
    select
} from "../../../common/commonHandlers";
import {getIcon, getLabel} from "../../MapViewPage/IconProvider";
import {getBasicAssetMarker, getBasicMarker} from "../../../utilities";
import "./CustomerWorkOrders.scss"
import ResourceComponent from "../../../components/ResourceComponent";
import {dateFormatter, dollarsFormatter} from "../../../components/BootStrapTableCellFormatters";
import ExcelJS from "exceljs";
import {saveAs} from "file-saver";
import _ from 'lodash'

class customerWorkOrders extends ResourceComponent {
    state = {
        mapParams: null,
        isMapLoaded: false
    }

    componentDidMount = () => {
        this.props.actions.fetchCustomerById(this.props.params.id)
    }

    componentWillReceiveProps = nextProps => {
        if (
            this.props.params.id &&
            nextProps.params.id &&
            this.props.params.id !== nextProps.params.id
        ) {
            this.props.actions.fetchCustomerById(nextProps.params.id)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.sitesAssets?.map(a => a.id)?.sort(), this.props.sitesAssets?.map(a => a.id)?.sort())) {
            this.reloadMapParams()
        }
        if (!_.isEqual(prevProps.sitesAssets, this.props.sitesAssets) && this.props.sitesAssets?.length === 0) {
            this.setState({isMapLoaded: false})
        }
    }

    reloadMapParams = (callback) => {
        const {
            selectedSiteInfo,
            customerSites,
            sitesAssets,
            client
        } = this.props;

        let coordinates = [];
        sitesAssets && sitesAssets.forEach(asset => {
            if (asset.polygons && asset.polygons.length > 0) {
                const polygon = asset.polygons.find(p => p.chosen)
                for (let p of polygon.polygon_points) {
                    coordinates.push({latitude: p.lat, longitude: p.lng});
                }
            } else if (asset.polygons && asset.polygons.length === 0 && asset.latitude && asset.longitude) {
                asset.lat = asset.latitude
                asset.lng = asset.longitude
                coordinates.push({latitude: asset.lat, longitude: asset.lng});
            }
        })
        const {center} = this.state;
        const mapParams = defaultMapParams(sitesAssets, center, null, client);

        if (customerSites?.length > 0 && coordinates.length === 0) {
            mapParams.center = {
                lat: selectedSiteInfo?.latitude || customerSites[0].latitude,
                lng: selectedSiteInfo?.longitude || customerSites[0].longitude
            };
            mapParams.zoom = 18;
        } else {
            fitBounds(this.map, coordinates);
        }
        this.setState({mapParams}, callback);

    };

    render() {
        const {
            customer,
            customerWorkOrders,
            customerSites,
            selectedSite,
            foundCustomers,
            sitesAssets,
            editingAssetId
        } = this.props

        const customerId = this.props.params.id ? this.props.params.id : null

        if (customerId === null) {
            return (<span>I need a customerid parameter. I can't go on</span>)
        }

        if (customer === null) {
            return (<span>Loading...</span>)
        }

        return (
            <Grid id="customer-work-orders" fluid>
                <Row>
                    <Col md={12}>
                        <CustomerHeader
                            customerId={parseInt(customerId, 10)}
                            customerList={foundCustomers.map(customer => ({
                                value: customer.id,
                                label: customer.name,
                            }))}
                            onUpdateCustomerSearch={this.props.actions.updateCustomerSearch}
                            onSelectCustomer={this.props.actions.updateSelectedCustomer}
                            title="Customer Work Orders"
                            salesArborist={customer.sales_arborist}
                            haveSites={customerSites?.length > 0}
                        />

                        {this._renderBillingAddress(customer)}
                        <br/>
                        {customerSites && customer &&
                        this._renderFilterBar(
                            customerSites,
                            selectedSite,
                            customer,
                        )}
                        <br/>
                        {this._renderProposalMapUi(sitesAssets, customerWorkOrders)}
                    </Col>
                </Row>

                {editingAssetId !== null
                    ? <AssetEditorModal
                        editingAssetId={editingAssetId}
                        doneEditingAsset={this._cancelModal}
                        updateAssetAttribute={this.props.actions.updateAssetAttribute}
                        onSuccess={this._onSuccess}
                    />
                    : null}

            </Grid>
        )
    }

    _onSuccess = () => {
        this.props.actions.fetchCustomerAssets()
    }

    _cancelModal = siteId => {
        console.log("cancelling modal with site ", siteId)
        //this.props.actions.dismissAssetEditorModal()
        this.props.actions.doneEditingAsset(siteId) //dismiss the modal, reload mapview assets for site which the asset editor modal depends on to show correct data
        this.props.actions.fetchCustomerAssets() //refresh them in case
    }

    _renderBillingAddress = customer => (
        <div>
            <strong>Billing Address:</strong>
            {" "}
            {customer.address_1},
            {customer.address_2}{" "}
            {customer.city}, {customer.state}{" "}
            {customer.zip}
        </div>
    )

    _applyHistoryFilter = () => {
        this.props.actions.applyHistoryFilter()
    }

    woFormatterXLSX = (wo_number, proposal_id) => {
        const primaryUrl = new URL(window.location.href).origin

        return {formula: `=HYPERLINK("${primaryUrl}/work_orders/${proposal_id}","${wo_number}")`};
    }

    dateFormatterXLSX = (date) => {
        return moment(date).format(defaultDateTimeFormat);
    }

    siteFormatterXLSX = (site_name, site_full_address) => {
        return {
            'richText': [
                {'font': {'bold': true, 'size': 8, 'name': 'Calibri'}, 'text': `${site_name ?? ""}\n`},
                {'font': {'size': 8, 'name': 'Calibri'}, 'text': `${site_full_address ?? ""}`}
            ]
        }
    };

    autoColumnWidth = (column) => {
        const lengths = column.values.map(v => v.toString().length);
        lengths[1] = 1.375515818 * lengths[1] //because header is in font size 11
        const maxLength = Math.max(...lengths.filter(v => typeof v === 'number')) * 0.727; //by default it works for font 11, we use font 8 so we need to convert result
        return maxLength;
    }

    exportToXLSX = async (customerWorkOrders) => {
        const data = _.cloneDeep(customerWorkOrders)
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("WorkOrders")

        worksheet.addRow([
            "Work Order No",
            "Service Count",
            "Site Name",
            "Arborist",
            "Proposed Date",
            "Accepted Date",
            "Created Date",
            "Scheduled Date",
            "Cost"
        ])

        data.forEach((item) => {
            worksheet.addRow([
                this.woFormatterXLSX(item.workOrderNo, item.proposalId),
                item.serviceCount,
                this.siteFormatterXLSX(item.siteName, item.siteAddress),
                item.arborist,
                dateFormatter(item.proposedDateCorrect),
                dateFormatter(item.acceptedDate),
                dateFormatter(item.createdDate),
                dateFormatter(item.scheduledEvent),
                parseFloat(item.woTotal)
            ])
        })

        worksheet.eachRow({includeEmpty: true}, (row, rowNumber) => {
            if (rowNumber > 1) {
                row.eachCell((cell, colNumber) => {
                    if ([1, 2, 4, 5, 6, 7, 8, 9].includes(colNumber)) {
                        cell.style = {font: {name: 'Calibri', size: 8}}
                    }
                })
                row.getCell(9).numFmt = '[$$-409]#,##0.00;-[$$-409]#,##0.00'
                row.height = 30
            }
        })

        worksheet.views = [
            {state: 'frozen', xSplit: 0, ySplit: 1, activeCell: 'A1'}
        ];

        worksheet.columns.forEach((column) => {
            let autoWidthColumns = [1, 2, 4, 5, 6, 7, 8]
            let colNumber = column._number
            if (autoWidthColumns.includes(colNumber)) {
                column.width = this.autoColumnWidth(column) + 0.62;
            } else if (colNumber === 3) {
                column.width = 34 + 0.62
            } else if (colNumber === 9) {
                column.width = 10 + 0.62
            }
        })

        const excelBuffer = await workbook.xlsx.writeBuffer();

        const csvFileName = `${this.props.customer.name}-WorkOrders-${moment().format(defaultDateFormat)}.csv`
        if (this.tableRef?.current) {
            this.props.actions.saveNewCsvDownload(this.tableRef.current, csvFileName, this.state.resource, "customerWorkOrders")
        }

        const dataBlob = new Blob([excelBuffer], {type: 'application/octet-stream'});
        saveAs(dataBlob, `${this.props.customer.name}-WorkOrders-${moment().format(defaultDateFormat)}.xlsx`);
    }

    _renderFilterBar = (
        customerSites,
        selectedSite,
        customer,
    ) => {
        const customerSitesOptions = mapForSelect(customerSites);
        return (
            <Row>

                <Col md={4}>
                    <Select className="Select" classNamePrefix="select"
                            name="select_site"
                            value={select(customerSitesOptions, selectedSite)}
                            options={customerSitesOptions}
                            onChange={this._updateSelectedSite}
                            placeholder="All Sites"
                    />
                </Col>


                <Col md={2}>
                    <Button bsStyle="success" disabled={customerSites.length === 0} onClick={this._applyHistoryFilter}>
                        Filter
                    </Button>

                </Col>

                <Col md={2}>
                    <Button bsStyle="success" disabled={!this.props.customerWorkOrders || this.props.customerWorkOrders?.length < 1}
                            onClick={() => this.exportToXLSX(this.props.customerWorkOrders)}>
                        Export to XLSX
                    </Button>
                </Col>

                <Col md={4} className="text-right">
                    {customer && selectedSite ?
                        <LinkContainer to={`/mapview?customer_id=${customer.id}&site_id=${selectedSite}`}>
                            <Button bsStyle="primary">New Proposal</Button>
                        </LinkContainer>
                        :
                        null
                    }
                </Col>
            </Row>
        )
    }

    _updateSelectedSite = selected => {
        this.props.actions.updateSelectedSite(
            selected === null ? null : selected.value
        )
    }

    _renderProposalMapUi = (assets, customerWorkOrders) => {
        return (
            <Row>
                <Col md={7}>
                    {this._renderServicesTable(customerWorkOrders)}
                </Col>

                <Col md={5}>
                    {this._renderAssetMap(assets, this.props.client)}
                </Col>
            </Row>
        )
    }

    dataFormat = (field, row) => {
        const start = row.promised_date_start
        const end = row.promised_date_end

        if (!end || !start) return field

        if ((row.status === 'In Work Order') || (row.status === 'Accepted') || (row.status === 'Scheduled')) {
            return (
                <div>
                    <div>{field}</div>
                    <div className="text-warning">
                        {moment(start).format("MM/DD/YYYY")} - {moment(end).format("MM/DD/YYYY")}
                    </div>
                </div>
            )
        } else {
            return field
        }
    }

    _renderServicesTable = data => (
        <BootstrapTable
            data={data}
            striped={true}
            bordered={false}
            hover={true}
            pagination={true}
            options={
                {
                    paginationPanel: this.renderPaginationPanel,
                    sizePerPageDropDown: () => {
                        return (
                            <SizePerPageDropDown
                                variation='dropup'
                            />
                        )
                    }
                }
            }
        >
            <TableHeaderColumn hidden dataField="id" isKey={true} dataSort={true}>
                ID
            </TableHeaderColumn>

            <TableHeaderColumn
                width="10%"
                dataField="workOrderNo"
                dataFormat={this._linkFormatter}
                dataSort={true}
                columnClassName="menu-show-fixer"
            >
                Work Order #
            </TableHeaderColumn>

            <TableHeaderColumn
                width="10%"
                dataField="serviceCount"
                dataSort={true}
                dataAlign={"left"}
            >
                Service Count
            </TableHeaderColumn>

            <TableHeaderColumn
                tdStyle={{whiteSpace: 'normal'}}
                thStyle={{whiteSpace: 'normal'}}
                width="20%"
                dataField="siteName"
                dataFormat={this.siteFormatter}
                dataSort={true}
                dataAlign={"left"}
            >
                Site Name
            </TableHeaderColumn>

            <TableHeaderColumn
                width="10%"
                dataField="arborist"
                dataSort={true}
                dataAlign={"left"}
                tdStyle={{whiteSpace: 'normal'}}
            >
                Arborist
            </TableHeaderColumn>

            <TableHeaderColumn
                width="10%"
                dataField="proposedDateCorrect"
                dataFormat={this._dateFormatter}
                dataSort={true}
            >
                Proposed Date
            </TableHeaderColumn>

            <TableHeaderColumn
                width="10%"
                dataField="acceptedDate"
                dataFormat={this._dateFormatter}
                dataSort={true}
            >
                Accepted Date
            </TableHeaderColumn>

            <TableHeaderColumn
                width="10%"
                dataField="createdDate"
                dataFormat={this._dateFormatter}
                dataSort={true}
            >
                Created Date
            </TableHeaderColumn>

            <TableHeaderColumn
                width="10%"
                dataField="scheduledEvent"
                dataFormat={this._eventFormatter}
                dataSort={true}
            >
                Scheduled Date
            </TableHeaderColumn>

            <TableHeaderColumn
                width="9%"
                dataField="woTotal"
                dataFormat={dollarsFormatter}
                dataSort={true}
            >
                Cost
            </TableHeaderColumn>

        </BootstrapTable>
    )

    _dateFormatter = cell => cell && moment(cell).format(defaultDateFormatShort)

    _linkFormatter = (cell, row) => (
        <Nav>
            <NavDropdown
                title={cell || "..."}
                id={`proposal-menu-${row.id}`}
                className="no-caret-in-dropdown">
                <LinkContainer to={`/work_orders/${row.id}`}>
                    <MenuItem>Manage Work Orders</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/complete_work_orders/${row.workOrderNo}`}>
                    <MenuItem>Complete Work Orders</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/print_work_orders/${row.workOrderNo}`}>
                    <MenuItem>Print Work Orders</MenuItem>
                </LinkContainer>
                <MenuItem divider/>
                <LinkContainer to={`/mapview/${row.id}`}>
                    <MenuItem>Edit Proposal</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/proposal_service_status/${row.id}`}>
                    <MenuItem>Proposal Service Statuses</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/print_proposal/${row.id}`}>
                    <MenuItem>Print Proposal</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    )

    _stateFormatter = (cell, row) => {
        if (cell === "In Work Order") {
            return (
                <div>
                    <div>{cell}</div>
                    <Link
                        to={`/work_orders/${row.proposal_id}`}

                        className="wo-link"
                    >
                        #{row.work_order_no}
                    </Link>
                </div>
            )
        } else {
            return (<div>{cell}</div>)
        }
    }

    siteFormatter(cell, row) {
        return (
            <div style={{display: 'grid'}}>
                <div>
                    <strong>{row.siteName}</strong>
                </div>
                <div>
                    {row.siteAddress}
                </div>
            </div>
        );
    }

    _eventFormatter = (cell, row) => {
        if (row?.scheduledEvents && row.scheduledEvents.length > 1) {
            return <div className="columnDirection">{row.scheduledEvents.map(ps => ps !== "" ?
                <span>{moment(ps).format(defaultDateFormatShort)}</span> : '')}</div>;
        }
        if (row?.scheduledEvents && row.scheduledEvents[0]) {
            return <div className="columnDirection">
                <span>{moment(row.scheduledEvents[0]).format(defaultDateFormatShort)}</span>
            </div>;
        }
        return null
    };

    _onMarkerClick = id => {
        this.props.actions.onMarkerClick(id)
    }

    _resetMap = site => {
        if (site === null || site === undefined) {
            console.log("Not resetting map because site is not a useful object.")
            return false
        }
        console.log("resetting map to site ", site)
        window._googleMapComponent &&
        window._googleMapComponent.panTo &&
        window._googleMapComponent.panTo({
            lat: site.latitude,
            lng: site.longitude,
        })
        //this._googleMapComponent.setZoom(site.defautZoom)
    }

    setShapeOpacity = (ass) => {
        const polygon = ass.polygons.find(p => p.chosen)

        if (polygon.type_polygon === 'polyLine') {
            return 0.75
        } else {
            return 0.5
        }
    }

    markerPolygon = (ass) => {
        const polygon = ass.polygons.find(p => p.chosen)
        const points = polygon.polygon_points
        let averageLat = (polygon.type_polygon !== 'circle' && polygon.type_polygon !== 'polyLine')
            ? points.reduce((previousValue, currentValue, i) => {
            if (currentValue.lat) {
                if (i === 1) {
                    return previousValue.lat + currentValue.lat
                }
                return previousValue + currentValue.lat
            } else {
                if (i === 1) {
                    return previousValue.latitude + currentValue.latitude
                }
                return previousValue + currentValue.latitude
            }
        }) / points.length : ass.latitude
        let averageLng = (polygon.type_polygon !== 'circle' && polygon.type_polygon !== 'polyLine')
            ? points.reduce((previousValue, currentValue, i) => {
            if (currentValue.lng) {
                if (i === 1) {
                    return previousValue.lng + currentValue.lng
                }
                return previousValue + currentValue.lng
            } else {
                if (i === 1) {
                    return previousValue.longitude + currentValue.longitude
                }
                return previousValue + currentValue.longitude
            }
        }) / points.length : ass.longitude
        if (polygon.type_polygon === 'polyLine') {
            const index = Math.round(points.length / 2) - 1
            if (points.length % 2 !== 0) {
                averageLat = points[index].lat ? points[index].lat : points[index].latitude
                averageLng = points[index].lng ? points[index].lng : points[index].longitude
            } else {
                if (points[0].lat) {
                    averageLat = (points[index].lat + points[index + 1].lat) / 2
                    averageLng = (points[index].lng + points[index + 1].lng) / 2
                } else {
                    averageLat = (points[index].latitude + points[index + 1].latitude) / 2
                    averageLng = (points[index].longitude + points[index + 1].longitude) / 2
                }
            }

        }
        return (
            <Marker
                position={{lat: averageLat, lng: averageLng}}
                icon={getLabel(ass.label)}
                strokeColor={"#ffffff"}
                onClick={() => this._onMarkerClick(ass.id)}
                zIndex={1000}
            />
        )
    }

    _renderAssetMap = (assets, client) => {
    const {mapParams, isMapLoaded} = this.state
const {customerSites, selectedSiteInfo} = this.props
return mapParams &&
    <GoogleMapLoader
            containerElement={
                <div
                    id="work_map_view"
                    style={{
                        height: "76vh",
                        width: "100%",
                    }}
                />
            }
            googleMapElement={
                <GoogleMap
                    {...mapParams}
                    ref={it => {
                        this.map = it;
                    }}
                    onIdle={() => {
                        if (!this.state.isMapLoaded) {
                            this.reloadMapParams()
                        }
                        if (customerSites && assets && assets.length > 0 && !isMapLoaded) {
                            this.setState({isMapLoaded: true})
                        }
                    }}
                >
                    {assets && assets.map((ass, i) => {
                        const polygon = ass.polygons.find(p => p.chosen)

                        if (polygon && polygon.type_polygon !== 'marker') {
                            const color = polygon.color ? colors[polygon.color] : colors[parseInt(ass.plant_color)]
                            const opacity = this.setShapeOpacity(ass)

                            if (polygon.type_polygon === 'circle') {
                                return (<><Circle
                                    defaultOpacity={1}
                                    onClick={() => this._onMarkerClick(ass.id)}
                                    defaultCenter={{
                                        lat: polygon.polygon_points[0].lat,
                                        lng: polygon.polygon_points[0].lng
                                    }}
                                    editable={false}
                                    label={ass.label}
                                    options={{
                                        fillOpacity: opacity,
                                        strokeColor: color,
                                        fillColor: color
                                    }}
                                    radius={polygon.radius}
                                    key={i}
                                />
                                    {this.markerPolygon(ass)}
                                </>)
                            } else if (polygon.type_polygon === 'polyLine') {
                                return (<><Polyline
                                    onClick={() => this._onMarkerClick(ass.id)}
                                    path={polygon.polygon_points}
                                    key={i}
                                    label={ass.label}
                                    options={{
                                        strokeOpacity: opacity,
                                        strokeColor: color,
                                        fillColor: color
                                    }}
                                    editable={false}
                                />
                                    {this.markerPolygon(ass)}
                                </>)
                            } else if (polygon.type_polygon === 'rectangle' && checkRectangleCoordinates(polygon)) {
                                return (<><Rectangle
                                    onClick={() => this._onMarkerClick(ass.id)}
                                    bounds={{
                                        north: polygon.polygon_points[0].lat,
                                        south: polygon.polygon_points[3].lat,
                                        east: polygon.polygon_points[1].lng,
                                        west: polygon.polygon_points[0].lng
                                    }}
                                    key={i}
                                    label={ass.label}
                                    options={{
                                        fillOpacity: opacity,
                                        strokeColor: color,
                                        fillColor: color
                                    }}
                                    editable={false}
                                />
                                    {this.markerPolygon(ass)}
                                </>)
                            } else {
                                return (<>
                                    <Polygon
                                        onClick={() => this._onMarkerClick(ass.id)}
                                        path={polygon.polygon_points}
                                        key={i}
                                        label={ass.label}
                                        options={{
                                            fillOpacity: opacity,
                                            strokeColor: color,
                                            fillColor: color
                                        }}
                                        editable={false}
                                    />
                                    {this.markerPolygon(ass)}
                                </>)
                            }
                        } else {
                            return (
                                <Marker
                                    key={ass.id}
                                    position={{lat: ass.latitude, lng: ass.longitude}}
                                    zIndex={null}
                                    icon={getBasicAssetMarker({asset: ass, label: ass.label})}
                                    title={
                                        ass.plant_name +
                                        (ass.plant_count > 1 ? " [" + ass.plant_count + "]" : "")
                                    }
                                    onClick={() => this._onMarkerClick(ass.id)}
                                >
                                </Marker>

                            )
                        }
                    })}
                    {selectedSiteInfo && assets?.length === 0 &&
                    <Marker
                        key={1}
                        position={{lat: selectedSiteInfo.latitude, lng: selectedSiteInfo.longitude}}
                        icon={getBasicMarker({selected: false, color: "#ff6479", label: "x"})}
                        title={selectedSiteInfo.name}
                    />}
                </GoogleMap>
            }
        />
    }
}

const mapStateToProps = state => {
    return {
        customer: state.customerWorkOrders.customer,
        customerWorkOrders: state.customerWorkOrders
            .customerWorkOrders,
        customerSites: state.customerWorkOrders.customerSites,
        selectedSite: state.customerWorkOrders.selectedSite,
        foundCustomers: state.customerWorkOrders.foundCustomers,
        sitesAssets: state.customerWorkOrders.sitesAssets,
        selectedSiteInfo: state.customerWorkOrders.selectedSiteInfo &&
            state.customerWorkOrders.selectedSiteInfo[
                `site_${state.customerWorkOrders.selectedSite}`
                ],

        editingAssetId: state.mapView.editingAssetId /*forasset editor modal*/,
        client: state.client.customerInfo

    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(customerWorkOrders)
