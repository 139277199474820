import React from "react"
import MasterDetail from "../../common/MasterDetail";
import {
    Button,
    Checkbox,
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Glyphicon,
    Grid, OverlayTrigger,
    Row,
    Tooltip
} from "react-bootstrap";
import {merge, select, validateDescription, validateName} from "../../common/commonHandlers";
import {bindActionCreators} from 'redux'
import createApiService from "../../common/clientActionsBuilder";
import {connect} from "react-redux";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ResourceComponent from "../../components/ResourceComponent";
import moment from "moment-timezone";
import * as actions from "./ServicesApi";
import Select from "react-select";
import {browserHistory, Link} from "react-router";
import {addAlert} from "../App/actions";
import Datetime from "react-datetime";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ServicesSaveModal from "./ServicesSaveModal";


const services = createApiService('services', 'service', 'Service');
const api = {
    ...services,
    ...actions,
    addAlert
};

class Services extends ResourceComponent {
    defaultValues = {
        name: '', description: '', disabled: false,
        employee_number: 1,
        estimated_total_time: 1,
        rate: 0,
        removal_service: false,
        semi_removal_service: false,
        legacy_data: false,
        bundle_service: false,
        bundle_checkbox_disabled: false
    }
    state = {
        allowEditing: false,
        saveModal: false,
        resource: {
        },
        errorMessages: {}
    };

    constructor(props, context) {
        super(props, context);
        this.qbAccountSearch = {
            searchOptions: () => [],
            search: () => {
            }
        };
    }

    updateResources = resources => {
        this.setState({...this.state, resources})
    };

    componentDidMount = () => {
        this.qbAccountSearch = this.buildSearchComponent('qb_account', this.props.actions.searchForQbAccount);
        this.props.actions.load({}, this.updateResources);
        this.props.actions.loadService(val => this.setState({...val}));
    };

    handleSelect = e => {
        this.setState({resource: {}, errorMessages: {}}, () => {
            this.props.actions.loadResource(
                e.resource.id,
                val => this.setState({
                    resource: {
                        ...merge(val.resource, this.defaultValues),
                        default_removal_service: !!val.resource?.removal_service,
                        default_semi_removal_service: !!val.resource?.semi_removal_service,
                    }}))
        });
    };

    reloadResources = resource => {
        this.handleSelect({resource: resource});
        this.componentDidMount();
    };

    allowEditing = () => {
        this.setState({allowEditing: !this.state.allowEditing})
    }

    addBlankDateRange = () => {
        const newResource = this.state.resource
        newResource.service_date_ranges.push({
            from_date: moment().format(),
            to_date: moment().format(),
        })
        this.setState({
            resource: newResource
        })
    }

    removeDateRange = id => {
        const newResource = this.state.resource
        newResource.service_date_ranges = newResource.service_date_ranges.filter((_, i) => i != id)
        this.setState({
            resource: newResource
        })
    }

    updateDateRangeAttribute = (id, type, newVal) => {
        const newResource = this.state.resource
        newResource.service_date_ranges[id][type] = newVal
        this.setState({
            resource: newResource
        })
    }

    downloadCSV = (resource) => {
        this.props.actions.downloadCsv(resource)
    }

    renderTooltip = (description) => (
        <Tooltip id="tooltip">
            <div className="text-left">
                {description}
            </div>
        </Tooltip>
    );

    saveService = (callback) => {
        const {resource} = this.state

        this.props.actions.save(resource, (response) => {
            this.reloadResources(response);
            callback && callback()
            if (resource.quickbooks_sync) {
                resource.id = response.id
                this.props.actions.updateService(resource, 'right');
            }
        })
    }

    validate = () => {
        let {resource, errorMessages} = this.state
        let validationName = validateName(resource.name)
        if (validationName.is_valid) {
            this.setState({errorMessages: {"name": null, "description": null}})
            return true
        } else {
            !validationName.is_valid && this.setState({errorMessages: {...errorMessages, "name": validationName.errorMessage}})
            return false
        }
    }

    render() {
        const {resource, serviceTypesList, resources, allowEditing, saveModal, errorMessages} = this.state;
        const accountOptions = this.qbAccountSearch.searchOptions();

        const description = {
            semiRemoval: "Give the asset opacity on 'Map_view' when the service is completed",
            removalService: "Hide asset on 'Map_view' when the service is completed",
            legacyData: "Set completed service assets as disabled for specific site",
            bundleService: "Set this service as bundled",
            archive: "Takes service off list for new proposal services",
            isMixture: "Combines all associated consumables into mixture"
        }

        return (
            resources ?
                <Grid fluid>
                    <MasterDetail
                        resourceName="Service"
                        resourceNamePlural="Services"
                        resources={resources}
                        resource={resource}
                        isServiceView={window.location.href.indexOf("/services") > -1 && true}
                        downloadCsv={this.downloadCSV}
                        select={this.handleSelect}
                        blockSave={Object.values(this.state.errorMessages).some((e) => e !== null)}
                        save={() => {
                            if(this.validate()){
                                if (!resource.service_type_id) {
                                    this.props.actions.addAlert({message: "Select service type to add new service"});
                                } else {
                                    if (resource.id && (resource.removal_service !== resource.default_removal_service || resource.semi_removal_service !== resource.default_semi_removal_service)) {
                                        this.setState({saveModal: true})
                                    } else {
                                        this.saveService()
                                    }
                                }
                            }
                        }}
                        delete={() => this.props.actions.remove(resource, x => {
                            this.setState({resource: null}, () => this.props.actions.load({}, this.updateResources));
                        })}>
                        {resource && <div>
                            <form onSubmit={e => this.submitForm(e, resource.id)}>
                                <Row>
                                    <Col xs={10}>
                                        <Row>
                                            <Col xs={8} className={'input38'}>
                                                <FormGroup bsSize="sm">
                                                    <ControlLabel>{'name'}</ControlLabel>
                                                    <FormControl
                                                        type="text"
                                                        name="name"
                                                        placeholder={"name"}
                                                        value={resource.name}
                                                        onChange={(e) => {
                                                            this.updateResourceAttr(e)
                                                            let validationName = validateName(resource.name)
                                                            !validationName.is_valid ?
                                                                this.setState({errorMessages: {...errorMessages, "name": validationName.errorMessage}})
                                                                : this.setState({errorMessages: {...errorMessages, "name": null}})
                                                        }}
                                                    />
                                                    {this.state.errorMessages.name &&
                                                    <p className="error-message-master-detail ">{this.state.errorMessages["name"]}</p>}
                                                </FormGroup>
                                            </Col>

                                            <Col xs={4}>
                                                <ControlLabel>{'Service Type'}</ControlLabel>
                                                <Select
                                                    value={select(this.state.serviceTypesList, resource.service_type_id)}
                                                    options={this.state.serviceTypesList}
                                                    placeholder="Select Service Type"
                                                    isClearable
                                                    onChange={e => this.selectResourceAttr('service_type_id')(e)}
                                                />
                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col xs={12}>
                                                {
                                                    <FormGroup bsSize="sm">
                                                        <ControlLabel>Description</ControlLabel>
                                                        <FormControl
                                                            componentClass="textarea"
                                                            placeholder="Description"
                                                            value={resource.skills || ""}
                                                            name="skills"
                                                            rows={5}
                                                            onChange={(e) => {
                                                                this.updateResourceAttr(e)
                                                            }}
                                                        />
                                                        {this.state.errorMessages.description &&
                                                        <p className="error-message-master-detail ">{this.state.errorMessages["description"]}</p>}
                                                    </FormGroup>
                                                }
                                                <FormGroup bsSize="sm" className='bottom0'>
                                                    <div style={{display: 'grid', gridTemplateColumns: '2fr 1fr'}}>
                                                        <div className='vertical-align'>
                                                            <Checkbox
                                                                name="semi_removal_service"
                                                                checked={resource.semi_removal_service}
                                                                onChange={this.updateResourceAttr}
                                                                disabled={resource.bundle_service}
                                                            >
                                                                Semi-Removal Service
                                                            </Checkbox>
                                                            <OverlayTrigger placement="top"
                                                                            overlay={this.renderTooltip(description.semiRemoval)}>
                                                                <FontAwesomeIcon icon={faInfoCircle}
                                                                                 className="marginLeft10 pointer"/>
                                                            </OverlayTrigger>
                                                        </div>
                                                        <div className='vertical-align'>
                                                            <Checkbox
                                                                name="bundle_service"
                                                                checked={resource.bundle_service}
                                                                disabled={resource.bundle_checkbox_disabled}
                                                                onChange={e => this.updateResourceAttr(e, () => this.setState({
                                                                    resource: {
                                                                        ...resource,
                                                                        removal_service: false,
                                                                        semi_removal_service: false
                                                                    }
                                                                }))}>
                                                                Bundle Service
                                                            </Checkbox>
                                                            <OverlayTrigger placement="top"
                                                                            overlay={this.renderTooltip(description.bundleService)}>
                                                                <FontAwesomeIcon icon={faInfoCircle}
                                                                                 className="marginLeft10 pointer"/>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                                <FormGroup bsSize="sm" className='bottom0'>
                                                    <div className='vertical-align'>
                                                        <Checkbox
                                                            name="removal_service"
                                                            checked={resource.removal_service}
                                                            onChange={this.updateResourceAttr}
                                                            disabled={resource.bundle_service}
                                                        >
                                                            Removal Service
                                                        </Checkbox>
                                                        <OverlayTrigger placement="top"
                                                                        overlay={this.renderTooltip(description.removalService)}>
                                                            <FontAwesomeIcon icon={faInfoCircle}
                                                                             className="marginLeft10 pointer"/>
                                                        </OverlayTrigger>
                                                    </div>
                                                </FormGroup>
                                                <FormGroup bsSize="sm">
                                                    <div className='vertical-align'>
                                                        <Checkbox
                                                            name="legacy_data"
                                                            checked={resource.legacy_data}
                                                            onChange={this.updateResourceAttr}
                                                        >
                                                            Legacy Data
                                                        </Checkbox>
                                                        <OverlayTrigger placement="top"
                                                                        overlay={this.renderTooltip(description.legacyData)}>
                                                            <FontAwesomeIcon icon={faInfoCircle}
                                                                             className="marginLeft10 pointer"/>
                                                        </OverlayTrigger>
                                                    </div>
                                                </FormGroup>
                                                <FormGroup bsSize="sm">
                                                    <div className='vertical-align'>
                                                        <Checkbox
                                                            name="consumables_is_mixture"
                                                            checked={resource.consumables_is_mixture}
                                                            onChange={this.updateResourceAttr}
                                                        >
                                                            Consumables are mixture
                                                        </Checkbox>
                                                        <OverlayTrigger placement="top"
                                                                        overlay={this.renderTooltip(description.isMixture)}>
                                                            <FontAwesomeIcon icon={faInfoCircle}
                                                                             className="marginLeft10 pointer"/>
                                                        </OverlayTrigger>
                                                    </div>
                                                </FormGroup>
                                            </Col>

                                        </Row>

                                    </Col>
                                    <Col xs={2} className={'input38'}>
                                        <FormGroup bsSize="sm">
                                            <ControlLabel>Number of Employees</ControlLabel>
                                            <FormControl
                                                type="number"
                                                placeholder="# Emp"
                                                value={resource.employee_number}
                                                onChange={this.updateResourceAttr}
                                                name={"employee_number"}
                                                disabled={resource.id > 0}
                                            />
                                        </FormGroup>

                                        <FormGroup bsSize="sm">
                                            <ControlLabel>Estimated time per employee (hr)</ControlLabel>
                                            <FormControl
                                                type="number"
                                                placeholder="Est. Time"
                                                value={resource.estimated_total_time}
                                                onChange={this.updateResourceAttr}
                                                name={"estimated_total_time"}
                                            />
                                        </FormGroup>

                                        <FormGroup bsSize="sm">
                                            <ControlLabel>Total Time</ControlLabel>
                                            <FormControl
                                                type="number"
                                                placeholder="Total Time"
                                                value={resource.estimated_total_time * resource.employee_number}
                                                disabled
                                            />
                                        </FormGroup>

                                        <FormGroup bsSize="sm">
                                            <ControlLabel>Rate</ControlLabel>
                                            <FormControl
                                                type="number"
                                                placeholder="Rate"
                                                value={resource.rate}
                                                name={"rate"}
                                                onChange={this.updateResourceAttr}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12} className="text-center">
                                        <h5>Service Associations</h5>
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={this.state.allowAssociationsEditing}
                                                onChange={this.allowEditing}
                                            />
                                            Allow Editing
                                        </label>
                                        <hr className="grey"/>

                                        <Row className={'bottom15'}>
                                            <Col xs={4}>
                                                <Select className="Select"
                                                        classNamePrefix="select"
                                                        value={select(this.state.consumables, resource.service_consumables)}
                                                        options={this.state.consumables}
                                                        placeholder="Consumables"
                                                        isClearable
                                                        isMulti
                                                        isDisabled={!allowEditing}
                                                        onChange={e => this.selectResourceAttr('service_consumables')(e)}
                                                />
                                            </Col>

                                            <Col xs={4}>
                                                <Select className="Select"
                                                        classNamePrefix="select"
                                                        value={select(this.state.equipments, resource.service_equipment)}
                                                        options={this.state.equipments}
                                                        placeholder="Equipment"
                                                        isClearable
                                                        isMulti
                                                        isDisabled={!allowEditing}
                                                        onChange={e => this.selectResourceAttr('service_equipment')(e)}
                                                />
                                            </Col>

                                            <Col xs={4}>
                                                <Select className="Select"
                                                        classNamePrefix="select"
                                                        value={select(this.state.trucks, resource.service_trucks)}
                                                        options={this.state.trucks}
                                                        placeholder="Trucks"
                                                        isClearable
                                                        isMulti
                                                        isDisabled={!allowEditing}
                                                        onChange={e => this.selectResourceAttr('service_trucks')(e)}
                                                />
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col xs={4}>
                                                <Select className="Select"
                                                        classNamePrefix="select"
                                                        value={select(this.state.applicationSites, resource.service_application_sites)}
                                                        options={this.state.applicationSites}
                                                        placeholder="App Site"
                                                        isClearable
                                                        isMulti
                                                        isDisabled={!allowEditing}
                                                        onChange={e => this.selectResourceAttr('service_application_sites')(e)}
                                                />
                                            </Col>

                                            <Col xs={4}>
                                                <Select className="Select"
                                                        classNamePrefix="select"
                                                        value={select(this.state.applicationTypes, resource.service_application_types)}
                                                        options={this.state.applicationTypes}
                                                        placeholder="App Type"
                                                        isClearable
                                                        isMulti
                                                        isDisabled={!allowEditing}
                                                        onChange={e => this.selectResourceAttr('service_application_types')(e)}
                                                />
                                            </Col>

                                            <Col xs={4}>
                                                <Select className="Select"
                                                        classNamePrefix="select"
                                                        value={select(this.state.plants, resource.service_relevant_plants)}
                                                        options={this.state.plants}
                                                        placeholder="Relevant Plants"
                                                        isClearable
                                                        isMulti
                                                        isDisabled={!allowEditing}
                                                        onChange={e => this.selectResourceAttr('service_relevant_plants')(e)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12} className="text-center">
                                        <h5>Service Date Ranges</h5>
                                        <hr className="grey"/>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12}>
                                        {resource.service_date_ranges &&
                                            resource.service_date_ranges.map((dr, i) => (
                                                <Row key={i}>
                                                    <Col xs={12}>
                                                        <Datetime
                                                            dateFormat="MMMM Do"
                                                            isClearable
                                                            timeFormat={null}
                                                            value={moment(dr.from_date)}
                                                            inputProps={{placeholder: 'From Date'}}
                                                            onChange={m =>
                                                                this.updateDateRangeAttribute(i, "from_date", moment(m).format())
                                                            }/>
                                                        <Datetime
                                                            dateFormat="MMMM Do"
                                                            isClearable
                                                            timeFormat={null}
                                                            value={moment(dr.to_date)}
                                                            inputProps={{placeholder: 'To Date'}}
                                                            onChange={m =>
                                                                this.updateDateRangeAttribute(i, "to_date", moment(m).format())
                                                            }/>
                                                        <a
                                                            style={{cursor: "pointer"}}
                                                            onClick={() => {
                                                                this.removeDateRange(i)
                                                            }}
                                                        >
                                                        <span className="text-danger">
                                                          <Glyphicon glyph="remove-sign"/>
                                                        </span>
                                                        </a>
                                                    </Col>
                                                </Row>
                                            ))}
                                    </Col>
                                </Row>
                                <Row className={'top25'}>
                                    <Col xs={12}>
                                        <Button bsStyle="info" onClick={this.addBlankDateRange}>
                                            Add Date Range
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className={'top25'}>
                                    <Col xs={4} className="vertical-align">
                                        <div className="mr-5">
                                            <img src="quickbooks.png"/>
                                        </div>
                                        <Link to={`/quickbooks?service_id=${resource.id}`}>
                                            Show in Quickbooks
                                        </Link>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={4} className="top10">
                                        <FormGroup bsSize="sm">
                                            <Checkbox
                                                name="quickbooks_sync"
                                                checked={resource.quickbooks_sync}
                                                onChange={this.updateResourceAttr}
                                            >
                                                Synchronize with Quickbooks
                                            </Checkbox>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={4}>
                                        <FormGroup bsSize="sm">
                                            <div className='vertical-align'>
                                                <Checkbox
                                                    name="disabled"
                                                    checked={resource.disabled}
                                                    onChange={this.updateResourceAttr}
                                                >
                                                    Archive Service
                                                </Checkbox>
                                                <OverlayTrigger placement="top"
                                                                overlay={this.renderTooltip(description.archive)}>
                                                    <FontAwesomeIcon icon={faInfoCircle}
                                                                     className="marginLeft10 pointer"/>
                                                </OverlayTrigger>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className='bottom20 top25'>
                                    <Col xs={12} className='columnDirection'>
                                        <span>{`Last updated ${resource.modified_at ? moment(resource.modified_at).format("llll") : <i>Unknown</i>} by ${resource.modified_by}`}</span>
                                        <span>{resource?.id && `Service ID: ${resource.id}`}</span>
                                    </Col>
                                </Row>
                                {saveModal && <ServicesSaveModal
                                    onHide={() => this.setState({saveModal: false})}
                                    onSave={callback => this.saveService(callback)}
                                    semiRemovalChange={resource.semi_removal_service !== resource.default_semi_removal_service}
                                    removalChange={resource.removal_service !== resource.default_removal_service}
                                />}
                            </form>
                        </div>}
                    </MasterDetail>
                </Grid> : null);
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(api, dispatch),
});

export default connect(undefined, mapDispatchToProps)(Services)
