import {
  UPDATE_WONO_SEARCH_TEXT,
  FOUND_WO_TO_COMPLETE,
  SELECT_COMPLETE_PROPOSAL_SERVICE,
  UN_SELECT_COMPLETE_PROPOSAL_SERVICE,
} from "./constants"

const initialState = {
  woNumberSearchText: "",
  workOrder: null,
  selectedPs: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_WONO_SEARCH_TEXT:
      return { ...state, woNumberSearchText: action.woNumberSearchText }

    case FOUND_WO_TO_COMPLETE:
      return { ...state, workOrder: action.workOrder, selectedPs: [] }
    case SELECT_COMPLETE_PROPOSAL_SERVICE:
      return {
        ...state,
        selectedPs: [...new Set(state.selectedPs.concat(action.psId))],
      }
    case UN_SELECT_COMPLETE_PROPOSAL_SERVICE:
      return {
        ...state,
        selectedPs: state.selectedPs.filter(ps => ps !== action.psId),
      }
    default:
      return state
  }
}

export default reducer
