import React from 'react';
import PropTypes from "prop-types"
import {Col, MenuItem, Nav, NavDropdown, OverlayTrigger, Panel, Row, Tooltip,} from "react-bootstrap"
import {LinkContainer} from "react-router-bootstrap"
import {Link} from "react-router"
import Dollars from "../../components/Dollars"
import moment from "moment-timezone"
import PromiseUi from "../../components/PromiseUi"
import {defaultDateFormat, mapForSelect, select, sortAssetsLabels} from "../../common/commonHandlers";
import * as actions from "./actions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PlantName from "../../components/PlantName";
import BundleServices from "../BundleServices/BundleServices";
import Select from "react-select";

const api = {
    ...actions
};

const eligible = (proposalServiceId, eligibleProposalServiceIds) =>
    eligibleProposalServiceIds.includes(proposalServiceId)

const getColor = (proposalServiceId, eligibleProposalServiceIds) => {
    return eligible(proposalServiceId, eligibleProposalServiceIds)
        ? undefined
        : "grey"
}

const StatusProposalServicesList = ({
                                        proposalServicesInfo,
                                        proposalInfo,
                                        selectedPs,
                                        selectPs,
                                        eligibleProposalServiceIds,
                                        actions,
                                        priorities
                                    }) => {
    const prioritiesOptions = mapForSelect(priorities.filter(p => !p.disabled));
    const prioritiesOptionsAll = mapForSelect(priorities);

    const handlePriorityChange = (e, proposalService) => {
            proposalService.priorities = e.map(p => ({id: p.value, name: p.label}))
            actions.updatePriority(proposalService.id, e.map(p => p.value), proposalInfo.id)
    }

    return (
        <div>
            {proposalServicesInfo
                .slice()
                .sort((a, b) => parseInt(a.serviceNo, 10) - parseInt(b.serviceNo, 10))
                .map((proposalService, index) => {
                    const renderTooltip = proposalService && (<div>
                            {proposalService.created_at &&
                            <span>Draft {moment(proposalService.created_at).format(defaultDateFormat)}<br/></span>}
                            {proposalService.proposed_date_correct ?
                            <span>Proposed {moment(proposalService.proposed_date_correct).format(defaultDateFormat)}<br/></span> : proposalService.proposal_date && <span>Proposed {moment(proposalService.proposal_date).format(defaultDateFormat)}<br/></span>}
                            {proposalService.accepted_date &&
                                <span>Accepted {moment(proposalService.accepted_date).format(defaultDateFormat)}<br/></span>}
                            {proposalService.scheduled_date &&
                                <span>Scheduled {moment(proposalService.scheduled_date).format(defaultDateFormat)}<br/></span>}
                            {proposalService.completed_at &&
                                <span>Completed {moment(proposalService.completed_at).format(defaultDateFormat)}<br/></span>}
                            {proposalService.invoiced_at &&
                                <span>Invoiced {moment(proposalService.invoiced_at).format(defaultDateFormat)}<br/></span>}
                            {proposalService.paid_at &&
                                <span>Paid {moment(proposalService.paid_at).format(defaultDateFormat)}<br/></span>}
                            {proposalService.declined_date &&
                                <span>Declined {moment(proposalService.declined_date).format(defaultDateFormat)}</span>}
                        </div>
                    );
                    let statusDate = null;
                    if (proposalService.proposalServiceStatus === 'Draft')
                        statusDate = proposalService.created_at && moment(proposalService.created_at).format(defaultDateFormat);
                    if (proposalService.proposalServiceStatus === 'Invoiced')
                        statusDate = proposalService.invoiced_at && moment(proposalService.invoiced_at).format(defaultDateFormat);
                    if (proposalService.proposalServiceStatus === 'Proposed')
                        statusDate = proposalService.proposed_date_correct ? moment(proposalService.proposed_date_correct).format(defaultDateFormat) : proposalService.proposal_date && moment(proposalService.proposal_date).format(defaultDateFormat);
                    if (proposalService.proposalServiceStatus === 'Accepted')
                        statusDate = proposalService.accepted_date && moment(proposalService.accepted_date).format(defaultDateFormat);
                    if (proposalService.proposalServiceStatus === 'Scheduled')
                        statusDate = proposalService.scheduled_date && moment(proposalService.scheduled_date).format(defaultDateFormat);
                    if (proposalService.proposalServiceStatus === 'Completed')
                        statusDate = proposalService.completed_at && moment(proposalService.completed_at).format(defaultDateFormat);
                    if (proposalService.proposalServiceStatus === 'Paid')
                        statusDate = proposalService.paid_at && moment(proposalService.paid_at).format(defaultDateFormat);
                    if (proposalService.proposalServiceStatus === 'Declined')
                        statusDate = proposalService.declined_date && moment(proposalService.declined_date).format(defaultDateFormat);

                    return (
                        <>
                            <Panel key={proposalService.id} className="panel-overflow-x">
                                <div
                                    style={{
                                        color: getColor(proposalService.id, eligibleProposalServiceIds),
                                    }}
                                >
                                    <Row>
                                        <Col xs={12}>
                                            <Row>
                                                <Col md={1}>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            disabled={
                                                                !eligible(
                                                                    proposalService.id,
                                                                    eligibleProposalServiceIds
                                                                )
                                                            }
                                                            onChange={e => selectPs(e, proposalService.id)}
                                                            checked={selectedPs.includes(proposalService.id)}
                                                        />
                                                        {" "}
                                                        #{proposalService.order_number_in_proposal}
                                                    </label>
                                                </Col>
                                                <Col md={3}>
                                                    <PromiseUi
                                                        promisedStart={proposalService.promised_start_at}
                                                        promisedEnd={proposalService.promised_end_at}
                                                        upsertPromise={actions.upsertPromise}
                                                        proposalServiceId={proposalService.id}
                                                        serviceNo={index + 1}
                                                    />
                                                </Col>
                                                <Col md={2}>
                                                    {proposalService.serviceName}<br/>
                                                </Col>
                                                <Col md={1}>
                                                    <OverlayTrigger placement="top"
                                                                    overlay={<Tooltip>{renderTooltip}</Tooltip>}>
                                                        <div>
                                                            <strong>{proposalService.proposalServiceStatus}</strong>{' '}
                                                            <span>{statusDate}</span>
                                                        </div>
                                                    </OverlayTrigger>
                                                    {
                                                        <Link
                                                            to={`/proposals/${proposalService.proposalId}/invoices`}

                                                            style={{textDecoration: 'none', color: '#d9534f'}}
                                                        >
                                                            {proposalService.invoice_no > 0 ? ` #${proposalService.invoice_no}` : null}
                                                        </Link>
                                                    }
                                                    <br/>
                                                    {proposalService.scheduled_events && proposalService.scheduled_events.map((event, i) => (
                                                        <div key={i}>
                                                            Work on: {moment(event.event_start).format("MMM D")}
                                                            {" "}
                                                            -
                                                            {" "}
                                                            {moment(event.event_end).format("MMM D")}
                                                        </div>
                                                    ))}
                                                </Col>
                                                <Col md={1}>
                                                    {proposalService.proposalServiceStatus !== "Proposed" && proposalService.proposalServiceStatus !== "Not Offered" && proposalService.proposalServiceStatus !== "Declined" && proposalService.proposalServiceStatus !== "Accepted" && proposalService.workOrderNo
                                                        ? <span>
                      <Nav>
                        <NavDropdown
                            eventKey={2}
                            title={`WO #${proposalService.workOrderNo}`}
                            id={`proposal-menu`}
                            className="reset-left-padding menu-show-fixer"
                        >
                            <LinkContainer to={`/work_orders/${proposalService.proposalId}`}>
                                <MenuItem bsSize="small">Manage Work Order</MenuItem>
                            </LinkContainer>
                            <LinkContainer to={`/complete_work_orders/${proposalService.workOrderNo}`}>
                                <MenuItem bsSize="small">Complete Work Order</MenuItem>
                            </LinkContainer>
                            <LinkContainer to={`/print_work_orders/${proposalService.workOrderNo}`}>
                                <MenuItem bsSize="small">Print Work Order</MenuItem>
                            </LinkContainer>
                          </NavDropdown>
                      </Nav>
                      </span>
                                                        : "None"
                                                    }
                                                </Col>
                                                <Col md={2}>
                                                    {proposalService.date_ranges.map((dateRange, i) => (
                                                        <div key={i}>
                                                            {moment(dateRange.from_date).format("MMM D")}
                                                            {" "}
                                                            -
                                                            {" "}
                                                            {moment(dateRange.to_date).format("MMM D")}
                                                        </div>
                                                    ))}
                                                    <Select
                                                        isMulti={true}
                                                        className="Select"
                                                        classNamePrefix="select"
                                                        value={select(prioritiesOptionsAll, proposalService.priorities)}
                                                        options={prioritiesOptions}
                                                        onChange={(e) => handlePriorityChange(e, proposalService)}
                                                        placeholder="Select Priority"
                                                    />
                                                </Col>
                                                <Col md={1}>
                                                    <div className="text-success">
                                                        {proposalService.isPhc ? `${proposalService.serviceEstHours}Qty` : `${proposalService.serviceEstHours}hrs`}
                                                    </div>
                                                </Col>
                                                <Col md={1}>
                                                    <Dollars amount={proposalService.serviceCost}/>
                                                </Col>

                                            </Row>
                                            <br/>
                                            <Row>
                                                <Col md={12} className="preserve-text-area-format">
                                                    <strong>Trees:</strong><br/>
                                                    {proposalService.plants && proposalService.plants.sort((a, b) => sortAssetsLabels(a, b, 'asset_label')).map(p => (
                                                            <span>
                                                    <PlantName plant={p.label}/>
                                                                {p.plant_count > 1 && `[#${p.plant_count}]`}
                                                                {" "}
                                                </span>
                                                        )
                                                    )}
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row>
                                                <Col xs={12} md={6} className="preserve-text-area-format">
                                                    <strong>ServiceNotes:</strong><br/>
                                                    {proposalService.notes}
                                                </Col>

                                                <Col xs={12} md={6} className="preserve-text-area-format">
                                                    <strong>WO Notes:</strong><br/>
                                                    {proposalService.woNotes}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} className="preserve-text-area-format">
                                                    <strong>Purchase Order:</strong><br/>
                                                    {proposalInfo.purchaseOrder ? proposalInfo.purchaseOrder : ''}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                {proposalService.is_bundle_service &&
                                    <BundleServices
                                        bundleServices={proposalService.bundle_services}
                                        view='StatusProposalServiceList'
                                        markedCheckboxes={selectedPs}
                                        proposalServiceId={proposalService.id}
                                        promisedDates={{
                                            promisedStart: proposalService.promised_start_at,
                                            promisedEnd: proposalService.promised_end_at
                                        }}
                                    />
                                }
                            </Panel>
                        </>)
                })}
        </div>
    )
}

StatusProposalServicesList.propTypes = {
    proposalServicesInfo: PropTypes.array.isRequired,
    destinationStatus: PropTypes.string,
    selectedPs: PropTypes.array.isRequired,
    selectPs: PropTypes.func.isRequired,
    eligibleProposalServiceIds: PropTypes.array.isRequired
}

function mapStateToProps(state, ownProps) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(api, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusProposalServicesList);
