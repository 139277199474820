import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as MyActions from './OpenProposalsApi';
import {
    Button,
    Col,
    ControlLabel,
    Form,
    FormControl,
    FormGroup,
    Glyphicon,
    Grid,
    MenuItem,
    Nav,
    NavDropdown,
    Row,
} from 'react-bootstrap';
import {Link} from "react-router"
import {LinkContainer} from 'react-router-bootstrap';
import moment from "moment/moment";
import Dollars from "../../components/Dollars";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import ResourceComponent from "../../components/ResourceComponent";

const smMobile = window.screen.width <= 450;
const isMobile = window.screen.width <= 1024;

const Actions = {...MyActions}

class OpenProposals extends ResourceComponent {
    state = {
        service_types: [],
        resource: {
            servicePromised: false,
            sort: 'ASC',
            sort_by: 'acceptedDate',
            page: 1,
            per_page: 25,
            service_type: 'all'
        }
    }

    componentDidMount() {
        this.props.actions.load(response => this.setState(response));
    }

    reload = () => {
        const {resource} = this.state;
        this.props.actions.fetchOpenProposals(resource, response => this.setState({
            openProposals: response.content,
            total: response.total
        }));
    }

    rowFormatter = (cell, row) => {
        return <div>
            <Row>
                <Col xs={6}>Customer Name</Col>
                <Col xs={6}>{this._linkFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Proposal #</Col>
                <Col xs={6}>{this._proposalLinkFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Title</Col>
                <Col xs={6}>{row.proposalTitle}</Col>
            </Row>
            <Row>
                <Col xs={6}>Arborist</Col>
                <Col xs={6}>{row.salesArborist}</Col>
            </Row>
            <Row>
                <Col xs={6}>Approved</Col>
                <Col xs={6}>{row.approved_status}</Col>
            </Row>
            <Row>
                <Col xs={6}>Accepted $</Col>
                <Col xs={6}>{this._dollarFormatter(row.acceptedAmount)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Accepted On</Col>
                <Col xs={6}>{this._dateFormatter(row.acceptedDate)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Promised Date From</Col>
                <Col xs={6}>{this._dateFormatter(row.date_from)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Promised Date To</Col>
                <Col xs={6}>{this._dateFormatter(row.date_to)}</Col>
            </Row>
            <Row>
                <Col xs={6}>WO's</Col>
                <Col xs={6}>{this._woLinkFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Service Type</Col>
                <Col xs={6}>{Array.from(new Set(row.serviceTypes)).join(', ')}</Col>
            </Row>
        </div>
    };

    render() {
        const {
            openProposals,
            total,
            service_types,
            resource,
        } = this.state;
        return (
            <Grid fluid id='open_proposals'>
                <Row>
                    <Col md={12}>
                    </Col>
                </Row>
                <Row className="vcenter">
                    <Col md={4} className="vcenter">
                        <h2 className="no-top-margin mr-9">Open Proposals</h2>
                        <h5 className={smMobile ? "text-primary nmt-10" : "text-primary"}>{total} returned</h5>
                    </Col>
                    <Col md={8} className="text-right">
                        <Form inline>
                            <FormGroup bsSize="sm">
                                <Row className={'vertical-align'}>
                                    <Col sm={3}>
                                        <ControlLabel>Filter</ControlLabel>{' '}
                                        <FormControl
                                            value={resource.service_type}
                                            componentClass="select"
                                            onChange={e => {
                                                let {resource} = this.state;
                                                resource.service_type = e.target.value;
                                                this.setState(resource)
                                            }}
                                        >
                                            <option value="all">All</option>
                                            {service_types.map(serviceType => (
                                                <option key={serviceType.value} value={serviceType.value}>
                                                    {serviceType.label}
                                                </option>
                                            ))}
                                        </FormControl>
                                    </Col>
                                    <Col sm={9} className={'d-flex'}>
                                        <ControlLabel>{`With ${resource.service_type === 'all' ? 'any services ' : `${service_types.find(s => +s.value === +resource.service_type).label} service `}still not promised`}</ControlLabel>{' '}
                                        <input
                                            type="checkbox"
                                            onChange={() => {
                                                let {resource} = this.state;
                                                resource.servicePromised = !this.state.resource.servicePromised;
                                                this.setState(resource)
                                            }}
                                            value={this.state.resource.servicePromised}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>{' '}
                            <Button bsSize="sm" bsStyle="info" className={'marginBottom8'}
                                    onClick={this.reload}>
                                Apply Filter
                            </Button>
                        </Form>
                    </Col>
                </Row>
                <hr className={'mb-10-mt-0'}/>
                <Row>
                    <Col md={3} className="ml-15">
                        <Button bsStyle="success"
                                onClick={() => this.props.actions.downloadCsv(resource)}>Export to CSV</Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        {openProposals ? this._renderTable(openProposals, total) : null}
                    </Col>
                </Row>
            </Grid>
        )
    }

    _renderTable = (data, total) => {
        const {page, per_page, sort_by, sort} = this.state.resource;
        return (
            !isMobile ? <BootstrapTable
                    data={data}
                    striped={true}
                    bordered={false}
                    hover={true}
                    pagination={true}
                    fetchInfo={{dataTotalSize: total}}
                    remote
                    csvFileName={`unscheduled-${moment().format()}.csv`}
                    options={
                        {
                            sizePerPageDropDown: this.renderSizePerPageDropDown,
                            onPageChange: (page, per_page) => {
                                let {resource} = this.state;
                                resource = {...resource, page, per_page};
                                this.setState({resource}, this.reload);
                            },
                            page,
                            sizePerPageList: [{
                                text: '10', value: 10
                            }, {
                                text: '25', value: 25
                            },
                                {
                                    text: '30', value: 30
                                },
                                {
                                    text: '50', value: 50
                                },
                                {
                                    text: '100', value: 100
                                },
                                {
                                    text: '200', value: 200
                                },
                                {
                                    text: 'All', value: total
                                }],
                            sizePerPage: per_page,
                            sortName: sort_by,
                            sortOrder: sort,
                            onSortChange: (sort_by, sort) => {
                                let {resource} = this.state;
                                resource = {...resource, sort_by, sort};
                                this.setState({resource}, this.reload);
                            }
                        }
                    }
                >
                    <TableHeaderColumn
                        dataField="customerId"
                        isKey={true}
                        hidden={true}
                    >
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="customer"
                        dataFormat={this._linkFormatter}
                        tdStyle={{whiteSpace: 'normal'}}
                        thStyle={{whiteSpace: 'normal'}}
                        dataSort={true}
                        columnClassName='menu-show-fixer'
                    >
                        Customer Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="proposalNo"
                        dataFormat={this._proposalLinkFormatter}
                        tdStyle={{whiteSpace: 'normal'}}
                        thStyle={{whiteSpace: 'normal'}}
                        dataSort={true}
                        columnClassName='menu-show-fixer'
                    >
                        Proposal #
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="proposalTitle"
                        tdStyle={{whiteSpace: 'normal'}}
                        thStyle={{whiteSpace: 'normal'}}
                        dataSort={true}
                        columnClassName='menu-show-fixer'
                    >
                        Title
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="salesArborist"
                        tdStyle={{whiteSpace: 'normal'}}
                        thStyle={{whiteSpace: 'normal'}}
                        dataSort={true}
                        columnClassName='menu-show-fixer'
                    >
                        Arborist
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="approved_status"
                        dataFormat={this._approvedFormatter}
                    >
                        Approved
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="acceptedAmount"
                        dataFormat={this._dollarFormatter}
                        tdStyle={{whiteSpace: 'normal'}}
                        thStyle={{whiteSpace: 'normal'}}
                        dataSort={true}
                        columnClassName='menu-show-fixer'
                    >
                        Accepted $
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="acceptedDate"
                        dataFormat={this._dateFormatter}
                        tdStyle={{whiteSpace: 'normal'}}
                        thStyle={{whiteSpace: 'normal'}}
                        dataSort={true}
                        columnClassName='menu-show-fixer'
                    >
                        Accepted On
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="date_from"
                        dataFormat={(dates) => (dates.filter(Boolean).map(data => this._dateFormatter(data)))}
                        tdStyle={{whiteSpace: 'normal'}}
                        thStyle={{whiteSpace: 'normal'}}
                        columnClassName='menu-show-fixer word-wrap'
                    >
                        Promised date from
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="date_to"
                        dataFormat={(dates) => (dates.filter(Boolean).map(data => this._dateFormatter(data)))}
                        tdStyle={{whiteSpace: 'normal'}}
                        thStyle={{whiteSpace: 'normal'}}
                        columnClassName='menu-show-fixer word-wrap'
                    >
                        Promised date to
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="workOrderNumbers"
                        dataFormat={this._woLinkFormatter}
                        tdStyle={{whiteSpace: 'normal'}}
                        thStyle={{whiteSpace: 'normal'}}
                        columnClassName='menu-show-fixer word-wrap'
                    >
                        WO's
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="serviceTypes"
                        dataFormat={cell => {
                            return Array.from(new Set(cell)).join(', ')
                        }}
                        tdStyle={{whiteSpace: 'normal'}}
                        thStyle={{whiteSpace: 'normal'}}
                        columnClassName='menu-show-fixer'
                    >
                        Service Type
                    </TableHeaderColumn>
                </BootstrapTable> :
                <BootstrapTable
                    data={data}
                    striped={true}
                    bordered={false}
                    hover={true}
                    pagination={true}
                    fetchInfo={{dataTotalSize: total}}
                    remote
                    csvFileName={`unscheduled-${moment().format()}.csv`}
                    options={
                        {
                            sizePerPageDropDown: this.renderSizePerPageDropDown,
                            onPageChange: (page, per_page) => {
                                let {resource} = this.state;
                                resource = {...resource, page, per_page};
                                this.setState({resource}, this.reload);
                            },
                            page,
                            sizePerPageList: [{
                                text: '10', value: 10
                            }, {
                                text: '25', value: 25
                            },
                                {
                                    text: '30', value: 30
                                },
                                {
                                    text: '50', value: 50
                                },
                                {
                                    text: '100', value: 100
                                },
                                {
                                    text: '200', value: 200
                                },
                                {
                                    text: 'All', value: total
                                }],
                            sizePerPage: per_page,
                            sortName: sort_by,
                            sortOrder: sort,
                            onSortChange: (sort_by, sort) => {
                                let {resource} = this.state;
                                resource = {...resource, sort_by, sort};
                                this.setState({resource}, this.reload);
                            }
                        }
                    }
                >
                    <TableHeaderColumn
                        dataField="id"
                        hidden
                        isKey
                    />
                    <TableHeaderColumn
                        dataField="id"
                        dataFormat={this.rowFormatter}
                    >
                    </TableHeaderColumn>
                </BootstrapTable>
        )
    };

    _linkFormatter = (cell, row) => {
        return (
            <Nav>
                <NavDropdown
                    eventKey={2}
                    title={`${row.customer}`}
                    id={`proposal-menu`}
                    className="menu-show-fixer proposal-menu-padding-reset"
                >
                    <LinkContainer to={`/customer/info/${row.customerId}`}>
                        <MenuItem bsSize="small">Info</MenuItem>
                    </LinkContainer>
                    <LinkContainer to={`/customer/proposals/${row.customerId}`}>
                        <MenuItem bsSize="small">Proposals</MenuItem>
                    </LinkContainer>
                    <LinkContainer to={`/customer/sites/${row.customerId}`}>
                        <MenuItem bsSize="small">Sites</MenuItem>
                    </LinkContainer>
                    <LinkContainer to={`/customer/work_orders/${row.customerId}`}>
                        <MenuItem bsSize="small">Work Orders</MenuItem>
                    </LinkContainer>
                    <LinkContainer to={`/customer/invoices/${row.customerId}`}>
                        <MenuItem bsSize="small">Invoices</MenuItem>
                    </LinkContainer>
                    <LinkContainer to={`/customer/work_history/${row.customerId}`}>
                        <MenuItem bsSize="small">Work History</MenuItem>
                    </LinkContainer>
                </NavDropdown>
            </Nav>
        )
    }

    _woLinkFormatter = (cell, row) => (
        <Nav>
            <NavDropdown
                eventKey={2}
                title={`WO's ${row.workOrderNumbers}`}
                id={`proposal-menu`}
                className="menu-show-fixer proposal-menu-padding-reset"
            >
                <LinkContainer to={`/work_orders/${row.proposalId}`}>
                    <MenuItem bsSize="small">Work Orders</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    )

    _proposalLinkFormatter = (cell, row) => (
        <Nav>
            <NavDropdown
                eventKey={2}
                title={row.proposalNo}
                id={`proposal-menu`}
                className="menu-show-fixer proposal-menu-padding-reset"
            >
                <LinkContainer to={`/mapview/${row.proposalId}`}>
                    <MenuItem bsSize="small">Edit Proposal</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/work_orders/${row.proposalId}`}>
                    <MenuItem bsSize="small">Work Orders</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/proposal_service_status/${row.proposalId}`}>
                    <MenuItem bsSize="small">Proposal Statuses</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/print_proposal/${row.proposalId}`}>
                    <MenuItem bsSize="small">Print Proposal</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    )

    _dateFormatter = cell => cell && moment(cell).format("M/D/YY")

    _dollarFormatter = cell => cell && <Dollars amount={cell}/>

    _approvedFormatter = (cell, row) => (
        <div>
            {
                row.approved_status
                !==
                null
                    ?
                    <span className='text-success'><Glyphicon glyph='ok-sign'/></span>
                    :
                    <span className='text-danger'><Glyphicon glyph='minus'/></span>
            }
        </div>
    )

    _acceptFormatter = (cell, row) => (
        <Link to={`/proposal_service_status/${row.proposalId}`}>
            <span className='text-success'><Glyphicon glyph='ok'/></span>
        </Link>
    )
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(Actions, dispatch)}
}

export default connect(null, mapDispatchToProps)(OpenProposals)
