import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Col, Glyphicon, Grid, Panel, Row, Tooltip, OverlayTrigger} from "react-bootstrap";
import * as api from "./SalesActualApi";
import ResourceComponent from "../../components/ResourceComponent";
import {showEmailDialog} from "../../components/email/actions"
import {browserHistory} from "react-router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Datetime from "react-datetime";
import Select from "react-select";
import {select} from "../../common/commonHandlers";
import buildURL from 'axios/lib/helpers/buildURL';
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table"
import {dollarsFormatter} from "../../components/BootStrapTableCellFormatters";
import Dollars from "../../components/Dollars";
import moment from "moment";
import ReactDock from "react-dock";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

const actions = {...api, showEmailDialog};
const isMobile = window.screen.width <= 1024;
const smMobile = window.screen.width <= 450;
const queryString = require('query-string');

class SalesActual extends ResourceComponent {

    search = () => {
        let {resource, reports_settings} = this.state;
        const url = buildURL('/sales_actual', resource);
        this.setState({resource: resource, visibleColumns: reports_settings.sales_actual.columns}, () => {
            this.props.actions.loadReport(resource, results => {
                this.setState({main_report: results.main_report, reports: results.reports}, () => {
                    browserHistory.push(url);
                });
            });
        });
    };

    constructor(props, context) {
        super(props, context);
        this.state = {
            main_report: {},
            reports: [],
            service_types: [],
            services: [],
            resource: {
                label: null,
                from: moment().startOf('month'),
                to: moment().endOf('month')
            },
            filterVisible: true,
            dateFilterOptions: ['Custom', 'Today', 'Yesterday', 'This Week', 'This Week-to-date', 'This Month', 'This Month-to-date', 'This Quarter', 'This Quarter-to-date', 'This Year', 'This Year-to-date', 'Last Week', 'Last Week-to-date', 'Last Month', 'Last Month-to-date', 'Last Quarter', 'Last Quarter-to-date', 'Last Year', 'Last Year-to-date'],
            employees: [],
            customFilter: false,
            showDock: false,
            visibleColumns: [],
            columnOptions: ['Accepted', '$ Accepted', 'ADTA', 'ASTA'],
            defaultFilter: 'Last Month',
            reports_settings: {}
        };
    }

    componentDidMount() {
        let {resource, customFilter, customDockFilter} = this.state;
        const url = buildURL('/sales_actual', resource);
        const parsed = this.props.location && queryString.parse(this.props.location.search);
        this.props.actions.load(result => this.setState(result, () => {
            if (Object.keys(parsed) && Object.keys(parsed).length > 0) {
                resource.from = parsed['from'] && moment(parsed['from'].substr(1).slice(0, -1));
                resource.to = parsed['to'] && moment(parsed['to'].substr(1).slice(0, -1));
                resource.label = parsed['label'] && parsed['label'];
                resource.service_type = parsed['service_type'] && +parsed['service_type'];
                resource.service_id = parsed['service_id'] && +parsed['service_id'];
                resource.arborist_id = parsed['arborist_id'] && +parsed['arborist_id'];
                customFilter = parsed['label'] === 'Custom';
                customDockFilter = result.reports_settings.sales_actual.default_filter === 'Custom';

            } else {
                if (result.reports_settings.sales_actual.default_filter === 'Custom') {
                    resource.from = moment(result.reports_settings.sales_actual.from);
                    resource.to = moment(result.reports_settings.sales_actual.to);
                }
                resource.label = result.reports_settings && result.reports_settings.sales_actual.default_filter;
                customDockFilter = result.reports_settings.sales_actual.default_filter === 'Custom';
                customFilter = result.reports_settings.sales_actual.default_filter === 'Custom';
            }
            this.setState({resource: resource, customFilter: customFilter, customDockFilter: customDockFilter}, () => {
                this.props.actions.loadReport(resource, results => {
                    this.setState({main_report: results.main_report, reports: results.reports}, () => {
                        browserHistory.push(url);
                    });
                });
                this.search();
            });

        }));
    }

    rowFormatter = (cell, row) => {
        const {visibleColumns} = this.state;
        return <div>
            <Row>
                <Col xs={6}>Rep</Col>
                <Col xs={6}>{row.person_full_name}</Col>
            </Row>
            {visibleColumns.includes('Accepted') &&
            <Row>
                <Col xs={6}>Accepted</Col>
                <Col xs={6}>{row.accepted_count}</Col>
            </Row>}
            {visibleColumns.includes('$ Accepted') &&
            <Row>
                <Col xs={6}>Accepted $</Col>
                <Col xs={6}><Dollars amount={row.accepted_total}/></Col>
            </Row>}
            {visibleColumns.includes('ADTA') &&
            <Row>
                <Col xs={6}>ADTA</Col>
                <Col xs={6}>{this.numberFormatter(row.avg)}</Col>
            </Row>}
            {visibleColumns.includes('ASTA') &&
            <Row>
                <Col xs={6}>ASTA</Col>
                <Col xs={6}>{this.astaFormatter(row.asta)}</Col>
            </Row>}
            {visibleColumns.includes('new') &&
            <Row>
                <Col xs={6}>New</Col>
                <Col xs={6}>{row.new}</Col>
            </Row>}
            {visibleColumns.includes('existing') &&
            <Row>
                <Col xs={6}>Existing</Col>
                <Col xs={6}>{this.daysFormatter(row.existing)}</Col>
            </Row>}
        </div>
    };

    daysFormatter = (cell, row) => {
        return cell && cell > 0 ? parseFloat(cell).toFixed(2) : '-';
    };

    astaFormatter = (cell, row) => {
        return cell && cell?.length > 0 ? (cell.reduce((accumulator, e) => {
            return accumulator + e.asta
        },0) / cell?.length).toFixed(2) : '-'
    }

    numberFormatter = (cell, row) => {
        return cell && cell > 0 ? parseFloat(cell).toFixed(2) : '-';
    };

    handleButtonsFilterChange = e => {
        let {resource} = this.state;
        if (e.value === 'Custom') {
            this.setState({customFilter: true}, () => {
                resource.label = e.value;
                this.setState({resource}, this.search);
            });
        } else {
            this.setState({customFilter: false}, () => {
                resource.label = e.value;
                resource.from = null;
                resource.to = null;
                this.setState({resource}, this.search);
            });
        }
    };

    saveReportSettings = () => {
        const {reports_settings} = this.state;
        this.props.actions.saveFilter(reports_settings, this.search)
    };

    handleDefaultFilterChange = e => {
        let {reports_settings} = this.state;
        reports_settings.sales_actual.default_filter = e.value;
        this.setState({reports_settings, customDockFilter: e.value === 'Custom'}, this.saveReportSettings);
    };

    handleDefaultFilterFromChange = e => {
        let {reports_settings} = this.state;
        reports_settings.sales_actual.from = moment(e);
        this.setState(reports_settings, this.saveReportSettings);
    };

    handleDefaultFilterToChange = e => {
        let {reports_settings} = this.state;
        reports_settings.sales_actual.to = moment(e);
        this.setState(reports_settings, this.saveReportSettings);
    };

    handleColumnOptionsChange = e => {
        let {visibleColumns, reports_settings} = this.state;
        reports_settings.sales_actual.columns = e.map(x => x.value);
        visibleColumns = e.map(x => x.value);
        this.setState({visibleColumns: visibleColumns, reports_settings: reports_settings}, this.saveReportSettings);
    };

    renderTooltip = (description) => (
        <Tooltip id="tooltip">
            {Array.isArray(description) ?
                <div className="columnDirection text-left">
                    <p className={smMobile ? "bottom15 text-orange font10" : "bottom15 text-orange font12"}>{description[0].title}</p>
                    {description[0] && description[0].cases.map((desc_case, i) => (
                        <li className={smMobile ? "list_style_none bottom10 font10" : "list_style_none bottom10 font12"}>
                            <span className="mr-5">#{i + 1}</span>
                            <span>{desc_case}</span>
                        </li>
                    ))}
                </div>
                : isMobile ?
                    <div>
                        {Object.entries(description).map(x => (
                            <div className="text-left bottom5">
                                <p className={smMobile ? "text-left bottom5 text-orange font10" : "text-left bottom5 text-orange font12"}>{x[0].charAt(0).toUpperCase() + x[0].slice(1)}</p>
                                <span className={smMobile ? "text-left font10" : "text-left font12"}>{x[1]}</span>
                            </div>
                        ))}
                    </div>
                    :
                    <div className="text-left font12">
                        {description}
                    </div>
            }
        </Tooltip>
    );

    renderDescriptionTooltip = (description) => (
        <OverlayTrigger placement={smMobile ? "bottom" : "right"}
                        overlay={this.renderTooltip(description)}>
            <FontAwesomeIcon icon={faInfoCircle} className={isMobile ? "ml-6 font12 pointer" : "ml-6 font14 pointer"}/>
        </OverlayTrigger>
    )

    render() {
        const {
            filterVisible, resource, dateFilterOptions, customFilter, customDockFilter, employees, main_report, service_types, services, reports, showDock, columnOptions, visibleColumns, reports_settings
        } = this.state;
        let dateOptionsSelect = dateFilterOptions.map(filter => {
            let result = {};
            result.value = filter;
            result.label = filter;
            return result;
        });
        let columnOptionsSelect = columnOptions.map(filter => {
            let result = {};
            result.value = filter;
            result.label = filter;
            return result;
        });
        const defaultFilter = reports_settings.sales_actual && reports_settings.sales_actual.default_filter;
        return (
            <Grid fluid>
                <Row className="vcenter">
                    <Col md={6} className="vcenter">
                        <h2 className="no-top-margin small-margin">Sales Actual</h2>
                        <span>Proposals accepted within selected date range regardless of proposal date</span>
                    </Col>
                    <Col md={3} className="text-left">
                        <Button
                            className="pointer no-top-margin"
                            bsStyle="warning"
                            onClick={() => {
                                this.setState({filterVisible: !filterVisible})
                            }}>
                            Filter {" "}<FontAwesomeIcon icon="search" className="small-margin"/>
                        </Button>
                    </Col>
                    <Col md={3}>
                        <Button
                            className="pointer no-top-margin"
                            bsStyle="warning"
                            onClick={() => {
                                this.setState({showDock: !showDock})
                            }}>
                            Customize
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Panel
                        collapsible
                        expanded={filterVisible}
                    >
                        <Row>
                            <Col md={2} className={`bottom10`}>
                                <Select className="Select"
                                        classNamePrefix="select"
                                        value={select(dateOptionsSelect, resource.label)}
                                        options={dateOptionsSelect}
                                        placeholder="Date"
                                        onChange={this.handleButtonsFilterChange}
                                />
                            </Col>
                            {
                                customFilter &&
                                <Col md={4} className={`small-right-padding`}>
                                    <Datetime
                                        dateFormat="MM/DD/YYYY"
                                        isClearable
                                        timeFormat={null}
                                        value={moment(resource.from)}
                                        className="half small-margin"
                                        inputProps={{placeholder: 'From'}}
                                        onChange={this.dateResourceAttr('from', this.search)}/>
                                    <Datetime
                                        dateFormat="MM/DD/YYYY"
                                        isClearable
                                        timeFormat={null}
                                        value={moment(resource.to)}
                                        className="half small-margin"
                                        inputProps={{placeholder: 'To'}}
                                        onChange={this.dateResourceAttr('to', this.search)}
                                    />
                                </Col>
                            }
                            <Col md={2}>
                                <Select className="Select" classNamePrefix="select"
                                        value={select(employees, resource.arborist_id)}
                                        options={employees}
                                        isClearable
                                        isMulti
                                        onChange={this.selectResourceAttr('arborist_id', this.search)}
                                        placeholder="Arborist"
                                />
                            </Col>
                            <Col md={2}>
                                <Select className="Select" classNamePrefix="select"
                                        value={select(service_types, resource.service_type)}
                                        options={service_types}
                                        isClearable
                                        onChange={this.selectResourceAttr('service_type', this.search)}
                                        placeholder="Service Type"
                                />
                            </Col>
                            <Col md={2}>
                                <Select className="Select" classNamePrefix="select"
                                        value={select(services, resource.service_id)}
                                        options={services}
                                        isClearable
                                        onChange={this.selectResourceAttr('service_id', this.search)}
                                        placeholder="Services"
                                />
                            </Col>
                        </Row>

                    </Panel>
                </Row>
                {main_report && main_report.length > 0 && <Row>
                    {isMobile ?
                        <Col xs={12}>
                            <h4 className='text-center'>All</h4>
                            <BootstrapTable
                                ref="table"
                                data={main_report}
                                striped={true}
                                bordered={false}
                                hover={true}
                                remote
                                className="wrapped"
                            >
                                <TableHeaderColumn
                                    dataField="id"
                                    hidden
                                    isKey
                                />
                                <TableHeaderColumn
                                    dataFormat={this.rowFormatter}
                                >
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </Col>
                        :
                        <Col xs={12}>
                            <h4 className='text-center'>All</h4>
                            <BootstrapTable
                                ref="table"
                                data={main_report}
                                striped={true}
                                bordered={false}
                                hover={true}
                                remote
                                className="wrapped"
                            >
                                <TableHeaderColumn
                                    dataField="person_full_name"
                                    width={"10%"}
                                    isKey
                                >
                                    Rep
                                    {this.renderDescriptionTooltip("Proposal reporter")}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="accepted_count"
                                    hidden={!visibleColumns.includes('Accepted')}
                                    width={"10%"}
                                >
                                    Accepted
                                    {this.renderDescriptionTooltip("Number of Accepted proposals")}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="accepted_total"
                                    dataFormat={dollarsFormatter}
                                    hidden={!visibleColumns.includes('$ Accepted')}
                                    width={"10%"}
                                >
                                    $ Accepted
                                    {this.renderDescriptionTooltip("Accepted Total $ amount")}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="avg"
                                    hidden={!visibleColumns.includes('ADTA')}
                                    dataFormat={this.daysFormatter}
                                    width={"10%"}
                                >
                                    ADTA
                                    {this.renderDescriptionTooltip("Average Days To Accepted - calculated between proposal date and accepted date")}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="asta"
                                    dataFormat={this.astaFormatter}
                                    hidden={!visibleColumns.includes('ASTA')}
                                    width={"10%"}
                                >
                                    ASTA
                                    {this.renderDescriptionTooltip("Average Sent to Accepted - calculated on number of emails sent until accepted")}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="new"
                                    width={"10%"}
                                >
                                    New
                                    {this.renderDescriptionTooltip("Number of New customers")}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="existing"
                                    width={"10%"}
                                >
                                    Existing
                                    {this.renderDescriptionTooltip("Number of Existing customers")}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </Col>}
                </Row>}
                {Object.keys(reports) && Object.keys(reports).length > 0 && Object.keys(reports).map(function (key, index) {
                        return <Row>
                            {isMobile ?
                                <Col xs={12} key={index}>
                                    <h4 className='text-center'>{key}</h4>
                                    <BootstrapTable
                                        ref="table"
                                        data={reports[key]}
                                        striped={true}
                                        bordered={false}
                                        hover={true}
                                        remote
                                        className="wrapped"
                                    >
                                        <TableHeaderColumn
                                            dataField="id"
                                            hidden
                                            isKey
                                        />
                                        <TableHeaderColumn
                                            dataFormat={this.rowFormatter}
                                        >
                                        </TableHeaderColumn>
                                    </BootstrapTable>
                                </Col>
                                :
                                <Col xs={12} key={index}>
                                    <h4 className='text-center'>{key}</h4>
                                    <BootstrapTable
                                        ref="table"
                                        data={reports[key]}
                                        striped={true}
                                        bordered={false}
                                        hover={true}
                                        remote
                                        className="wrapped"
                                    >
                                        <TableHeaderColumn
                                            dataField="person_full_name"
                                            width={"10%"}
                                            isKey
                                        >
                                            Rep
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="accepted_count"
                                            hidden={!visibleColumns.includes('Accepted')}
                                            width={"10%"}
                                        >
                                            Accepted
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="accepted_total"
                                            hidden={!visibleColumns.includes('$ Accepted')}
                                            dataFormat={dollarsFormatter}
                                            width={"10%"}
                                        >
                                            $ Accepted
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="avg"
                                            dataFormat={this.daysFormatter}
                                            hidden={!visibleColumns.includes('ADTA')}
                                            width={"10%"}
                                        >
                                            ADTA
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="asta"
                                            dataFormat={this.astaFormatter}
                                            hidden={!visibleColumns.includes('ASTA')}
                                            width={"10%"}
                                        >
                                            ASTA
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="new"
                                            width={"10%"}
                                        >
                                            New
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="existing"
                                            width={"10%"}
                                        >
                                            Existing
                                        </TableHeaderColumn>
                                    </BootstrapTable>
                                </Col>}
                        </Row>
                    }.bind(this)
                )}
                <ReactDock position='right'
                           isVisible={showDock}
                           dimMode="none"
                           defaultSize={window.innerWidth > 990 ? 0.6 : 0.9}
                           dockStyle={{overflow: 'hidden'}}
                           zIndex={1031}
                >
                    <div className="close-dock">
                        <h4 className="margin10 pull-left">Customize Report</h4>
                        <div className="pull-right small-margin">
                            <Glyphicon glyph='remove' className="pointer margin10"
                                       onClick={() => this.setState({showDock: null})}/>
                        </div>
                    </div>
                    <div className="margin10 top50">
                        <h5 className='top80'>Set default report period</h5>
                        <Select className="Select"
                                classNamePrefix="small-select"
                                value={select(dateOptionsSelect, defaultFilter)}
                                options={dateOptionsSelect}
                                placeholder="Date"
                                onChange={this.handleDefaultFilterChange}
                        />
                        {
                            customDockFilter &&
                            <div className={`small-right-padding`}>
                                <Datetime
                                    dateFormat="MM/DD/YYYY"
                                    isClearable
                                    timeFormat={null}
                                    value={moment(reports_settings.sales_actual.from)}
                                    className="half small-margin"
                                    inputProps={{placeholder: 'From'}}
                                    onChange={this.handleDefaultFilterFromChange}/>
                                <Datetime
                                    dateFormat="MM/DD/YYYY"
                                    isClearable
                                    timeFormat={null}
                                    value={moment(reports_settings.sales_actual.to)}
                                    className="half small-margin"
                                    inputProps={{placeholder: 'To'}}
                                    onChange={this.handleDefaultFilterToChange}
                                />
                            </div>
                        }
                        <h5 className='top40'>Columns to show</h5>
                        <Select className="Select"
                                classNamePrefix="small-select"
                                value={select(columnOptionsSelect, visibleColumns)}
                                options={columnOptionsSelect}
                                isMulti
                                placeholder="Columns"
                                onChange={this.handleColumnOptionsChange}
                        />
                    </div>
                </ReactDock>
            </Grid>
        );
    }
}

SalesActual.propTypes = {
    //myProp: PropTypes.object.isRequired
};

SalesActual.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        email: state.auth.email,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesActual);
