import {Col, Modal, Row} from "react-bootstrap";
import React, {Component} from 'react';

class HolidayRemovalModal extends Component {

    render() {
        return (
            <Modal
                animation={false}
                show={this.props.show}
                onHide={this.props.onHide}
                className={"heightAuto maxHeight90 fontIOS"}
                style={this.props.overflow}
                bsSize="small"
            >
                <Modal.Header>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.children}
                </Modal.Body>

                <Modal.Footer>
                    <Row>
                        <Col md={6} className="text-left">
                            {this.props.infoContent}
                        </Col>
                        <Col md={6}>
                            <button type="button" onClick={this.props.closeButton} className="btn btn-default"
                                    data-dismiss="modal">No
                            </button>
                            <button type="button" onClick={this.props.onHide} className="btn btn-success">Yes</button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default HolidayRemovalModal;