// Bootstrap Table Cell formatter commonly used
import React from "react"
import {MenuItem, Nav, NavDropdown, OverlayTrigger, Tooltip,} from "react-bootstrap"
import {LinkContainer} from "react-router-bootstrap"
import moment from "moment"
import Dollars from "./Dollars"
import {Link} from "react-router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ColorCheckbox from "../containers/Scheduler/ColorCheckbox";
import {defaultDateFormat, defaultDateTimeFormat, updateExternalLinks} from "../common/commonHandlers";
import {faDownload} from "@fortawesome/free-solid-svg-icons";

export const optionsFormatter = (options, moreOptions = () => {
}) => (cell, row) => {
    const params = {};
    if (options.to) {
        params.to = `${options.to}/${cell}`;
    } else {
        params.onClick = () => options.onClick(cell);
    }
    const removeDisabled = options.allowRemove && !options.allowRemove(row);
    const link = <Link onClick={() => {
        if (!removeDisabled) {
            const choice = window.confirm('Are you sure?');
            if (choice) {
                options.remove(cell);
            }
        }
    }} className={`pointer small-margin ${removeDisabled ? 'disabled' : ''}`}>
        {
            options.remove &&
            <FontAwesomeIcon icon="trash-alt" className="bigger"/>
        }

    </Link>;
    return <span>
        <Link {...params} className="pointer"><FontAwesomeIcon icon="edit" className="bigger"/></Link>
        {moreOptions(cell)}
        {removeDisabled ?
            <OverlayTrigger placement="top" overlay={
                <Tooltip id="tooltip">
                    {options.allowRemoveReason}
                </Tooltip>
            }>{link}
            </OverlayTrigger>
            : link}
        </span>
};
export const downloadLinkFormatter = cell => <a href={cell}><FontAwesomeIcon icon={faDownload}/></a>
export const checkboxFormatter = cell => <ColorCheckbox value={cell} disabled/>
export const descriptionFormatter = cell => {
    cell = updateExternalLinks(null, cell)
    return <div dangerouslySetInnerHTML={{__html: cell}}/>
};
export const dollarsFormatter = cell => (!cell || isNaN(cell)) ? <Dollars amount={0}/> : <Dollars amount={parseFloat(cell)}/>
export const dateFormatter = cell => cell ? moment(cell).format(defaultDateTimeFormat) : 'N/A'
export const dateNoTimeFormatter = cell => cell ? moment(cell).format(defaultDateFormat) : 'N/A'
export const dateArrayFormatter = cell => {
    return <div>{cell && cell.length > 0 && cell.map((date, index) => date && <div key={index}>
        {moment(date).format('MM/DD/YYYY h:mmA')}
    </div>)}</div>
}
export const options = {
    page: 1,  //which page you want to show as default
    sizePerPageList: [25, 75, 100, 200], //you can change the dropdown list for size per page
    sizePerPage: 75,  //which size per page you want to locate as default
    paginationSize: 3,  //the pagination bar size.
}
export const ageFormatter = cell => {
    return cell ? Math.round(moment().diff(moment(cell), 'hours')/24) : '0';
};

export const proposalNoFormatter = (cell, row) => (
    <Nav>
        <NavDropdown
            title={cell || "..."}
            id={`proposal-menu-${row.id}`}
            className="menu-show-fixer no-caret-in-dropdown"
        >
            <LinkContainer target="_blank" to={`/mapview/${row.proposalId}`}>
                <MenuItem>Edit Proposal</MenuItem>
            </LinkContainer>
            <LinkContainer target="_blank"

                to={`/proposal_service_status/${row.proposalId}`}
            >
                <MenuItem>Edit Service Statuses</MenuItem>
            </LinkContainer>
            <LinkContainer target="_blank" to={`/print_proposal/${row.proposalId}`}>
                <MenuItem>Print Proposal</MenuItem>
            </LinkContainer>
        </NavDropdown>
    </Nav>
)

export const proposalNoFormatterWithEmail = (cell, row, onEmail, showWo = false) => (
    <Nav>
        <NavDropdown
            title={cell || "..."}
            id={`proposal-menu-${row.id}`}
            className="menu-show-fixer no-caret-in-dropdown"
        >
            <LinkContainer target="_blank" target="_blank" to={`/mapview/${row.proposalId}`}>
                <MenuItem>Edit Proposal</MenuItem>
            </LinkContainer>
            {showWo && <LinkContainer target="_blank"

                to={`/work_orders/${row.proposalId}`}
            >
                <MenuItem>Work Orders</MenuItem>
            </LinkContainer>}
            <LinkContainer target="_blank"

                to={`/proposal_service_status/${row.proposalId}`}
            >
                <MenuItem>Service Statuses</MenuItem>
            </LinkContainer>
            <LinkContainer target="_blank" to={`/print_proposal/${row.proposalId}`}>
                <MenuItem>Print Proposal</MenuItem>
            </LinkContainer>
            <li className="pointer">
                <Link onClick={() => onEmail(row.proposalId)}>Email</Link>
            </li>
        </NavDropdown>
    </Nav>
)


export const _proposalLinkFormatter = (cell, row) => (
    <Nav>
        <NavDropdown
            eventKey={2}
            title={cell}
            id={`proposal-menu`}
            className="menu-show-fixer proposal-menu-padding-reset"
        >
            <LinkContainer target="_blank" to={`/mapview/${row.stickable_id}`}>
                <MenuItem bsSize="small">Edit Proposal</MenuItem>
            </LinkContainer>
            <LinkContainer target="_blank" to={`/proposal_service_status/${row.stickable_id}`}>
                <MenuItem bsSize="small">Proposal Statuses</MenuItem>
            </LinkContainer>
            <LinkContainer target="_blank" to={`/print_proposal/${row.stickable_id}`}>
                <MenuItem bsSize="small">Print Proposal</MenuItem>
            </LinkContainer>
        </NavDropdown>
    </Nav>
)

export const workOrderNoFormatter = (cell, row) => (
    <Nav>
        <NavDropdown
            title={cell || "..."}
            id={`wo-menu-${row.id}`}
            className="no-caret-in-dropdown"
        >
            <LinkContainer target="_blank" to={`/work_orders/${row.proposalId}`}>
                <MenuItem>Manage WO</MenuItem>
            </LinkContainer>
            <LinkContainer target="_blank"

                to={`/complete_work_orders/${row.workOrderNo}`}
            >
                <MenuItem>Complete WO</MenuItem>
            </LinkContainer>
            <LinkContainer target="_blank" to={`/print_work_orders/${row.workOrderNo}`}>
                <MenuItem>Print Work Order</MenuItem>
            </LinkContainer>
        </NavDropdown>
    </Nav>
)

export const serviceNameFormatter = (cell, row) => (
    <p>#{row.serviceNo} {row.serviceName}<br/>{row.plants.map(p=>{return <p>{p.label}</p>})}</p>
)

export const customerNameFormatter = (cell, row) => (
    <Nav>
        <NavDropdown
            eventKey={2}
            title={cell}
            id={`proposal-menu`}
        >
            <LinkContainer target="_blank" to={`/customer/info/${row.customer_id}`}>
                <MenuItem bsSize="small">Customer Info</MenuItem>
            </LinkContainer>
            <LinkContainer target="_blank" to={`/customer/proposals/${row.customer_id}`}>
                <MenuItem bsSize="small">Customer Proposals</MenuItem>
            </LinkContainer>
            <LinkContainer target="_blank" to={`/customer/sites/${row.customer_id}`}>
                <MenuItem bsSize="small">Customer Sites</MenuItem>
            </LinkContainer>
            <LinkContainer target="_blank" to={`/customer/work_orders/${row.customer_id}`}>
                <MenuItem bsSize="small">Customer Work Orders</MenuItem>
            </LinkContainer>
            <LinkContainer target="_blank" to={`/customer/invoices/${row.customer_id}`}>
                <MenuItem bsSize="small">Customer Invoices</MenuItem>
            </LinkContainer>
            <LinkContainer target="_blank" to={`/customer/work_history/${row.customer_id}`}>
                <MenuItem bsSize="small">Customer Work History</MenuItem>
            </LinkContainer>
        </NavDropdown>
    </Nav>
)

export const _customerNameLink = (cell, row) => (
    <Nav>
        <NavDropdown
            eventKey={2}
            title={cell}
            id={`proposal-menu`}
        >
            <LinkContainer target="_blank" to={`/customer/info/${row.customer_id}`}>
                <MenuItem bsSize="small">Info</MenuItem>
            </LinkContainer>
            <LinkContainer target="_blank" to={`/customer/proposals/${row.customer_id}`}>
                <MenuItem bsSize="small">Proposals</MenuItem>
            </LinkContainer>
            <LinkContainer target="_blank" to={`/customer/sites/${row.customer_id}`}>
                <MenuItem bsSize="small">Sites</MenuItem>
            </LinkContainer>
            <LinkContainer target="_blank" to={`/customer/work_orders/${row.customer_id}`}>
                <MenuItem bsSize="small">Work Orders</MenuItem>
            </LinkContainer>
            <LinkContainer target="_blank" to={`/customer/invoices/${row.customer_id}`}>
                <MenuItem bsSize="small">Invoices</MenuItem>
            </LinkContainer>
            <LinkContainer target="_blank" to={`/customer/work_history/${row.customer_id}`}>
                <MenuItem bsSize="small">Work History</MenuItem>
            </LinkContainer>
        </NavDropdown>
    </Nav>
)
