import React from "react";
import "../CustomerTimeline.scss"
import moment from "moment-timezone";

const TimelineAxisDate = ({date}) => {
    return <div className="timeline-axis-date">
        {moment(date).format('MM/DD/YYYY, h:mm a')}
    </div>
}

export default TimelineAxisDate;