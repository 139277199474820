import React, {useEffect, useState} from "react"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import moment from "moment-timezone";
import MDSpinner from "react-md-spinner";
import TimelineNote from "../CustomerPage/CustomerInfo/TimelineElements/TimelineNote";
import DateRangeFilter from "../../components/DateRangeFilter";
import TimelineAxisDate from "../CustomerPage/CustomerInfo/TimelineElements/TimelineAxisDate";
import * as actions from "./TrucksApi"
import './TruckFinancialTimeline.scss'

const TruckFinancialTimeline = ({actions, truckFinancialId, updateFinancialNotes}) => {
    const [ready, setReady] = useState(false)
    const [timelineData, setTimelineData] = useState([])
    const [currentUserPersonId, setCurrentUserPersonId] = useState(null)
    const [filters, setFilters] = useState({date_from: null, date_to: null})
    const [employees, setEmployees] = useState([])

    const reload = () => {
        setReady(false)
        actions.truckFinancialTimelineData(truckFinancialId, filters, result => {
            setReady(true)
            setTimelineData(result.timeline_result)
            setCurrentUserPersonId(result.current_user_person_id)
            setEmployees(result.employees)
            let messages = document.getElementById("timeline-body")
            let addNewElement = document.getElementById("timeline").lastElementChild
            messages.scrollTop = messages.scrollHeight;
            new ResizeObserver(() => {
                messages.scrollTop = messages.scrollHeight;
            }).observe(addNewElement)
            updateFinancialNotes(result.timeline_result.map(tr => tr.data))
        })
    }


    useEffect(() => {
        reload()
    }, [filters.date_from, filters.date_to, truckFinancialId])

    const saveNote = note => {
        actions.saveTruckFinancialNote(note, truckFinancialId, () => reload())
    }

    const getDate = (date) => {
        if (date && date.length > 0) {
            let datearray = date.split("/");
            let parsed = datearray[1] + '/' + datearray[0] + '/' + datearray[2];
            return moment(parsed).format('DD/MM/YYYY');
        } else {
            return null;
        }
    }

    const handleRangeDateChange = (start, end) => {
        const from = getDate(start);
        const to = getDate(end);
        setFilters({...filters, date_from: from && from, date_to: to && to})
    }

    const employee = person_id => employees.find(e => e.person_id === person_id)


    return (ready ? <div className="truck-timeline">
            <Row>
                <Col xs={12} className="text-center mobile-width"><h4>Notes</h4></Col>
            </Row>
            <Row className="vertical-align">
                <Col xs={12} className="text-center mobile-width">
                    <DateRangeFilter
                        customClassName="truck-DateRangeFilter"
                        promisedStart={timelineData.length > 0 ? moment(timelineData[0].data.created_at).format('MM/DD/YYYY') : moment().subtract(10, "days").format('MM/DD/YYYY')}
                        promisedEnd={moment().format('MM/DD/YYYY')}
                        upsertPromise={(id, start, end) => handleRangeDateChange(start, end)}
                        serviceNo={null}
                        index={1}
                        format="M/DD/YY"
                    />
                </Col>
            </Row>
            <Row id="timeline-body">
                {ready ? <div id='timeline' className={`${timelineData.length > 0 && "timeline"}`}>
                    {timelineData.length > 0 ? timelineData.map((el, i) => {
                        const elementEmployee = employee(el.data.person_id)
                        const elementColor = elementEmployee && elementEmployee.color ? elementEmployee.color : "#dadada"
                        const toSetNextDay = timelineData[i - 1] ? moment(timelineData[i - 1].data.created_at).format('MM/DD/YYYY') !== moment(el.data.created_at).format('MM/DD/YYYY') ? true : false : true
                        if (el.type === 'note') {
                            return <>
                                {toSetNextDay && <TimelineAxisDate date={el.data.created_at}/>}
                                <TimelineNote
                                    note={el.data}
                                    color={elementColor}
                                    employeeName={elementEmployee?.label}
                                    employeeDisabled={elementEmployee?.disabled}
                                    currentUserPersonId={currentUserPersonId}
                                    onSave={note => saveNote(note)}
                                    onDelete={id => actions.deleteTruckFinancialNote(id, () => reload())}/>
                            </>
                        }
                    }) : <p className="no-data">Nothing to report...</p>}
                    <TimelineNote
                        employeeName={employee(currentUserPersonId)?.label}
                        employeeDisabled={employee(currentUserPersonId)?.disabled}
                        color={employee(currentUserPersonId)?.color}
                        onSave={note => saveNote(note)}
                    />
                </div> : <div className="vhCenter full-height">
                    <MDSpinner size={70} borderSize={2}/>
                </div>}
            </Row>
        </div> : <div className="truck-timeline vhCenter">
            <MDSpinner size={70} borderSize={2}/>
        </div>
    )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({actions: bindActionCreators(actions, dispatch)})

export default connect(mapStateToProps, mapDispatchToProps)(TruckFinancialTimeline)