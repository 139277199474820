import {Col, ControlLabel, FormGroup, Modal, Row} from "react-bootstrap";
import React from 'react';
import ResourceComponent from "../../components/ResourceComponent";
import Datetime from "react-datetime";
import {defaultDateFormat} from "../../common/commonHandlers";
import moment from "moment/moment";
import * as Actions from "./FetchHolidaysModalApi"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

class FetchHolidaysModal extends ResourceComponent {

    constructor(props) {
        super(props);
        this.state = {
            resource: {},
            showInfoComplete: false,
            showInfoDateDiff: false
        };
    }

    onSave(){
        const {resource} = this.state
        if (resource.from && resource.to){
            this.setState({showInfoComplete: false});

            if (resource?.from < resource?.to){
                this.setState({showInfoDateDiff: false});
                this.props.actions.fetchHolidays(resource, this.props.onHide)
            }
            else{
                this.setState({showInfoDateDiff: true});
            }
        }
        else{
            this.setState({showInfoComplete: true})
        }
    }

    render() {
        const {resource} = this.state;
        return (
            <Modal
                animation={false}
                show={this.props.show}
                onHide={this.props.onHide}
                className={"heightAuto maxHeight90 modalLeadsFilterBody fontIOS"}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Fetch holidays</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            <form>
                                <Row>
                                    <Col md={6} className="mt10">
                                        <FormGroup>
                                            <ControlLabel>From</ControlLabel>
                                            <Datetime
                                                dateFormat={defaultDateFormat}
                                                timeFormat={false}
                                                value={resource.from && moment(resource.from).format(defaultDateFormat)}
                                                name={'from'}
                                                inputProps={{placeholder: "From"}}
                                                closeOnSelect
                                                onChange={this.dateResourceAttr("from")}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <ControlLabel>To</ControlLabel>
                                            <Datetime
                                                dateFormat={defaultDateFormat}
                                                timeFormat={false}
                                                value={resource.to && moment(resource.to).format(defaultDateFormat)}
                                                name={'to'}
                                                inputProps={{placeholder: "To"}}
                                                closeOnSelect
                                                onChange={this.dateResourceAttr("to")}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </form>
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Row>
                        <Col md={6} className="text-left" style={{color: "red"}}>
                            {this.state.showInfoComplete ? <span>Both From and To date must be selected.</span> : null}
                            {this.state.showInfoDateDiff ? <span>Date range can't be less than 1 day.</span> : null}
                        </Col>
                        <Col md={6}>
                            <button type="button" onClick={this.props.onHide} className="btn btn-default"
                                    data-dismiss="modal">Close
                            </button>
                            <button type="button" onClick={() => this.onSave()}
                                    className="btn btn-success">Save
                            </button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch),
});

export default connect(null, mapDispatchToProps)(FetchHolidaysModal)
