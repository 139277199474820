import React, {Component} from "react"
import {Button, Col, Grid, MenuItem, Nav, NavDropdown, Row,} from "react-bootstrap"
import {Link} from "react-router"
import {LinkContainer} from "react-router-bootstrap"
import * as Actions from "./actions"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"

import Select from "react-select"
import moment from "moment"
//demo map
import {GoogleMap, Marker} from "react-google-maps"
import {GoogleMapLoader} from "../../../components/GoogleMapLoader";
import Dollars from "../../../components/Dollars"
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table"
import AssetEditorModal from "../../AssetEditorModal"

import CustomerHeader from "../../../components/CustomerHeader"
import {getIcon} from "../../MapViewPage/IconProvider";
import {colors, mapForSelect, select} from "../../../common/commonHandlers";
import {getBasicAssetMarker, getBasicMarker} from "../../../utilities";

class WorkHistory extends Component {
    componentDidMount = () => {
        this.props.actions.fetchCustomerById(this.props.params.id)
    }

    componentWillReceiveProps = nextProps => {
        if (
            this.props.params.id &&
            nextProps.params.id &&
            this.props.params.id !== nextProps.params.id
        ) {
            this.props.actions.fetchCustomerById(nextProps.params.id)
        }
    }

    render() {
        const {
            customer,
            recentServices,
            proposalServiceStatuses,
            serviceStatus,
            customerSites,
            selectedSite,
            foundCustomers,
            sitesAssets,
            editingAssetId,
            selectedSiteInfo,
        } = this.props

        const customerId = this.props.params.id ? this.props.params.id : null

        if (customerId === null) {
            return (<span>I need a customerid parameter. I can't go on</span>)
        }
        if (customer === null) {
            return (<span>Loading...</span>)
        }
        return (
            <Grid fluid>
                <Row>
                    <Col md={12}>
                        <CustomerHeader
                            customerId={parseInt(customerId, 10)}
                            customerList={foundCustomers.map(customer => ({
                                value: customer.id,
                                label: customer.name,
                            }))}
                            onUpdateCustomerSearch={this.props.actions.updateCustomerSearch}
                            onSelectCustomer={this.props.actions.updateSelectedCustomer}
                            title="Work History"
                            salesArborist={customer.sales_arborist}
                            haveSites={customerSites?.length > 0}
                        />
                        {this._renderBillingAddress(customer)}
                        <br/>
                        {proposalServiceStatuses &&
                        customerSites && customer &&
                        this._renderFilterBar(
                            proposalServiceStatuses,
                            serviceStatus,
                            customerSites,
                            selectedSite,
                            customer,
                        )}
                        <br/>
                        {this._renderProposalMapUi(recentServices, sitesAssets)}
                        {selectedSiteInfo && this._resetMap(selectedSiteInfo)}
                    </Col>
                </Row>
                {editingAssetId !== null
                    ? <AssetEditorModal
                        editingAssetId={editingAssetId}
                        doneEditingAsset={this._cancelModal}
                        updateAssetAttribute={this.props.actions.updateAssetAttribute}
                        onSuccess={this._onSuccess}
                    />
                    : null}
            </Grid>
        )
    }

    _onSuccess = () => {
        this.props.actions.fetchCustomerAssets()
    }
    _cancelModal = siteId => {
        console.log("cancelling modal with site ", siteId)
        //this.props.actions.dismissAssetEditorModal()
        this.props.actions.doneEditingAsset(siteId) //dismiss the modal, reload mapview assets for site which the asset editor modal depends on to show correct data
        this.props.actions.fetchCustomerAssets() //refresh them in case
    }
    _renderBillingAddress = customer => (
        <div>
            <strong>Billing Address:</strong>
            {" "}
            {customer.address_1},
            {customer.address_2}{" "}
            {customer.city}, {customer.state}{" "}
            {customer.zip}
        </div>
    )
    _applyHistoryFilter = () => {
        this.props.actions.applyHistoryFilter()
    }
    _renderFilterBar = (proposalServiceStatuses,
                        serviceStatus,
                        customerSites,
                        selectedSite,
                        customer) => {
        const customerSitesOptions = mapForSelect(customerSites);
        const proposalServiceStatusesOptions = proposalServiceStatuses.map(x => ({value: x.code, label: x.label}));
        return (<Row>
            <Col md={2}>
                <Select className="Select" classNamePrefix="select"
                        name="select_site"
                        value={select(customerSitesOptions, selectedSite)}
                        options={customerSitesOptions
                            .sort((a, b) => a.label < b.label)}
                        onChange={this._updateSelectedSite}
                        placeholder="All Sites"
                />
            </Col>
            <Col md={2}>
                <Select className="Select" classNamePrefix="select"
                        value={select(proposalServiceStatusesOptions, serviceStatus)}
                        options={proposalServiceStatusesOptions}
                        onChange={this._updateServiceStatus}
                        placeholder="All Service Statuses"
                />
            </Col>
            <Col md={2}>
                <Button bsStyle="success" disabled={customerSites.length === 0} onClick={this._applyHistoryFilter}>
                    Filter
                </Button>
            </Col>
            <Col md={6} className="text-right">
                {customer && selectedSite ?
                    <LinkContainer to={`/mapview?customer_id=${customer.id}&site_id=${selectedSite}`}>
                        <Button bsStyle="primary">New Proposal</Button>
                    </LinkContainer>
                    :
                    null
                }
            </Col>
        </Row>)
    }

    _updateServiceStatus = selected => {
        this.props.actions.updateServiceStatus(
            selected === null ? null : selected.value
        )
    }
    _updateSelectedSite = selected => {
        this.props.actions.updateSelectedSite(
            selected === null ? null : selected.value
        )
    }
    _renderProposalMapUi = (recentServices, assets) => (
        <Row>
            <Col md={7} style={{height: "500px", overflow: "auto"}}>
                {this._renderServicesTable(recentServices)}
            </Col>
            <Col md={5}>
                {assets && assets.length
                    ? this._renderAssetMap(assets, this.props.client)
                    : <span>No assets to map</span>}
            </Col>
        </Row>
    )
    dataFormat = (field, row) => {
        const start = row.promised_date_start
        const end = row.promised_date_end
        if (!end || !start) return field
        if ((row.status === 'In Work Order') || (row.status === 'Accepted') || (row.status === 'Scheduled')) {
            return (
                <div>
                    <div>{field}</div>
                    <div className="text-warning">
                        {moment(start).format("MM/DD/YYYY")} - {moment(end).format("MM/DD/YYYY")}
                    </div>
                </div>
            )
        } else {
            return field
        }
    }
    _renderServicesTable = data => (
        <BootstrapTable
            data={data}
            striped={true}
            bordered={false}
            hover={true}
            search={true}
        >
            <TableHeaderColumn hidden dataField="id" isKey={true} dataSort={true}>
                ID
            </TableHeaderColumn>
            <TableHeaderColumn width="35%" dataField="service_name" dataSort={true} dataFormat={this.dataFormat}>
                Service
            </TableHeaderColumn>
            <TableHeaderColumn
                width="10%"
                dataField="proposal_no"
                dataFormat={this._linkFormatter}
                dataSort={true}
                columnClassName="menu-show-fixer"
            >
                Proposal #
            </TableHeaderColumn>
            <TableHeaderColumn
                width="20%"
                dataField="asset_ids"
                dataFormat={this._treeFormatter}
                dataSort={false}
                tdStyle={{whiteSpace: "normal"}}
            >
                Trees & Plants
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="status"
                dataSort={true}
                width="15%"
                dataFormat={this._stateFormatter}
                columnClassName="menu-show-fixer"
            >
                Status
            </TableHeaderColumn>
            <TableHeaderColumn
                width="10%"
                dataField="status_date"
                dataFormat={this._dateFormatter}
                dataSort={true}
            >
                Date
            </TableHeaderColumn>
            <TableHeaderColumn
                width="10%"
                dataField="cost"
                dataFormat={this._dollarFormatter}
                dataSort={true}
                dataAlign={"right"}
            >
                Cost
            </TableHeaderColumn>
        </BootstrapTable>
    )
    _treeFormatter = (cell, row) => {
        if (cell === [] || cell === null || this.props.sitesAssets === null) {
            return "..."
        }
        const things = cell.map(id => {
            const ass = this.props.sitesAssets.find(sa => {
                return parseInt(sa.id, 10) === parseInt(id, 10)
            })
            if (ass === undefined) {
                return <span>--</span>
            }
            return (
                <span key={ass.id}>
          <a
              style={{cursor: "pointer", textDecoration: 'none'}}
              onClick={() => this._onAssetClick(ass)}
          >
            {ass.label +
            ". " +
            ass.plant_name +
            (ass.plant_count > 1 ? " [" + ass.plant_count + "]" : "") +
            " " +
            ass.description}
          </a><br/>
        </span>
            )
        })
        return <div>{things}</div>
    }
    _dollarFormatter = cell => cell && <Dollars amount={cell}/>
    _dateFormatter = cell => cell && moment(cell).format("M/D/YY")
    _linkFormatter = (cell, row) => (
        <Nav>
            <NavDropdown
                title={cell || "..."}
                id={`proposal-menu-${row.proposal_id}`}
            >
                <LinkContainer to={`/mapview/${row.proposal_id}`}>
                    <MenuItem>Edit Proposal</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/proposal_service_status/${row.proposal_id}`}>
                    <MenuItem>Proposal Service Statuses</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/print_proposal/${row.proposal_id}`}>
                    <MenuItem>Print Proposal</MenuItem>
                </LinkContainer>
                <MenuItem divider/>
                <LinkContainer to={`/work_orders/${row.proposal_id}`}>
                    <MenuItem>Manage Work Orders</MenuItem>
                </LinkContainer>
                {row.work_order_no !== null ?
                    <LinkContainer to={`/print_work_orders/${row.work_order_no}`}>
                        <MenuItem>Print Work Orders</MenuItem>
                    </LinkContainer>
                    :
                    null
                }
            </NavDropdown>
        </Nav>
    )
    _stateFormatter = (cell, row) => {
        if (cell === "In Work Order") {
            return (
                <Nav>
                    <NavDropdown
                        bsSize="small"
                        bsStyle="default"
                        title={`WO #${row.work_order_no}` || "..."}
                        id={`proposal-menu-${row.proposal_id}`}
                        className="no-caret-in-dropdown"
                    >
                        <LinkContainer to={`/work_orders/${row.proposal_id}`}>
                            <MenuItem>Manage Work Orders</MenuItem>
                        </LinkContainer>
                        <LinkContainer to={`/print_work_orders/${row.work_order_no}`}>
                            <MenuItem>Print Work Order</MenuItem>
                        </LinkContainer>
                        <LinkContainer to={`/complete_work_orders/${row.work_order_no}`}>
                            <MenuItem>Complete Work Order</MenuItem>
                        </LinkContainer>
                    </NavDropdown>
                </Nav>
            )
        } else if (cell === "Invoiced") {
            return (
                <span>
          {
              <Link
                  to={`/customer/manage_invoices/${row.customer_id}?invoice=${row.invoice_id}`}

                  style={{textDecoration: 'none', color: '#d9534f'}}
              >
                  {`Invoiced #${row.invoice_no}`}
              </Link>
          }
        </span>
            )
        } else {
            return (<div>{cell}</div>)
        }
    }
    _onMarkerClick = id => {
        this.props.actions.onMarkerClick(id)
    }
    _onAssetClick = asset => {
        asset.selected = !asset.selected
        this._panTo(asset.latitude, asset.longitude)
        if (asset.selected) {
            this.props.actions.onAssetLocked(asset.id)
        } else {
            this.props.actions.onAssetUnlocked(asset.id)
        }
    }
    _panTo = (lat, lng) => {
        //window._googleMapComponent.props.map.setCenter({lat: lat, lng: lng})
        window._googleMapComponent.panTo &&
        window._googleMapComponent.panTo({lat: lat, lng: lng})
    }
    _resetMap = site => {
        if (site === null || site === undefined) {
            console.log("Not resetting map because site is not a useful object.")
            return false
        }
        console.log("resetting map to site ", site)
        window._googleMapComponent &&
        window._googleMapComponent.panTo &&
        window._googleMapComponent.panTo({
            lat: site.latitude,
            lng: site.longitude,
        })
        //this._googleMapComponent.setZoom(site.defautZoom)
    }
    _renderAssetMap = (assets, client) => (
        <GoogleMapLoader
            containerElement={
                <div
                    id="work_map_view"
                    style={{
                        height: "500px",
                        width: "100%",
                    }}
                />
            }
            googleMapElement={
                <GoogleMap
                    ref={it => (window._googleMapComponent = it)}
                    defaultZoom={20}
                    mapTypeId="hybrid"
                    tilt={0}
                    defaultCenter={
                        assets
                            ? {lat: assets[0].latitude, lng: assets[0].longitude}
                            : ((client && client.organization_longitude) ?
                            {
                                lat: client.organization_latitude,
                                lng: client.organization_longitude
                            }
                            : {
                                lat: 40.0,
                                lng: -105.0
                            })
                    }
                >
                    {assets.map(ass => (
                        <Marker
                            key={ass.id}
                            position={{lat: ass.latitude, lng: ass.longitude}}
                            icon={getBasicAssetMarker({asset: ass, label: ass.label})}
                            title={
                                ass.plant_name +
                                (ass.plant_count > 1 ? " [" + ass.plant_count + "]" : "")
                            }
                            onClick={() => this._onMarkerClick(ass.id)}
                        />
                    ))}
                </GoogleMap>
            }
        />
    )
}

const mapStateToProps = state => {
    return {
        customer: state.workHistory.customer,
        recentServices: state.workHistory.recentServices,
        proposalServiceStatuses: state.proposalServiceStatus
            .proposalServiceStatuses,
        serviceStatus: state.workHistory.serviceStatus,
        customerSites: state.workHistory.customerSites,
        selectedSite: state.workHistory.selectedSite,
        foundCustomers: state.workHistory.foundCustomers,
        sitesAssets: state.workHistory.sitesAssets,
        selectedSiteInfo: state.workHistory.selectedSiteInfo &&
            state.workHistory.selectedSiteInfo[
                `site_${state.workHistory.selectedSite}`
                ],
        editingAssetId: state.mapView.editingAssetId /*forasset editor modal*/,
        client: state.client.customerInfo
    }
}
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(WorkHistory)
