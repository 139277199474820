import {Col, ControlLabel, FormControl, FormGroup, Modal, Row, Radio} from "react-bootstrap";
import React from 'react';
import ResourceComponent from "../../components/ResourceComponent";
import Datetime from "react-datetime";
import {defaultDateFormat} from "../../common/commonHandlers";
import moment from "moment/moment";
import Dropzone from "react-dropzone";
import Attachment from "../../components/image/Attachment";
import {DocAttachment} from "../../components/docs/DocAttachment"
import HolidayRemovalModal from "./HolidayRemovalModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import './HolidayModal.scss'

const isMobile = window.screen.width <= 1024;

class HolidayModal extends ResourceComponent {

    state = {
        isRemovalModalOpened: false,
        inputProps: {
            placeholder: 'Date',
            disabled: this.props.holiday && this.props.holiday.is_custom? false : true
        },
        format: this.props.holiday && this.props.holiday.recurring ? 'MM/DD' : 'MM/DD/YYYY',
        disabledButton: false
     }

    valid = (current) => {
        const yesterday = moment().subtract(1, 'day');
        return current.isAfter(yesterday);
    };




    render() {
        const {holiday, images, uploadFile, removeImage, removeFile, rotateImage, docs, recurring} = this.props;
        const {isRemovalModalOpened, inputProps, format, disabledButton} = this.state;

        const AttachmentName = (file) => {
            return <a href={file.original} target="_blank" title="Open in a new window">
        <span className="attachment_name" target="_blank">
            {file?.created_at}
            {file?.name?.substring(0, 20)}
            {file?.name?.length > 20 && '...'}
        </span>
            </a>
        }

        return (
            <Modal
                animation={false}
                show={this.props.show}
                bsSize="large"
                onHide={this.props.onHide}
                className={"heightAuto maxHeight90 modalLeadsFilterBody fontIOS"}
                id="holiday-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{holiday && holiday.id ? `Edit mode` : 'New holiday'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            <form>
                                <Row>
                                    <Col md={6} className="mt10">
                                        <FormGroup>
                                            <ControlLabel>
                                                Name
                                            </ControlLabel>
                                            <FormControl
                                                name="name"
                                                placeholder="Name"
                                                onChange={this.props.onUpdateName}
                                                value={holiday && holiday.name}
                                                disabled={holiday && holiday.id && !holiday.is_custom}
                                            />
                                        </FormGroup>
                                        <ControlLabel>
                                            {holiday && holiday.id && holiday.is_custom ? 'Edit event type' : 'Date'}
                                        </ControlLabel>
                                        <FormGroup className='d-flex '
                                                   style={{
                                                       justifyContent: 'space-between',
                                                       alignItems: 'center',
                                                       gap: '20px'
                                                   }}>
                                            {holiday && holiday.id && !holiday.is_custom ||
                                                <div>
                                                    <Radio name="radioGroup"
                                                           id='oneTime'
                                                           checked={holiday && holiday.is_custom && !holiday.recurring}
                                                           onChange={(e) => {
                                                               this.props.onUpdateEventType(e);
                                                               this.setState({
                                                                   inputProps: {
                                                                       ...inputProps,
                                                                       disabled: false,
                                                                   },
                                                                   format: 'MM/DD/YYYY'
                                                               })
                                                           }}
                                                    >
                                                        One time
                                                    </Radio>
                                                    <Radio name="radioGroup"
                                                           id='recurring'
                                                           checked={holiday && holiday.is_custom && holiday.recurring}
                                                           onChange={(e) => {
                                                               this.props.onUpdateEventType(e);
                                                               this.setState({
                                                                   inputProps: {
                                                                       ...inputProps,
                                                                       disabled: false
                                                                   },
                                                                   format: 'MM/DD'
                                                               })
                                                           }
                                                           }>
                                                        Recurring
                                                    </Radio>
                                                </div>
                                            }

                                            <div style={{width: '100%'}}>
                                                <Datetime
                                                    timeFormat={false}
                                                    value={holiday && holiday.date && moment.utc(holiday.date).format(format) || !inputProps.disabled && moment().format(format) }
                                                    name={'oneTime'}
                                                    closeOnSelect
                                                    onChange={(e) => {
                                                        this.props.onUpdateDate(e)
                                                        this.valid(moment())
                                                    }}
                                                    inputProps={this.state.inputProps}
                                                    isValidDate={!recurring && this.valid}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    {holiday && holiday.id &&
                                        <Col md={6} className="mt10">
                                            <FormGroup>
                                                <ControlLabel>
                                                    Upload Image/Docs
                                                </ControlLabel>
                                                <Dropzone
                                                    disabled={!(holiday && holiday.id)}
                                                    onDrop={files => {
                                                        this.setState({disabledButton: true}, () => uploadFile(files, () => this.setState({disabledButton: false})))
                                                    }}
                                                    style={{height: !isMobile && 109}}
                                                    className="dropzone pointer small">
                                                    <div className="flex-column-center">
                                                        <p className="no-margin">Drop a file here or click to select a
                                                            file to upload.</p>
                                                        <span className="font10">(Maximum file size: 10mb)</span>
                                                    </div>
                                                </Dropzone>
                                            </FormGroup>
                                        </Col>}
                                </Row>
                                {(images && images.length > 0 || docs && docs.length > 0) &&
                                    <div className="bottom15">
                                        <hr className={'marginBottom10 top10'}/>
                                        {images && images.length > 0 &&
                                            <Row>
                                                <Col md={12}>
                                                    <div>
                                                        <p className="bottom15 bold">Images:</p>
                                                        <div className="images-container">
                                                        {images && images.map(i => <Attachment key={i.id}
                                                                                               image={i}
                                                                                               rotateImage={rotateImage}
                                                                                               allowRemove={true}
                                                                                               deleteImage={imageId => removeImage(imageId)}
                                                                                               showName={true}/>
                                                                                               )}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        }
                                        {(images && images.length > 0 && docs && docs.length > 0) &&
                                            <hr className={'marginBottom10 top15'}/>}
                                        {docs && docs.length > 0 &&
                                            <Row>
                                                <Col md={12}>
                                                    <div>
                                                        <p className="bottom15 bold">Documents:</p>
                                                        {docs && docs.map(doc => <DocAttachment key={doc.id}
                                                                                                file={doc}
                                                                                                renderName={true}
                                                                                                deleteDoc={docId => removeFile(docId)}
                                                        />)}
                                                    </div>
                                                </Col>
                                            </Row>
                                        }
                                    </div>
                                }
                            </form>
                        </Col>
                    </Row>
                </Modal.Body>
                {isRemovalModalOpened &&
                    <HolidayRemovalModal
                        title="Delete holiday"
                        show={isRemovalModalOpened}
                        onHide={() => this.setState({isRemovalModalOpened: false}, this.props.onDelete)}
                        closeButton={() => this.setState({isRemovalModalOpened: false})}
                    >
                        <div className="rate-modal-body-padding d-flex">
                            <FontAwesomeIcon icon={faExclamationTriangle}
                                             className={isMobile ? "font20 alert-font-color mt-3" : "small-margin font20 alert-font-color mt-3"}/>
                            <p className={isMobile ? "ml-10 font12 line_height17_5" : "ml-10 line_height17_5"}>
                                Deleting this holiday will remove all files it contains. {isMobile && <br/>} Are you
                                sure ?
                            </p>
                        </div>
                    </HolidayRemovalModal>
                }

                <Modal.Footer>
                    <Row className="d-flex">
                        <Col md={3} className="text-left">
                            <button
                                type="button"
                                onClick={() => {
                                    if (images && images.length > 0 || docs && docs.length > 0) {
                                        this.setState({isRemovalModalOpened: true})
                                    } else {
                                        this.props.onDelete()
                                    }
                                }}
                                className="btn btn-warning"
                                disabled={!holiday || (holiday && !holiday.id) || disabledButton}
                            >
                                Delete
                            </button>
                        </Col>
                        <Col md={9} className="full-width">
                            <button
                                type="button"
                                onClick={this.props.onHide}
                                className="btn btn-default"
                                data-dismiss="modal"
                                disabled={disabledButton}
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                onClick={this.props.onSave}
                                className="btn btn-success"
                                disabled={disabledButton}
                            >
                                Save
                            </button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default HolidayModal;
