import React, {Component} from 'react';
import PropTypes from "prop-types"
import {Button, Col, Grid, Row,} from "react-bootstrap"
import * as Actions from "./actions"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import Select from "react-select"
import moment from "moment"
import Dollars from "../../../components/Dollars"
import CustomerHeader from "../../../components/CustomerHeader"
import ProposalServicesList from "./ProposalServicesList"
import InvoiceServicesList from "./InvoiceServicesList"
import {LinkContainer} from "react-router-bootstrap";
import {mapForSelect, select} from "../../../common/commonHandlers";
import Datetime from "react-datetime";

class CustomerManageInvoices extends Component {

    constructor(props) {
        super(props);
        this.selectServices = this.selectServices.bind(this);

        // NOTE: do not remove initialInvoice
        let initialInvoice;
        if (this.props.location.query.invoice) {
            initialInvoice = {id: parseInt(this.props.location.query.invoice)};
        }
        this.state = {
            selectedProposalServices: [],
            showFilterRows: false,
            initialInvoice
        };
    }

    componentDidMount = () => {
        this.props.actions.fetchCustomerById(this.props.params.id)

        if (this.props.location.query.invoice) {
            // fetch invoice
            this.setState()
            this.props.actions.fetchInvoice(this.props.location.query.invoice)
            this.props.actions.fetchInvoiceProposalServicesInfo(this.props.location.query.invoice)
        }
    };

    componentWillReceiveProps = nextProps => {
        if (
            this.props.params.id &&
            nextProps.params.id &&
            this.props.params.id !== nextProps.params.id
        ) {
            this.props.actions.fetchCustomerById(nextProps.params.id)
        }

        if (nextProps.possibleInvoiceNo) {
            this.setState({
                invoiceNo: nextProps.possibleInvoiceNo
            })
        }

        // on selected proposal update we fetch proposal invoices

        if ((this.props.selectedProposal !== nextProps.selectedProposal) && this.props.selectedSite) {
            // selected site
            // selected proposal
            // customer Id
            const {
                selectedSite,
                params,
            } = this.props

            this.props.actions.updateInvoicesFromProposal(selectedSite,
                nextProps.selectedProposal ? nextProps.selectedProposal.value : null, params.id)
        }

        if (nextProps.invoice &&
            (!this.props.invoice || nextProps.invoice.id !== this.props.invoice.id)) {
            const newRoute = this.props.location.pathname + (nextProps.invoice ? `?invoice=${nextProps.invoice.id}` : '');
            this.context.router.push(newRoute);
        } else if (this.props.invoice && !nextProps.invoice && this.props.location.query.invoice) {
            this.context.router.push(this.props.location.pathname);
        }

        if(!this.props.invoice && !nextProps.invoice){
            this.setState({
                invoicePaidDate: undefined,
                invoicedDate: moment()
            });
        }

        // onloading invoice, we select site and proposal associated with invoice
        if ((!this.props.invoice && nextProps.invoice) ||
            (this.props.invoice && nextProps.invoice
                && this.props.invoice.id !== nextProps.invoice.id)) {
            // set invoicePaidDate
            this.setState({
                invoicePaidDate: nextProps.invoice.paid_at,
                invoicedDate: nextProps.invoice.invoiced_at
            });

            // NOTE: assign only once that's why we check for proposal id
            if (this.state.initialInvoice && !this.state.initialInvoice.proposal_id
                && nextProps.invoice.id === this.state.initialInvoice.id) {
                this.setState({initialInvoice: nextProps.invoice});
            }

            if (nextProps.invoice.site_id) {
                this.props.actions.updateSelectedSite(nextProps.invoice.site_id, nextProps.params.id)
            }

            if (nextProps.invoice.proposal_id) {
                this.props.actions.updateSelectedProposal({
                    value: nextProps.invoice.proposal_id,
                    label: nextProps.invoice.proposal_id,
                }, nextProps.invoice, nextProps.invoiceProposalServices);

                const {
                    params
                } = this.props;

                const customerId = params.id;

                // fetch invoices

                this.props.actions.updateInvoicesFromProposal(nextProps.invoice.site_id, nextProps.invoice.proposal_id, customerId)
            }
        }
    };

    // toggle filter rows
    toggleFilterRows = () => {
        this.setState({
            showFilterRows: !this.state.showFilterRows
        })
    };

    // select proposal services on propoal list
    selectServices(selected) {
        this.setState({
            selectedProposalServices: selected
        })
    }

    // update invoice list
    selectInvoiceServices() {
        const {proposalServices} = this.props
        const selectedProposalServices = proposalServices.filter(ps => this.state.selectedProposalServices.includes(ps.id))
        this.props.actions.selectInvoiceServices(selectedProposalServices)
        this.setState({
            selectedProposalServices: []
        })
    }

    // save invoiced date
    saveInvoicedDate = () => {
        const {
            invoicedDate
        } = this.state

        const {
            invoice
        } = this.props
        // call actions

        if (invoice) {
            this.props.actions.saveInvoicedDate(invoice.id, invoicedDate)
        }
    }

    // mark current invoice paid
    markInvoicePaid = () => {
        const {
            invoicePaidDate
        } = this.state

        const {
            invoice
        } = this.props
        // call actions

        // invoicePaid date can be null
        if (invoice) {
            this.props.actions.markInvoicesPaid([invoice.id], invoicePaidDate)
        }
    }

    render() {
        const {
            customer,
            customerSites,
            selectedSite,
            foundCustomers,
            invoice
        } = this.props;

        const customerId = this.props.params.id ? this.props.params.id : null;

        if (customerId === null) {
            return (<span>I need a customerid parameter. I can't go on</span>)
        }

        if (customer === null) {
            return (<span>Loading...</span>)
        }

        return (
            <Grid fluid>
                <Row>
                    <Col md={12}>
                        <CustomerHeader
                            customerId={parseInt(customerId, 10)}
                            customerList={foundCustomers.map(customer => ({
                                value: customer.id,
                                label: customer.name,
                            }))}
                            onUpdateCustomerSearch={this.props.actions.updateCustomerSearch}
                            onSelectCustomer={this.props.actions.updateSelectedCustomer}
                            title="Customer Invoices"
                            salesArborist={customer.sales_arborist}
                            haveSites={customerSites?.length > 0}
                        />
                    </Col>
                </Row>
                 <Row>
                    <Col md={6}>
                        {this._renderBillingAddress(customer)}
                    </Col>
                   <Col md={6}>
                     {invoice && invoice.invoice_no && <h4 className="text-success text-left">
                       Invoice #{invoice.invoice_no}
                       {" "}
                       <LinkContainer to={`/print_invoice/${invoice.id}`}>
                         <Button bsStyle="primary">Print</Button>
                       </LinkContainer>
                       </h4>}
                   </Col>
                </Row>
                <br/>
                <Row>
                    <Col md={4}>
                        {customerSites &&
                        this._renderFilterBar(
                            customerSites,
                            selectedSite
                        )}
                    </Col>
                    <Col md={2} className="text-right">
                        <strong className="text-info">Tel:</strong>
                        {" "}
                        {customer.phone_number}
                    </Col>
                    <Col md={2} className="text-left">
                        <strong className="text-info">Email:</strong>
                        {" "}
                        {customer.email}
                        <br/>
                      {invoice ? invoice.paid_at === null ? <h4 className="text-danger">{"NOT PAID"}</h4> : <h4 className="text-success">{"PAID"}</h4> : null}
                    </Col>
                    <Col md={4}>
                        {customerSites &&
                        this._renderBillingInfo()}
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col md={6}>
                        {this._renderProposalServices()}
                    </Col>
                    <Col md={6} className="invoice-border">
                        {this._renderInvoiceServices()}
                    </Col>
                </Row>
            </Grid>
        )
    }

    _renderBillingAddress = customer => (
        <div>
            <strong className="text-info">Billing Address:</strong>
            {" "}
            {customer.address_1},
            {customer.address_2}{" "}
            {customer.city}, {customer.state}{" "}
            {customer.zip}
        </div>
    );
    _renderFilterBar = (customerSites,
                        selectedSite) => {
        const customerSitesOptions = mapForSelect(customerSites);
        return (
            <Row>
                <Col md={10}>
                    <Select className="Select" classNamePrefix="select"
                        name="select_site"
                        value={select(customerSitesOptions, selectedSite)}
                        options={customerSitesOptions}
                        onChange={this._updateSelectedSite}
                        placeholder="All Sites"
                    />
                </Col>
            </Row>
        )
    };

    _renderBillingInfo = () => {
        const {invoicePaidDate} = this.state;
        const {
            invoice,
        } = this.props;

        return (
            <Row>
                <Col md={6} className="text-right">
                    <Button bsStyle="info" disabled={!invoice} onClick={() => this.markInvoicePaid()}>Update Paid
                        Date</Button>
                </Col>
                <Col md={6} className="text-left">
                    <Datetime
                        dateFormat="MM/DD/YYYY"
                        timeFormat={null}
                        value={invoicePaidDate ? moment(invoicePaidDate) : null}
                        inputProps={{placeholder: 'Paid Date'}}
                        onChange={m =>
                            this._onSingleSelect("invoicePaidDate", {
                                value: moment(m).isValid() ? moment(m).format() : null,
                            })}/>
                </Col>
            </Row>
        )
    };

    _updateSelectedSite = selected => {
        const customerId = this.props.params.id ? this.props.params.id : null;
        this.props.actions.updateSelectedSite(
            selected === null ? null : selected.value, customerId
        );
        // update invoice dropdowns
        const {
            params
        } = this.props;
        if (params.id && selected && selected.value) {
            this.props.actions.updateInvoicesFromProposal(selected.value, null, params.id)
        }
    }

    _onSingleSelect = (name, selected) => {
        this.setState({
            [name]: selected && selected.value
                ? selected.value
                : ["promisedStart", "promisedEnd"].includes(name) ? "" : null,
        })

        if (name === "selectedInvoiceType") {
            //if we change the service type then zero out the selected services too
            this.setState({selectedInvoice: []})
        }
    }

    cancelInvoice = () => {
        this.setState({
            selectedProposalServices: []
        })
    }

    createInvoice = () => {
        const {invoiceProposalServices, invoiceNoVerified} = this.props
        const psIds = invoiceProposalServices.map(ps => ps.id)

        let {
            invoiceNo,
            invoicedDate
        } = this.state

        const {
            params,
            selectedSite
        } = this.props

        const customerId = params.id

        if (!invoiceNoVerified) {
            invoiceNo = null
        }

        if (customerId && psIds) {
            this.props.actions.createInvoice(psIds, invoiceNo, invoicedDate, customerId, selectedSite)
        }

    }

    deleteInvoice = () => {
        const action_confirmed = window.confirm('Before you REALLY delete this invoice, double check you have also DELETED this invoice in Quickbooks.')

        if (action_confirmed) {
            const {
                selectedProposal,
                selectedSite
            } = this.props;
            const {invoice} = this.props;
            const proposalId = selectedProposal && selectedProposal.value;
            const customerId = this.props.params.id ? this.props.params.id : null;
            this.props.actions.deleteInvoice(invoice.id, customerId, proposalId, selectedSite);
        }
    }

    verifyInvoiceNumber = (invoiceNo) => {
        this.props.actions.verifyInvoiceNumber(invoiceNo)
    }

    dataFormat = (field, row) => {
        const start = row.promised_date_start
        const end = row.promised_date_end

        if (!end || !start) return field

        if ((row.status === 'In Work Order') || (row.status === 'Accepted') || (row.status === 'Scheduled')) {
            return (
                <div>
                    <div>{field}</div>
                    <div className="text-warning">
                        {moment(start).format("M/D/YYYY")} - {moment(end).format("M/D/YYYY")}
                    </div>
                </div>
            )
        } else {
            return field
        }
    }
    _renderProposalServices = () => {
        const {
            selectedProposal,
            proposals,
            proposalServices,
            selectedSite
        } = this.props;

        const {
            selectedProposalServices,
            showFilterRows,
            initialInvoice
        } = this.state;

        const customerId = this.props.params.id ? this.props.params.id : null;

        if (customerId === null) {
            return (<span>I need a customerid parameter. I can't go on</span>)
        }

        const proposalId = selectedProposal && selectedProposal.value

        let proposalOptions = proposals && proposals.map(proposal => ({
            value: proposal.id,
            label: proposal.proposalNo,
        }));

        // NOTE: do not remove initialInvoice
        // this is important to display right proposal if there are not completed proposals in proposal dropdown
        if (proposals && initialInvoice && selectedSite && selectedSite === initialInvoice.site_id) {
            const siteAlreadyInProposals = proposalOptions.some(p => p.value === initialInvoice.proposal_id);
            if(!siteAlreadyInProposals){
                proposalOptions.push({value: initialInvoice.proposal_id, label: initialInvoice.proposal_no});
            }
        }

        return (
            <Row>
                <Col md={12}>
                    <Row>
                        {proposals &&
                        <Col md={4}>
                            Proposal #
                            <Select className="Select" classNamePrefix="select"
                                value={select(proposalOptions, proposalId)}
                                options={proposalOptions}
                                onInputChange={this.props.actions.updateProposalSearch}
                                onChange={(x)=>this.props.actions.updateSelectedProposal(x)}
                                placeholder="Proposal #No"
                            />
                        </Col>
                        }
                        <Col md={2}>
                            <br/>
                            <Button bsStyle="warning" bsSize="small" onClick={this.toggleFilterRows}>Filter
                                Services</Button>
                        </Col>
                        <Col md={4} className="text-right">
                            <br/>
                            <Button bsStyle="success" bsSize="small" onClick={() => this.selectInvoiceServices()}>Add to
                                Invoice</Button>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={12}>
                            {proposalId && proposalServices && proposalServices.length ?
                            <ProposalServicesList
                                proposalServicesInfo={proposalServices}
                                selectedProposalServices={selectedProposalServices}
                                selectedPs={selectedProposalServices}
                                selectServices={this.selectServices}
                                showFilterRows={showFilterRows}
                            /> : 'No proposal services'
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    handleInvoiceSelect = invoice => {
        this.props.actions.updateSelectedInvoice(invoice);
    };

    _renderInvoiceServices = () => {
        const {
            customerInvoices,
            invoiceProposalServices,
            invoice
        } = this.props;


        let {
            invoicedDate
        } = this.state;


        const invoiceTotal = invoiceProposalServices ? invoiceProposalServices.reduce(
            (accumulator, proposalService) => (accumulator + parseFloat(proposalService.serviceCost)), 0) : 0

        const customerId = this.props.params.id ? this.props.params.id : null

        if (customerId === null) {
            return (<span>I need a customerid parameter. I can't go on</span>)
        }

        let invoiceId = null
        if (invoice) {
            invoiceId = invoice.id
        }

        const invoices = customerInvoices.map(invoice => ({
            value: invoice.id,
            label: invoice.invoice_no,
        }));
        return (
            <Row>
                <Col md={12}>
                    <Row>
                        {customerInvoices &&
                        <Col md={4}>
                            Invoice #
                            <Select className="Select" classNamePrefix="select"
                                value={select(invoices, invoiceId)}
                                options={invoices}
                                onInputChange={this.props.actions.updateInvoiceSearch}
                                onChange={this.handleInvoiceSelect}
                                placeholder="New Invoice"
                            />
                        </Col>
                        }
                        <Col md={6}>
                            <br/>
                            <Row className="vertical-align">
                                <Col md={6} className="text-right">
                                    <Button bsStyle="info"
                                            bsSize="xsmall"
                                            disabled={!invoice}
                                            onClick={() => this.saveInvoicedDate()}>
                                        Save Invoiced Date
                                    </Button>
                                </Col>
                                <Col md={6}>
                                    <Datetime
                                        dateFormat="MM/DD/YYYY"
                                        timeFormat={null}
                                        value={moment(invoicedDate).isValid() ? moment(invoicedDate) : null}
                                        inputProps={{placeholder: "Invoiced Date"}}
                                        onChange={m =>
                                            this._onSingleSelect("invoicedDate", {
                                                value: moment(m).isValid() ? moment(m).format() : null,
                                            })}/>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={2} className="text-right text-success">
                            <br/>
                            <br/>
                            Total <Dollars amount={invoiceTotal}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            {invoiceProposalServices && invoiceProposalServices.length > 0 &&
                            <InvoiceServicesList
                                proposalServicesInfo={invoiceProposalServices}
                                removePsFromInvoice={(row) => this.props.actions.removePsFromInvoice(row)}
                                disableRemovingSingleRecord={invoice}
                            />
                            }
                        </Col>
                    </Row>
                    {invoiceProposalServices && invoiceProposalServices.length > 0 &&
                    <Row>
                        <Col md={4}>
                        </Col>
                        <Col md={4}>
                            {!invoiceProposalServices.length &&
                            <Button bsStyle="danger" onClick={this.cancelInvoice}>Cancel</Button>
                            }
                            {!invoice &&
                            <Button bsStyle="success" onClick={this.createInvoice}>Create Invoice</Button>
                            }
                            {invoice &&
                            <Button bsStyle="danger" bsSize="xsmall" onClick={this.deleteInvoice}>Delete
                                Invoice</Button>
                            }
                        </Col>
                        <Col md={4} className="text-right">
                            <p className="text-success">
                                Total <Dollars amount={invoiceTotal}/>
                            </p>
                        </Col>
                    </Row>
                    }
                </Col>
            </Row>
        )
    }
}

CustomerManageInvoices.contextTypes = {
    router: PropTypes.object
};

const mapStateToProps = state => {
    return {
        customer: state.customerManageInvoices.customer,
        customerInvoices: state.customerManageInvoices
            .customerInvoices,
        customerSites: state.customerManageInvoices.customerSites,
        proposals: state.customerManageInvoices.proposals,
        proposalServices: state.customerManageInvoices.proposalServices,
        salesArborists: state.salesArborists.salesArboristsList,
        selectedSite: state.customerManageInvoices.selectedSite,
        selectedProposal: state.customerManageInvoices.selectedProposal,
        invoiceProposalServices: state.customerManageInvoices.invoiceProposalServices,
        foundCustomers: state.customerManageInvoices.foundCustomers,
        sitesAssets: state.customerManageInvoices.sitesAssets,
        invoice: state.customerManageInvoices.invoice,
        invoiceNoVerified: state.customerManageInvoices.invoiceNoVerified,
        possibleInvoiceNo: state.customerManageInvoices.possibleInvoiceNo
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerManageInvoices)
