import React, {Component} from 'react';
import PropTypes from "prop-types"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import {browserHistory} from "react-router"
import {addAlert} from "../App/actions"
import * as MyActions from "./actions"
import WoProposalServiceList from "./WoProposalServiceList"

import {Button, Col, Form, FormControl, FormGroup, Grid, Panel, Row} from "react-bootstrap"
import ProposalMenu from "../MapViewPage/new/ProposalMenu";
import {formatInlineDateOnly} from "../../common/commonHandlers";
import Dollars from "../../components/Dollars";
import _ from 'lodash'
import ConsumablesConsumptionModal from "../../common/ConsumablesConsumptionModal";
import './ChangeServiceStatusPage.scss'
import {debounce} from "throttle-debounce";
import './ChangeServiceStatusPage.scss'
import moment from "moment-timezone";
import MemoizedCompletedDate from "./MemoizedCompletedDate";

const Actions = {...MyActions, addAlert}


class ChangeServiceStatusPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.delayedUpdatePsDate = debounce(1000, this.updatePsDate);
        this.delayedCompleteAndUpdatePsDate = debounce(1000, this.completeAndUpdatePsDate);
        this.state = {
            remountKey: 0,
            globalCompletedDate: null,
            updateInProgress: false,
            consumableConsumptionModal: {
                show: false,
                psIds: [],
                proposalServices: {}
            },
        }
    }

    componentDidMount = () => {
        if (this.props.params && this.props.params.workOrderNo) {
            this.props.actions.updateWorkOrderNoSearchText(
                this.props.params.workOrderNo
            )
            this.props.actions.searchPsByWorkOrderNo()
        }
    }

    componentWillReceiveProps = nextProps => {
        if (
            nextProps.params.workOrderNo &&
            this.props.params.workOrderNo !== nextProps.params.workOrderNo
        ) {
            this.props.actions.updateWorkOrderNoSearchText(
                nextProps.params.workOrderNo
            )
            this.props.actions.searchPsByWorkOrderNo()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.psForThisWo, this.props.psForThisWo)) {
            this.setState({remountKey: this.state.remountKey + 1})
        }
    }

    onSearch = e => {
        e.preventDefault()
        this.props.actions.searchPsByWorkOrderNo()
    }

    totalAccepted = proposalServicesInfo =>
        proposalServicesInfo.reduce(
            (memo, ps) =>
                memo + (ps.proposalServiceStatus !== "Proposed" && ps.proposalServiceStatus !== "Not Offered" && ps.proposalServiceStatus !== "Declined" ? ps.serviceCost : 0),
            0
        )

    totalProposed = proposalServicesInfo =>
        proposalServicesInfo.reduce(
            (memo, ps) =>
                memo + ps.serviceCost,
            0
        )
    updatePsDate = (e) => {
        this.props.actions.updateCompletedDateInPs(this.allPsIds(), e, () => {
            this.setState({updateInProgress: false})
        })
    }
    completeAndUpdatePsDate = (e) => {
        this.props.actions.completeServices(this.elegiblePsToComplete().map((ps) => ps.id), true, () => {
            this.props.actions.updateCompletedDateInPs(this.allPsIds(), e, () => {
                this.setState({updateInProgress: false})
            })
        })
    }


    renderSearchForm = woNumberSearchText => (
        <div className={`top15`}>
            <Row className={`no-margin`}>
                <Col md={4}>
                    <div><strong className="small-margin">Find Work Orders</strong></div>
                    <Form inline onSubmit={this.onSearch} className={`remaining vertical-align`}>
                        <FormGroup bsSize="small">
                            <FormControl
                                value={woNumberSearchText}
                                className={`full-width`}
                                placeholder="Work Order No"
                                onChange={e =>
                                    this.props.actions.updateWorkOrderNoSearchText(e.target.value)}
                            />
                        </FormGroup>
                        {" "}
                        <Button type="submit" bsSize="small" bsStyle="info" className={`self-start`}>
                            Find Work Order
                        </Button>
                    </Form>
                </Col>
                <Col md={6} className={`remaining vertical-align`}>
                    <h5><strong>
                        Proposed On:
                        {" "}
                        {this.props && this.props.proposalInfo && formatInlineDateOnly(this.props.proposalInfo.proposalDate)}
                    </strong></h5>
                    <h5><strong>
                        Proposed:
                        {" "}
                        <Dollars
                            amount={this.totalProposed(this.props.proposalServicesInfo)}
                        />
                    </strong></h5>
                    <h5 className="text-success"><strong>
                        Accepted:
                        {" "}
                        <Dollars
                            amount={this.totalAccepted(this.props.proposalServicesInfo)}
                        />
                    </strong></h5>
                </Col>
            </Row>
        </div>
    )

    handleSelectAllChecked = e => {
        const psForThisWo = this.props.proposalServicesInfo.filter(
            ps => ps.workOrderNo === this.props.workOrder.work_order_no
        )

        psForThisWo.forEach(ps => {
            if (
                ["Not Scheduled", "Scheduled", "In Work Order"].includes(
                    ps.proposalServiceStatus
                )
            ) {
                this.props.actions.selectPs(e, ps.id)
            }
        })
    }

    elegiblePsToComplete = () =>
        this.props.proposalServicesInfo
            .filter(ps => ps.workOrderNo === this.props.workOrder.work_order_no)
            .filter(eps =>
                ["Not Scheduled", "Scheduled", "In Work Order"].includes(
                    eps.proposalServiceStatus
                )
            )

    allPsIds = () =>{
        return this.props.proposalServicesInfo
            .filter(ps => ps.workOrderNo === this.props.workOrder.work_order_no)
            .map(ps=>ps.id)
    }

    renderServiceHeader = () => (
        <Panel id='service-header'>
            <Row>
                <Col xs={1}>
                    <label>
                        <input
                            type="checkbox"
                            checked={
                                this.props.selectedPs.length ===
                                this.elegiblePsToComplete().length &&
                                this.props.selectedPs.length
                            }
                            onChange={this.handleSelectAllChecked}
                            disabled={this.elegiblePsToComplete().length === 0}
                        />
                        {" "}
                        #
                    </label>
                </Col>
                <Col xs={2}>
                    Service Description
                </Col>
                <Col xs={2}>
                    Trees & Plants
                </Col>
                <Col xs={2}>
                    Status
                </Col>
                <Col xs={1}>
                    Cost
                </Col>
                <Col xs={1}>
                    WO #
                </Col>
                <Col id='completed-at' xs={3}>
                    <span className="text-nowrap">Completed At</span>
                    <MemoizedCompletedDate
                        completedDate={moment(this.state.globalCompletedDate)}
                        updateInProgress={this.state.updateInProgress}
                        onChange={e => {
                            this.setState({globalCompletedDate: e, updateInProgress: true}, () => {
                                if (this.elegiblePsToComplete().length > 0) {
                                    this.delayedCompleteAndUpdatePsDate(e)
                                } else {
                                    this.delayedUpdatePsDate(e)
                                }

                            })
                        }}
                    />
                </Col>
            </Row>
        </Panel>
    )

    completeServices = (e) => {
        e.preventDefault()
        let {psForThisWo, selectedPs} = this.props
        if (selectedPs && selectedPs.length > 0) {
            selectedPs.map((psId, idx) => {
                this.props.actions.loadConsumables(psId, (res) => {
                    if (res.consumables && res.consumables.length > 0) {
                        let {psIds} = this.state.consumableConsumptionModal
                        psIds.push(psId)
                        this.setState({
                            consumableConsumptionModal: {
                                ...this.state.consumableConsumptionModal,
                                psIds: psIds
                            }
                        })
                        if (idx === selectedPs.length - 1) {
                            this.setState({
                                consumableConsumptionModal: {
                                    ...this.state.consumableConsumptionModal,
                                    show: true,
                                    proposalServices: psForThisWo
                                }
                            })
                        }
                    } else if (idx === selectedPs.length - 1) {
                        let {psIds} = this.state.consumableConsumptionModal
                        if (psIds.length > 0) {
                            this.setState({
                                consumableConsumptionModal: {
                                    ...this.state.consumableConsumptionModal,
                                    show: true,
                                    proposalServices: psForThisWo
                                }
                            })
                        } else {
                            this.props.actions.completeServices()
                        }
                    }
                })
            })
        } else {
            this.props.actions.completeServices()
        }
    }

    renderWoPsForm = proposalServicesInfo => (
        <form id='wo-ps-form' onSubmit={this.completeServices}>
            <Button type="submit" bsStyle="warning" bsSize="small">
                Mark Completed
            </Button>
            <WoProposalServiceList
                proposalServicesInfo={proposalServicesInfo}
                selectPs={this.props.actions.selectPs}
                selectedPs={this.props.selectedPs}
                globalCompletedDate={this.state.globalCompletedDate}
                setGlobalCompletedDate={(globalDate)=>this.setState({globalCompletedDate: globalDate})}
                updateInProgress={this.state.updateInProgress}
            />
            <Button type="submit" bsStyle="warning" bsSize="small">
                Mark Completed
            </Button>
        </form>
    )

    handleProposalIdChange = (id) => {
        browserHistory.push(`/mapview/${id}`)
    };

    render() {
        const {woNumberSearchText, proposalInfo, workOrder, psForThisWo} = this.props
        const {consumableConsumptionModal} = this.state

        return (
            <Grid fluid key={this.state.remountKey} id="change-service-status-page">
                {consumableConsumptionModal.show && <ConsumablesConsumptionModal
                    completeServices={this.props.actions.completeServices}
                    loadConsumables={this.props.actions.loadConsumables}
                    sendConsumablesConsumption={this.props.actions.sendConsumablesConsumption}
                    psIds={consumableConsumptionModal.psIds}
                    proposalServices={consumableConsumptionModal.proposalServices}
                    showModal={consumableConsumptionModal.show}
                    closeModal={() => {
                        this.setState({
                            consumableConsumptionModal: {
                                show: false,
                                psIds: [],
                                proposalServices: {}
                            }
                        })
                        this.props.actions.completeServices()
                    }}
                />}
                <ProposalMenu
                    id={this.props && this.props.workOrder && this.props.workOrder.proposal_id}
                    expandButton={false}
                    onReload={this.handleProposalIdChange}
                />
                <Row>
                    {this.renderSearchForm(woNumberSearchText)}
                </Row>

                <div id="wrapper">
                    {psForThisWo.length ? this.renderServiceHeader() : null}

                    {psForThisWo.length ? this.renderWoPsForm(psForThisWo) : null}
                </div>

            </Grid>
        )
    }
}

ChangeServiceStatusPage.propTypes = {
    woNumberSearchText: PropTypes.string.isRequired,
    workOrder: PropTypes.object,
    proposalServicesInfo: PropTypes.array,
    proposalInfo: PropTypes.object,
    selectedPs: PropTypes.array.isRequired,
    psForThisWo: PropTypes.array.isRequired,
}

const getPsForThisWo = (workOrder, proposalServicesInfo) => {
    if (workOrder === null || proposalServicesInfo.length === 0) {
        return []
    }
    return proposalServicesInfo.filter(
        ps => workOrder.work_order_no && ps.workOrderNo === workOrder.work_order_no
    )
}

const mapStateToProps = state => ({
    woNumberSearchText: state.changeServiceStatus.woNumberSearchText,
    workOrder: state.changeServiceStatus.workOrder,
    proposalInfo: state.workOrders.proposalInfo,
    proposalServicesInfo: state.workOrders.proposalServicesInfo,
    selectedPs: state.changeServiceStatus.selectedPs,
    psForThisWo: getPsForThisWo(
        state.changeServiceStatus.workOrder,
        state.workOrders.proposalServicesInfo
    ),
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(
    ChangeServiceStatusPage
)
