import React from "react"
import MasterDetail from "../../common/MasterDetail";
import {
    Button,
    Carousel,
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Grid,
    Image, InputGroup, Modal,
    OverlayTrigger,
    Row,
    Tab,
    Tabs,
    Tooltip
} from "react-bootstrap";
import {
    creatableSelect,
    defaultDateFormat,
    merge,
    reverseColor,
    saveNewCsvDownload,
    select,
    validateName
} from "../../common/commonHandlers";
import {bindActionCreators} from 'redux'
import createApiService from "../../common/clientActionsBuilder";
import {connect} from "react-redux";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ResourceComponent from "../../components/ResourceComponent";
import moment from "moment-timezone";
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import Select, {Creatable} from "react-select";
import Datetime from "react-datetime";
import {TruckModal} from "./TrucksModal";
import * as actions from "./TrucksApi";
import {addAlert} from "../App/actions";
import './Truck.scss'
import Dropzone from "react-dropzone";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    dateFormatter,
    dateNoTimeFormatter,
    dollarsFormatter
} from "../../components/BootStrapTableCellFormatters";
import {faExclamationCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import QRCode from "qrcode.react";
import {browserHistory} from "react-router";
import TrucksFilterModal from "./TrucksFilterModal";
import {trucksDataFilterModal} from "./constants";
import tableFilter from "../../common/tableFilter";
import documentIcon from '../../images/document.png'
import csvIcon from '../../images/csv.png'
import pdfIcon from '../../images/pdf.png'
import wordIcon from '../../images/word.png'
import txtIcon from '../../images/txt.png'
import zipIcon from '../../images/zip.png'
import xlsxIcon from '../../images/xlsx.png'
import InputGroupAddon from "react-bootstrap/lib/InputGroupAddon";
import Option from './Option'
import _ from 'lodash'
import ReorderingDragAndDrop from "../../components/ReorderingDragAndDrop/ReorderingDragAndDrop";
import {boxesPerRowReorderingDND, boxesPerRowReorderingDNDTrucks} from "../CustomerPage/CustomerInfo/constants";
import TruckFinancialTimeline from "./TruckFinancialTimeline";
import {utils, writeFileXLSX} from 'xlsx';
import TruckHistoryModal from "./TruckHistoryModal";

const isMobile = window.screen.width <= 1024;
const trucks = createApiService('trucks', 'truck', 'Truck');

const api = {
    ...trucks,
    ...actions,
    addAlert,
    saveNewCsvDownload
};
const documentIcons = {
    csv: csvIcon,
    pdf: pdfIcon,
    docx: wordIcon,
    txt: txtIcon,
    zip: zipIcon,
    xlsx: xlsxIcon
}

const colors = ['#F090BB', '#FBC2CD', '#FADD8D', '#82DEAB', '#93ECEA', '#3ECACB', '#00BFDC', '#9EA3E4', '#CA7EC4', '#FF6875', '#B6DEE7', '#FCD9DD', '#F9E2CF', '#F2CDAF', '#ED5D8F', '#FF98A8', '#FFB4C9', '#FFC89A', '#FF9D60', '#FF6875']

const termOptions = [{value: 1, label: 'Month'}, {value: 12, label: 'Year'}]

class Trucks extends ResourceComponent {
    defaultValues = {name: '', description: '', disabled: false, tanks_number: 0};
    state = {
        key: 0,
        isModalOpen: false,
        modalData: {},
        inputType: '',
        field: '',
        truckFilterModalShow: false,
        filter: {},
        shouldEdit: false,
        last_payment_date: null,
        shouldRecalculate: false,
        paymentAmountChanged: false,
        allowAssociationsEditing: false,
        truckSaved: false,
        showPaymentModal: false,
        paymentValidError: '',
        isServiceLoaded: false
    };

    tableRef = React.createRef()

    updateResources = resources => {
        this.setState({
            resources: resources.data,
            resources_all: resources,
            resource: resources.data.find(r => r.id === this.state.resource?.id) || this.state.resource,
            editService: this.state.shouldEdit
        }, () => {
            const {resources} = this.state
            this.setState({editService: !this.state.truckSaved})
            this.state.isModalOpen && this.setModalData(this.state.field, this.state.resources_all[`${this.state.field}${this.state.field.charAt(this.state.field.length - 1) === 's' ? 'es' : 's'}`], this.state.inputType)
            if(this.props.location?.query?.service_id) {
                resources.map(r => {
                    if (r.truck_services) {
                        r.truck_services.map(ts => {
                            if (ts.id.toString() === this.props.location?.query?.service_id){
                                this.setState({
                                    resource: r
                                }, ()=>{
                                    this.setState({
                                        editService: true,
                                        resource: {
                                            ...this.state.resource,
                                            truck_service_attributes: ts
                                        }
                                    })
                                })
                            }
                        })
                    }
                })
            }
        })
        if (window.location.href.includes('new_service')) {
            let stuff = window.location.href.split('/')
            let truck_id = +stuff[stuff.length - 2]
            if (this.props.roles.find(role => role === "admin")) {
                browserHistory.push({
                    pathname: '/trucks',
                    state: {
                        resource: truck_id ? {
                            ...resources.data.find(r => r.id === truck_id),
                            truck_service_attributes: {}
                        } : null,
                        key: 1,
                        first: true
                    }
                })
            } else {
                browserHistory.push(`/trucks/${truck_id}/new_service_page`)
            }
        }
        if (this.props.location.state?.first) {
            this.setState({
                resource: this.props.location.state.resource,
                key: this.props.location.state.key,
                editService: true
            })
            browserHistory.push({pathname: '/trucks', state: {first: false}})
        }
    };

    componentDidMount = () => {
        this.props.actions.load({}, this.updateResources);
    };
    reload = () => {
        this.props.actions.load({}, this.updateResources);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {resource, resources} = this.state;
        if ((prevState.resources !== resources) && resource && !_.isEqual(prevState.resources?.find(r => r.id === resource?.id), resources?.find(r => r.id === resource?.id))) {
            if (resources.length > prevState.resources.length) {
                resource.id = resource?.id ? resource.id : Math.max.apply(Math, resources.map((o) => o.id))
            }
            let updatedResource = _.cloneDeep(resources.find(r => r.id === resource?.id))
            let updatedToMerge = resources.find(r => r.id === resource?.id)
            const merged = _.merge({..._.omitBy(updatedToMerge, _.isNull)}, {..._.omitBy(prevState.resource, _.isNull)})
            updatedResource = {
                ...merged,
                audit_activity: updatedResource?.audit_activity,
                truck_financial_attributes: {
                    ...merged.truck_financial_attributes,
                    audit_activity: updatedResource?.truck_financial_attributes.audit_activity
                },
                truck_service_attributes: {
                    ...merged.truck_service_attributes,
                    service_records: updatedResource?.truck_services.find(s => s.id === merged.truck_service_attributes.id)?.service_records,
                    audit_activity: updatedResource?.truck_service_attributes.audit_activity
                },
                truck_financial_payments: updatedResource?.truck_financial_payments,
                truck_photos: updatedResource?.truck_photos,
                truck_services: updatedResource?.truck_services,
                truck_financial_files: updatedResource?.truck_financial_files,
                modified_at_date: updatedResource.modified_at_date,
                modified_by_name: updatedResource.modified_by_name
            }
            this.setState(() => ({resource: updatedResource}))
        }
    }

    resetResource = () => {
        this.setState({resource: null})
    }
    updateFinancialNotes = (notes) => {
        this.setState({resource: {...this.state.resource, truck_financial_notes: notes}})
    }

    handleSelect = e => {
        this.setState({
            resource: merge(e.resource, this.defaultValues),
            editService: false
        }, () => this.calculateLoanData());
    };
    handleTabSelect = (key) => {
        if (key === 'history') {
            this.setState({showHistory: true})
        } else {
            this.setState({key, editService: false});
        }
    }
    setModalData = (field, options, inputType) => {
        this.setState({
            modalData: {field: field, options: options ? options : []},
            inputType: inputType,
            field: field
        })
    }
    shouldRecalculate = () => {
        this.setState({
            shouldRecalculate: true,
            isCalendarOpen: undefined
        })
    }
    paymentAmountChanged = () => {
        this.setState({
            paymentAmountChanged: true
        })
    }

    addOption = (field, newOption, callback) => {
        if (field === 'truck_year') {
            newOption = {...newOption, year: newOption.label}
        } else if (field === 'truck_unit') {
            newOption = {...newOption, unit: newOption.label}
        } else {
            newOption = {...newOption, name: newOption.label}
        }
        newOption.color = newOption?.color ? newOption.color : colors[Math.floor(Math.random() * colors.length)]
        this.props.actions.addOption(field, newOption, (res) => {
            callback && callback(res)
            this.setState({truckSaved: false})
            this.reload()
        })
    }
    removeOption = (field, id,) => {
        this.props.actions.removeOption(field, id, () => {
            this.reload()
        })
    }
    updateOption = (field, id, option) => {
        this.props.actions.updateOption(field, id, option, () => {
            this.reload()
        })
    }
    updateOptionsOrder = (field, updatedOrder) => {
        this.props.actions.updateOptionsOrder(field, updatedOrder, () => {
            this.reload()
        })
    }

    closeModal = () => {
        this.setState({isModalOpen: false})
    }

    closeTruckFilterModal = () => {
        this.setState({truckFilterModalShow: false})
    }
    isEmptyValue = (value) => {
        if (value === undefined || value === null || value === 'NaN') {
            return ""
        } else {
            return value
        }
    }

    handleFilterData = (filteredData) => {
        const {resource, resources_all} = this.state

        this.setState({filter: filteredData})
        this.setState({
            resource: {
                ...resource, truck_services:
                    (tableFilter(resources_all.data.find(r => r.id === resource.id).truck_services, filteredData))
            }
        })
    }
    dollarFormat = value => {
        return value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
    }
    truckFinancialCSVFormatter = (data) => {
        Object.keys(data).forEach(key => {
            if (['id', 'created_at', 'updated_at', 'audit_activity', 'doc_order', 'number_of_completed_payments', 'truck_id'].includes(key)) {
                delete data[key]
            } else if (['origin_date', 'payment_date'].includes(key)) {
                data[key] = dateNoTimeFormatter(data[key])
            } else if (['financial_lender_id'].includes(key)) {
                data[key] = this.state.resources_all.financial_lenders.find(l => l.id === data[key])?.label || ''
            } else if (['term_period'].includes(key)) {
                data[key] = `${data[key] ? 'month' : 'N/A'}`
            } else if (['original_amount', 'down_payment', 'paid', 'balance', 'value', 'equity', 'payment_amount'].includes(key)) {
                data[key] = parseFloat(data[key]?.toString().replace(/[^0-9.-]/g, '')) || 0
            } else if (['notes'].includes(key)) {
                data[key] = this.state.resource.truck_financial_notes.map(n => n.notes).join(', ').replace(/<[^>]*>/g, '')
            } else {
                data[key] = data[key]
            }
        })
        return data
    }
    truckFinancialPaymentsCSVFormatter = (dataArray) => {
        dataArray.forEach(data => {
            Object.keys(data).forEach(key => {
                if (['id'].includes(key)) {
                    delete data[key]
                } else if (['payment_date'].includes(key)) {
                    data[key] = dateNoTimeFormatter(data[key])
                } else if (['payment_amount', 'interest', 'principal'].includes(key)) {
                    data[key] = parseFloat(data[key]?.toString().replace(/[^0-9.-]/g, '')) || 0
                } else {
                    data[key] = data[key]
                }
            })
        })
        return dataArray

    }
    createCustomToolBar = props => {
        return (
            <div className='table-toolbar'>
                <div className='buttons'>
                    {props.components.btnGroup}
                    <Button
                        bsStyle="success"
                        disabled={this.state.shouldRecalculate}
                        onClick={async () => {
                            const data = this.truckFinancialCSVFormatter(_.cloneDeep(this.state.resource?.truck_financial_attributes))
                            const data1 = this.truckFinancialPaymentsCSVFormatter(_.cloneDeep(this.state.resource.truck_financial_payments).filter(p => moment(p.payment_date).isBefore(moment())) || [])
                            const ws1 = utils.json_to_sheet([data]);
                            const ws2 = utils.json_to_sheet(data1);
                            const wb = utils.book_new();
                            utils.book_append_sheet(wb, ws1, "Truck Financial");
                            utils.book_append_sheet(wb, ws2, "Truck Financial Payments");

                            writeFileXLSX(wb, `truck-${this.state.resource.name}-financial-${moment().format(defaultDateFormat)}.xlsx`);

                            let blob = await fetch(writeFileXLSX(wb, `truck-${this.state.resource.name}-financial-${moment().format(defaultDateFormat)}.xlsx`)).then(r => r.blob())
                            blob.name = `truck-${this.state.resource.name}-financial-${moment().format()}.xlsx`
                            this.props.actions.saveDownloadedFile(blob, null, 'truck financial')
                        }}>
                        <i class="fa glyphicon glyphicon-export fa-download"/> Export to CSV
                    </Button>
                    <Button
                        bsStyle="info"
                        disabled={this.state.resource.truck_financial_payments.length < 1 || this.state.shouldRecalculate}
                        onClick={() => this.setState({
                            showPaymentModal: true,
                            resource: {
                                ...this.state.resource,
                                truck_financial_payment_attributes: {}
                            }
                        })}
                    >Add Payment</Button>
                    <Button
                        bsStyle="primary"
                        onClick={() => this.calculateLoanData()}
                        disabled={!this.calculateEnabled()}
                    >Recalculate</Button>{' '}
                    {(this.state.shouldRecalculate || this.state.paymentAmountChanged) &&
                        <OverlayTrigger placement="top"
                                        overlay={<Tooltip
                                            id="tooltip">
                                            The main data for calculating the installment has been changed. Recalculate
                                            button will regenerate the list of payments
                                        </Tooltip>}>
                            <FontAwesomeIcon
                                className="bigger pointer"
                                icon={faExclamationCircle}/>
                        </OverlayTrigger>}
                </div>

                {this.state.resource.truck_financial_attributes?.origin_date && <div><h4>Pay-off
                    date: {moment(this.state.resource.truck_financial_attributes?.origin_date).add(this.state.resource.truck_financial_attributes?.term_amount, 'months').format(defaultDateFormat)}</h4>
                </div>}
                <div>
                    {props.components.searchPanel}
                </div>
            </div>
        );
    }

    calculateLoanData = () => {
        const {resource, shouldRecalculate, paymentAmountChanged} = this.state
        if (resource.id) {
            let number_of_payments = +resource.truck_financial_attributes?.term_amount
            let interest_rate = parseFloat(resource.truck_financial_attributes?.interest_rate) ? (parseFloat(resource.truck_financial_attributes?.interest_rate) / 100) / 12 : 0
            let loan_amount = parseFloat(resource.truck_financial_attributes?.original_amount?.toString().replace(/[^0-9.-]/g, ''))
            let down_payment = resource.truck_financial_attributes?.down_payment ? parseFloat(resource.truck_financial_attributes?.down_payment?.toString().replace(/[^0-9.-]/g, '')) : 0
            loan_amount = loan_amount - down_payment
            let actualPaymentAmount = resource.truck_financial_attributes?.payment_amount && parseFloat(resource.truck_financial_attributes?.payment_amount?.toString().replace(/[^0-9.-]/g, ''))
            let calculatedPaymentAmount = (interest_rate && loan_amount) ? (((interest_rate * loan_amount) / (1 - Math.pow((1 + interest_rate), -number_of_payments))) * 100) / 100 : parseFloat(resource.truck_financial_attributes?.payment_amount?.toString().replace(/[^0-9.-]/g, ''))
            let paymentAmount = (!shouldRecalculate && actualPaymentAmount) ? actualPaymentAmount === (Math.round(calculatedPaymentAmount * 100) / 100) ? calculatedPaymentAmount : actualPaymentAmount : calculatedPaymentAmount
            let number_of_completed_payments = 0
            let next_payment_date = null
            if (resource.truck_financial_attributes?.origin_date) {
                number_of_completed_payments = Math.floor(moment().diff(moment(resource.truck_financial_attributes?.origin_date), 'months', true))
                number_of_completed_payments = number_of_completed_payments < number_of_payments ? number_of_completed_payments : number_of_payments
                number_of_completed_payments = number_of_completed_payments > 0 ? number_of_completed_payments : 0
                next_payment_date = moment(resource.truck_financial_attributes?.origin_date).add(number_of_completed_payments + 1, 'months')
                next_payment_date = number_of_completed_payments === number_of_payments ? null : next_payment_date
            }
            if (interest_rate === 0) {
                paymentAmount = loan_amount / number_of_payments
            }
            this.state.resource["truck_financial_attributes"]["payment_amount"] = this.dollarFormat(paymentAmount)

            this.state.resource["truck_financial_attributes"]["payment_date"] = next_payment_date
            this.state.resource["truck_financial_attributes"]["number_of_completed_payments"] = number_of_completed_payments


            let savedPayments = resource.truck_financial_payments
            this.state.resource["truck_financial_attributes"]["regenerate_payment_list"] = false
            if (shouldRecalculate || paymentAmountChanged) {
                savedPayments = []
                this.state.resource["truck_financial_attributes"]["regenerate_payment_list"] = true
            }
            let generatedPayments = []
            if (savedPayments.length < 1 && number_of_payments && loan_amount) {
                generatedPayments = [...Array(number_of_payments)].map((_, i) => {
                    let futureValue = loan_amount * Math.pow(1 + interest_rate, i)
                    let futureValueOfPayments = paymentAmount * ((Math.pow(1 + interest_rate, i) - 1) / interest_rate)
                    if (interest_rate === 0) {
                        futureValueOfPayments = paymentAmount
                    }
                    let balance = futureValue - futureValueOfPayments
                    let interest = balance * interest_rate
                    return {
                        payment_number: i + 1,
                        payment_amount: this.dollarFormat(paymentAmount),
                        payment_date: moment(resource.truck_financial_attributes?.origin_date).add(i, 'month'),
                        interest: interest,
                        principal: paymentAmount - interest
                    }
                })
            }
            let payment_numbers = new Set(savedPayments.map(sp => sp.payment_number));
            this.state.resource["truck_financial_payments"] = [...savedPayments, ...generatedPayments.filter(gp => !payment_numbers.has(gp.payment_number))].sort((a, b) => moment(b.payment_date).diff(moment(a.payment_date)))
            let calculatedPaid = resource.truck_financial_payments.filter(p => moment(p.payment_date).isBefore(moment())).reduce((acc, payment) => acc + parseFloat((payment.payment_amount || 0).toString().replace(/[^0-9.-]/g, '')), 0)
            let calculatedBalance = loan_amount - resource.truck_financial_payments.filter(p => moment(p.payment_date).isBefore(moment())).reduce((acc, payment) => acc + parseFloat((payment.principal || 0).toString().replace(/[^0-9.-]/g, '')), 0)
            let calculatedEquity = (resource.truck_financial_attributes?.value ? parseFloat(resource.truck_financial_attributes?.value.toString().replace(/[^0-9.-]/g, '')) : 0) - calculatedBalance
            this.state.resource["truck_financial_attributes"]["paid"] = this.dollarFormat(calculatedPaid)
            this.state.resource["truck_financial_attributes"]["balance"] = this.dollarFormat(calculatedBalance)
            this.state.resource["truck_financial_attributes"]["equity"] = this.dollarFormat(calculatedEquity)
            this.setState({resource: this.state.resource, shouldRecalculate: false, paymentAmountChanged: false});
        }
    }
    calculateEnabled = () => {
        const {resource} = this.state
        let calcuateEnabled = false

        if (resource.truck_financial_attributes?.origin_date && resource.truck_financial_attributes?.original_amount && resource.truck_financial_attributes?.term_amount && resource.truck_financial_attributes?.interest_rate) {
            calcuateEnabled = true
        }
        return calcuateEnabled
    }

    downloadQR = () => {
        const {resource} = this.state
        const canvas = document.getElementById(`truck-${resource.id}`);
        let url = canvas.toDataURL("image/png");
        let link = document.createElement("a");
        link.download = `truck-${resource.id}.png`;
        link.href = url;
        link.click();
    };

    dollarSortFunc = (a, b, order, name) => {
        if (order === 'desc') {
            return (parseFloat(a[name]?.toString().replace(/[^0-9.-]/g, '')) || 0) - (parseFloat(b[name]?.toString().replace(/[^0-9.-]/g, '')) || 0);
        } else {
            return (parseFloat(b[name]?.toString().replace(/[^0-9.-]/g, '')) || 0) - (parseFloat(a[name]?.toString().replace(/[^0-9.-]/g, '')) || 0);
        }
    }

    allowEditing = () => {
        this.setState({allowAssociationsEditing: !this.state.allowAssociationsEditing})
    }
    isValidDate = function (e) {
        return e.isBefore(moment());
    };
    isValidEditDate = (e, row) => {
        const {resource} = this.state
        let prevPayment = resource.truck_financial_payments[resource.truck_financial_payments.findIndex(tfp => tfp.payment_number === row.payment_number) + 1]?.payment_date || (resource.truck_financial_attributes?.origin_date && moment(resource.truck_financial_attributes?.origin_date))
        let nextPayment = resource.truck_financial_payments[resource.truck_financial_payments.findIndex(tfp => tfp.payment_number === row.payment_number) - 1]?.payment_date || moment()
        return e.isBefore(moment()) && e.isAfter(prevPayment) && e.isBefore(nextPayment)
    };
    validatePaymentAmount = (interest = null, paymentDate, callback) => {
        const {resource} = this.state
        let amount = parseFloat(resource.truck_financial_payment_attributes?.payment_amount.toString().replace(/[^0-9.-]/g, ''))
        let paymentInterest = interest
        if (!paymentInterest && paymentDate) {
            let copyPayments = _.cloneDeep(resource.truck_financial_payments)
            let payment = copyPayments.sort((a, b) => moment(a.payment_date).diff(moment(b.payment_date))).find(tfp => moment(tfp.payment_date).isSameOrAfter(moment(paymentDate).format("MM/DD/YYYY")))
            if (payment) {
                paymentInterest = parseFloat(payment.interest.toString().replace(/[^0-9.-]/g, ''))
            } else {
                payment = copyPayments[copyPayments.length - 1]
                paymentInterest = parseFloat(payment.interest.toString().replace(/[^0-9.-]/g, ''))
            }
        }
        if (!amount || amount < (Math.round(paymentInterest * 100) / 100)) {
            this.setState({paymentValidError: `Minimum payment amount is: ${Math.round(paymentInterest * 100) / 100}`})
        } else {
            this.setState({paymentValidError: ''})
            callback && callback()
        }
    }
    closePaymentModal = () => {
        this.setState({showPaymentModal: false, paymentValidError: ''}, () => this.calculateLoanData())
    }
    addPayment = () => {
        const {resource} = this.state
        let amount = parseFloat(resource.truck_financial_payment_attributes?.payment_amount.toString().replace(/[^0-9.-]/g, ''))

        let newTruckFinancialPayments = resource.truck_financial_payments.concat(resource.truck_financial_payment_attributes).sort((a, b) => moment(b.payment_date).diff(moment(a.payment_date))).map((tfp, index, arr) => {
            return {...tfp, payment_number: arr.length - index}
        })
        let paymentNumber = newTruckFinancialPayments.find(tfp => moment(tfp.payment_date).isSame(resource.truck_financial_payment_attributes?.payment_date) && !tfp.principal).payment_number
        newTruckFinancialPayments = newTruckFinancialPayments.map(p => {
            if (p.payment_number >= paymentNumber && (p.payment_number < newTruckFinancialPayments[0].payment_number)) {
                const matchingPayment = resource.truck_financial_payments.find(tfp => tfp.payment_number === p.payment_number)
                p.interest = matchingPayment.interest
                p.principal = parseFloat(p.payment_amount.toString().replace(/[^0-9.-]/g, '')) - parseFloat(matchingPayment.interest.toString().replace(/[^0-9.-]/g, ''))
                return p
            } else if (p.payment_number === newTruckFinancialPayments[0].payment_number) {
                return null
            } else {
                return p
            }
        }).filter(p => p)

        let payment = newTruckFinancialPayments.find(p => p.payment_number === paymentNumber)
        if (payment) {
            payment.payment_amount = amount
            payment.payment_date = resource.truck_financial_payment_attributes.payment_date
            payment.principal = amount - parseFloat(payment.interest.toString().replace(/[^0-9.-]/g, ''))
        } else {
            let interest_rate = (parseFloat(resource.truck_financial_attributes?.interest_rate) / 100) / 12
            let loan_amount = parseFloat(resource.truck_financial_attributes?.original_amount.toString().replace(/[^0-9.-]/g, ''))
            let down_payment = resource.truck_financial_attributes?.down_payment ? parseFloat(resource.truck_financial_attributes?.down_payment.toString().replace(/[^0-9.-]/g, '')) : 0
            loan_amount = loan_amount - down_payment
            let actualPaymentAmount = resource.truck_financial_attributes?.payment_amount && parseFloat(resource.truck_financial_attributes?.payment_amount.toString().replace(/[^0-9.-]/g, ''))
            let calculatedPaymentAmount = (((interest_rate * loan_amount) / (1 - Math.pow((1 + interest_rate), -resource.truck_financial_attributes.number_of_payments))) * 100) / 100
            let paymentAmount = actualPaymentAmount ? actualPaymentAmount === (Math.round(calculatedPaymentAmount * 100) / 100) ? calculatedPaymentAmount : actualPaymentAmount : calculatedPaymentAmount
            let futureValue = loan_amount * Math.pow(1 + interest_rate, paymentNumber - 1)
            let futureValueOfPayments = paymentAmount * ((Math.pow(1 + interest_rate, paymentNumber - 1) - 1) / interest_rate)
            let balance = futureValue - futureValueOfPayments
            let interest = balance * interest_rate
            resource.truck_financial_payments = [{
                ...resource.truck_financial_payment_attributes,
                interest: interest,
                principal: amount - interest
            }, ...resource.truck_financial_payments]
            resource.truck_financial_payments = resource.truck_financial_payments.sort((a, b) => moment(b.payment_date).diff(moment(a.payment_date))).map((tfp, index, arr) => {
                return {...tfp, payment_number: arr.length - index}
            })
        }
        this.setState({
            resource: {
                ...resource,
                truck_financial_payments: newTruckFinancialPayments
            }
        }, () => this.closePaymentModal())
    }
    deletePaymentRow = (row) => {
        const {resource} = this.state
        let reorderedPayment = resource.truck_financial_payments.filter(p => p.payment_number !== row.payment_number)
        reorderedPayment = reorderedPayment.map(p => {
            if (p.payment_number > row.payment_number) {
                p.payment_number = p.payment_number - 1
                p.principal = resource.truck_financial_payments[resource.truck_financial_payments.findIndex(tfp => tfp.payment_number === p.payment_number) + 1].principal
                p.interest = resource.truck_financial_payments[resource.truck_financial_payments.findIndex(tfp => tfp.payment_number === p.payment_number) + 1].interest
                return p
            } else {
                return p
            }
        })
        this.setState({
            resource: {
                ...resource,
                truck_financial_payments: reorderedPayment
            },
        }, this.calculateLoanData)
    }
    renderSizePerPageDropDown = ({currSizePerPage, sizePerPageList, changeSizePerPage}) => (
        <div className="btn-group dropup">
            <button className="btn btn-info dropdown-toggle" type="button" id="dropdownMenu2"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span>{currSizePerPage}</span>
                <span className="ml-6 caret"/>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                {sizePerPageList.map(({text, value}) =>
                    <li className='pointer'>
                        <a
                            className='table-pagination dropdown-item'
                            onClick={() => changeSizePerPage(value)}
                        >{text}</a>
                    </li>
                )}
            </ul>
        </div>
    );

    updatePrincipal = (row) => {
        let amount = parseFloat(row.payment_amount.toString().replace(/[^0-9.-]/g, ''))
        let interest = parseFloat(row.interest.toString().replace(/[^0-9.-]/g, ''))
        this.state.resource['truck_financial_payment_attributes']['principal'] = amount - interest;
        this.setState({resource: this.state.resource}, () => this.calculateLoanData());
    }
    isNameDuplicated = () => {
        const {resource, resources} = this.state;
        if (resource) {
            return resources.find(t => t.name.toLowerCase() === resource.name.toLowerCase() && t.id !== resource.id)
        }
    }

    setServiceLoaded = () => {
        this.setState({key:1, isServiceLoaded: true},()=>{
            browserHistory.push("trucks")
        })
    }

    render() {
        const {resource, resources, resources_all, allowAssociationsEditing} = this.state;

        return (
            resources ?
                <Grid fluid id='trucks' style={isMobile ? {padding: "unset"} : {}}>
                    <MasterDetail
                        resourceName=""
                        resourceNamePlural="Fleet"
                        resources={resources}
                        resource={resource}
                        resources_all={resources_all}
                        showParent={true}
                        resetResource={this.resetResource}
                        handleTabSelect={this.handleTabSelect}
                        buttonsBottom={true}
                        blockSave={(this.state.shouldRecalculate || this.state.paymentAmountChanged || this.isNameDuplicated())}
                        filtersData={trucksDataFilterModal}
                        saveNewCsvDownload={this.props.actions.saveNewCsvDownload}
                        select={this.handleSelect}
                        save={() => {
                            let validation = validateName(resource.name)
                            if (validation.is_valid) {
                                this.props.actions.save(resource, this.reload)
                                this.setState({truckSaved: true})
                            } else {
                                this.setState({errorMessage: validation.errorMessage})
                            }
                        }}
                        delete={() => this.props.actions.remove(resource, x => {
                            this.setState({resource: null}, () => this.props.actions.load({}, this.updateResources));
                        })}>
                        {resource &&
                            <Tabs activeKey={this.props.location?.query?.service_id && !this.state.isServiceLoaded ? 1 : this.state.key}
                                  id="controlled-tab-example"
                                  mountOnEnter={true}
                                  onSelect={this.handleTabSelect}
                                  animation={false} id="noanim-tab-example">
                                <Tab eventKey={0} title={`${resource.name || 'New'}`}>
                                    {this.state.key === 0 && <div>
                                        <Row>
                                            <Col md={12} lg={6} className='margin-top-bottom-20'>
                                                <FormGroup>
                                                    <ControlLabel>
                                                        <OverlayTrigger placement="top"
                                                                        overlay={<Tooltip id="tooltip">
                                                                            Last
                                                                            updated: {resource?.audit_activity && moment(JSON.parse(resource?.audit_activity)?.name).format('llll')}
                                                                        </Tooltip>}>
                                                            <span>Name</span>
                                                        </OverlayTrigger>
                                                    </ControlLabel>
                                                    <FormControl
                                                        name="name"
                                                        onChange={this.updateResourceAttr}
                                                        value={resource.name}
                                                    />
                                                    {this.isNameDuplicated() &&
                                                        <span className='text-danger'>Truck name already exists</span>}
                                                    {this.state.errorMessage && <p className="error-message-master-detail ">{this.state.errorMessage}</p>}
                                                </FormGroup>
                                                <Row>
                                                    <Col md={3}>
                                                        <FormGroup>
                                                            <ControlLabel>
                                                                <OverlayTrigger placement="top"
                                                                                overlay={<Tooltip id="tooltip">
                                                                                    Last
                                                                                    updated: {resource?.audit_activity && moment(JSON.parse(resource?.audit_activity)?.truck_year_id).format('llll')}
                                                                                </Tooltip>}>
                                                                    <a onClick={() => {
                                                                        this.setState({isModalOpen: true})
                                                                        this.setModalData("truck_year", resources_all.truck_years, "number")
                                                                    }}>Year</a>
                                                                </OverlayTrigger>
                                                            </ControlLabel>
                                                            <Creatable
                                                                isClearable
                                                                options={resources_all.truck_years}
                                                                value={creatableSelect(resources_all.truck_years, resource.truck_year_id)}
                                                                onChange={e => {
                                                                    let isnum = /^\d+$/.test(e?.label)
                                                                    if (isnum) {
                                                                        if (e?.label && !resources_all.truck_years.find(o => o.label === e.label)) {
                                                                            let newOption = {
                                                                                id: null,
                                                                                value: null,
                                                                                label: e.label,
                                                                                color: '',
                                                                                description: '',
                                                                                order: resources_all.truck_years.length
                                                                            }
                                                                            this.addOption('truck_year', newOption, (res) => {
                                                                                this.setState({
                                                                                    resource: {
                                                                                        ...resource,
                                                                                        truck_year_id: res.id
                                                                                    }
                                                                                })
                                                                            })
                                                                        } else {
                                                                            this.selectCreatableResourceAttr('truck_year_id')(e)
                                                                        }
                                                                    }
                                                                }}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <ControlLabel>
                                                                <OverlayTrigger placement="top"
                                                                                overlay={<Tooltip id="tooltip">
                                                                                    Last
                                                                                    updated: {resource?.audit_activity && moment(JSON.parse(resource?.audit_activity)?.truck_make_id).format('llll')}
                                                                                </Tooltip>}>
                                                                    <a onClick={() => {
                                                                        this.setState({isModalOpen: true})
                                                                        this.setModalData("truck_make", resources_all.truck_makes, "text")
                                                                    }}>Make</a>
                                                                </OverlayTrigger>
                                                            </ControlLabel>
                                                            <Creatable
                                                                isClearable
                                                                options={resources_all.truck_makes}
                                                                value={creatableSelect(resources_all.truck_makes, resource.truck_make_id)}
                                                                onChange={e => {
                                                                    if (e?.label && !resources_all.truck_makes.find(o => o.label === e.label)) {
                                                                        let newOption = {
                                                                            id: null,
                                                                            value: null,
                                                                            label: e.label,
                                                                            color: '',
                                                                            description: '',
                                                                            order: resources_all.truck_makes.length
                                                                        }
                                                                        this.addOption('truck_make', newOption, (res) => {
                                                                            this.setState({
                                                                                resource: {
                                                                                    ...resource,
                                                                                    truck_make_id: res.id
                                                                                }
                                                                            })
                                                                        })
                                                                    } else {
                                                                        this.selectCreatableResourceAttr('truck_make_id')(e)
                                                                    }
                                                                }}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={5}>
                                                        <FormGroup>
                                                            <ControlLabel>
                                                                <OverlayTrigger placement="top"
                                                                                overlay={<Tooltip id="tooltip">
                                                                                    Last
                                                                                    updated: {resource?.audit_activity && moment(JSON.parse(resource?.audit_activity)?.truck_model_id).format('llll')}
                                                                                </Tooltip>}>
                                                                    <a onClick={() => {
                                                                        this.setState({isModalOpen: true})
                                                                        this.setModalData("truck_model", resources_all.truck_models, "text")
                                                                    }}>Model</a>
                                                                </OverlayTrigger>
                                                            </ControlLabel>
                                                            <Creatable
                                                                isClearable
                                                                options={resources_all.truck_models}
                                                                value={creatableSelect(resources_all.truck_models, resource.truck_model_id)}
                                                                onChange={e => {
                                                                    if (e?.label && !resources_all.truck_models.find(o => o.label === e.label)) {
                                                                        let newOption = {
                                                                            id: null,
                                                                            value: null,
                                                                            label: e.label,
                                                                            color: '',
                                                                            description: '',
                                                                            order: resources_all.truck_models.length
                                                                        }
                                                                        this.addOption('truck_model', newOption, (res) => {
                                                                            this.setState({
                                                                                resource: {
                                                                                    ...resource,
                                                                                    truck_model_id: res.id
                                                                                }
                                                                            })
                                                                        })
                                                                    } else {
                                                                        this.selectCreatableResourceAttr('truck_model_id')(e)
                                                                    }
                                                                }}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={5}>
                                                        <FormGroup>
                                                            <ControlLabel>
                                                                <OverlayTrigger placement="top"
                                                                                overlay={<Tooltip id="tooltip">
                                                                                    Last
                                                                                    updated: {resource?.audit_activity && moment(JSON.parse(resource?.audit_activity)?.truck_type_id).format('llll')}
                                                                                </Tooltip>}>
                                                                    <a onClick={() => {
                                                                        this.setState({isModalOpen: true})
                                                                        this.setModalData("truck_type", resources_all.truck_types, "text")
                                                                    }}>Type</a>
                                                                </OverlayTrigger>
                                                            </ControlLabel>
                                                            <Creatable
                                                                isClearable
                                                                options={resources_all.truck_types}
                                                                value={creatableSelect(resources_all.truck_types, resource.truck_type_id)}
                                                                onChange={e => {
                                                                    if (e?.label && !resources_all.truck_types.find(o => o.label === e.label)) {
                                                                        let newOption = {
                                                                            id: null,
                                                                            value: null,
                                                                            label: e.label,
                                                                            color: '',
                                                                            description: '',
                                                                            order: resources_all.truck_types.length
                                                                        }
                                                                        this.addOption('truck_type', newOption, (res) => {
                                                                            this.setState({
                                                                                resource: {
                                                                                    ...resource,
                                                                                    truck_type_id: res.id
                                                                                }
                                                                            })
                                                                        })
                                                                    } else {
                                                                        this.selectCreatableResourceAttr('truck_type_id')(e)
                                                                    }
                                                                }}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <ControlLabel>
                                                                <OverlayTrigger placement="top"
                                                                                overlay={<Tooltip id="tooltip">
                                                                                    Last
                                                                                    updated: {resource?.audit_activity && moment(JSON.parse(resource?.audit_activity)?.truck_class_id).format('llll')}
                                                                                </Tooltip>}>
                                                                    <a onClick={() => {
                                                                        this.setState({isModalOpen: true})
                                                                        this.setModalData("truck_class", resources_all.truck_classes, "text")
                                                                    }}>Class</a>
                                                                </OverlayTrigger>
                                                            </ControlLabel>
                                                            <Creatable
                                                                isClearable
                                                                options={resources_all.truck_classes}
                                                                value={creatableSelect(resources_all.truck_classes, resource.truck_class_id)}
                                                                onChange={e => {
                                                                    if (e?.label && !resources_all.truck_classes.find(o => o.label === e.label)) {
                                                                        let newOption = {
                                                                            id: null,
                                                                            value: null,
                                                                            label: e.label,
                                                                            color: '',
                                                                            description: '',
                                                                            order: resources_all.truck_classes.length
                                                                        }
                                                                        this.addOption('truck_class', newOption, (res) => {
                                                                            this.setState({
                                                                                resource: {
                                                                                    ...resource,
                                                                                    truck_class_id: res.id
                                                                                }
                                                                            })
                                                                        })
                                                                    } else {
                                                                        this.selectCreatableResourceAttr('truck_class_id')(e)
                                                                    }
                                                                }}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={3}>
                                                        <FormGroup>
                                                            <ControlLabel>
                                                                <OverlayTrigger placement="top"
                                                                                overlay={<Tooltip id="tooltip">
                                                                                    Last
                                                                                    updated: {resource?.audit_activity && moment(JSON.parse(resource?.audit_activity)?.truck_unit_id).format('llll')}
                                                                                </Tooltip>}>
                                                                    <a onClick={() => {
                                                                        this.setState({isModalOpen: true})
                                                                        this.setModalData("truck_unit", resources_all.truck_units, "text")
                                                                    }}> Unit#</a>
                                                                </OverlayTrigger>
                                                            </ControlLabel>
                                                            <Creatable
                                                                isClearable
                                                                options={resources_all.truck_units}
                                                                value={creatableSelect(resources_all.truck_units, resource.truck_unit_id)}
                                                                onChange={e => {
                                                                    if (e?.label && !resources_all.truck_units.find(o => o.label === e.label)) {
                                                                        let newOption = {
                                                                            id: null,
                                                                            value: null,
                                                                            label: e.label,
                                                                            color: '',
                                                                            description: '',
                                                                            order: resources_all.truck_units.length
                                                                        }
                                                                        this.addOption('truck_unit', newOption, (res) => {
                                                                            this.setState({
                                                                                resource: {
                                                                                    ...resource,
                                                                                    truck_unit_id: res.id
                                                                                }
                                                                            })
                                                                        })
                                                                    } else {
                                                                        this.selectCreatableResourceAttr('truck_unit_id')(e)
                                                                    }
                                                                }}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={3}>
                                                        <FormGroup>
                                                            <ControlLabel>
                                                                <OverlayTrigger placement="top"
                                                                                overlay={<Tooltip id="tooltip">
                                                                                    Last
                                                                                    updated: {resource?.audit_activity && moment(JSON.parse(resource?.audit_activity)?.license_plate).format('llll')}
                                                                                </Tooltip>}>
                                                                    <span>License plate</span>
                                                                </OverlayTrigger>
                                                            </ControlLabel>
                                                            <FormControl
                                                                name="license_plate"
                                                                type="text"
                                                                onChange={this.updateResourceAttr}
                                                                value={resource.license_plate !== undefined ? resource.license_plate : ''}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <ControlLabel>
                                                                <OverlayTrigger placement="top"
                                                                                overlay={<Tooltip id="tooltip">
                                                                                    Last
                                                                                    updated: {resource?.audit_activity && moment(JSON.parse(resource?.audit_activity)?.expiration).format('llll')}
                                                                                </Tooltip>}>
                                                                    <span>Expiration</span>
                                                                </OverlayTrigger>
                                                            </ControlLabel>
                                                            <Datetime
                                                                dateFormat="MM/DD/YYYY"
                                                                isClearable
                                                                timeFormat={null}
                                                                closeOnSelect={true}
                                                                value={resource.expiration && moment(resource.expiration).format("MM/DD/YYYY")}
                                                                className="text-center"
                                                                inputProps={{placeholder: 'Expiration date'}}
                                                                onChange={this.dateResourceAttr('expiration')}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={5}>
                                                        <FormGroup>
                                                            <ControlLabel>
                                                                <OverlayTrigger placement="top"
                                                                                overlay={<Tooltip id="tooltip">
                                                                                    Last
                                                                                    updated: {resource?.audit_activity && moment(JSON.parse(resource?.audit_activity)?.vin).format('llll')}
                                                                                </Tooltip>}>
                                                                    <span>VIN</span>
                                                                </OverlayTrigger>
                                                            </ControlLabel>
                                                            <FormControl
                                                                name="vin"
                                                                type="text"
                                                                onChange={this.updateResourceAttr}
                                                                value={resource.vin !== undefined ? resource.vin : ''}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            {resource.id && <Col md={12} lg={6} className='margin-top-bottom-20'>
                                                {resource.truck_photos?.length > 0 && resource.truck_photos.filter(i => i.is_image).length > 0 &&
                                                    <Carousel variant="dark" interval={null}>
                                                        {
                                                            resource.truck_photos.filter(i => i.is_image).map((p, index) => (
                                                                    <Carousel.Item key={index}>
                                                                        <Image src={p.original} className='image-carousel'/>
                                                                    </Carousel.Item>
                                                                )
                                                            )
                                                        }
                                                    </Carousel>}
                                                <div className="dropzone_with_thumbnails">
                                                    <Dropzone
                                                        onDrop={files => this.props.actions.uploadTruckAttachment(resource.id, files, () => {
                                                            this.reload()
                                                        })} className="dropzone pointer border_none">
                                                        <p>Drop a file here or click to select a file to
                                                            upload.</p>
                                                    </Dropzone>
                                                    <ReorderingDragAndDrop
                                                        files={resource.truck_photos?.sort((a, b) => a.order - b.order)}
                                                        deleteAttachment={(tp) => this.props.actions.deleteTruckAttachment(tp, () => {
                                                            this.reload()
                                                        })}
                                                        reorderAttachments={(orderedFiles) => {
                                                            const order = JSON.stringify(orderedFiles.map((image) => image.id))
                                                            this.props.actions.reorderAttachment(order, resource.id,
                                                                () => {
                                                                    this.setState({
                                                                        resource: {
                                                                            ...resource,
                                                                            truck_photos: orderedFiles
                                                                        }
                                                                    })
                                                                })
                                                        }}
                                                        downloadButton={true}
                                                        numberOfColumns={boxesPerRowReorderingDNDTrucks}
                                                    />
                                                </div>
                                            </Col>}
                                        </Row>
                                        <Row>
                                            <Col xs={12} className="text-center">
                                                <h5>Truck Associations</h5>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        checked={allowAssociationsEditing}
                                                        onChange={this.allowEditing}
                                                    />
                                                    Allow Editing
                                                </label>
                                                <hr className="grey"/>

                                                <Row className={'bottom15'}>

                                                    <Col smOffset={4} f xs={4}>
                                                        <Select className="Select"
                                                                classNamePrefix="select"
                                                                menuPlacement="top"
                                                                value={select(resources_all.truck_equipments, resource.truck_equipments_ids)}
                                                                options={resources_all.truck_equipments}
                                                                placeholder="Equipment"
                                                                isClearable
                                                                isMulti
                                                                isDisabled={!allowAssociationsEditing}
                                                                onChange={e => this.selectResourceAttr('truck_equipments_ids')(e)}
                                                        />
                                                    </Col>

                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>}
                                </Tab>
                                {resource.id && <Tab eventKey={1} title="Service">
                                    {this.props.location?.query?.service_id && !this.state.isServiceLoaded && this.setServiceLoaded()}
                                    {this.state.key === 1 && !this.state.editService ? <div>
                                            <Row>
                                                <Col md={3} mdOffset={9} className='align-right margin-top-20'>
                                                    <Button
                                                        bsStyle="primary"
                                                        className={"no-top-margin small-margin"}
                                                        onClick={() => this.setState({
                                                            editService: true,
                                                            resource: {...resource, truck_service_attributes: {}}
                                                        })}
                                                    >New</Button>
                                                    <Button
                                                        className="pointer no-top-margin"
                                                        bsStyle="warning"
                                                        onClick={() => this.setState({
                                                            truckFilterModalShow: !this.state.truckFilterModalShow,
                                                            oldResource: {...resource}
                                                        })}>
                                                        Filter {" "}<FontAwesomeIcon icon="search"
                                                                                     className="small-margin"/>
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row><Col md={9} lg={10}>
                                                <BootstrapTable
                                                    ref={this.tableRef}
                                                    data={resource.truck_services}
                                                    striped={true}
                                                    bordered={false}
                                                    hover={true}
                                                    pagination={true}
                                                    exportCSV={true}
                                                    search={true}
                                                    height={!isMobile ? '500px' : ''}
                                                    options={{
                                                        page: 1,  //which page you want to show as default
                                                        sizePerPageList: [{
                                                            text: '10', value: 10
                                                        }, {
                                                            text: '25', value: 25
                                                        }, {
                                                            text: '30', value: 30
                                                        }, {
                                                            text: '50', value: 50
                                                        }],
                                                        //you can change the dropdown list for size per page
                                                        sizePerPage: 10,  //which size per page you want to locate as default
                                                        paginationSize: 3,  //the pagination bar size.
                                                        sizePerPageDropDown: this.renderSizePerPageDropDown,
                                                    }}
                                                    csvFileName={() => {
                                                        const fileName = `truck-${resource.name}-services-${moment().format()}.csv`
                                                        if (this.tableRef?.current) {
                                                            this.props.actions.saveNewCsvDownload(this.tableRef.current, fileName, this.state.filter, "truck")
                                                        }
                                                        return fileName
                                                    }}
                                                >

                                                    <TableHeaderColumn
                                                        width={120}
                                                        dataField="truck_service_status_id"
                                                        tdStyle={{whiteSpace: 'normal'}}
                                                        thStyle={{whiteSpace: 'normal'}}
                                                        dataSort={true}
                                                        dataFormat={(cell) => {
                                                            let status = resources_all.truck_service_statuses.find(s => s.id === cell)
                                                            return <span className='service-status'
                                                                         style={{
                                                                             backgroundColor: `${status?.color}`,
                                                                             color: `${reverseColor(status?.color)}`
                                                                         }}>{status?.label}</span> || ''
                                                        }}
                                                        csvFormat={(cell) => {
                                                            return resources_all.truck_service_statuses.find(s => s.id === cell)?.label || ''
                                                        }}
                                                    >
                                                        Status
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        width={100}
                                                        dataField="scheduled_date"
                                                        tdStyle={{whiteSpace: 'normal'}}
                                                        thStyle={{whiteSpace: 'normal'}}
                                                        dataSort={true}
                                                        dataFormat={dateNoTimeFormatter}
                                                        csvFormat={dateNoTimeFormatter}
                                                        columnClassName='menu-show-fixer'
                                                    >
                                                        Scheduled date
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        width={100}
                                                        dataField="details"
                                                        tdStyle={{whiteSpace: 'normal'}}
                                                        thStyle={{whiteSpace: 'normal'}}
                                                        dataSort={true}
                                                        columnClassName='menu-show-fixer'
                                                    >
                                                        Details
                                                    </TableHeaderColumn>

                                                    <TableHeaderColumn
                                                        width={100}
                                                        dataField="completed_date"
                                                        tdStyle={{whiteSpace: 'normal'}}
                                                        thStyle={{whiteSpace: 'normal'}}
                                                        dataSort={true}
                                                        dataFormat={dateFormatter}
                                                        csvFormat={dateFormatter}
                                                        columnClassName='menu-show-fixer'
                                                    >
                                                        Completed date
                                                    </TableHeaderColumn>

                                                    <TableHeaderColumn
                                                        width={100}
                                                        dataField="cost"
                                                        tdStyle={{whiteSpace: 'normal'}}
                                                        thStyle={{whiteSpace: 'normal'}}
                                                        dataSort={true}
                                                        sortFunc={this.dollarSortFunc}
                                                        columnClassName='menu-show-fixer'
                                                        dataFormat={(cell) => dollarsFormatter(cell ? cell.toString().replace(/[^0-9.-]/g, '') : cell)}
                                                    >
                                                        Cost
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        width={100}
                                                        dataField="reporter"
                                                        tdStyle={{whiteSpace: 'normal'}}
                                                        thStyle={{whiteSpace: 'normal'}}
                                                        dataSort={true}
                                                        columnClassName='menu-show-fixer'
                                                    >
                                                        Reporter
                                                    </TableHeaderColumn>

                                                    <TableHeaderColumn
                                                        width={100}
                                                        dataField="service_records"
                                                        tdStyle={{whiteSpace: 'normal'}}
                                                        thStyle={{whiteSpace: 'normal'}}
                                                        dataSort={true}
                                                        columnClassName='menu-show-fixer'
                                                        dataFormat={(cell) => {
                                                            return <>{cell?.sort((a, b) => a.order - b.order).map(c => <a
                                                                    href={c.original} target='_blank'>
                                                                    <Image className='table-image'
                                                                           src={documentIcons[c.document_type] || (c.is_image && c?.mini) || documentIcon}/>
                                                                </a>
                                                            )}</>
                                                        }}
                                                        csvFormat={(cell) => {
                                                            return cell?.map(c => c?.original)
                                                        }}
                                                    >
                                                        Records
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        width={100}
                                                        dataField="id"
                                                        tdStyle={{whiteSpace: 'normal'}}
                                                        thStyle={{whiteSpace: 'normal'}}
                                                        columnClassName='menu-show-fixer'
                                                        export={false}
                                                        dataFormat={(cell) => {
                                                            return <>
                                                            <span className='table-actions' onClick={() => {
                                                                this.setState({
                                                                    editService: true,
                                                                    resource: {
                                                                        ...resource,
                                                                        truck_service_attributes: resource.truck_services.find(s => s.id === cell)
                                                                    }
                                                                })
                                                            }}>Edit</span> {' '}
                                                                <span className='table-actions'
                                                                      onClick={() => this.props.actions.deleteTruckService(resource.id, cell, () => {
                                                                          resource.truck_services = resource.truck_services.filter(s => s.id !== cell)
                                                                          this.setState({resource: resource})
                                                                      })}>Delete</span>
                                                            </>
                                                        }}
                                                    >
                                                        Actions
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        hidden
                                                        dataField="id"
                                                        isKey={true}
                                                    >
                                                    </TableHeaderColumn>

                                                </BootstrapTable>
                                            </Col>
                                                <Col md={3} lg={2}>
                                                    <QRCode id={`truck-${resource.id}`}
                                                            className='d-block'
                                                            value={`https://${window.location.hostname}/trucks/${resource.id}/new_service`}
                                                            size={180}
                                                    />
                                                    <a onClick={this.downloadQR}>Download QR</a>
                                                </Col>
                                            </Row>
                                        </div> :
                                        <div>
                                            <Row>
                                                <Col md={6} className='margin-top-20'>
                                                    <Row>
                                                        <Col md={4}>
                                                            <FormGroup>
                                                                <ControlLabel>
                                                                    <OverlayTrigger placement="top"
                                                                                    overlay={<Tooltip id="tooltip">
                                                                                        Last
                                                                                        updated: {resource.truck_service_attributes?.audit_activity && moment(JSON.parse(resource.truck_service_attributes?.audit_activity)?.truck_service_status_id).format('llll')}
                                                                                    </Tooltip>}>
                                                                        <a onClick={() => {
                                                                            this.setState({isModalOpen: true})
                                                                            this.setModalData("truck_service_status", resources_all.truck_service_statuses, "text")
                                                                        }}> Status</a>
                                                                    </OverlayTrigger>
                                                                </ControlLabel>
                                                                <Creatable
                                                                    isClearable
                                                                    components={{Option}}
                                                                    options={resources_all.truck_service_statuses}
                                                                    value={creatableSelect(resources_all.truck_service_statuses, resource.truck_service_attributes?.truck_service_status_id)}
                                                                    onChange={e => {
                                                                        if (e?.label && !resources_all.truck_service_statuses.find(o => o.label === e.label)) {
                                                                            let newOption = {
                                                                                id: null,
                                                                                value: null,
                                                                                label: e.label,
                                                                                color: '',
                                                                                description: '',
                                                                                order: resources_all.truck_service_statuses.length
                                                                            }
                                                                            this.addOption('truck_service_status', newOption, (res) => {
                                                                                this.setState({
                                                                                    resource: {
                                                                                        ...resource,
                                                                                        truck_service_attributes: {
                                                                                            ...resource.truck_service_attributes,
                                                                                            truck_service_status_id: res.id
                                                                                        }
                                                                                    }
                                                                                })
                                                                            })
                                                                        } else {
                                                                            this.selectCreatableResourceNestedAttr('truck_service_attributes', 'truck_service_status_id')(e)
                                                                        }
                                                                    }}/>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={4}>
                                                            <FormGroup>
                                                                <ControlLabel>
                                                                    <OverlayTrigger placement="top"
                                                                                    overlay={<Tooltip id="tooltip">
                                                                                        Last
                                                                                        updated: {resource.truck_service_attributes?.audit_activity && moment(JSON.parse(resource.truck_service_attributes?.audit_activity)?.scheduled_date).format('llll')}
                                                                                    </Tooltip>}>
                                                                        <span>Scheduled date</span>
                                                                    </OverlayTrigger>
                                                                </ControlLabel>
                                                                <Datetime
                                                                    dateFormat="MM/DD/YYYY"
                                                                    isClearable
                                                                    timeFormat={null}
                                                                    closeOnSelect={true}
                                                                    value={resource.truck_service_attributes?.scheduled_date && moment(resource.truck_service_attributes?.scheduled_date).format("MM/DD/YYYY")}
                                                                    className="text-center"
                                                                    inputProps={{placeholder: 'Scheduled date'}}
                                                                    onChange={this.dateResourceNestedAttr('truck_service_attributes', 'scheduled_date')}/>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={4}>
                                                            <FormGroup>
                                                                <ControlLabel>
                                                                    <OverlayTrigger placement="top"
                                                                                    overlay={<Tooltip id="tooltip">
                                                                                        Last
                                                                                        updated: {resource.truck_service_attributes?.audit_activity && moment(JSON.parse(resource.truck_service_attributes?.audit_activity)?.completed_date).format('llll')}
                                                                                    </Tooltip>}>
                                                                        <span>Completed date</span>
                                                                    </OverlayTrigger>
                                                                </ControlLabel>
                                                                <Datetime
                                                                    dateFormat="MM/DD/YYYY"
                                                                    isClearable
                                                                    timeFormat={null}
                                                                    closeOnSelect={true}
                                                                    value={resource.truck_service_attributes?.completed_date && moment(resource.truck_service_attributes?.completed_date).format("MM/DD/YYYY")}
                                                                    className="text-center"
                                                                    inputProps={{placeholder: 'Completed date'}}
                                                                    onChange={this.dateResourceNestedAttr('truck_service_attributes', 'completed_date')}/>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={9}>
                                                            <FormGroup>
                                                                <ControlLabel>
                                                                    <OverlayTrigger placement="top"
                                                                                    overlay={<Tooltip id="tooltip">
                                                                                        Last
                                                                                        updated: {resource.truck_service_attributes?.audit_activity && moment(JSON.parse(resource.truck_service_attributes?.audit_activity)?.details).format('llll')}
                                                                                    </Tooltip>}>
                                                                        <span>Details</span>
                                                                    </OverlayTrigger>
                                                                </ControlLabel>
                                                                <FormControl
                                                                    name="truck_service_attributes"
                                                                    type="text"
                                                                    onChange={(e) => this.updateResourceNestedAttr(e, 'details')}
                                                                    value={this.isEmptyValue(resource.truck_service_attributes?.details)}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={3}>
                                                            <FormGroup>
                                                                <ControlLabel>
                                                                    <OverlayTrigger placement="top"
                                                                                    overlay={<Tooltip id="tooltip">
                                                                                        Last
                                                                                        updated: {resource.truck_service_attributes?.audit_activity && moment(JSON.parse(resource.truck_service_attributes?.audit_activity)?.cost).format('llll')}
                                                                                    </Tooltip>}>
                                                                        <span>Cost</span>
                                                                    </OverlayTrigger>
                                                                </ControlLabel>
                                                                <InputGroup>
                                                                    <InputGroupAddon>
                                                                        $
                                                                    </InputGroupAddon>
                                                                    <FormControl
                                                                        name="truck_service_attributes"
                                                                        type="text"
                                                                        onChange={(e) => this.updateResourceNestedAttr(e, 'cost')}
                                                                        onBlur={(e) => this.dollarFormatNestedAttr(e, 'cost')}
                                                                        value={this.isEmptyValue(resource.truck_service_attributes?.cost)}
                                                                    />
                                                                </InputGroup>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                {resource.truck_service_attributes?.id &&
                                                    <Col md={6} className='margin-top-bottom-20'>
                                                        <div className="dropzone_with_thumbnails">
                                                            <Dropzone
                                                                onDrop={files => this.props.actions.uploadTruckServiceAttachment(resource.truck_service_attributes.id, files, () => {
                                                                    this.reload()
                                                                })} className="dropzone pointer border_none">
                                                                <p>Drop a file here or click to select a file to
                                                                    upload.</p>
                                                            </Dropzone>
                                                            <ReorderingDragAndDrop
                                                                files={resource.truck_service_attributes?.service_records?.sort((a, b) => a.order - b.order)}
                                                                deleteAttachment={(sr) => this.props.actions.deleteTruckServiceAttachment(sr, () => {
                                                                    this.reload()
                                                                })}
                                                                reorderAttachments={(orderedFiles) => {
                                                                    const order = JSON.stringify(orderedFiles.map((image) => image.id))
                                                                    this.props.actions.reorderServiceAttachment(order, resource.truck_service_attributes.id,
                                                                        () => {
                                                                            this.setState({
                                                                                resource: {
                                                                                    ...resource,
                                                                                    truck_service_attributes: {
                                                                                        ...resource.truck_service_attributes,
                                                                                        service_records: orderedFiles
                                                                                    }
                                                                                }
                                                                            })
                                                                        })
                                                                }}
                                                                downloadButton={true}
                                                                numberOfColumns={boxesPerRowReorderingDNDTrucks}
                                                            />
                                                        </div>
                                                    </Col>}
                                            </Row>
                                        </div>
                                    }
                                </Tab>}
                                {resource.id && <Tab eventKey={2} title="Financial">
                                    {this.state.key === 2 && <div>
                                        <Row className='margin-top-20'>
                                            <Col lg={8} md={12}>
                                                <Row>
                                                    <Col md={3}>
                                                        <FormGroup>
                                                            <ControlLabel>
                                                                <OverlayTrigger placement="top"
                                                                                overlay={<Tooltip id="tooltip">
                                                                                    Last
                                                                                    updated: {resource.truck_financial_attributes?.audit_activity && moment(JSON.parse(resource.truck_financial_attributes?.audit_activity)?.financial_lender_id).format('llll')}
                                                                                </Tooltip>}>
                                                                    <a onClick={() => {
                                                                        this.setState({isModalOpen: true})
                                                                        this.setModalData("financial_lender", resources_all.financial_lenders, "text")
                                                                    }}>Lender</a>
                                                                </OverlayTrigger>
                                                            </ControlLabel>
                                                            <Creatable
                                                                isClearable
                                                                styles={{
                                                                    menuPortal: (provided) => ({
                                                                        ...provided,
                                                                        zIndex: 9999
                                                                    }),
                                                                    menu: (provided) => ({...provided, zIndex: 9999})
                                                                }}
                                                                options={resources_all.financial_lenders}
                                                                value={creatableSelect(resources_all.financial_lenders, resource.truck_financial_attributes?.financial_lender_id)}
                                                                onChange={e => {
                                                                    if (e?.label && !resources_all.financial_lenders.find(o => o.label === e.label)) {
                                                                        let newOption = {
                                                                            id: null,
                                                                            value: null,
                                                                            label: e.label,
                                                                            color: '',
                                                                            description: '',
                                                                            order: resources_all.financial_lenders.length
                                                                        }
                                                                        this.addOption('financial_lender', newOption, (res) => {
                                                                            this.setState({
                                                                                resource: {
                                                                                    ...resource,
                                                                                    truck_financial_attributes: {
                                                                                        ...resource.truck_financial_attributes,
                                                                                        financial_lender_id: res.id
                                                                                    }
                                                                                }
                                                                            })
                                                                        })
                                                                    } else {
                                                                        this.selectCreatableResourceNestedAttr('truck_financial_attributes', 'financial_lender_id')(e)
                                                                    }
                                                                }}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={2}>
                                                        <FormGroup>
                                                            <ControlLabel>
                                                                <OverlayTrigger placement="top"
                                                                                overlay={<Tooltip id="tooltip">
                                                                                    Last
                                                                                    updated: {resource.truck_financial_attributes?.audit_activity && moment(JSON.parse(resource.truck_financial_attributes?.audit_activity)?.loan).format('llll')}
                                                                                </Tooltip>}>
                                                                    <span>Loan/Contract #</span>
                                                                </OverlayTrigger>
                                                            </ControlLabel>
                                                            <FormControl
                                                                name="truck_financial_attributes"
                                                                onChange={(e) => this.updateResourceNestedAttr(e, 'loan')}
                                                                value={this.isEmptyValue(resource.truck_financial_attributes?.loan)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={2}>
                                                        <FormGroup>
                                                            <ControlLabel>
                                                                <OverlayTrigger placement="top"
                                                                                overlay={<Tooltip id="tooltip">
                                                                                    Last
                                                                                    updated: {resource.truck_financial_attributes?.audit_activity && moment(JSON.parse(resource.truck_financial_attributes?.audit_activity)?.original_amount).format('llll')}
                                                                                </Tooltip>}>
                                                                    <span>Original Amount</span>
                                                                </OverlayTrigger>
                                                            </ControlLabel>
                                                            <InputGroup>
                                                                <InputGroupAddon>
                                                                    $
                                                                </InputGroupAddon>
                                                                <FormControl
                                                                    type='text'
                                                                    name="truck_financial_attributes"
                                                                    onChange={(e) => this.updateResourceNestedAttr(e, 'original_amount', this.shouldRecalculate)}
                                                                    onBlur={(e) => this.dollarFormatNestedAttr(e, 'original_amount')}
                                                                    value={this.isEmptyValue(resource.truck_financial_attributes?.original_amount)}
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={2}>
                                                        <FormGroup>
                                                            <ControlLabel>
                                                                <OverlayTrigger placement="top"
                                                                                overlay={<Tooltip id="tooltip">
                                                                                    Last
                                                                                    updated: {resource.truck_financial_attributes?.audit_activity && moment(JSON.parse(resource.truck_financial_attributes?.audit_activity)?.down_payment).format('llll')}
                                                                                </Tooltip>}>
                                                                    <span>Down Payment</span>
                                                                </OverlayTrigger>
                                                            </ControlLabel>
                                                            <InputGroup>
                                                                <InputGroupAddon>
                                                                    $
                                                                </InputGroupAddon>
                                                                <FormControl
                                                                    type='text'
                                                                    name="truck_financial_attributes"
                                                                    onChange={(e) => this.updateResourceNestedAttr(e, 'down_payment', this.shouldRecalculate)}
                                                                    onBlur={(e) => this.dollarFormatNestedAttr(e, 'down_payment')}
                                                                    value={this.isEmptyValue(resource.truck_financial_attributes?.down_payment)}
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={3}>
                                                        <FormGroup>
                                                            <ControlLabel>
                                                                <OverlayTrigger placement="top"
                                                                                overlay={<Tooltip id="tooltip">
                                                                                    Last
                                                                                    updated: {resource.truck_financial_attributes?.audit_activity && moment(JSON.parse(resource.truck_financial_attributes?.audit_activity)?.origin_date).format('llll')}
                                                                                </Tooltip>}>
                                                                    <span>First Payment Date</span>
                                                                </OverlayTrigger>
                                                            </ControlLabel>
                                                            <Datetime
                                                                dateFormat="MM/DD/YYYY"
                                                                isClearable
                                                                timeFormat={null}
                                                                closeOnSelect={true}
                                                                value={resource.truck_financial_attributes?.origin_date && moment(resource.truck_financial_attributes?.origin_date).format("MM/DD/YYYY")}
                                                                className="text-center"
                                                                inputProps={{placeholder: 'First Payment Date'}}
                                                                onChange={this.dateResourceNestedAttr('truck_financial_attributes', 'origin_date', this.shouldRecalculate)}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={3}>
                                                        <FormGroup>
                                                            <ControlLabel>
                                                                <OverlayTrigger placement="top"
                                                                                overlay={<Tooltip id="tooltip">
                                                                                    Last
                                                                                    updated: {resource.truck_financial_attributes?.audit_activity && moment(JSON.parse(resource.truck_financial_attributes?.audit_activity)?.term_amount).format('llll')}
                                                                                </Tooltip>}>
                                                                    <span>Term Amount</span>
                                                                </OverlayTrigger>
                                                            </ControlLabel>
                                                            <InputGroup>

                                                                <FormControl
                                                                    className='term-input'
                                                                    type='number'
                                                                    step={1}
                                                                    min={1}
                                                                    name="truck_financial_attributes"
                                                                    onChange={(e) => this.updateResourceNestedAttr(e, 'term_amount', this.shouldRecalculate)}
                                                                    value={this.isEmptyValue(resource.truck_financial_attributes?.term_amount)}
                                                                />
                                                                <InputGroupAddon>
                                                                    Month
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={2}>
                                                        <FormGroup>
                                                            <ControlLabel>
                                                                <OverlayTrigger placement="top"
                                                                                overlay={<Tooltip id="tooltip">
                                                                                    Last
                                                                                    updated: {resource.truck_financial_attributes?.audit_activity && moment(JSON.parse(resource.truck_financial_attributes?.audit_activity)?.interest_rate).format('llll')}
                                                                                </Tooltip>}>
                                                                    <span>Interest Rate</span>
                                                                </OverlayTrigger>
                                                            </ControlLabel>
                                                            <FormControl
                                                                type='text'
                                                                name="truck_financial_attributes"
                                                                onChange={(e) => this.updateResourceNestedAttr(e, 'interest_rate', this.shouldRecalculate)}
                                                                onBlur={(e) => this.percentFormatNestedAttr(e, 'interest_rate')}
                                                                value={this.isEmptyValue(resource.truck_financial_attributes?.interest_rate)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <ControlLabel>
                                                                <OverlayTrigger placement="top"
                                                                                overlay={<Tooltip id="tooltip">
                                                                                    Last
                                                                                    updated: {resource.truck_financial_attributes?.audit_activity && moment(JSON.parse(resource.truck_financial_attributes?.audit_activity)?.payment_amount).format('llll')}
                                                                                </Tooltip>}>
                                                                    <span>Payment Amount</span>
                                                                </OverlayTrigger>
                                                            </ControlLabel>
                                                            <InputGroup>
                                                                <InputGroupAddon>
                                                                    $
                                                                </InputGroupAddon>
                                                                <FormControl
                                                                    type='text'
                                                                    name="truck_financial_attributes"
                                                                    onChange={(e) => this.updateResourceNestedAttr(e, 'payment_amount', this.paymentAmountChanged)}
                                                                    onBlur={(e) => this.dollarFormatNestedAttr(e, 'payment_amount')}
                                                                    value={this.isEmptyValue(resource.truck_financial_attributes?.payment_amount)}
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={3}>
                                                        <FormGroup>
                                                            <ControlLabel>
                                                                <OverlayTrigger placement="top"
                                                                                overlay={<Tooltip id="tooltip">
                                                                                    Last
                                                                                    updated: {resource.truck_financial_attributes?.audit_activity && moment(JSON.parse(resource.truck_financial_attributes?.audit_activity)?.payment_date).format('llll')}
                                                                                </Tooltip>}>
                                                                    <span>Next Payment date</span>
                                                                </OverlayTrigger>
                                                            </ControlLabel>
                                                            <Datetime
                                                                dateFormat="MM/DD/YYYY"
                                                                isClearable
                                                                timeFormat={null}
                                                                closeOnSelect={true}
                                                                value={resource.truck_financial_attributes?.payment_date && moment(resource.truck_financial_attributes?.payment_date).format("MM/DD/YYYY")}
                                                                className="text-center"
                                                                inputProps={{
                                                                    placeholder: 'Payment date',
                                                                    disabled: true
                                                                }}
                                                                onChange={this.dateResourceNestedAttr('truck_financial_attributes', 'payment_date')}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={3}>
                                                        <FormGroup>
                                                            <ControlLabel>
                                                                <span>Paid</span>
                                                            </ControlLabel>
                                                            <InputGroup>
                                                                <InputGroupAddon>
                                                                    $
                                                                </InputGroupAddon>
                                                                <FormControl
                                                                    className='disabled-input-bg'
                                                                    disabled={true}
                                                                    type='text'
                                                                    name="truck_financial_attributes"
                                                                    value={this.isEmptyValue(resource.truck_financial_attributes?.paid)}
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={3}>
                                                        <FormGroup>
                                                            <ControlLabel>
                                                                <OverlayTrigger placement="top"
                                                                                overlay={<Tooltip id="tooltip">
                                                                                    Last
                                                                                    updated: {resource.truck_financial_attributes?.audit_activity && moment(JSON.parse(resource.truck_financial_attributes?.audit_activity)?.balance).format('llll')}
                                                                                </Tooltip>}>
                                                                    <span>Balance</span>
                                                                </OverlayTrigger>
                                                            </ControlLabel>
                                                            <InputGroup>
                                                                <InputGroupAddon>
                                                                    $
                                                                </InputGroupAddon>
                                                                <FormControl
                                                                    className='disabled-input-bg'
                                                                    disabled={true}
                                                                    type='text'
                                                                    name="truck_financial_attributes"
                                                                    value={this.isEmptyValue(resource.truck_financial_attributes?.balance)}
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={3}>
                                                        <FormGroup>
                                                            <ControlLabel>
                                                                <OverlayTrigger placement="top"
                                                                                overlay={<Tooltip id="tooltip">
                                                                                    Last
                                                                                    updated: {resource.truck_financial_attributes?.audit_activity && moment(JSON.parse(resource.truck_financial_attributes?.audit_activity)?.value).format('llll')}
                                                                                </Tooltip>}>
                                                                    <span>Value</span>
                                                                </OverlayTrigger>
                                                            </ControlLabel>
                                                            <InputGroup>
                                                                <InputGroupAddon>
                                                                    $
                                                                </InputGroupAddon>
                                                                <FormControl
                                                                    type='text'
                                                                    name="truck_financial_attributes"
                                                                    onChange={(e) => this.updateResourceNestedAttr(e, 'value', this.calculateLoanData)}
                                                                    onBlur={(e) => this.dollarFormatNestedAttr(e, 'value')}
                                                                    value={this.isEmptyValue(resource.truck_financial_attributes?.value, true)}
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={3}>
                                                        <FormGroup>
                                                            <ControlLabel>
                                                                <OverlayTrigger placement="top"
                                                                                overlay={<Tooltip id="tooltip">
                                                                                    Last
                                                                                    updated: {resource.truck_financial_attributes?.audit_activity && moment(JSON.parse(resource.truck_financial_attributes?.audit_activity)?.equity).format('llll')}
                                                                                </Tooltip>}>
                                                                    <span>Equity</span>
                                                                </OverlayTrigger>
                                                            </ControlLabel>
                                                            <InputGroup>
                                                                <InputGroupAddon>
                                                                    $
                                                                </InputGroupAddon>
                                                                <FormControl
                                                                    className='disabled-input-bg'
                                                                    disabled={true}
                                                                    type='text'
                                                                    name="truck_financial_attributes"
                                                                    value={this.isEmptyValue(resource.truck_financial_attributes?.equity)}
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12} className='truck-financial-table'>
                                                        <FormGroup>
                                                            <BootstrapTable
                                                                data={resource.truck_financial_payments.filter(p => moment(p.payment_date).isBefore(moment())).sort((a, b) => moment(b.payment_date).diff(moment(a.payment_date)))}
                                                                striped={true}
                                                                bordered={false}
                                                                hover={true}
                                                                pagination={true}
                                                                search={true}
                                                                height={!isMobile ? '300px' : ''}
                                                                options={{
                                                                    toolBar: this.createCustomToolBar,
                                                                    page: 1,  //which page you want to show as default
                                                                    sizePerPageList: [{
                                                                        text: '10', value: 10
                                                                    }, {
                                                                        text: '25', value: 25
                                                                    }, {
                                                                        text: '30', value: 30
                                                                    }, {
                                                                        text: '50', value: 50
                                                                    }],
                                                                    //you can change the dropdown list for size per page
                                                                    sizePerPage: 10,  //which size per page you want to locate as default
                                                                    paginationSize: 3,  //the pagination bar size.
                                                                    sizePerPageDropDown: this.renderSizePerPageDropDown,
                                                                }}
                                                                csvFileName={() => {
                                                                    const fileName = `truck-${resource.name}-financial-payment-history-${moment().format()}.csv`
                                                                    if (this.tableRef?.current) {
                                                                        this.props.actions.saveNewCsvDownload(this.tableRef.current, fileName, this.state.filter, "truck")
                                                                    }
                                                                    return fileName
                                                                }}
                                                            >
                                                                <TableHeaderColumn
                                                                    width={isMobile ? '200px' : '20%'}
                                                                    dataField="payment_number"
                                                                    tdStyle={{whiteSpace: 'normal'}}
                                                                    thStyle={{whiteSpace: 'normal'}}
                                                                    dataSort={!this.state.editPayment}
                                                                    columnClassName='menu-show-fixer'
                                                                >
                                                                    Payment number
                                                                </TableHeaderColumn>
                                                                <TableHeaderColumn
                                                                    width={isMobile ? '250px' : '25%'}
                                                                    dataField="payment_date"
                                                                    tdStyle={{whiteSpace: 'normal'}}
                                                                    thStyle={{whiteSpace: 'normal'}}
                                                                    dataSort={!this.state.editPayment}
                                                                    dataFormat={(cell, row) => this.state.editPayment && row.payment_number === resource.truck_financial_payment_attributes?.payment_number ?
                                                                        <Datetime
                                                                            dateFormat="MM/DD/YYYY"
                                                                            isValidDate={(e) => this.isValidEditDate(e, row)}
                                                                            isClearable
                                                                            timeFormat={null}
                                                                            closeOnSelect={true}
                                                                            value={resource.truck_financial_payment_attributes?.payment_date && moment(resource.truck_financial_payment_attributes?.payment_date).format("MM/DD/YYYY")}
                                                                            className="text-center"
                                                                            inputProps={{placeholder: 'Payment date'}}
                                                                            onChange={this.dateResourceNestedAttr('truck_financial_payment_attributes', 'payment_date')}/>
                                                                        : dateNoTimeFormatter(cell)}
                                                                    csvFormat={dateNoTimeFormatter}
                                                                    columnClassName='menu-show-fixer'
                                                                >
                                                                    Payment date
                                                                </TableHeaderColumn>
                                                                <TableHeaderColumn
                                                                    width={isMobile ? '200px' : '20%'}
                                                                    dataField="payment_amount"
                                                                    tdStyle={{whiteSpace: 'normal'}}
                                                                    thStyle={{whiteSpace: 'normal'}}
                                                                    dataSort={!this.state.editPayment}
                                                                    sortFunc={this.dollarSortFunc}
                                                                    dataFormat={(cell, row) => this.state.editPayment && row.payment_number === resource.truck_financial_payment_attributes?.payment_number ?
                                                                        <FormGroup>
                                                                            <InputGroup>
                                                                                <InputGroupAddon>
                                                                                    $
                                                                                </InputGroupAddon>
                                                                                <FormControl
                                                                                    name="truck_financial_payment_attributes"
                                                                                    type="text"
                                                                                    onChange={(e) => this.updateResourceNestedAttr(e, 'payment_amount', () => this.validatePaymentAmount(row.interest))}
                                                                                    onBlur={(e) => this.dollarFormatNestedAttr(e, 'payment_amount')}
                                                                                    value={this.isEmptyValue(resource.truck_financial_payment_attributes?.payment_amount)}
                                                                                />
                                                                            </InputGroup>
                                                                        </FormGroup> : dollarsFormatter(cell ? cell.toString().replace(/[^0-9.-]/g, '') : cell)
                                                                    }
                                                                >
                                                                    Payment amount
                                                                </TableHeaderColumn>
                                                                <TableHeaderColumn
                                                                    width={isMobile ? '200px' : '20%'}
                                                                    dataField="principal"
                                                                    tdStyle={{whiteSpace: 'normal'}}
                                                                    thStyle={{whiteSpace: 'normal'}}
                                                                    dataSort={!this.state.editPayment}
                                                                    sortFunc={this.dollarSortFunc}
                                                                    dataFormat={(cell) => dollarsFormatter(cell ? cell.toString().replace(/[^0-9.-]/g, '') : cell)}
                                                                    hidden={false}
                                                                >
                                                                    Principal
                                                                </TableHeaderColumn>
                                                                <TableHeaderColumn
                                                                    width={isMobile ? '200px' : '20%'}
                                                                    dataField="interest"
                                                                    tdStyle={{whiteSpace: 'normal'}}
                                                                    thStyle={{whiteSpace: 'normal'}}
                                                                    dataSort={!this.state.editPayment}
                                                                    sortFunc={this.dollarSortFunc}
                                                                    dataFormat={(cell) => dollarsFormatter(cell ? cell.toString().replace(/[^0-9.-]/g, '') : cell)}
                                                                    hidden={false}
                                                                >
                                                                    Interest
                                                                </TableHeaderColumn>
                                                                <TableHeaderColumn
                                                                    width={isMobile ? '200px' : '20%'}
                                                                    dataField="id"
                                                                    tdStyle={{whiteSpace: 'normal'}}
                                                                    thStyle={{whiteSpace: 'normal'}}
                                                                    columnClassName='menu-show-fixer'
                                                                    export={false}
                                                                    dataFormat={(cell, row) => {
                                                                        return (this.state.editPayment && resource.truck_financial_payment_attributes?.payment_number === row.payment_number) ?
                                                                            <><span
                                                                                className={`table-actions ${this.state.paymentValidError ? 'disabled' : ''}`}
                                                                                onClick={() => {
                                                                                    this.updatePrincipal(row)
                                                                                    this.setState({
                                                                                        editPayment: false,
                                                                                        resource: {
                                                                                            ...resource,
                                                                                            truck_financial_payments: resource.truck_financial_payments.map(fp => {
                                                                                                if (fp.payment_number === resource.truck_financial_payment_attributes?.payment_number) {
                                                                                                    return resource.truck_financial_payment_attributes
                                                                                                } else {
                                                                                                    return fp
                                                                                                }
                                                                                            }),
                                                                                            truck_financial_payment_attributes: {}
                                                                                        }
                                                                                    }, this.calculateLoanData)
                                                                                }}>Confirm</span>{' '}
                                                                                <span className='table-actions'
                                                                                      onClick={() => {
                                                                                          this.setState({
                                                                                              editPayment: false,
                                                                                              resource: {
                                                                                                  ...resource,
                                                                                                  truck_financial_payments: resource.truck_financial_payments.map(fp => {
                                                                                                      if (fp.payment_number === row.payment_number) {
                                                                                                          return this.state.beforeEdit
                                                                                                      } else {
                                                                                                          return fp
                                                                                                      }
                                                                                                  }),
                                                                                                  truck_financial_payment_attributes: {}
                                                                                              }
                                                                                          })
                                                                                      }}>Cancel</span></> :
                                                                            !this.state.editPayment ? <>
                                                                                    <span className='table-actions'
                                                                                          onClick={() => {
                                                                                              this.setState({
                                                                                                  editPayment: true,
                                                                                                  resource: {
                                                                                                      ...resource,
                                                                                                      truck_financial_payment_attributes: resource.truck_financial_payments.find(p => p.payment_number === row.payment_number)
                                                                                                  },
                                                                                                  beforeEdit: {...row}
                                                                                              })
                                                                                          }}>Edit</span> {' '}
                                                                                <span className='table-actions'
                                                                                      onClick={() => this.deletePaymentRow(row)}>Delete</span>
                                                                            </> : null
                                                                    }}
                                                                >
                                                                    Actions
                                                                </TableHeaderColumn>
                                                                <TableHeaderColumn
                                                                    hidden
                                                                    dataField="id"
                                                                    isKey={true}
                                                                >
                                                                </TableHeaderColumn>

                                                            </BootstrapTable>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            {resource.truck_financial_attributes?.id &&
                                                <Col lg={4} md={12} xs={12} className='margin-top-bottom-20'>
                                                    <Row>
                                                        <div className="dropzone_with_thumbnails">
                                                            <Dropzone
                                                                onDrop={files => this.props.actions.uploadTruckFinancialAttachment(resource.truck_financial_attributes.id, files, () => {
                                                                    this.reload()
                                                                })} className="dropzone pointer border_none">
                                                                <p>Drop a file here or click to select a file to
                                                                    upload.</p>
                                                            </Dropzone>
                                                            <ReorderingDragAndDrop
                                                                files={resource.truck_financial_files.sort((a, b) => a.order - b.order)}
                                                                deleteAttachment={(tf) => this.props.actions.deleteTruckFinancialAttachment(tf, () => {
                                                                    this.reload()
                                                                })}
                                                                reorderAttachments={(orderedFiles) => {
                                                                    const order = JSON.stringify(orderedFiles.map((image) => image.id))
                                                                    this.props.actions.reorderFinancialAttachment(order, resource.truck_financial_attributes.id,
                                                                        () => {
                                                                            this.setState({
                                                                                resource: {
                                                                                    ...resource,
                                                                                    truck_financial_files: orderedFiles
                                                                                }
                                                                            })
                                                                        })
                                                                }}
                                                                downloadButton={true}
                                                                numberOfColumns={boxesPerRowReorderingDND}
                                                            />
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12}>
                                                            <TruckFinancialTimeline
                                                                truckFinancialId={resource.truck_financial_attributes?.id}
                                                                updateFinancialNotes={this.updateFinancialNotes}/>
                                                        </Col>
                                                    </Row>
                                                </Col>}
                                        </Row>
                                    </div>}
                                </Tab>}
                                <Tab eventKey={'history'} title='History'/>
                                <div className={isMobile ? '' : 'custom-footer'}>
                                    <div>
                                        <ColorCheckbox value={resource.disabled}
                                                       label="Archive"
                                                       onChange={this.selectCheckboxAttr('disabled')}/>
                                    </div>
                                    <div>
                                        Last
                                        updated {resource.modified_at_date ? moment(resource.modified_at_date).format('llll') : <i>Unknown</i>} by {resource.modified_by_name}
                                    </div>
                                </div>
                            </Tabs>
                        }

                    </MasterDetail>
                    {this.state.isModalOpen && <TruckModal inputType={this.state.inputType}
                                                           isOpen={this.state.isModalOpen}
                                                           closeModal={this.closeModal}
                                                           data={this.state.modalData}
                                                           updateOption={this.updateOption}
                                                           updateOptionsOrder={this.updateOptionsOrder}
                                                           addOption={this.addOption}
                                                           removeOption={this.removeOption}
                    />}
                    {this.state.truckFilterModalShow &&
                        <TrucksFilterModal isOpen={this.state.truckFilterModalShow}
                                           closeModal={this.closeTruckFilterModal}
                                           modalBuilder={trucksDataFilterModal.services}
                                           data={this.state.resources_all}
                                           filterData={this.handleFilterData}
                                           initialFilter={this.state.filter}

                        />
                    }
                    {this.state.showPaymentModal && <Modal
                        bsSize="small"
                        animation={false}
                        centered={true}
                        show={this.state.showPaymentModal}
                        onHide={this.closePaymentModal}
                        backdrop="static"
                        dialogClassName="trucks-payment-modal"
                    >
                        <Modal.Header>
                            <div className='modal_header'>
                                <p>Add Payment</p>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <FormGroup>
                                <ControlLabel>
                                    <span>Payment Date</span>
                                </ControlLabel>
                                <Datetime
                                    dateFormat="MM/DD/YYYY"
                                    isValidDate={this.isValidDate}
                                    isClearable
                                    timeFormat={null}
                                    closeOnSelect={true}
                                    value={resource.truck_financial_payment_attributes?.payment_date && moment(resource.truck_financial_payment_attributes?.payment_date).format("MM/DD/YYYY")}
                                    className="text-center"
                                    inputProps={{placeholder: 'Payment date'}}
                                    onChange={this.dateResourceNestedAttr('truck_financial_payment_attributes', 'payment_date')}/>
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>
                                    <span>Payment Amount</span>
                                </ControlLabel>
                                <InputGroup>
                                    <InputGroupAddon>
                                        $
                                    </InputGroupAddon>
                                    <FormControl
                                        name="truck_financial_payment_attributes"
                                        type="text"
                                        step={0.01}
                                        disabled={!resource.truck_financial_payment_attributes?.payment_date}
                                        onChange={(e) => this.updateResourceNestedAttr(e, 'payment_amount', () => this.validatePaymentAmount(null, resource.truck_financial_payment_attributes?.payment_date))}
                                        onBlur={(e) => this.dollarFormatNestedAttr(e, 'payment_amount')}
                                        value={this.isEmptyValue(resource.truck_financial_payment_attributes?.payment_amount)}
                                    />
                                </InputGroup>
                                {this.state.paymentValidError &&
                                    <p className={'text-danger'}>{this.state.paymentValidError}</p>}
                            </FormGroup>
                        </Modal.Body>
                        <Modal.Footer>
                            <Row>
                                <Col className="text-left" xs={6}>
                                    <Button
                                        bsSize="small"
                                        bsStyle="warning"
                                        onClick={() => this.closePaymentModal()}
                                    >
                                        Cancel
                                    </Button>
                                </Col>

                                <Col className="text-right" xs={6}>
                                    <Button
                                        bsSize="small"
                                        bsStyle="success"
                                        disabled={this.state.paymentValidError || !resource.truck_financial_payment_attributes?.payment_date || !resource.truck_financial_payment_attributes?.payment_amount}
                                        onClick={() => this.validatePaymentAmount(null, resource.truck_financial_payment_attributes?.payment_date, () => this.addPayment())}
                                    >
                                        Confirm
                                    </Button>
                                </Col>
                            </Row>
                        </Modal.Footer>
                    </Modal>}
                    {this.state.showHistory && <TruckHistoryModal onHide={() => this.setState({showHistory: false})}
                                                                  history={resource.history}/>}
                </Grid> : null);
    }

}

const mapStateToProps = (state) => ({
    roles: state.auth.roles
});
const mapDispatchToProps = dispatch => ({
        actions: bindActionCreators(api, dispatch),
    }

);

export default connect(mapStateToProps, mapDispatchToProps)(Trucks)
